import * as Yup from 'yup'

import { t } from '../../i18n'
import { EExecutionMode } from '../../models/ExecutionMode'
import SaleSettings from '../../models/SaleSettings'

/******* ATTENTION ce schéma est utilisé par défaut, il y a aussi des schéma pour le trader et le salesman (modifier les 3 si besoin donc) */

export default (
  remainingToSell: number,
  saleSettings: SaleSettings,
  remainingVolume?: number
) =>
  Yup.object().shape({
    tonnage: Yup.number()
      .required()
      .min(0, 'Le tonnage doit être supérieur à 0.')
      .test(
        'is-below-maximal-volume',
        t(
          `Le tonnage entré ne doit pas dépasser ${saleSettings.maximalVolume} t.`
        ),
        x =>
          !saleSettings ||
          saleSettings.maximalVolume === 0 ||
          x <= saleSettings.maximalVolume
      )
      .test(
        'has-maximum-of-3-decimals',
        t(`Le tonnage entré ne peut pas avoir plus de trois décimales.`),
        x =>
          x.toString().split('.').length === 1 ||
          x.toString().split('.')[1].length <= 3
      )
      .test(
        'is-below-offer-remaining-volume',
        t(
          `Le tonnage entré ne peut pas dépasser le tonnage restant de l'offre (${remainingVolume} t).`
        ),
        x => !remainingVolume || x <= remainingVolume
      )
      .when(
        'executionMode.type',
        (executionModeType: EExecutionMode, schema: Yup.NumberSchema) => {
          switch (executionModeType) {
            case 'DEPOSIT_DELIVERY':
              return schema.test(
                'is-below-remaining-to-sell',
                t(
                  `Le tonnage ne peut pas être supérieur au stock du produit chez le négociant (${remainingToSell} t).`
                ),
                x => !remainingToSell || x <= remainingToSell
              )
          }
          return schema
        }
      )
      .when('modePMG', (modePMG: boolean, schema: Yup.NumberSchema) => {
        if (!modePMG) {
          return schema
        }
        return schema.test(
          'pmg-offer-multiple-of-50-tons',
          t(
            "Le tonnage d'une vente avec option PMG doit être un multiple de 50 tonnes."
          ),
          x => x % 50 === 0
        )
      }),
    executionMode: Yup.object().required(),
    executionMonth: Yup.string()
      .nullable(true)
      .required(),
    payment: saleSettings.hasDeferredPayment
      ? Yup.date().required()
      : Yup.date(),
    modePMG: Yup.boolean().required(),
    strike: Yup.string().when(
      'modePMG',
      (modePMG: boolean, schema: Yup.StringSchema) => {
        if (!modePMG) {
          return schema
        }
        return schema.required()
      }
    ),
  })
