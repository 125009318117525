import * as React from 'react'

import {
  Editor as DraftEditor,
  DraftEditorCommand,
  EditorState,
} from 'draft-js'
import {
  IconButton,
  Paper,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core'

import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined'
import ExtendedRichUtils, {
  blockStyleFn,
  stateFromHTMLWithAlignment,
  stateToHTMLWithAlignment,
} from '../../../../config/draftjs'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    left: {
      display: 'flex',
      flexDirection: 'column',
      flex: 2,
      marginRight: theme.spacing(1),
    },
    right: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      marginLeft: theme.spacing(1) - theme.spacing(1 / 2),
    },
    actionsContainer: {
      marginBottom: theme.spacing(1),
    },
    editorContainer: {
      flex: 1,
      padding: theme.spacing(2),
      ...theme.typography.body1,
      alignSelf: 'stretch',
      overflowY: 'auto',
      '& .DraftEditor-root, & .DraftEditor-editorContainer, & .public-DraftEditor-content': {
        height: '100%',
      },
    },
  })

const inlines = [
  { style: 'BOLD', icon: FormatBoldIcon },
  { style: 'ITALIC', icon: FormatItalicIcon },
  { style: 'UNDERLINE', icon: FormatUnderlinedIcon },
]

interface Props extends WithStyles<typeof styles> {
  defaultValue: string
  onChange: (template: string) => void
}

interface State {
  editorState: EditorState
}

class EditorAdvice extends React.Component<Props, State> {
  private readonly editor: React.RefObject<DraftEditor> = React.createRef()

  constructor(props: Props) {
    super(props)
    this.state = {
      editorState: props.defaultValue
        ? EditorState.createWithContent(
            stateFromHTMLWithAlignment(props.defaultValue)
          )
        : EditorState.createEmpty(),
    }
  }

  onChange = (editorState: EditorState) => {
    this.setState({ editorState })
    this.props.onChange(
      stateToHTMLWithAlignment(editorState.getCurrentContent())
    )
  }

  handleKeyCommand = (
    command: DraftEditorCommand,
    editorState: EditorState
  ) => {
    const newState = ExtendedRichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  handleInlineCommand = (style: string) => (event: React.MouseEvent) => {
    event.preventDefault()
    this.onChange(
      ExtendedRichUtils.toggleInlineStyle(this.state.editorState, style)
    )
  }

  render() {
    const { classes } = this.props
    const { editorState } = this.state
    const currentInlineStyles = editorState.getCurrentInlineStyle()

    return (
      <div className={classes.root}>
        <div className={classes.left}>
          <div className={classes.actionsContainer}>
            {inlines.map(({ style, icon: InlineIcon }, index) => (
              <IconButton
                key={index}
                color={currentInlineStyles.has(style) ? 'primary' : undefined}
                onMouseDown={this.handleInlineCommand(style)}
              >
                <InlineIcon />
              </IconButton>
            ))}
          </div>
          <Paper className={classes.editorContainer}>
            <DraftEditor
              ref={this.editor}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              onChange={this.onChange}
              blockStyleFn={blockStyleFn}
            />
          </Paper>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(EditorAdvice)
