import amber from '@material-ui/core/colors/amber'
import blue from '@material-ui/core/colors/blue'
import brown from '@material-ui/core/colors/brown'
import cyan from '@material-ui/core/colors/cyan'
import deepOrange from '@material-ui/core/colors/deepOrange'
import deepPurple from '@material-ui/core/colors/deepPurple'
import green from '@material-ui/core/colors/green'
import indigo from '@material-ui/core/colors/indigo'
import lightBlue from '@material-ui/core/colors/lightBlue'
import lightGreen from '@material-ui/core/colors/lightGreen'
import lime from '@material-ui/core/colors/lime'
import orange from '@material-ui/core/colors/orange'
import pink from '@material-ui/core/colors/pink'
import purple from '@material-ui/core/colors/purple'
import red from '@material-ui/core/colors/red'
import teal from '@material-ui/core/colors/teal'
import yellow from '@material-ui/core/colors/yellow'

const colors: string[] = [
  amber[500],
  amber[900],
  blue[500],
  blue[900],
  brown[500],
  brown[900],
  cyan[500],
  cyan[900],
  deepOrange[500],
  deepOrange[900],
  deepPurple[500],
  deepPurple[900],
  green[500],
  green[900],
  indigo[500],
  indigo[900],
  lightBlue[500],
  lightBlue[900],
  lightGreen[500],
  lightGreen[900],
  lime[500],
  lime[900],
  orange[500],
  orange[900],
  pink[500],
  pink[900],
  purple[500],
  purple[900],
  red[500],
  red[900],
  teal[500],
  teal[900],
  yellow[500],
  yellow[900],
]

export default (): string => {
  return colors[Math.floor(Math.random() * Math.floor(colors.length))]
}
