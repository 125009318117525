import axios, { AxiosError, AxiosRequestConfig } from 'axios'

import * as authService from '../services/authService'

import './overrideDatePrototype'

type TokenRefreshedCallback = (errRefreshing?: Error, token?: string) => void

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:8080',
  timeout: parseInt(process.env.REACT_APP_API_TIMEOUT || '900000'),
  headers: {
    Accept: 'application/json',
  },
})

instance.interceptors.request.use((reqConfig: AxiosRequestConfig) => {
  if (
    reqConfig &&
    reqConfig.url &&
    !reqConfig.url.includes('/oauth/token') &&
    !reqConfig.url.includes('/authenticate/token')
  ) {
    reqConfig.headers.Authorization = `${localStorage.getItem(
      'token_type'
    )} ${localStorage.getItem('access_token')}`
  }
  return reqConfig
}, Promise.reject)

let isFetchingToken = false
let tokenSubscribers: TokenRefreshedCallback[] = []

const subscribeTokenRefresh = (cb: TokenRefreshedCallback) => {
  tokenSubscribers.push(cb)
}
const onTokenRefreshed: TokenRefreshedCallback = (
  errRefreshing,
  newTokenHeader
) => {
  tokenSubscribers.forEach(cb => cb(errRefreshing, newTokenHeader))
}
const forceLogout = () => {
  isFetchingToken = false
  localStorage.clear()
  window.location.href = '/sign-in'
}

instance.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (!error || !error.response) {
      return Promise.reject(error)
    }
    if (
      error.response.config.url &&
      error.response.config.url.includes('/oauth/token')
    ) {
      if (
        error.response.data.error_description.contains('Invalid refresh token')
      ) {
        forceLogout()
      }
      return Promise.reject(error)
    }

    if (error.response.status !== 401) {
      return Promise.reject(error)
    }

    if (!isFetchingToken) {
      isFetchingToken = true

      authService
        .refreshAccessToken()
        .then(response => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          const { token_type, access_token } = response.data
          // eslint-disable-next-line @typescript-eslint/camelcase
          const newAccessToken = `${access_token}`

          isFetchingToken = false
          onTokenRefreshed(undefined, newAccessToken)
          tokenSubscribers = []
          localStorage.setItem('token_type', token_type)
          localStorage.setItem('access_token', access_token)
          return response
        })
        .catch(() => {
          onTokenRefreshed(
            new Error('Unable to refresh access token'),
            undefined
          )
          tokenSubscribers = []

          forceLogout()
        })
    }

    return new Promise((resolve, reject) => {
      subscribeTokenRefresh((errRefreshing, newTokenHeader) => {
        if (errRefreshing) {
          return reject(errRefreshing)
        }
        error.config.headers.Authorization = newTokenHeader
        return resolve(axios(error.config))
      })
    })
  }
)

export default instance
