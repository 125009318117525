import React, { useState, useEffect, useContext } from 'react'
import memoizeOne from 'memoize-one'
import { MUIDataTableColumnDef } from 'mui-datatables'
import orange from '@material-ui/core/colors/orange'
import green from '@material-ui/core/colors/green'
import PreviewIcon from '@material-ui/icons/VisibilityOutlined'
import RefreshIcon from 'mdi-material-ui/Sync'
import DownloadIcon from 'mdi-material-ui/Download'
import {
  IconButton,
  Tooltip,
  makeStyles,
  Theme,
  Omit,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core'
import AutoRenewIcon from '@material-ui/icons/Autorenew'
import LockIcon from '@material-ui/icons/Lock'
import Flex from './Flex'

import { t } from '../i18n'
import DataTable from './DataTable'
import contractPMGService, {
  ContractPmgListItemView,
} from '../services/contractPMGService'
import { ContractPMGStatus } from '../models/ContractPMG'
import transactionService from '../services/transactionService'
import ErrorContext from '../contexts/ErrorContext'

const useStyles = makeStyles((theme: Theme) => ({
  iconBtn: {
    padding: 4,
  },
  warningCanceledBtn: {
    color: orange[600],
  },
  warningCreatedBtn: {
    color: green[600],
  },
  icon: {
    fontSize: '2rem',
    [theme.breakpoints.only('xs')]: {
      fontSize: '3.5rem',
    },
  },
}))

interface Props {
  contractsPmg: ContractPmgListItemView[]
  columns: MUIDataTableColumnDef[]
  tableDataMapper: (
    contractPMG: ContractPmgListItemView,
    index: number
  ) => Array<string | object | number | ContractPmgListItemView>
  refresh: () => void
  handlePreview: (value: ContractPmgListItemView) => void
  status: Omit<ContractPMGStatus, 'FIXED'>
  autoPriceUpdate?: boolean
}

export const SalesPmgList = ({
  contractsPmg,
  refresh,
  columns,
  tableDataMapper,
  status,
  autoPriceUpdate,
  handlePreview,
}: Props) => {
  const classes = useStyles()
  const errorContext = useContext(ErrorContext)
  const [data, setData] = useState<ContractPmgListItemView[]>(contractsPmg)
  const [contractPmgToFixed, setContractPmgToFixed] = useState<
    Optional<ContractPmgListItemView>
  >()
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleShowModal = (value: ContractPmgListItemView) => () => {
    setShowModal(true)
    setContractPmgToFixed(value)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const fetchSupplement = (
    contractPMG: ContractPmgListItemView
  ) => async () => {
    const { data: supplement } = await contractPMGService
      .computeSupplement(contractPMG.contractPMGId)
      .catch(() => {
        errorContext.displayError(t('Erreur lors du calcul des compléments.'))
        return { data: undefined }
      })

    setData(contracts => {
      const index = contracts.findIndex(
        c => c.contractPMGId === contractPMG.contractPMGId
      )
      const cts = [...contracts]
      cts[index] = {
        ...cts[index],
        supplement,
      }
      return cts
    })
  }

  const fetchAllSupplements = async (views: ContractPmgListItemView[]) => {
    for (let idxData = 0; idxData < views.length; idxData++) {
      await fetchSupplement(views[idxData])()
    }
  }

  useEffect(() => {
    setData(contractsPmg)
    if (status === 'CREATED' && autoPriceUpdate) {
      fetchAllSupplements(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractsPmg, status, autoPriceUpdate])

  const handlePreviewPMG = (value: ContractPmgListItemView) => () => {
    handlePreview(value)
  }

  const dataTableOptions = {
    customToolbar: () => {
      return (
        <>
          {refresh && (
            <Tooltip title={t('Rafraîchir les données')}>
              <IconButton
                color="default"
                className={classes.iconBtn}
                onClick={refresh}
              >
                <RefreshIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </>
      )
    },
  }

  const handleDownloadContract = (
    value: ContractPmgListItemView
  ) => async () => {
    try {
      await transactionService.downloadContract(value.transactionId)
    } catch (e) {
      errorContext.displayError(t('Erreur lors du téléchargement du contrat.'))
    }
  }

  const handleDownloadSupplement = (
    value: ContractPmgListItemView
  ) => async () => {
    try {
      await transactionService.downloadContractSupplement(value.transactionId)
    } catch (e) {
      errorContext.displayError(
        t('Erreur lors du téléchargement du complément.')
      )
    }
  }

  const handleSecureContract = async () => {
    try {
      await contractPMGService.secure(contractPmgToFixed!.contractPMGId)
      refresh()
      setShowModal(false)
    } catch (e) {
      if (e.response) {
        switch (e.response.status) {
          case 400:
            errorContext.displayError(
              t(
                'Impossible de fixer le contrat car le complément est insuffisant, veuillez le ré-actualiser.'
              )
            )
            break
          case 423:
            errorContext.displayError(
              t(
                'Impossible de fixer le contrat car nous sommes en dehors des heures de marché.'
              )
            )
            break
          default:
            errorContext.displayError(
              t('Erreur lors de la fixation du contrat.')
            )
            break
        }
      } else {
        errorContext.displayError(t('Impossible de fixer le contrat.'))
      }
    }
  }

  const getTableColumns: () => MUIDataTableColumnDef[] = memoizeOne(() => {
    // Adds a custom complement column
    if (status === 'CREATED') {
      let supplementBodyRenderer = (value: ContractPmgListItemView) => (
        <>
          <Typography>
            {value.supplement != null ? value.supplement + '€/t' : '-- €/t'}
          </Typography>
          <Flex direction="row" justify="center">
            {!autoPriceUpdate && (
              <Tooltip
                title={t(
                  'Calculer ou actualiser le complément pour ce contrat'
                )}
              >
                <IconButton onClick={fetchSupplement(value)}>
                  <AutoRenewIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={t('Fixer le contrat')}>
              <IconButton
                color="primary"
                disabled={
                  value.supplement && value.supplement > 0 ? false : true
                }
                onClick={handleShowModal(value)}
              >
                <LockIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </Flex>
        </>
      )
      columns = columns.concat({
        name: 'Complément',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: supplementBodyRenderer,
        },
      })
    }
    // Adds contracts download column
    return columns.concat({
      name: '',
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value: ContractPmgListItemView) => (
          <Flex direction="row">
            <Tooltip title={t('Télécharger le contrat')}>
              <IconButton
                color="primary"
                className={classes.iconBtn}
                onClick={handleDownloadContract(value)}
              >
                <DownloadIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            {/*
            // @ts-ignore */}
            {value.supplement > 0 && value.status !== 'CREATED' && (
              <Tooltip title={t('Télécharger le complément')}>
                <IconButton
                  color="primary"
                  className={classes.iconBtn}
                  onClick={handleDownloadSupplement(value)}
                >
                  <DownloadIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={t('Voir les détails de la vente')}>
              <IconButton
                color="primary"
                className={classes.iconBtn}
                onClick={handlePreviewPMG(value)}
              >
                <PreviewIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </Flex>
        ),
      },
    })
  })

  const contractsPmgToTableData = memoizeOne(
    (data: ContractPmgListItemView[]) =>
      data.map((contractPMG, index) =>
        tableDataMapper(contractPMG, index).concat(contractPMG, contractPMG)
      )
  )

  return (
    <>
      <DataTable
        columns={getTableColumns()}
        title={
          status === 'CREATED'
            ? t('Vos ventes PMG en cours')
            : t('Vos ventes PMG fixées ou cloturées')
        }
        data={contractsPmgToTableData(data)}
        emptyContent={t('Aucun ordre de vente')}
        options={dataTableOptions}
      />
      {showModal && (
        <Dialog onClose={handleCloseModal} open={true} maxWidth="lg">
          <DialogContent>
            <DialogContentText>
              {t('Êtes-vous sûr de vouloir fixer ce contrat ?')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color={'primary'} onClick={handleCloseModal}>
              {t('Annuler')}
            </Button>
            <Button color={'primary'} onClick={handleSecureContract}>
              {t('Confirmer')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default SalesPmgList
