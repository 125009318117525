import { FormControl, FormLabel } from '@material-ui/core'
import isValid from 'date-fns/isValid'
import { FieldProps, getIn } from 'formik'
import { InlineDateTimePicker } from 'material-ui-pickers'
import { DateTimePickerInlineProps } from 'material-ui-pickers/DateTimePicker/DateTimePickerInline'
import * as React from 'react'

import { t } from '../../i18n'
import { format } from '../../i18n/format'

export interface DateTimePickerProps
  extends FieldProps,
    DateTimePickerInlineProps {}

export const DateTimePickerMinDate = new Date()

export const fieldToInlineDateTimePicker = ({
  field,
  form,
  ...props
}: DateTimePickerProps): DateTimePickerInlineProps => {
  const { name } = field
  const { touched, errors, setFieldValue, setFieldTouched } = form

  const fieldError = getIn(errors, name)
  const showError = getIn(touched, name) && !!fieldError

  // override formik onChange instead of setting type to date which add native arrow to date inputs
  const onChange = (value: Date) => {
    setFieldTouched(name, true)
    setFieldValue(name, value)
  }
  const onError = (value: Date) => {
    setFieldTouched(name, true)
    setFieldValue(name, value)
  }

  let helperText = null
  if (fieldError) {
    if (fieldError === 'Requis') {
      helperText = t('Requis')
    } else if (fieldError.includes('field must be later than')) {
      helperText = t(
        `La date doit être postérieure au ${format(
          DateTimePickerMinDate,
          'dd/MM/yyyy HH:mm'
        )}`
      )
    } else {
      helperText = t('Date invalide (jj/mm/aaaa hh:mm)')
    }
  }

  return {
    ...field,
    onChange,
    onError,
    minDate: DateTimePickerMinDate,
    error: showError,
    helperText,
    ...props,
    disableOpenOnEnter: true,
    keyboard: true,
    showTabs: false,
    autoOk: true,
    ampm: false,
    format: 'dd/MM/yyyy HH:mm',
    mask: [
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      ':',
      /\d/,
      /\d/,
    ],
    value: isValid(field.value) ? field.value : null,
  }
}

export const DateTimePicker: React.ComponentType<DateTimePickerProps> = ({
  label,
  ...others
}: DateTimePickerProps) => (
  <FormControl>
    {label && <FormLabel htmlFor={others.field.name}>{label}</FormLabel>}
    <InlineDateTimePicker
      id={others.field.name}
      name={others.field.name}
      margin="dense"
      variant="filled"
      {...fieldToInlineDateTimePicker(others)}
    />
  </FormControl>
)

DateTimePicker.displayName = 'FormikMaterialUIDateTimePicker'
