import React, { useState, useEffect, useContext } from 'react'
import { Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Button, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import { AxiosPromise } from 'axios'
import UploadIcon from 'mdi-material-ui/Upload'

import { t } from '../i18n'

import Flex from './Flex'
import { usePrevious } from '../hooks/usePrevious'
import UserContext from '../contexts/UserContext'

const LOGO_SIZE_LIMIT = 100 * 1024

const useStyles = makeStyles((theme: Theme) => ({
  fileInput: {
    opacity: 0,
    height: 0,
    width: 0,
  },
  errorText: {
    overflow: 'auto',
    color: theme.palette.error.light,
    whiteSpace: 'pre-line',
    // :hankey: Safari bug
    '-webkit-transform': 'translate3d(0, 0, 0)',
  },
}))

type Props = {
  size?: ButtonProps['size']
  action?: (image: File) => AxiosPromise<string>
}

export const UploadLogo = ({ size, action }: Props) => {
  const classes = useStyles()
  const [stateUpload, setStateUpload] = useState<string>('DEFAULT')
  const [error, setError] = useState<Optional<string>>()
  const userContext = useContext(UserContext)
  const prevStateUpload = usePrevious(stateUpload)

  useEffect(() => {
    return () => {
      if (!prevStateUpload) {
        return
      }
      if (prevStateUpload === 'DEFAULT' && stateUpload === 'ERROR') {
        setError(undefined)
      }
    }
  }, [stateUpload, prevStateUpload])

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateUpload('UPLOADING')
    const files = e.target.files
    const types = ['image/png', 'image/jpeg', 'image/gif']

    if (action && files && files.length === 1) {
      const file = files[0]

      if (types.every(type => file.type !== type)) {
        setStateUpload('ERROR')
        setError(t("Le format du fichier n'est pas supporté."))
      } else if (file.size > LOGO_SIZE_LIMIT) {
        setStateUpload('ERROR')
        setError(
          t(`Le poids du fichier est trop grand (${LOGO_SIZE_LIMIT / 1024}Ko).`)
        )
      } else if (error === undefined) {
        try {
          await action(file)
          setStateUpload('SUCCESS')
          userContext.refreshLogo!()
        } catch (e) {
          setError(
            (e.response && e.response.data && e.response.data.message) ||
              'Erreur technique, veuillez contacter un administrateur.'
          )
          setStateUpload('ERROR')
        }
      }
    }
  }

  const handleModalClose = () => setStateUpload('DEFAULT')

  const renderModalContent = () => {
    switch (stateUpload) {
      case 'UPLOADING':
        return (
          <>
            <DialogTitle>{t('Import en cours … 🤓')}</DialogTitle>
            <DialogContent>
              <Flex fullWidth justify={'center'} alignItems={'center'}>
                <CircularProgress />
              </Flex>
            </DialogContent>
          </>
        )
      case 'SUCCESS':
        return (
          <>
            <DialogTitle>{t('Import fini ! 🙂')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("L'image a été correctement importée.")}
              </DialogContentText>
              <DialogActions>
                <Button onClick={handleModalClose} color="primary">
                  {t('Fermer')}
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        )
      case 'ERROR':
        return (
          <>
            <DialogTitle>
              {t('🙁 Oups, un problème est survenu durant l’upload … ')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.errorText}>
                {error}
              </DialogContentText>
              <DialogActions>
                <Button onClick={handleModalClose} color="primary">
                  {t('Fermer')}
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        )
    }
    return <div />
  }

  return (
    <>
      <input
        id="upload-button"
        accept="image/*"
        type="file"
        className={classes.fileInput}
        value=""
        onChange={handleFileUpload}
      />
      <Button
        component="label"
        htmlFor="upload-button"
        size={size}
        color={'primary'}
      >
        <UploadIcon /> {t('Importer votre logo')}
      </Button>
      <Typography variant="caption">
        ({t('Largeur et hauteur max. 250px')}, {t('poids max.')}{' '}
        {`${LOGO_SIZE_LIMIT / 1024}Ko`})
      </Typography>
      <Dialog
        fullWidth
        open={stateUpload !== 'DEFAULT'}
        onClose={handleModalClose}
      >
        {renderModalContent()}
      </Dialog>
    </>
  )
}

export default UploadLogo
