import React, { useContext } from 'react'
import { IconButton, makeStyles, Theme } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import PreviewIcon from '@material-ui/icons/VisibilityOutlined'
import DownloadIcon from 'mdi-material-ui/Download'
import RefreshIcon from 'mdi-material-ui/Sync'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import memoizeOne from 'memoize-one'
import { MUIDataTableColumnDef } from 'mui-datatables'

import ErrorContext from '../../contexts/ErrorContext'
import { t } from '../../i18n'
import transactionService, {
  TransactionListItemView,
} from '../../services/transactionService'
import DataTable, { SortOrder } from '../DataTable'
import Flex from '../Flex'
import UserContext from '../../contexts/UserContext'

const useStyles = makeStyles((theme: Theme) => ({
  iconBtn: {
    padding: 4,
  },
  icon: {
    fontSize: '2rem',
    [theme.breakpoints.only('xs')]: {
      fontSize: '3.5rem',
    },
  },
}))

interface Props {
  transactions?: TransactionListItemView[]
  columns: MUIDataTableColumnDef[]
  tableDataMapper: (
    transaction: TransactionListItemView
  ) => Array<string | object | number | TransactionListItemView>
  handlePreview: (value: TransactionListItemView) => void
  refresh?: () => void
  title: string
  apiCall?: (...params: any[]) => any[] | any
  toBackendColumnsNames?: { [columnKey: string]: string }
  csvDataMap?: (separator: string) => (data: TransactionListItemView) => string
  defaultSort?: SortOrder
  handleOnAbort?: (value: TransactionListItemView) => void
}

export const SalesList = ({
  transactions,
  apiCall,
  columns,
  tableDataMapper,
  handlePreview,
  refresh,
  title,
  toBackendColumnsNames,
  csvDataMap,
  defaultSort,
  handleOnAbort,
}: Props) => {
  const errorContext = useContext(ErrorContext)
  const userContext = useContext(UserContext)

  const classes = useStyles()

  const dataTableOptions = {
    download: true,
    downloadOptions: {
      filename: 'Mes ventes.csv',
      separator: ';',
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    serverSide: transactions && transactions.length ? false : true,
    customToolbar: () => {
      if (refresh) {
        return (
          <Tooltip title={t('Rafraîchir les données')}>
            <IconButton
              color="default"
              className={classes.iconBtn}
              onClick={refresh}
            >
              <RefreshIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )
      }
      return
    },
  }

  const handleDownload = (value: TransactionListItemView) => async () => {
    try {
      await transactionService.downloadContract(value.transactionId)
    } catch (e) {
      errorContext.displayError(t('Erreur lors du téléchargement du contrat.'))
    }
  }

  const handlePreviewTransaction = (value: TransactionListItemView) => () => {
    handlePreview(value)
  }

  const handleAbortContract = (value: TransactionListItemView) => () => {
    handleOnAbort && handleOnAbort(value)
  }

  const getTableColumns: () => MUIDataTableColumnDef[] = memoizeOne(() => {
    return columns.concat({
      name: '',
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value: TransactionListItemView) => (
          <Flex direction="row">
            <Tooltip title={t('Télécharger le contrat')}>
              <IconButton
                color="primary"
                className={classes.iconBtn}
                onClick={handleDownload(value)}
              >
                <DownloadIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('Voir les détails de la vente')}>
              <IconButton
                color="primary"
                className={classes.iconBtn}
                onClick={handlePreviewTransaction(value)}
              >
                <PreviewIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            {handleOnAbort &&
              userContext.hasRole!('ROLE_TRADER') &&
              value.customerSocialReason !== 'Contrats annulés' && (
                <Tooltip title={t('Annuler ce contrat')}>
                  <IconButton
                    color="primary"
                    className={classes.iconBtn}
                    onClick={handleAbortContract(value)}
                  >
                    <RemoveCircleOutlineIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              )}
          </Flex>
        ),
      },
    })
  })

  const transactionsToTableData = memoizeOne(
    (transactions: TransactionListItemView[]) =>
      (transactions || []).map(transaction =>
        tableDataMapper(transaction).concat(transaction)
      )
  )

  return (
    <DataTable
      columns={getTableColumns()}
      title={title}
      dataConverter={transactionsToTableData}
      apiCall={apiCall}
      data={transactions}
      emptyContent={t('Aucune vente')}
      options={dataTableOptions}
      storageSaveId={'salesList'}
      toBackendColumnsNames={toBackendColumnsNames}
      csvDataMap={csvDataMap}
      defaultSort={defaultSort}
    />
  )
}

export default SalesList
