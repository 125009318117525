import { Grid, Typography } from '@material-ui/core'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import Fetcher from '../../../components/Fetcher'
import Margin from '../../../components/Margin'
import SaleCard from '../../../components/Sale/SaleCard'
import { t } from '../../../i18n'
import Campaign from '../../../models/Campaign'
import CustomerStock from '../../../models/CustomerStock'
import ExecutionMode from '../../../models/ExecutionMode'
import Offer from '../../../models/Offer'
import Product from '../../../models/Product'
import SaleSettings from '../../../models/SaleSettings'
import Transaction from '../../../models/Transaction'
import { StrikeValue } from '../../../models/StrikeSetting'
import campaignService from '../../../services/campaignService'
import customerStockService from '../../../services/customerStockService'
import offerService, {
  OfferExecutionView,
} from '../../../services/offerService'
import saleSettingsService from '../../../services/saleSettingsService'
import CustomerStocksCard from '../Home/CustomerStocksCard'
import TargetOrdersInProgress from '../../../components/TargetOrdersInProgress'
import targetOrderService, {
  TargetOrderView,
} from '../../../services/targetOrderService'

interface RouteParams {
  executionMode?: ExecutionMode
  executionMonth?: string
  campaign?: Campaign
  product?: Product
  tonnage?: number
  payment?: Date
  strike?: StrikeValue
  transaction?: Transaction
  token?: string
}

interface OffersAndOfferExecution {
  offers: Offer[]
  offerExecutions: OfferExecutionView[]
}

type Props = RouteComponentProps<{}, {}, RouteParams> & {}

class CustomerSale extends React.Component<Props> {
  componentDidMount(): void {
    const { location, history } = this.props
    if (
      !location.state ||
      !location.state.executionMode ||
      !location.state.executionMonth ||
      !location.state.campaign ||
      !location.state.product
    ) {
      history.replace('/')
    }
  }

  renderSaleCard = ([
    { offerExecutions, offers },
    saleSettings,
    customerStocks,
  ]: [OffersAndOfferExecution, SaleSettings, CustomerStock[]]) => {
    const {
      executionMode,
      executionMonth,
      campaign,
      product,
      tonnage,
      payment,
      strike,
      transaction,
      token,
    } = this.props.location.state

    const canForcePrice = (order: Transaction) => false
    return (
      <SaleCard
        campaign={campaign!}
        product={product!}
        executionMode={executionMode!}
        offerExecutions={offerExecutions || []}
        executionMonth={executionMonth!}
        offers={offers || []}
        saleSettings={saleSettings}
        customerStocks={customerStocks}
        tonnage={tonnage}
        payment={payment}
        strike={strike}
        defaultTransaction={transaction}
        defaultToken={token}
        canForcePrice={canForcePrice}
      />
    )
  }

  renderCustomerStock = ([campaigns, customerStocks]: [
    Campaign[],
    CustomerStock[]
  ]) => {
    return (
      <CustomerStocksCard
        campaigns={campaigns}
        customerStocks={customerStocks}
      />
    )
  }

  fetchOffersAndOfferExecution = async () => {
    const { campaign, product } = this.props.location.state
    const { data: offers } = await offerService.findAllByCampaignAndProduct(
      campaign!,
      product!
    )
    const offerIds = offers.map(o => o.id!)
    const { data: offerExecutions } = await offerService.getOfferExecutions(
      offerIds,
      campaign!.startDateCalendar,
      campaign!.endDateCalendar
    )

    return { data: { offers, offerExecutions } }
  }

  fetchTargetOrder = () => {
    return targetOrderService.findAll(['CREATED'])
  }

  renderTargetOrdersInProgress = (
    targetOrders: TargetOrderView[],
    refresh: () => void
  ) => {
    return (
      <TargetOrdersInProgress
        targetOrders={targetOrders}
        refresh={refresh}
        currentUserIsCustomer={true}
      />
    )
  }

  render() {
    const { location } = this.props
    if (
      !location.state ||
      !location.state.executionMode ||
      !location.state.executionMonth ||
      !location.state.campaign ||
      !location.state.product
    ) {
      return null
    }

    return (
      <>
        <Margin bottom={2} top={2}>
          <Typography variant="h6">{t('Réaliser une vente')}</Typography>
        </Margin>
        <Fetcher
          fetch={[
            this.fetchOffersAndOfferExecution,
            saleSettingsService.getOne,
            customerStockService.findAll,
          ]}
        >
          {this.renderSaleCard}
        </Fetcher>
        <Margin top={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Fetcher
                fetch={[
                  campaignService.findAllActive,
                  customerStockService.findAll,
                ]}
              >
                {this.renderCustomerStock}
              </Fetcher>
            </Grid>
            <Grid item xs={12} md={6}>
              <Fetcher fetch={this.fetchTargetOrder}>
                {this.renderTargetOrdersInProgress}
              </Fetcher>
            </Grid>
          </Grid>
        </Margin>
      </>
    )
  }
}

export default withRouter<Props>(CustomerSale)
