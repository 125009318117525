import * as React from 'react'

import {
  Button,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core'
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik'
import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ParityMatrix, { ParityMatrixLine } from '../../../models/ParityMatrix'

import DeliverySite from '../../../models/DeliverySite'
import ErrorContext from '../../../contexts/ErrorContext'
import Fetcher from '../../../components/Fetcher'
import Loading from '../../../components/Loading'
import Sector from '../../../models/Sector'
import TextField from '../../../components/Form/TextField'
import TraderSettingsConfigCard from './TraderSettingsConfigCard'
import TransportParityValidationSchema from './TransportParityValidationSchema'
import adviceService from '../../../services/adviceService'
import deliverySiteService from '../../../services/deliverySiteService'
import parityMatrixService from '../../../services/parityMatrixService'
import sectorService from '../../../services/sectorService'
import { t } from '../../../i18n'

type State = {
  error: boolean
  isLoading: boolean
  parityMatrix?: ParityMatrix
  deliverySites: DeliverySite[]
  sectors: Sector[]
}

const styles = (theme: Theme) =>
  createStyles({
    loading: {
      padding: 30,
    },
    table: {
      width: 'auto',
      '& th, & td': {
        paddingLeft: 0,
      },
    },
    tableRow: {
      height: theme.spacing(5),
    },
    headerLabel: {
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    form: {
      paddingRight: 20,
    },
  })

type Props = WithStyles<typeof styles>

type FormValues = {
  parityMatrixLines: ParityMatrixLine[]
}

class ParityMatrixSettings extends React.Component<Props, State> {
  static contextType = ErrorContext

  state: State = {
    error: false,
    isLoading: true,
    parityMatrix: undefined,
    deliverySites: [],
    sectors: [],
  }

  getInitialFormValues = (): FormValues => {
    const { parityMatrix, deliverySites, sectors } = this.state
    if (!parityMatrix) {
      return { parityMatrixLines: [] as ParityMatrixLine[] }
    }
    return {
      parityMatrixLines: parityMatrixService.transformParityMatrixToLines(
        parityMatrix,
        deliverySites,
        sectors
      ),
    }
  }

  componentDidMount(): void {
    this.loadData()
  }

  loadData = async () => {
    try {
      await Promise.all([
        this.loadParityMatrix(),
        this.loadSectors(),
        this.loadDeliverySites(),
      ])
    } catch (e) {
      this.context.displayError(
        'Une erreur est survenue, veuillez recharger la page'
      )
    } finally {
      this.setState({ isLoading: false })
    }
  }

  loadSectors = async () => {
    const { data: sectors } = await sectorService.findAllActives()
    this.setState({ sectors })
  }

  loadDeliverySites = async () => {
    const { data: deliverySites } = await deliverySiteService.findAllActives()
    this.setState({ deliverySites })
  }

  loadParityMatrix = async () => {
    const { data: parityMatrix } = await parityMatrixService.getParityMatrix()
    this.setState({ parityMatrix })
  }

  onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikActions<FormValues>
  ) => {
    const { sectors, parityMatrix } = this.state
    parityMatrix!.values = parityMatrixService.transformParityMatrixLinesToValues(
      values.parityMatrixLines,
      sectors
    )
    this.setState({ parityMatrix })
    try {
      await parityMatrixService.updateParityMatrix(parityMatrix!)
    } catch (e) {
      this.context.displayError(
        'Erreur lors de la sauvegarde des valeurs, veuillez recharger la page'
      )
    } finally {
      setSubmitting(false)
    }
  }

  renderForm = ({
    values,
    isSubmitting,
    isValid,
    ...formikProps
  }: FormikProps<FormValues>) => {
    const { classes } = this.props
    const { sectors } = this.state

    return (
      <Form className={classes.form}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell />
              {sectors.map((sector: Sector) => (
                <TableCell key={sector.id}>
                  <Typography color="primary" className={classes.headerLabel}>
                    {sector.name}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {values.parityMatrixLines &&
              values.parityMatrixLines.length > 0 &&
              values.parityMatrixLines.map(
                (parityMatrixLine: ParityMatrixLine, col: number) => (
                  <TableRow key={col} className={classes.tableRow}>
                    <TableCell>
                      <Typography color="textPrimary">
                        {parityMatrixLine.deliverySite.name}
                      </Typography>
                    </TableCell>
                    {sectors.map((sector: Sector) => (
                      <TableCell key={col + '-' + sector.id}>
                        <Field
                          component={TextField}
                          name={`parityMatrixLines[${col}].values[${sector.id}]`}
                          value={parityMatrixLine.values[sector.id]}
                          variant="standard"
                          type="number"
                          required={true}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={isSubmitting || !isValid}
          className={classes.submitButton}
        >
          {t('Enregistrer')}
        </Button>
      </Form>
    )
  }

  render() {
    const { classes } = this.props
    const { isLoading } = this.state
    if (isLoading) {
      return (
        <Card className={classes.loading}>
          <Loading />
        </Card>
      )
    }
    return (
      <>
        <TraderSettingsConfigCard
          cardTitle={'Vos parités de transport'}
          adviceMessage={
            <Fetcher
              fetch={adviceService.findOne}
              fetchProps={['TRANSPORT_PARITY']}
            >
              {advice => (
                <div dangerouslySetInnerHTML={{ __html: advice.template }} />
              )}
            </Fetcher>
          }
        >
          <Formik
            initialValues={this.getInitialFormValues()}
            onSubmit={this.onSubmit}
            render={this.renderForm}
            validationSchema={TransportParityValidationSchema}
          />
        </TraderSettingsConfigCard>
      </>
    )
  }
}

export default withStyles(styles)(ParityMatrixSettings)
