/* eslint-disable react/jsx-no-bind */
import {
  Theme,
  Typography,
  FormControl,
  IconButton,
  Button,
} from '@material-ui/core'
import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FormikActions,
  FieldArray,
  FastField,
} from 'formik'
import { t } from '../../../../i18n'

import TextFieldCustom from '../../../../components/Form/TextField'
import Margin from '../../../../components/Margin'
import Flex from '../../../../components/Flex'
import PmgSettings from '../../../../models/PmgSettings'
import pmgSettingsService from '../../../../services/pmgSettingsService'
import ErrorContext from '../../../../contexts/ErrorContext'
import { DatePicker } from '../../../../components/Form/DatePicker'
import SuperAdminPMGSettingsValidationSchema from './SuperAdminPMGSettingsValidationSchema'

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    paddingRight: 15,
    width: '100%',
  },
  textField: {
    padding: '6px 0 7px !important',
    width: 200,
    backgroundColor: '#FFF',
  },
  formControl: {
    marginRight: theme.spacing(8),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  icon: {
    fontSize: '2rem',
    [theme.breakpoints.only('xs')]: {
      fontSize: '3.5rem',
    },
  },
  loading: {
    padding: 30,
  },
  datePicker: {
    maxWidth: 270,
  },
}))

type FormValues = PmgSettings

type Props = {
  settings: PmgSettings
}

type SetFieldValue = FormikProps<FormValues>['setFieldValue']

export const SuperAdminPMGSettingsForm = ({ settings }: Props) => {
  const classes = useStyles()
  const errorContext = useContext(ErrorContext)
  const getInitialValues = (settings: PmgSettings): FormValues => ({
    id: settings.id || 1,
    pmgParams: {
      zoneId: settings.pmgParams.zoneId || '',
      openingTime: settings.pmgParams.openingTime || '',
      closureTime: settings.pmgParams.closureTime || '',
      daysOfClosure: settings.pmgParams.daysOfClosure || [],
    },
    secondsForCallTimeout: settings.secondsForCallTimeout || 60,
    secureAmountLimit: settings.secureAmountLimit || 0,
  })

  const clearField = (
    setFieldValue: SetFieldValue,
    fieldName: string
  ) => () => {
    setFieldValue(fieldName, undefined)
  }

  const onDaysOfClosureDateChange = (
    setFieldValue: SetFieldValue,
    index: number
  ) => (dayOfClosure?: Date) => {
    setFieldValue(`pmgParams.daysOfClosure.${index}`, dayOfClosure)
  }

  const renderForm = () => (formikProps: FormikProps<FormValues>) => {
    const { values, setFieldValue, isSubmitting } = formikProps

    return (
      <Form>
        <Typography color="primary" variant="overline">
          {t("Heure d'ouverture et fermeture du marché")}
        </Typography>
        <Margin top={1} bottom={1}>
          <Typography variant="caption" display="block">
            {t(
              "Champs pour l'heure d'ouverture et de fermeture du marché (du lundi au vendredi)."
            )}
          </Typography>
        </Margin>
        <Flex alignItems="center" direction="row">
          <FormControl className={classes.formControl}>
            <Field
              id="time"
              label={t("Heure d'ouverture")}
              type="time"
              name="pmgParams.openingTime"
              className={classes.textField}
              component={TextFieldCustom}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              id="time"
              label={t('Heure de fermeture')}
              type="time"
              name="pmgParams.closureTime"
              margin="dense"
              className={classes.textField}
              component={TextFieldCustom}
            />
          </FormControl>
        </Flex>
        <Flex direction="row">
          <FieldArray
            name="pmgParams.daysOfClosure"
            render={arrayHelpers => (
              <div>
                <Margin top={1}>
                  <Typography color="primary" variant="overline">
                    {t('Jours de fermeture')}
                  </Typography>
                </Margin>
                {values.pmgParams.daysOfClosure.map((v, index) => (
                  <Flex key={index} alignItems="center" direction="row">
                    <FastField
                      name={`pmgParams.daysOfClosure.${index}`}
                      className={classes.datePicker}
                      component={DatePicker}
                      onChange={onDaysOfClosureDateChange(setFieldValue, index)}
                      onClear={clearField(
                        setFieldValue,
                        `pmgParams.daysOfClosure.${index}`
                      )}
                      clearable={true}
                    />
                    <IconButton
                      color="primary"
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Flex>
                ))}
                <Margin top={1}>
                  <IconButton
                    color="primary"
                    onClick={() => arrayHelpers.push('')}
                  >
                    <AddIcon />
                  </IconButton>
                </Margin>
              </div>
            )}
          />
        </Flex>
        <Margin top={2} bottom={2}>
          <Typography color="primary" variant="overline">
            {t("Paramétrez la durée maximale avant abandon d'un CALL")}
          </Typography>
          <FormControl className={classes.formControl}>
            <Field
              id="secondsForCallTimeout"
              label={t('Nombre de secondes :')}
              type="number"
              name="secondsForCallTimeout"
              component={TextFieldCustom}
              className={classes.textField}
            />
          </FormControl>
        </Margin>
        <Margin top={2} bottom={2}>
          <Typography color="primary" variant="overline">
            {t("Paramétrez le seuil minimum d'un complément PMG")}
          </Typography>
          <FormControl className={classes.formControl}>
            <Field
              id="secureAmountLimit"
              label={t('Montant :')}
              type="number"
              name="secureAmountLimit"
              component={TextFieldCustom}
              className={classes.textField}
            />
          </FormControl>
        </Margin>
        <Flex alignItems="center">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={isSubmitting}
          >
            {t('Enregistrer')}
          </Button>
        </Flex>
      </Form>
    )
  }

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikActions<FormValues>
  ) => {
    try {
      await pmgSettingsService.update(values)
    } catch (e) {
      errorContext.displayError(
        'Erreur lors de la sauvegarde des valeurs, veuillez recharger la page'
      )
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className={classes.cardContent}>
      <Formik
        initialValues={getInitialValues(settings)}
        onSubmit={onSubmit}
        validationSchema={SuperAdminPMGSettingsValidationSchema}
      >
        {renderForm()}
      </Formik>
    </div>
  )
}

export default SuperAdminPMGSettingsForm
