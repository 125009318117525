import React from 'react'
import { Typography } from '@material-ui/core'
import { capitalize } from '@material-ui/core/utils/helpers'
import Fetcher from '../../../../components/Fetcher'

import { t } from '../../../../i18n'
import marketOperationService from '../../../../services/marketOperationService'
import ControllerDataOperationList from './ControllerDataOperationList'
import MarketOperation from '../../../../models/MarketOperation'
import formatDate from '../../../../utils/formatDate'

const humanReadableStatusConverter = {
  FILLED: 'Traité',
  FAILED: 'Echec',
  PENDING: 'En cours',
  REGISTERED: 'En cours',
  EXECUTED: 'Traité',
}

const ControllerDataOperation = () => {
  const getTableColumns = () => [
    t("Date de l'ordre"),
    t('Code'),
    t('Instrument'),
    t('Statut'),
    t('Type'),
    t('Quantité'),
    t('Prix moyen (€/t)'),
    t('Contrat(s) lié(s)'),
  ]

  const dataToTable = (marketOperation: MarketOperation) => [
    formatDate(marketOperation.creationDate),
    marketOperation.orderCode,
    marketOperation.instrument.symbol,
    humanReadableStatusConverter[capitalize(marketOperation.status)],
    capitalize(marketOperation.type),
    marketOperation.quantity,
    marketOperation.averagePrice,
    marketOperation.contractIdList.join(', '),
  ]

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t('Listes des données des opérations sur le marché à terme')}
      </Typography>
      <Fetcher fetch={marketOperationService.findAll}>
        {(marketOperation, refresh) => (
          <ControllerDataOperationList
            marketOperation={marketOperation}
            columns={getTableColumns()}
            tableDataMapper={dataToTable}
            refresh={refresh}
            title=""
          />
        )}
      </Fetcher>
    </>
  )
}

export default ControllerDataOperation
