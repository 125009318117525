import { Page, Pageable } from '../services/pageableService'
import transactionService, {
  TransactionListItemView,
} from '../services/transactionService'
import formatDate from '../utils/formatDate'

const saleListToBackendColumnsNames = {
  Client: 'c.socialReason',
  'Numéro contrat': 'id',
  'Date de la vente': 'createdAt',
  Produit: 'o.product.name',
  Campagne: 'o.campaign.name',
  tons: 'tons',
  Modalité: 'em.name',
  price: 'effectivePrices.netPrice',
}

const controllerSaleListToBackendColumnsNames = {
  Client: 'c.socialReason',
  'Numéro contrat': 'id',
  'Date de la vente': 'createdAt',
  Produit: 'o.product.name',
  Campagne: 'o.campaign.name',
  tons: 'tons',
  Modalité: 'em.name',
  price: 'effectivePrices.netPrice',
}

const getAllTransactionsCreationDateFormatted = async (pageable?: Pageable) => {
  const res = await transactionService.getAll(pageable)

  if (pageable) {
    ;(res.data as Page<TransactionListItemView>).content = (res.data as Page<
      TransactionListItemView
    >).content.map((d: TransactionListItemView) => ({
      ...d,
      creationDate: formatDate(d.creationDate as Date),
    }))
  } else {
    res.data = (res.data as TransactionListItemView[]).map(
      (d: TransactionListItemView) => ({
        ...d,
        creationDate: formatDate(d.creationDate as Date),
      })
    )
  }
  return res
}

export default {
  getAllTransactionsCreationDateFormatted,
  saleListToBackendColumnsNames,
  controllerSaleListToBackendColumnsNames,
}
