import axios from '../config/axios'
import Market from '../models/Market'

export const findAll = () => axios.get<Market[]>('api/market')
export const update = (market: Market) =>
  axios.put<Market>(`api/market/${market.id}`, market)
export const create = (market: Market) =>
  axios.post<Market>('api/market/', market)
export const deleteById = (id: number) => axios.delete(`api/market/${id}`)

export default { findAll, update, create, deleteById }
