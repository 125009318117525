import axios from '../config/axios'
import { User } from '../contexts/UserContext'

export const findAll = () => axios.get<User[]>('api/users')
export const update = (user: User) =>
  axios.put<User>('api/users/' + user.id, user)
export const create = (user: User) => axios.post<User>('api/users/', user)
export const importCsv = (csv: File) => {
  const formData = new FormData()
  formData.append('csv', csv)
  return axios.post<string>('api/users/import', formData, {
    timeout: 60 * 60 * 1000,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const findCustomers = (enabled: boolean = true) =>
  axios.get<User[]>('api/users/customers?enabled=' + enabled)
export const findCustomer = (id: number) =>
  axios.get<User>(`api/users/customers/${id}`)
export const updateCustomer = (customer: User) =>
  axios.put<User>(`api/users/customers/${customer.id}`, customer)

export default {
  findAll,
  update,
  create,
  importCsv,
  findCustomers,
  findCustomer,
  updateCustomer,
}
