import * as React from 'react'

import SnackbarsExtended from '../components/SnackbarsExtended'

export interface ErrorContextType {
  displayError: (message: string) => void
}

const ErrorContext = React.createContext<ErrorContextType>({
  /* tslint:disable-next-line:no-empty */
  displayError: () => {},
})

interface State {
  displayError: (message: string) => void
  open: boolean
  message?: string
}

export class ErrorContextProvider extends React.Component<{}, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      open: false,
      displayError: this.displayError,
    }
  }

  displayError = (message: string) => {
    this.setState({ open: true, message })
  }

  handleSnackbarClose = () => {
    this.setState({ open: false, message: undefined })
  }

  render() {
    const { children } = this.props
    const { open, message } = this.state
    return (
      <ErrorContext.Provider value={this.state}>
        <>
          {children}
          <SnackbarsExtended
            open={open}
            variant="error"
            message={message}
            onClose={this.handleSnackbarClose}
          />
        </>
      </ErrorContext.Provider>
    )
  }
}

export default ErrorContext
