import * as React from 'react'

import {
  Typography,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core'

import Add from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Switch from '@material-ui/core/Switch'
import CreateNewDeliverySiteDialog from './CreateNewDeliverySiteDialog'
import DeliverySite from '../../../models/DeliverySite'
import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Loading from '../../../components/Loading'
import SettingsConflictErrorDialog from './SettingsConflictErrorDialog'
import SnackbarsExtended from '../../../components/SnackbarsExtended'
import TraderSettingsConfigCard from './TraderSettingsConfigCard'
import adviceService from '../../../services/adviceService'
import deliverySiteService from '../../../services/deliverySiteService'
import { t } from '../../../i18n'

const styles = () =>
  createStyles({
    cardContent: {
      width: '100%',
      paddingRight: 15,
    },
    borderSite: {
      borderBottom: '1px solid #ccc',
    },
    location: {
      paddingLeft: 70,
      marginBottom: 10,
    },
    loading: {
      padding: 30,
    },
  })

type Props = WithStyles<typeof styles> & {}

type State = {
  error: boolean
  conflictError: boolean
  isLoading: boolean
  deliverySites: DeliverySite[]
  createSiteDialogOpen: boolean
}

class DeliverySitesSettings extends React.Component<Props, State> {
  readonly state: State = {
    error: false,
    conflictError: false,
    isLoading: true,
    deliverySites: [],
    createSiteDialogOpen: false,
  }

  componentDidMount(): void {
    this.loadDeliverySites()
  }

  loadDeliverySites = async () => {
    try {
      const { data: deliverySites } = await deliverySiteService.findAll()
      this.setState({ deliverySites, isLoading: false })
    } catch (e) {
      this.setState({ error: true })
    }
  }

  handleSwitch = (deliverySite: DeliverySite) => async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target

    this.setState(({ deliverySites }) => ({
      deliverySites: deliverySites.map(ds =>
        ds.id === deliverySite.id
          ? {
              ...ds,
              active: checked,
            }
          : ds
      ),
    }))

    try {
      deliverySite.active = checked
      await deliverySiteService.updateOne(deliverySite)
    } catch (error) {
      switch (error.response.status) {
        case 409:
          deliverySite.active = !checked

          this.setState(({ deliverySites }) => ({
            deliverySites: deliverySites.map(ds =>
              ds.id === deliverySite.id
                ? {
                    ...ds,
                    active: !checked,
                  }
                : ds
            ),
            conflictError: true,
          }))
          break
        default:
          this.setState({ error: true })
          break
      }
    }
  }

  toggleCreateSiteDialog = () => {
    this.setState(({ createSiteDialogOpen }) => ({
      createSiteDialogOpen: !createSiteDialogOpen,
    }))
  }
  closeConflictDialog = () => {
    this.setState({ conflictError: false })
  }

  newDeliverySiteSaved = (deliverySite: DeliverySite) => {
    this.setState(({ deliverySites }) => ({
      deliverySites: [...deliverySites, deliverySite],
    }))
    this.toggleCreateSiteDialog()
  }

  handleSnackbarClose = () => {
    this.setState({ error: false })
  }

  render() {
    const { classes } = this.props
    const {
      error,
      isLoading,
      deliverySites,
      createSiteDialogOpen,
      conflictError,
    } = this.state

    if (isLoading) {
      return (
        <Card className={classes.loading}>
          <Loading />
        </Card>
      )
    }

    return (
      <>
        <TraderSettingsConfigCard
          cardTitle={'Vos lieux de destination'}
          adviceMessage={
            <Fetcher fetch={adviceService.findOne} fetchProps={['DELIVERY']}>
              {advice => (
                <div dangerouslySetInnerHTML={{ __html: advice.template }} />
              )}
            </Fetcher>
          }
        >
          <div className={classes.cardContent}>
            <Typography variant="button" className={classes.location}>
              {t('Lieu')}
            </Typography>
            {deliverySites.map((deliverySite: DeliverySite) => (
              <Flex
                direction={'row'}
                justify={'space-between'}
                alignItems={'center'}
                key={deliverySite.id}
                className={classes.borderSite}
              >
                <Typography variant={'body2'}>{deliverySite.name}</Typography>
                <Switch
                  color={'primary'}
                  checked={deliverySite.active}
                  onChange={this.handleSwitch(deliverySite)}
                />
              </Flex>
            ))}
            <Button color={'primary'} onClick={this.toggleCreateSiteDialog}>
              <Add />
            </Button>
          </div>
        </TraderSettingsConfigCard>

        <CreateNewDeliverySiteDialog
          open={createSiteDialogOpen}
          onClose={this.toggleCreateSiteDialog}
          onSave={this.newDeliverySiteSaved}
        />
        <SettingsConflictErrorDialog
          open={conflictError}
          errorTitle={'Impossible de désactiver ce lieu de destination'}
          errorContent={
            'Des offres ou des ordres de vente sont en cours sur ce lieu.\nVeuillez les clôturer avant de le désactiver.'
          }
          onClose={this.closeConflictDialog}
        />
        <SnackbarsExtended
          onClose={this.handleSnackbarClose}
          open={error}
          variant={'error'}
          message={'Une erreur est survenue, veuillez recharger la page'}
        />
      </>
    )
  }
}

export default withStyles(styles)(DeliverySitesSettings)
