import {
  Button,
  Card,
  FormControl,
  FormLabel,
  MenuItem,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik'
import memoizeOne from 'memoize-one'
import * as React from 'react'

import ErrorContext from '../contexts/ErrorContext'
import { User } from '../contexts/UserContext'
import { t } from '../i18n'
import ExecutionMode, { EExecutionMode } from '../models/ExecutionMode'
import UserTag from '../models/UserTag'
import googleMapService from '../services/googleMapService'
import userService from '../services/userService'
import formatCustomerName from '../utils/formatCustomerName'

import CardContentColored from './CardContentColored'
import Flex from './Flex'
import { DownshiftMultiple } from './Form/DownshiftMultiple'
import { Select } from './Form/Select'
import TextFieldCustom from './Form/TextField'
import Margin from './Margin'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    lineHeight: 1.5,
  },
  title: {
    fontSize: '1.97rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  topCard: {
    minHeight: 230,
    position: 'relative',
    paddingRight: 280 + theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto',
      paddingRight: 0,
    },
  },
  mapContainer: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    width: 280,
    height: 230,
    [theme.breakpoints.down('xs')]: {
      position: 'initial',
      marginTop: theme.spacing(1),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  map: {
    width: '100%',
    height: '100%',
    border: 0,
  },
  formControl: {
    minWidth: 270,
  },
  topCardFormLabel: {
    color: '#fff',
  },
}))

type Props = {
  customer: User
  userTags: UserTag[]
  executionModes: ExecutionMode[]
  onCustomerChanged?: () => void
}

type FormValues = {
  tags: UserTag[]
  executionModes: EExecutionMode[]
  bushelDepartureBonus: number
}

export const CustomerDetails = ({
  customer,
  userTags,
  executionModes,
  onCustomerChanged,
}: Props) => {
  const errorContext = React.useContext(ErrorContext)
  const classes = useStyles()
  const getTitle = memoizeOne(formatCustomerName)

  const getInitialValues = (customer: User): FormValues => ({
    tags: customer.tags || [],
    executionModes: customer.executionModes || [],
    bushelDepartureBonus: customer.bushelDepartureBonus || 0,
  })

  const bushelDepartureExecutionMode = executionModes
    .filter(em => em.type === 'BUSHEL_DEPARTURE')
    .pop()!

  const allowedToBushelDeparture =
    customer.executionModes.length === 0 ||
    customer.executionModes.filter(em => em === 'BUSHEL_DEPARTURE').length > 0

  const renderForm = ({ isSubmitting }: FormikProps<FormValues>) => {
    return (
      <Form>
        <Flex direction="column">
          <FormControl
            component={'fieldset' as 'div'}
            className={classes.formControl}
          >
            <Margin bottom={2}>
              <FormLabel component={'legend' as 'label'}>
                {t('Catégorie de client')}
              </FormLabel>
            </Margin>
            <Field
              name="tags"
              suggestions={userTags}
              suggestionLabel="name"
              placeholder={t('Ajouter une catégorie')}
              component={DownshiftMultiple}
            />
          </FormControl>
          <Margin bottom={2} />
          <FormControl className={classes.formControl}>
            <Field
              component={Select}
              label={t('Modalités d’exécution')}
              name="executionModes"
              multiple
            >
              {executionModes.map((executionMode: ExecutionMode) => (
                <MenuItem key={executionMode.id} value={executionMode.type}>
                  {executionMode.name}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
          <Margin bottom={2} />
          <FormControl className={classes.formControl}>
            <FormLabel>Commercial</FormLabel>
            <TextField
              className={classes.formControl}
              variant="filled"
              margin="dense"
              value={
                customer.delegatedSalesman
                  ? `${customer.delegatedSalesman.firstname} ${customer.delegatedSalesman.lastname}`
                  : t('Inconnu')
              }
              disabled
            />
          </FormControl>
          {allowedToBushelDeparture && (
            <>
              <Margin bottom={2} />
              <FormControl className={classes.formControl}>
                <FormLabel>
                  Prime &quot;{bushelDepartureExecutionMode.name.toLowerCase()}
                  &quot;
                </FormLabel>
                <Field
                  className={classes.formControl}
                  variant="filled"
                  margin="dense"
                  component={TextFieldCustom}
                  type="number"
                  name="bushelDepartureBonus"
                />
              </FormControl>
            </>
          )}
          <Flex alignItems="center" fullWidth>
            <Margin top={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {t('Sauvegarder')}
              </Button>
            </Margin>
          </Flex>
        </Flex>
      </Form>
    )
  }

  const saveChanges = async (
    values: FormValues,
    { setSubmitting }: FormikActions<FormValues>
  ) => {
    try {
      await userService.updateCustomer({ ...customer, ...values })
      if (onCustomerChanged) {
        onCustomerChanged()
      }
    } catch (e) {
      errorContext.displayError(t('Une erreur est survenue.'))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Card className={classes.card}>
      <CardContentColored color="primary" className={classes.topCard}>
        <Typography className={classes.title} color="inherit">
          {getTitle(customer)} ({customer.externalId})
        </Typography>
        <Typography variant="body2" color="inherit">
          {customer.address} {customer.address2} {customer.postalCode}{' '}
          {customer.city}
        </Typography>
        <Typography variant="body2" color="inherit">
          {customer.email}
        </Typography>
        <Typography variant="body2" color="inherit">
          {customer.phone}
        </Typography>
        <Margin top={2} />
        <FormControl className={classes.formControl}>
          <FormLabel className={classes.topCardFormLabel}>Secteur</FormLabel>
          <TextField
            variant="filled"
            margin="dense"
            value={customer.sector ? customer.sector.name : t('Inconnu')}
            disabled
          />
        </FormControl>
        <div className={classes.mapContainer}>
          <iframe
            title="customerLocationMap"
            className={classes.map}
            src={googleMapService.getMapEmbedUrl(customer)}
          />
        </div>
      </CardContentColored>
      <CardContent>
        <Formik
          initialValues={getInitialValues(customer)}
          onSubmit={saveChanges}
          render={renderForm}
        />
      </CardContent>
    </Card>
  )
}

export default CustomerDetails
