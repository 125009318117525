import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton/IconButton'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import { TypographyProps } from '@material-ui/core/Typography'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneIcon from '@material-ui/icons/Phone'
import UserIcon from 'mdi-material-ui/AccountCircleOutline'
import * as React from 'react'

import { User } from '../../contexts/UserContext'
import { t } from '../../i18n'
import authService from '../../services/authService'

const styles = (theme: Theme) =>
  createStyles({
    list: {
      width: 300,
      paddingTop: 0,
    },
    infoListItem: {
      paddingTop: theme.spacing(1 / 2),
      paddingBottom: 0,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    actionListItem: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(1),
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main,
      cursor: 'pointer',
    },
    titleListItem: {
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
      paddingRight: theme.spacing(1),
    },
    divider: {
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: theme.spacing(2),
      width: 300 - theme.spacing(5),
      backgroundColor: theme.palette.primary.main,
    },
    link: {
      '& > span': {
        [theme.breakpoints.only('xs')]: {
          fontSize: '1.8rem',
        },
      },
    },
  })

const propsTitleText: Partial<TypographyProps> = {
  variant: 'h6',
  color: 'primary',
  align: 'right',
}
const propsInfoText: Partial<TypographyProps> = {
  variant: 'body2',
  align: 'right',
}
const propsActionText: Partial<TypographyProps> = {
  variant: 'caption',
  color: 'primary',
  align: 'right',
}
const propsErrorText: Partial<TypographyProps> = {
  variant: 'caption',
  color: 'error',
  align: 'right',
}

interface Props extends WithStyles<typeof styles> {
  user: User
}

interface State {
  open: boolean
  error: boolean
}

class CustomerMenu extends React.Component<Props, State> {
  state = {
    open: false,
    error: false,
  }

  toggleDrawer = (open: boolean) => () => {
    this.setState({ open })
  }

  handleLogout = () => {
    this.toggleDrawer(false)
    authService.logout()
  }

  handleChangeInformationsClick = () => {
    const { user } = this.props
    if (!user.traderMailContact) {
      this.setState({ error: true })
    } else {
      window.location.href = `mailto:${user.traderMailContact}?subject=Demande de modifications de mon compte&body=Bonjour,%0D%0A%0D%0AVeuillez prendre en compte les modifications suivantes sur mon compte :%0D%0A%0D%0A%0D%0A`
    }
  }

  render() {
    const { open, error } = this.state
    const { classes, user } = this.props
    const salesman = user.delegatedSalesman ? user.delegatedSalesman : undefined

    return (
      <>
        <IconButton
          aria-owns={open ? 'user-menu' : undefined}
          aria-haspopup="true"
          onClick={this.toggleDrawer(true)}
        >
          <UserIcon />
        </IconButton>
        <Drawer
          anchor="right"
          open={this.state.open}
          onClose={this.toggleDrawer(false)}
        >
          <List className={classes.list}>
            <ListItem className={classes.titleListItem}>
              <ListItemText
                primary={t('Votre compte')}
                primaryTypographyProps={propsTitleText}
              />
            </ListItem>
            <ListItem className={classes.infoListItem}>
              <ListItemText
                primary={user.socialReason}
                primaryTypographyProps={propsInfoText}
              />
            </ListItem>
            <ListItem className={classes.infoListItem}>
              <ListItemText
                primary={user.address}
                primaryTypographyProps={propsInfoText}
              />
            </ListItem>
            <ListItem className={classes.infoListItem}>
              <ListItemText
                primary={user.address2}
                primaryTypographyProps={propsInfoText}
              />
            </ListItem>
            <ListItem className={classes.infoListItem}>
              <ListItemText
                primary={`${user.postalCode} ${user.city}`}
                primaryTypographyProps={propsInfoText}
              />
            </ListItem>
            <ListItem className={classes.infoListItem}>
              <ListItemText
                primary={user.email}
                primaryTypographyProps={propsInfoText}
              />
            </ListItem>
            <ListItem className={classes.infoListItem}>
              <ListItemText
                primary={`Tél : ${user.phone}`}
                primaryTypographyProps={propsInfoText}
              />
            </ListItem>
            <ListItem className={classes.actionListItem}>
              <ListItemText
                primary={t('Modifier mes coordonnées')}
                primaryTypographyProps={propsActionText}
                onClick={this.handleChangeInformationsClick}
                className={classes.link}
              />
            </ListItem>
            {error && (
              <ListItem>
                <ListItemText
                  primary={t(
                    'Une erreur est survenue, veuillez contacter un administrateur.'
                  )}
                  primaryTypographyProps={propsErrorText}
                />
              </ListItem>
            )}
            {salesman && (
              <>
                <Divider className={classes.divider} />

                <ListItem className={classes.titleListItem}>
                  <ListItemText
                    primary={t('Votre commercial')}
                    primaryTypographyProps={propsTitleText}
                  />
                </ListItem>
                <ListItem className={classes.infoListItem}>
                  <ListItemText
                    primary={`${salesman.firstname} ${salesman.lastname}`}
                    primaryTypographyProps={propsInfoText}
                  />
                </ListItem>
                <ListItem className={classes.infoListItem}>
                  <ListItemIcon>
                    <MailOutlineIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={salesman.email}
                    primaryTypographyProps={propsInfoText}
                  />
                </ListItem>
                <ListItem className={classes.infoListItem}>
                  <ListItemIcon>
                    <PhoneIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Fixe : ${salesman.phone}`}
                    primaryTypographyProps={propsInfoText}
                  />
                </ListItem>
                <ListItem className={classes.infoListItem}>
                  <ListItemIcon>
                    <PhoneIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Mobile : ${salesman.mobile}`}
                    primaryTypographyProps={propsInfoText}
                  />
                </ListItem>
              </>
            )}
            <ListItem
              onClick={this.handleLogout}
              className={classes.actionListItem}
            >
              <ListItemText
                primary={t('Me déconnecter')}
                primaryTypographyProps={propsActionText}
                className={classes.link}
              />
            </ListItem>
          </List>
        </Drawer>
      </>
    )
  }
}

export default withStyles(styles)(CustomerMenu)
