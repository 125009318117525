import * as React from 'react'

import {
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
  Button,
  FormControl,
} from '@material-ui/core'

import Card from '@material-ui/core/Card/Card'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import CreateOutlined from '@material-ui/icons/CreateOutlined'
import SnackbarsExtended from '../../../../components/SnackbarsExtended'
import SettingsConflictErrorDialog from '../../../trader/Settings/SettingsConflictErrorDialog'
import Loading from '../../../../components/Loading'
import Flex from '../../../../components/Flex'
import Advice from '../../../../models/Advice'

import { t } from '../../../../i18n'

import adviceService from '../../../../services/adviceService'

import TraderSettingsConfigCard from '../../../trader/Settings/TraderSettingsConfigCard'
import EditorAdvice from './EditorAdvice'

const styles = (theme: Theme) =>
  createStyles({
    cardContent: {
      paddingRight: 15,
      width: '100%',
    },
    select: {
      padding: 10 + '!important',
    },
    loading: {
      padding: 30,
    },
    modal: {
      minWidth: '400px',
    },
    modalContent: {
      paddingBottom: '4px',
    },
    table: {
      '& td:last-child': {
        paddingRight: 0,
      },
    },
    tableRow: {
      height: theme.spacing(5),
    },
    tableButtonCell: {
      paddingRight: 0,
    },
    headerLabel: {
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
    },
    textfield: {
      width: '400px',
    },
  })

interface Props extends WithStyles<typeof styles> {}

interface State {
  error: boolean
  isLoading: boolean
  advices: Advice[]
  dialogOpen: boolean
  conflictError: boolean
  adviceToEdit: Advice
}

class AdvicesSettings extends React.Component<Props, State> {
  readonly state: State = {
    error: false,
    isLoading: true,
    advices: [],
    dialogOpen: false,
    conflictError: false,
    adviceToEdit: { id: 99999, label: '', template: '', code: '' },
  }

  componentDidMount(): void {
    this.loadAdvices()
  }

  loadAdvices = async () => {
    try {
      const { data: advices } = await adviceService.findAll()
      this.setState({ advices, isLoading: false })
    } catch (e) {
      this.setState({ error: true })
    }
  }

  closeConflictDialog = () => {
    this.setState({ conflictError: false })
  }

  onCloseError = () => {
    this.setState({ error: false })
  }

  onAdviceOpen = (advice: Advice) => () => {
    this.setState({ dialogOpen: true, adviceToEdit: advice })
  }

  onTextModify = (template: string) => {
    this.setState(({ adviceToEdit }) => ({
      adviceToEdit: { ...adviceToEdit, template },
    }))
  }

  setAdvice = (advice: Advice) => async (event: any) => {
    this.setState(({ advices }) => ({
      advices: advices.map(ad =>
        ad.id === advice.id
          ? {
              ...ad,
              template: advice.template,
            }
          : ad
      ),
    }))
    try {
      await adviceService.update(advice)
      this.setState({ dialogOpen: false })
    } catch (error) {
      this.setState({ error: true })
    }
  }

  onModifyAdviceCancel = () => {
    this.setState({ dialogOpen: false })
  }

  render() {
    const { classes } = this.props
    const {
      error,
      isLoading,
      advices,
      dialogOpen,
      conflictError,
      adviceToEdit,
    } = this.state

    if (isLoading) {
      return (
        <Card className={classes.loading}>
          <Loading />
        </Card>
      )
    }

    return (
      <>
        <TraderSettingsConfigCard
          cardTitle={'Vos conseils'}
          adviceMessage={
            <Typography>
              {t('Gérez vos conseils pour vos différents traders.')}
            </Typography>
          }
        >
          <div className={classes.cardContent}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell>
                    <Typography color="primary" className={classes.headerLabel}>
                      {t('Label')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="primary"
                      className={classes.headerLabel}
                      align={'right'}
                    >
                      {t('Modifier')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {advices.map((advice: Advice) => (
                  <TableRow key={`${advice.id}`} className={classes.tableRow}>
                    <TableCell>
                      <Typography color="textPrimary">
                        {advice.label}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableButtonCell}>
                      <Flex direction="row" justify="flex-end">
                        <Button
                          color="primary"
                          onClick={this.onAdviceOpen(advice)}
                          title={t('Modifier le texte du conseil affiché')}
                        >
                          <CreateOutlined />
                        </Button>
                      </Flex>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </TraderSettingsConfigCard>
        <SnackbarsExtended
          onClose={this.onCloseError}
          open={error}
          variant={'error'}
          message={
            'Une erreur est survenue, si le problème persiste veuillez consulter un administrateur.'
          }
        />
        <SettingsConflictErrorDialog
          open={conflictError}
          errorTitle={'Impossible de modifier ce conseil'}
          errorContent={
            'Une erreur est survenue, si le problème persiste veuillez consulter un administrateur.'
          }
          onClose={this.closeConflictDialog}
        />
        <Dialog
          open={dialogOpen}
          onClose={this.onModifyAdviceCancel}
          aria-labelledby="form-dialog-title"
          className={classes.modal}
        >
          <DialogContent>
            <DialogContentText className={classes.headerLabel}>
              {adviceToEdit.label}
            </DialogContentText>
            <FormControl className={classes.modalContent}>
              <EditorAdvice
                defaultValue={adviceToEdit.template}
                onChange={this.onTextModify}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onModifyAdviceCancel} color="primary">
              Annuler
            </Button>
            <Button onClick={this.setAdvice(adviceToEdit)} color="primary">
              Modifier
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withStyles(styles)(AdvicesSettings)
