import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText'
import { ErrorMessage } from 'formik'
import * as React from 'react'

interface Props {
  name: string
}

const FieldError: React.FunctionComponent<Props> = ({ name }) => (
  <ErrorMessage name={name}>
    {msg => <FormHelperText error>{msg}</FormHelperText>}
  </ErrorMessage>
)

export default FieldError
