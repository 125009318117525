import * as React from 'react'

import { Theme, Typography, WithStyles } from '@material-ui/core'

import BackButton from '@material-ui/icons/ArrowBack'
import Button from '@material-ui/core/Button/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Link } from 'react-router-dom'
import SmsOutlined from '@material-ui/icons/SmsOutlined'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import Flex from '../../../components/Flex'
import Margin from '../../../components/Margin'
import { t } from '../../../i18n'

const styles = (theme: Theme) =>
  createStyles({
    title: {
      backgroundColor: theme.palette.primary.main,
      color: 'white !important',
      padding: '8px 18px',
    },
    content: {
      padding: 20,
      overflowX: 'auto',
      '& div:first-child': {
        minWidth: 'min-content',
      },
    },
    advices: {
      textAlign: 'left',
      borderLeft: '2px solid #ccc',
      padding: 10,
      width: '40%',
      whiteSpace: 'pre-line',
      '& p': {
        margin: 0,
      },
      minWidth: '250px',
    },
    adviceText: {
      whiteSpace: 'pre-line',
    },
    adviceTitle: {
      marginBottom: theme.spacing(1),
    },
  })

interface Props extends WithStyles<typeof styles> {
  adviceMessage: string | JSX.Element
  cardTitle: string
}

class TraderSettingsConfigCard extends React.Component<Props> {
  render() {
    const { adviceMessage, cardTitle, classes, children } = this.props

    return (
      <>
        <Margin bottom={2}>
          <Link to="/settings">
            <Button color="primary" size="small">
              <BackButton />
              {t('Retour')}
            </Button>
          </Link>
        </Margin>
        <Card>
          <Typography data-cy={'cardTitle'} className={classes.title}>
            {t(cardTitle)}
          </Typography>
          <CardContent className={classes.content}>
            <Flex
              direction={'row'}
              alignItems={'stretch'}
              justify={'space-between'}
            >
              {children}
              <div className={classes.advices}>
                <Typography variant="subtitle2">
                  <Flex direction={'row'}>
                    <SmsOutlined
                      color={'primary'}
                      className={classes.adviceTitle}
                    />
                    &nbsp;<b>{t('Nos conseils :')}</b>
                  </Flex>
                </Typography>
                <Margin top={1}>
                  <Typography variant={'body2'} className={classes.adviceText}>
                    {adviceMessage}
                  </Typography>
                </Margin>
              </div>
            </Flex>
          </CardContent>
        </Card>
      </>
    )
  }
}

export default withStyles(styles)(TraderSettingsConfigCard)
