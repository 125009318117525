import { capitalize } from '@material-ui/core/utils/helpers'
import * as React from 'react'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import orange from '@material-ui/core/colors/orange'
import { makeStyles } from '@material-ui/styles'

import { MUIDataTableColumnDef } from 'mui-datatables'
import Fetcher from '../../../components/Fetcher'
import { t } from '../../../i18n'
import { format } from '../../../i18n/format'
import targetOrderService, {
  TargetOrderView,
} from '../../../services/targetOrderService'
import TargetOrderList from '../../../components/TargetOrderList'

const useStyles = makeStyles(() => ({
  cell: {
    fontWeight: 'bold',
    color: orange[600],
  },
}))

export const SalesmanTargetOrdersCanceled = () => {
  const classes = useStyles()

  const getTableTargetOrderColumns = (): Array<
    string | MUIDataTableColumnDef
  > => [
    t('Client'),
    t("Date de l'ordre"),
    t('Produit'),
    t('Campagne'),
    { name: 'tons', label: t('Tonnage souscrit'), options: { filter: false } },
    {
      name: 'tonnageExecuted',
      label: t('Exécuté'),
      options: {
        filter: false,
        customBodyRender: (value: number) =>
          value !== 0 ? (
            <span className={classes.cell}>{value}</span>
          ) : (
            <span>{value}</span>
          ),
      },
    },
    t('Modalité'),
    t('Execution'),
    {
      name: 'customerTargetPrice',
      label: t('Prix objectif (€/t)'),
      options: { filter: false },
    },
  ]

  const targetOrderToTableData = (targetOrder: TargetOrderView) => [
    capitalize(targetOrder.customerSocialReason),
    format(targetOrder.builtAt, 'dd/MM/yyyy'),
    capitalize(targetOrder.productName),
    targetOrder.campaignName,
    targetOrder.tons,
    targetOrder.tonnageExecuted,
    capitalize(targetOrder.executionModeLabel),
    format(targetOrder.executionDate, 'MMMM yyyy'),
    targetOrder.customerTargetPrice,
  ]

  const fetchTargetOrder = () => {
    return targetOrderService.findAll(['CANCELED'])
  }

  const renderTargetOrderSaleCancel = (
    targetOrders: TargetOrderView[],
    refresh: () => void
  ) => {
    return (
      <>
        <Link to={'/sales/list'}>
          <Button color="primary">
            <KeyboardArrowLeft />
            {t('Retour à la liste des ventes')}
          </Button>
        </Link>
        <TargetOrderList
          targetOrders={targetOrders}
          columns={getTableTargetOrderColumns()}
          tableDataMapper={targetOrderToTableData}
          refresh={refresh}
          status="CANCELED"
        />
      </>
    )
  }

  return (
    <>
      <Fetcher fetch={fetchTargetOrder}>{renderTargetOrderSaleCancel}</Fetcher>
    </>
  )
}

export default SalesmanTargetOrdersCanceled
