import {
  CircularProgress,
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import * as React from 'react'

import Flex from './Flex'

const styles = createStyles({
  root: {
    height: '100%',
  },
})

type Props = WithStyles<typeof styles>

const Loading: React.FunctionComponent<Props> = ({ classes }) => (
  <Flex
    alignItems={'center'}
    justify={'center'}
    fullWidth={true}
    className={classes.root}
  >
    <CircularProgress color="primary" size={64} />
  </Flex>
)

export default withStyles(styles)(Loading)
