import {
  createStyles,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import Card from '@material-ui/core/Card/Card'
import CardActions from '@material-ui/core/CardActions/CardActions'
import CardContent from '@material-ui/core/CardContent/CardContent'
import * as React from 'react'

import Fetcher from '../../../components/Fetcher'
import Poster from '../../../components/Poster'
import { t } from '../../../i18n'
import OfferDisplaySetting from '../../../models/OfferDisplaySetting'
import campaignService from '../../../services/campaignService'
import customerSettingsService from '../../../services/customerSettingsService'
import productService from '../../../services/productService'

import CustomerSettingsCampaigns from './CustomerSettingsCampaigns'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 18,
      paddingBottom: 50,
    },
    cardContent: {
      paddingTop: 10,
      paddingLeft: 20,
    },
    caption: {
      marginTop: -5,
      color: theme.palette.grey['600'],
    },
    actions: {
      justifyContent: 'center',
    },
  })

interface Props extends WithStyles<typeof styles> {}

interface State {
  settings: OfferDisplaySetting[]
}

class CustomerSettings extends React.Component<Props, State> {
  state: State = {
    settings: [],
  }

  onSettingsChange = (settings: OfferDisplaySetting[]) => {
    this.setState({ settings })
  }

  submitSettings = () => {
    return customerSettingsService.save(this.state.settings)
  }

  render() {
    const { classes } = this.props

    return (
      <Fetcher
        fetch={[
          campaignService.findAllActive,
          productService.findAll,
          customerSettingsService.findAll,
        ]}
      >
        {([campaigns, products, settings]) => (
          <>
            <Typography variant="h5">
              {t("Paramétrez l'affichage des offres")}
            </Typography>
            <Card className={classes.root}>
              <CardContent className={classes.cardContent}>
                <Typography variant="subtitle1">
                  {t(
                    'Classez les produits ci-dessous en fonction de vos besoins pour paramétrer l’affichage des offres.'
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  className={classes.caption}
                >
                  {t(
                    'Pas d’inquiétude, vous pourrez toujours modifier vos choix plus tard.'
                  )}
                </Typography>
                <CustomerSettingsCampaigns
                  campaigns={campaigns}
                  products={products}
                  initialSettings={settings}
                  onSettingsChange={this.onSettingsChange}
                />
              </CardContent>

              <CardActions className={classes.actions}>
                <Poster
                  buttonText={'Confirmer mes choix'}
                  size={'large'}
                  onSubmit={this.submitSettings}
                />
              </CardActions>
            </Card>
          </>
        )}
      </Fetcher>
    )
  }
}

export default withStyles(styles)(CustomerSettings)
