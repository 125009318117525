import { FilledInput, FormControl, FormLabel } from '@material-ui/core'
import MuiSelect, {
  SelectProps as MuiSelectProps,
} from '@material-ui/core/Select'
import { capitalize } from '@material-ui/core/utils/helpers'
import { FieldProps, getIn } from 'formik'
import * as React from 'react'

import { CSSProperties } from '@material-ui/styles'
import { Omit } from '../../utils/Omit'

import FieldError from './FieldError'

type SelectChangeEvent = React.ChangeEvent<HTMLSelectElement>

export interface SelectProps
  extends FieldProps,
    Omit<MuiSelectProps, 'error' | 'name' | 'value' | 'onChange' | 'onBlur'> {
  label?: React.ReactNode
  labelStyle?: CSSProperties
  filled: boolean
  type: string
  options: any[]
  onChange: (e: React.ChangeEvent<any>) => void
}

export const fieldToSelect = ({
  field,
  form,
  disabled = false,
  ...props
}: SelectProps): MuiSelectProps => {
  const { type, options } = props
  const { name } = field
  const { isSubmitting, setFieldValue, touched, errors } = form

  const fieldError = getIn(errors, name)
  const error = getIn(touched, name) && !!fieldError

  if (type === 'object') {
    props.onChange = (e: SelectChangeEvent) => {
      setFieldValue(
        name,
        options.find((t: any) => t.id === Number(e.target.value))
      )
    }
  }

  return {
    disabled: isSubmitting || disabled,
    ...field,
    ...props,
    error,
    value:
      (type === 'object'
        ? field && field.value && field.value.name
        : field.value) || '',
  }
}

export const Select: React.ComponentType<SelectProps> = ({
  label,
  labelStyle,
  ...others
}: SelectProps) => {
  const { error, ...selectProps } = fieldToSelect(others)
  return (
    <FormControl>
      {label && (
        <FormLabel htmlFor={others.field.name} style={labelStyle}>
          {label}
        </FormLabel>
      )}
      <FormControl margin="dense" error={error}>
        <MuiSelect
          id={others.field.name}
          name={others.field.name}
          input={<FilledInput />}
          renderValue={
            (others.type === 'object' && ((value: any) => capitalize(value))) ||
            undefined
          }
          {...selectProps}
        />
        {others.type !== 'object' && <FieldError name={others.field.name} />}
      </FormControl>
    </FormControl>
  )
}

Select.displayName = 'FormikMaterialUISelect'
