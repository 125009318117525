import {
  createStyles,
  IconButton,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import { ModalProps } from '@material-ui/core/Modal'
import CloseIcon from '@material-ui/core/SvgIcon/SvgIcon'
import TextField from '@material-ui/core/TextField/TextField'
import isEmpty from 'lodash/isEmpty'
import React, { Component } from 'react'

import Flex from '../../../../components/Flex'
import Editor from '../../../../components/Form/Editor'
import { t } from '../../../../i18n'
import MailTemplate from '../../../../models/MailTemplate'
import { Omit } from '../../../../utils/Omit'

const styles = () =>
  createStyles({
    dialog: {
      height: '100%',
    },
    title: {
      paddingBottom: 0,
      display: 'flex',
      flexDirection: 'row',
    },
    dialogContent: {
      height: '100%',
    },
    name: {
      width: '50%',
    },
  })

type Props = WithStyles<typeof styles> &
  Omit<ModalProps, 'classes' | 'onClose' | 'children'> & {
    mailTemplate?: MailTemplate
    open: boolean
    onClose: () => void
    onSave: (mailTemplate: MailTemplate) => void
    tags: string[]
  }

interface State {
  mailTemplate?: MailTemplate
}

class TraderSettingsMailTemplatesDialog extends Component<Props, State> {
  readonly state: State = {
    mailTemplate: undefined,
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (
      !prevProps.mailTemplate &&
      prevProps.mailTemplate !== this.props.mailTemplate
    ) {
      this.setState({ mailTemplate: this.props.mailTemplate })
    }
  }

  handleClose = () => {
    const { onClose } = this.props
    this.setState({ mailTemplate: undefined })
    if (onClose) {
      onClose()
    }
  }

  handleSubmit = async () => {
    const { onSave } = this.props
    const { mailTemplate } = this.state

    if (onSave) {
      await onSave(mailTemplate!)
    }
  }

  handleTemplateChange = (template: string) => {
    this.setState(({ mailTemplate }) => ({
      mailTemplate: { ...mailTemplate!, template },
    }))
  }

  handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const subject = event.target.value
    this.setState(({ mailTemplate }) => ({
      mailTemplate: { ...mailTemplate!, subject },
    }))
  }

  renderContent() {
    const { classes, tags } = this.props
    const { mailTemplate } = this.state

    if (!mailTemplate) {
      return <div />
    }

    return (
      <>
        <DialogTitle disableTypography classes={{ root: classes.title }}>
          <TextField
            label={t("Sujet de l'email")}
            name="subject"
            value={mailTemplate.subject}
            onChange={this.handleSubjectChange}
            className={classes.name}
            error={isEmpty(mailTemplate.subject)}
            helperText={isEmpty(mailTemplate.subject) && t('Requis')}
          />
          <Flex grow />
          <IconButton onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Editor
            defaultValue={mailTemplate.template}
            onChange={this.handleTemplateChange}
            tags={tags}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            {t('Annuler')}
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
          >
            {t('sauvegarder')}
          </Button>
        </DialogActions>
      </>
    )
  }

  render() {
    const { open } = this.props

    return (
      <Dialog open={open} onClose={this.handleClose} fullWidth maxWidth="xl">
        {this.renderContent()}
      </Dialog>
    )
  }
}

export default withStyles(styles)(TraderSettingsMailTemplatesDialog)
