import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Layout from '../components/Layout/Layout'
import ResetPassword from '../scenes/ResetPassword'
import SignIn from '../scenes/SignIn'
import SecuredAuth from '../scenes/SecuredAuth'

import LoggedRoutes from './LoggedRoutes'
import ForgotPassword from '../scenes/ForgotPassword'
import UserContext from '../contexts/UserContext'

const Routes = () => {
  const { user } = React.useContext(UserContext)

  if (user) {
    return (
      <Layout>
        <LoggedRoutes />
      </Layout>
    )
  }

  return (
    <Switch>
      <Route exact path="/sign-in" component={SignIn} />
      <Route exact path="/secured-auth" component={SecuredAuth} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Redirect to="/sign-in" />
    </Switch>
  )
}

export default Routes
