import axios from '../config/axios'
import Advice from '../models/Advice'

export const findAll = () => axios.get<Advice[]>('api/advices')
export const findOne = (code: string) =>
  axios.get<Advice>(`api/advices/${code}`)
export const update = (advice: Advice) =>
  axios.put<Advice>(`api/advices/${advice.code}`, advice)

export default { findAll, findOne, update }
