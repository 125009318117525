import { Dialog, Typography, Button } from '@material-ui/core'
import { capitalize } from '@material-ui/core/utils/helpers'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import green from '@material-ui/core/colors/green'

import { MUIDataTableColumnDef } from 'mui-datatables'
import Fetcher from '../../../components/Fetcher'
import SalesList from '../../../components/Sale/SalesList'
import TargetOrderList from '../../../components/TargetOrderList'
import SalesListDetails from '../../../components/Sale/SalesListDetails'
import { t } from '../../../i18n'
import { format } from '../../../i18n/format'
import transactionService, {
  TransactionListItemView,
} from '../../../services/transactionService'
import targetOrderService, {
  TargetOrderView,
} from '../../../services/targetOrderService'
import Margin from '../../../components/Margin'
import transactionTransform from '../../../transform/transactionTransform'

const useStyles = makeStyles(() => ({
  cell: {
    fontWeight: 'bold',
    color: green[600],
  },
}))

export const CustomerSalesList = () => {
  const classes = useStyles()
  const [showDetails, setShowDetails] = useState<
    Optional<TransactionListItemView>
  >()

  const getTableColumns = (): Array<string | MUIDataTableColumnDef> => [
    t('Date de la vente'),
    t('Produit'),
    t('Campagne'),
    { name: 'tons', label: t('Tonnage'), options: { filter: false } },
    t('Modalité'),
    { name: 'price', label: t('Prix (€/t)'), options: { filter: false } },
    t('Exécution'),
    t('Paiement'),
  ]

  const getTableTargetOrderColumns = (): Array<
    string | MUIDataTableColumnDef
  > => [
    t("Date de l'ordre"),
    t('Produit'),
    t('Campagne'),
    { name: 'tons', label: t('Tonnage souscrit'), options: { filter: false } },
    {
      name: 'tonnageExecuted',
      label: t('Tonnage exécuté'),
      options: {
        filter: false,
        customBodyRender: (value: number) =>
          value !== 0 ? (
            <span className={classes.cell}>{value}</span>
          ) : (
            <span>{value}</span>
          ),
      },
    },
    t('Modalité'),
    t('Execution'),
    {
      name: 'actualPrice',
      label: t('Prix actuel (€/t)'),
      options: { filter: false },
    },
    {
      name: 'customerTargetPrice',
      label: t('Prix objectif (€/t)'),
      options: { filter: false },
    },
  ]

  const transactionToTableData = (transaction: TransactionListItemView) => [
    transaction.creationDate,
    capitalize(transaction.productName),
    transaction.campaignYear,
    transaction.tonnage,
    capitalize(transaction.executionMode),
    transaction.price,
    format(transaction.executionDate, 'dd/MM/yyyy'),
    format(transaction.paymentDate, 'dd/MM/yyyy'),
  ]

  const targetOrderToTableData = (targetOrder: TargetOrderView) => [
    format(targetOrder.builtAt, 'dd/MM/yyyy'),
    capitalize(targetOrder.productName),
    targetOrder.campaignName,
    targetOrder.tons,
    targetOrder.tonnageExecuted,
    capitalize(targetOrder.executionModeLabel),
    format(targetOrder.executionDate, 'MMMM yyyy'),
    targetOrder.actualPrice ? targetOrder.actualPrice : '--',
    targetOrder.customerTargetPrice,
  ]

  const handlePreview = (value: TransactionListItemView) => {
    setShowDetails(value)
  }

  const handleCloseDetails = () => {
    setShowDetails(undefined)
  }

  const fetchTargetOrder = () => {
    return targetOrderService.findAll(['CREATED'])
  }

  const renderListTargetOrder = (
    targetOrders: TargetOrderView[],
    refresh: () => void
  ) => {
    return (
      <TargetOrderList
        targetOrders={targetOrders}
        columns={getTableTargetOrderColumns()}
        tableDataMapper={targetOrderToTableData}
        refresh={refresh}
        status="CREATED"
      />
    )
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t('Listes de vos ventes')}
      </Typography>
      <Link to={'/target-order/cancel'}>
        <Button color="primary">
          {t('Voir les ordres annulés et/ou partiellement exécutés')}
        </Button>
      </Link>
      <Margin bottom={2}>
        <Fetcher fetch={fetchTargetOrder}>{renderListTargetOrder}</Fetcher>
      </Margin>

      <Fetcher
        fetch={transactionTransform.getAllTransactionsCreationDateFormatted}
      >
        {(transactions, refresh) => (
          <SalesList
            transactions={transactions}
            columns={getTableColumns()}
            tableDataMapper={transactionToTableData}
            handlePreview={handlePreview}
            refresh={refresh}
            title="Ventes réalisées"
          />
        )}
      </Fetcher>
      {showDetails && (
        <Dialog
          onClose={handleCloseDetails}
          open={true}
          maxWidth="lg"
          fullWidth
          scroll="body"
        >
          <SalesListDetails transaction={showDetails} />
        </Dialog>
      )}
    </>
  )
}

export default CustomerSalesList
