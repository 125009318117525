import React, { useState, useContext } from 'react'
import { RouteComponentProps } from 'react-router'
import { Typography, Dialog, Button } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import capitalize from 'lodash/capitalize'
import format from 'date-fns/format'
import { t } from '../../../i18n'
import targetOrderService from '../../../services/targetOrderService'
import Margin from '../../../components/Margin'
import TargetOrderRecapCard from '../../../components/TargetOrderRecapCard'
import Fetcher from '../../../components/Fetcher'
import transactionService, {
  TransactionListItemView,
} from '../../../services/transactionService'
import SalesList from '../../../components/Sale/SalesList'
import SalesListDetails from '../../../components/Sale/SalesListDetails'
import Flex from '../../../components/Flex'
import ErrorContext from '../../../contexts/ErrorContext'

type Props = RouteComponentProps<{ id: string }>

export const CustomerTargetOrderDetail = ({
  location,
  history,
  match,
}: Props) => {
  const errorContext = useContext(ErrorContext)
  const targetOrderId = Number(match.params.id)
  const [showDetails, setShowDetails] = useState<
    Optional<TransactionListItemView>
  >()

  const getTableColumns = () => [
    t('Date de la vente'),
    t('Produit'),
    t('Campagne'),
    t('Tonnage'),
    t("Modalités d'exécution"),
    t('Prix (€/t)'),
    t("Date d'exécution"),
    t('Date de paiement'),
  ]

  const transactionToTableData = (transaction: TransactionListItemView) => [
    transaction.creationDate,
    capitalize(transaction.productName),
    transaction.campaignYear,
    transaction.tonnage,
    capitalize(transaction.executionMode),
    transaction.price,
    format(transaction.executionDate, 'dd/MM/yyyy'),
    format(transaction.paymentDate, 'dd/MM/yyyy'),
  ]

  const fetchTargetOrder = () => {
    return targetOrderService.findById(targetOrderId)
  }

  const fetchRelatedTransactions = () => {
    return transactionService.findByTargetOrderId(targetOrderId)
  }

  const handlePreview = (value: TransactionListItemView) => {
    setShowDetails(value)
  }

  const handleCloseDetails = () => {
    setShowDetails(undefined)
  }

  const onDelete = async () => {
    try {
      const { id } = location.state.targetOrder
      if (id) {
        await targetOrderService.cancelById(id)
      }
      history.push('/sales/list')
    } catch (e) {
      errorContext.displayError("Impossible de retirer l'ordre")
    }
  }

  return (
    <Fetcher fetch={fetchTargetOrder}>
      {targetOrder => (
        <>
          <Margin bottom={2}>
            <Flex direction="row" justify="space-between">
              <Typography variant="h6">
                {t("Détail de l'ordre de vente")}
              </Typography>
              <Button
                color="primary"
                onClick={onDelete}
                disabled={targetOrder.status !== 'CREATED'}
              >
                <Close />
                {t("Retirer l'ordre")}
              </Button>
            </Flex>
          </Margin>
          <Margin bottom={2}>
            <TargetOrderRecapCard targetOrder={targetOrder} />
          </Margin>
          <Fetcher fetch={fetchRelatedTransactions}>
            {(transactions, refresh) => (
              <SalesList
                transactions={transactions}
                columns={getTableColumns()}
                tableDataMapper={transactionToTableData}
                handlePreview={handlePreview}
                refresh={refresh}
                title="Ventes réalisées via cet ordre"
              />
            )}
          </Fetcher>
          {showDetails && (
            <Dialog
              onClose={handleCloseDetails}
              open={true}
              maxWidth="lg"
              fullWidth
            >
              <SalesListDetails transaction={showDetails} />
            </Dialog>
          )}
        </>
      )}
    </Fetcher>
  )
}

export default CustomerTargetOrderDetail
