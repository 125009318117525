import { FormControl, FormLabel } from '@material-ui/core'
import isValid from 'date-fns/isValid'
import { FieldProps, getIn } from 'formik'
import { InlineDatePicker } from 'material-ui-pickers'
import { DatePickerInlineProps } from 'material-ui-pickers/DatePicker/DatePickerInline'
import * as React from 'react'

import { t } from '../../i18n'
import { format } from '../../i18n/format'

export interface InlineDatePickerProps
  extends FieldProps,
    DatePickerInlineProps {}

export const DatePickerMinDate = new Date()

export const fieldToInlineDatePicker = ({
  field,
  form,
  ...props
}: InlineDatePickerProps): DatePickerInlineProps => {
  const { name } = field
  const { touched, errors, setFieldValue, setFieldTouched } = form

  const fieldError = getIn(errors, name)
  const showError = getIn(touched, name) && !!fieldError

  // override formik onChange instead of setting type to date which add native arrow to date inputs
  const onChange = (value: Date) => {
    setFieldTouched(name, true)
    setFieldValue(name, value)
  }
  const onError = (value: Date) => {
    setFieldTouched(name, true)
    setFieldValue(name, value)
  }

  let helperText = null
  if (fieldError) {
    if (fieldError === 'Requis') {
      helperText = t('Requis')
    } else if (fieldError.includes('field must be later than')) {
      helperText = t(
        `La date doit être postérieure au ${format(
          DatePickerMinDate,
          'dd/MM/yyyy'
        )}`
      )
    } else {
      helperText = t('Date invalide (jj/mm/aaaa)')
    }
  }

  return {
    ...field,
    onChange,
    onError,
    minDate: DatePickerMinDate,
    error: showError,
    helperText,
    ...props,
    disableOpenOnEnter: true,
    keyboard: true,
    format: 'dd/MM/yyyy',
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    value: isValid(field.value) ? field.value : null,
  }
}

export const DatePicker: React.ComponentType<InlineDatePickerProps> = ({
  label,
  ...others
}: InlineDatePickerProps) => (
  <FormControl>
    {label && <FormLabel htmlFor={others.field.name}>{label}</FormLabel>}
    <InlineDatePicker
      id={others.field.name}
      name={others.field.name}
      margin="dense"
      variant="filled"
      {...fieldToInlineDatePicker(others)}
    />
  </FormControl>
)

DatePicker.displayName = 'FormikMaterialUIInlineDatePicker'
