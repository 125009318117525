import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import RefreshIcon from 'mdi-material-ui/Sync'
import memoizeOne from 'memoize-one'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { t } from '../../../../i18n'
import DataTable from '../../../../components/DataTable'
import MarketOperation from '../../../../models/MarketOperation'

const useStyles = makeStyles(() => ({
  iconBtn: {
    padding: 4,
  },
}))

interface Props {
  marketOperation: MarketOperation[]
  columns: MUIDataTableColumnDef[]
  tableDataMapper: (
    marketOperation: MarketOperation
  ) => Array<string | number | MarketOperation>
  refresh?: () => void
  title: string
}

export const ControllerDataOperationList = ({
  marketOperation,
  columns,
  tableDataMapper,
  refresh,
  title,
}: Props) => {
  const classes = useStyles()

  const dataTableOptions = {
    download: true,
    downloadOptions: {
      filename: 'Ordres au marché.csv',
      separator: ';',
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    customToolbar: () => {
      if (refresh) {
        return (
          <Tooltip title={t('Rafraîchir les données')}>
            <IconButton
              color="default"
              className={classes.iconBtn}
              onClick={refresh}
            >
              <RefreshIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )
      }
      return
    },
  }

  const dataToTable = memoizeOne((marketOperation: MarketOperation[]) =>
    marketOperation.map(data => tableDataMapper(data).concat(data))
  )

  return (
    <DataTable
      columns={columns}
      title={title}
      data={dataToTable(marketOperation)}
      emptyContent={t('Aucune donnée')}
      options={dataTableOptions}
      defaultSort={{ name: "Date de l'ordre", direction: 'desc' }}
    />
  )
}

export default ControllerDataOperationList
