import {
  Card,
  createStyles,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent/CardContent'
import * as React from 'react'

import CardContentColored from '../../../components/CardContentColored'
import Margin from '../../../components/Margin'
import VerticalSelect from '../../../components/VerticalSelect'
import { t } from '../../../i18n'
import Campaign from '../../../models/Campaign'
import CustomerStock from '../../../models/CustomerStock'
import getCurrentCampaign from '../../../utils/getCurrentCampaign'
import sortCampaigns from '../../../utils/sortCampaigns'

import StocksCardDeposit from './CustomerStocksCardDeposit'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
      minHeight: 200,
    },
    products: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'hidden',
    },
    cardContent: {
      padding: 0,
    },
  })

interface Props extends WithStyles<typeof styles> {
  campaigns: Campaign[]
  customerStocks: CustomerStock[]
}

interface State {
  selectedCampaign: Campaign
}

class CustomerStocksCard extends React.Component<Props, State> {
  readonly state = {
    selectedCampaign: getCurrentCampaign(sortCampaigns(this.props.campaigns)),
  }

  onCampaignChange = (selectedCampaign: Campaign) =>
    this.setState({ selectedCampaign })
  campaignsKeyExtractor = (c: Campaign) => c.id
  campaignsRenderItem = (c: Campaign) => t(c.name)

  render() {
    const { classes, campaigns, customerStocks } = this.props
    const { selectedCampaign } = this.state

    const customerStocksSelectedCampaign = customerStocks.filter(
      cStock => cStock.campaign.id === selectedCampaign.id
    )

    return (
      <>
        <Card className={classes.root}>
          <CardContentColored color="primary" className={classes.cardContent}>
            <Margin left={3} right={3} top={2} bottom={2}>
              <Typography
                variant="subtitle2"
                align="center"
                color="inherit"
                noWrap
              >
                {t('CAMPAGNE')}
              </Typography>
            </Margin>
            <VerticalSelect
              values={sortCampaigns(campaigns)}
              defaultValue={selectedCampaign}
              onChange={this.onCampaignChange}
              keyExtractor={this.campaignsKeyExtractor}
              renderItem={this.campaignsRenderItem}
            />
          </CardContentColored>
          <CardContent className={classes.products}>
            <StocksCardDeposit
              customerStocks={customerStocksSelectedCampaign}
              campaign={selectedCampaign}
            />
          </CardContent>
        </Card>
      </>
    )
  }
}

export default withStyles(styles)(CustomerStocksCard)
