import { PropTypes, WithStyles, CircularProgress } from '@material-ui/core'
import Button, { ButtonProps } from '@material-ui/core/Button/Button'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import * as React from 'react'

import { t } from '../i18n'

import SnackbarsExtended from './SnackbarsExtended'

type PromiseFunction = (...props: any[]) => Promise<any>
type FunctionError = (...props: any[]) => string

const styles = () =>
  createStyles({
    errorParagraph: {
      position: 'absolute',
      color: '#f44336',
    },
    button: {
      position: 'relative',
    },
  })

interface Props extends WithStyles<typeof styles> {
  color?: PropTypes.Color
  variant?: ButtonProps['variant']
  size?: ButtonProps['size']
  loadingIconSizePx?: number
  disabled?: boolean
  onSubmit: PromiseFunction
  onSubmitParams?: any[]
  buttonText: string
  errorInSnackBar?: boolean
  message: string
  onError?: FunctionError
}

interface State {
  error: boolean
  isLoading: boolean
  message: string
}

class Poster extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    color: 'primary',
    variant: 'contained',
    size: 'medium',
    loadingIconSizePx: 21,
    disabled: false,
    errorInSnackBar: false,
    message: 'Une erreur est survenue. Veuillez réessayer.',
  }

  state = {
    error: false,
    isLoading: false,
    message: this.props.message,
  }

  handleSnackbarClose = () => {
    this.setState({ error: false })
  }

  handleSubmit = async () => {
    const { onSubmit, onSubmitParams, onError } = this.props

    this.setState({ isLoading: true })

    try {
      await onSubmit(...(onSubmitParams || []))

      this.setState({ error: false })
    } catch (e) {
      this.setState({ error: true })

      if (onError) {
        this.setState({ message: onError(e) })
      }
    }

    this.setState({ isLoading: false })
  }

  render() {
    const {
      color,
      variant,
      buttonText,
      size,
      loadingIconSizePx,
      disabled,
      errorInSnackBar,
      classes,
    } = this.props
    const { error, isLoading, message } = this.state

    return (
      <>
        <span className={classes.button}>
          <Button
            onClick={this.handleSubmit}
            color={color}
            variant={variant}
            size={size}
            disabled={disabled || isLoading}
            autoFocus
          >
            {!isLoading && t(buttonText)}
            {isLoading && (
              <CircularProgress color={'inherit'} size={loadingIconSizePx} />
            )}
          </Button>
          {!errorInSnackBar && !isLoading && error && (
            <p className={classes.errorParagraph}>{message}</p>
          )}
        </span>
        {errorInSnackBar && !isLoading && error && (
          <SnackbarsExtended
            open={error}
            variant="error"
            message={message}
            onClose={this.handleSnackbarClose}
          />
        )}
      </>
    )
  }
}

export default withStyles(styles)(Poster)
