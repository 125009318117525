import {
  CardContent,
  createStyles,
  FormLabel,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { InlineDateTimePicker } from 'material-ui-pickers'
import { MaterialUiPickersDate } from 'material-ui-pickers/typings/date'
import * as React from 'react'

import { t } from '../../../i18n'

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
    },
    rowLayout: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  })

export type FilterValues = {
  startDate: Date
}

type OuterProps = {
  values: FilterValues
  onChange: (values: FilterValues) => void
}

type Props = WithStyles<typeof styles> & OuterProps

const TraderOffersListFiltersCard: React.FunctionComponent<Props> = ({
  classes,
  onChange,
  values,
}) => {
  const handleDateChange = (startDate: MaterialUiPickersDate) => {
    if (startDate !== values.startDate) {
      onChange({ ...values, startDate })
    }
  }

  return (
    <Card data-cy={'offers-list-filters'}>
      <CardContent>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel
                htmlFor="startDateFilter"
                className={classes.rowLayout}
              >
                {t('Je recherche une offre en cours le')}
              </FormLabel>
              <InlineDateTimePicker
                id="startDateFilter"
                name="startDate"
                value={values.startDate}
                className={classes.rowLayout}
                onChange={handleDateChange}
                invalidDateMessage={t('Date invalide')}
                disableOpenOnEnter={true}
                keyboard={true}
                showTabs={false}
                ampm={false}
                autoOk={true}
                format={'dd/MM/yyyy HH:mm'}
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  ' ',
                  /\d/,
                  /\d/,
                  ':',
                  /\d/,
                  /\d/,
                ]}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(TraderOffersListFiltersCard)
