import * as React from 'react'

import { Field, Form, Formik, FormikActions, FormikProps } from 'formik'
import { Theme, Typography, WithStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import createStyles from '@material-ui/core/styles/createStyles'
import memoizeOne from 'memoize-one'
import withStyles from '@material-ui/core/styles/withStyles'
import ExecutionMode, { EExecutionMode } from '../../../models/ExecutionMode'

import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Loading from '../../../components/Loading'
import Margin from '../../../components/Margin'
import SettingsConflictErrorDialog from './SettingsConflictErrorDialog'
import SnackbarsExtended from '../../../components/SnackbarsExtended'
import { Switch } from '../../../components/Form/Switch'
import TextField from '../../../components/Form/TextField'
import TraderSettingsConfigCard from './TraderSettingsConfigCard'
import adviceService from '../../../services/adviceService'
import executionModeService from '../../../services/executionModeService'
import { t } from '../../../i18n'
import { CheckboxWithLabel } from '../../../components/Form/CheckboxWithLabel'

const styles = (theme: Theme) =>
  createStyles({
    formMain: {
      width: '100%',
      paddingRight: 20,
    },
    formControl: {
      marginRight: theme.spacing(8),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: '100%',
    },
    submit: {
      width: '100%',
    },
    loading: {
      padding: 30,
    },
    form: {
      width: '100%',
    },
    field: {
      width: '30%',
    },
    fieldDefinition: {
      width: '75%',
    },
  })
type Props = WithStyles<typeof styles>

type ExecutionModesMap = { [key: string]: ExecutionMode }

type State = {
  executionModes: ExecutionModesMap
  isLoading: boolean
  error: 'NONE' | 'CONFLICT' | 'OTHER'
}

class ExecutionModesSettings extends React.Component<Props, State> {
  state: State = {
    executionModes: {},
    isLoading: true,
    error: 'NONE',
  }

  getInitialFormValues: () => ExecutionModesMap = memoizeOne(() => {
    const { executionModes } = this.state
    return executionModes
  })

  componentDidMount(): void {
    this.getSaleSettings()
  }

  getSaleSettings = async () => {
    const { data: executionModesList } = await executionModeService.findAll()
    const executionModes = executionModesList.reduce((map, value) => {
      map[value.type] = value
      return map
    }, {})
    this.setState({ executionModes, isLoading: false })
  }

  handleSubmit = async (
    values: ExecutionModesMap,
    { setSubmitting }: FormikActions<ExecutionModesMap>
  ) => {
    try {
      await executionModeService.update(Object.values(values))
    } catch (e) {
      if (e.response.status === 409) {
        this.setState({ error: 'CONFLICT' })
      } else {
        this.setState({ error: 'OTHER' })
      }
    } finally {
      setSubmitting(false)
    }
  }

  closeConflictDialog = () => {
    this.setState({ error: 'NONE' })
  }

  handleSnackbarClose = () => {
    this.setState({ error: 'NONE' })
  }

  renderSaleSettings = ({
    values,
    isSubmitting,
  }: FormikProps<ExecutionModesMap>) => {
    const { classes } = this.props
    const executionModeTypesInOrder: EExecutionMode[] = [
      'HARVEST_SILO_DELIVERY',
      'DEPOSIT_DELIVERY',
      'STORED_SILO_DELIVERY',
      'BUSHEL_DEPARTURE',
    ]
    return (
      <Form className={classes.form}>
        <Flex>
          <Margin bottom={2} className={classes.formMain}>
            {executionModeTypesInOrder.map(
              (mode, index) =>
                !!values[mode] && (
                  <>
                    <Flex
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                    >
                      <Typography color="primary" variant="overline">
                        Modalité {index + 1} -{' '}
                        {mode === 'BUSHEL_DEPARTURE' && t('Départ Direct')}
                        {mode === 'DEPOSIT_DELIVERY' &&
                          t('Déstockage du dépôt')}
                        {mode === 'HARVEST_SILO_DELIVERY' &&
                          t('Livraison silo récolte')}
                        {mode === 'STORED_SILO_DELIVERY' &&
                          t('Livraison silo stocké')}
                      </Typography>
                      <FormControl>
                        <Field
                          component={Switch}
                          name={`${mode}.active`}
                          color="primary"
                        />
                      </FormControl>
                    </Flex>
                    <Margin>
                      <FormControl>
                        <Field
                          component={CheckboxWithLabel}
                          name={`${mode}.byDefault`}
                          disabled={!values[mode].active}
                          color="primary"
                          label={{ label: t('Par défaut') }}
                        />
                      </FormControl>
                    </Margin>
                    <Margin>
                      <FormControl className={classes.formControl}>
                        <Field
                          className={classes.field}
                          component={TextField}
                          disabled={!values[mode].active}
                          name={`${mode}.name`}
                          label="Nom"
                        />
                        <FormHelperText>
                          {t('Max. 30 caractères')}
                        </FormHelperText>
                      </FormControl>
                      <Margin top={1} bottom={2}>
                        <FormControl className={classes.formControl}>
                          <Field
                            className={classes.fieldDefinition}
                            component={TextField}
                            name={`${mode}.definition`}
                            disabled={!values[mode].active}
                            label="Définition"
                          />
                          <FormHelperText>
                            {t('Max. 100 caractères')}
                          </FormHelperText>
                        </FormControl>
                      </Margin>
                    </Margin>
                  </>
                )
            )}
          </Margin>
          <Flex alignItems="center" className={classes.submit}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
            >
              {t('Valider')}
            </Button>
          </Flex>
        </Flex>
      </Form>
    )
  }

  render() {
    const { classes } = this.props
    const { isLoading, error } = this.state

    if (isLoading) {
      return (
        <Card className={classes.loading}>
          <Loading />
        </Card>
      )
    }

    return (
      <TraderSettingsConfigCard
        cardTitle="Vos modalités d'exécution"
        adviceMessage={
          <Fetcher
            fetch={adviceService.findOne}
            fetchProps={['EXECUTION_MODE']}
          >
            {advice => (
              <div dangerouslySetInnerHTML={{ __html: advice.template }} />
            )}
          </Fetcher>
        }
      >
        <Formik
          initialValues={this.getInitialFormValues()}
          onSubmit={this.handleSubmit}
          render={this.renderSaleSettings}
        />
        <SettingsConflictErrorDialog
          open={error === 'CONFLICT'}
          errorTitle={
            "Impossible de désactiver une modalité d'exécution que vous avez modifiée"
          }
          errorContent={
            'Des offres ou des ordres de vente sont en cours sur une modalité que vous tentez de désactiver.\nVeuillez les clôturer avant de la désactiver.\n\nVous devriez maintenant recharger la page pour vous synchroniser avec la base de données.'
          }
          onClose={this.closeConflictDialog}
        />
        <SnackbarsExtended
          onClose={this.handleSnackbarClose}
          open={error === 'OTHER'}
          variant={'error'}
          message={'Une erreur est survenue, veuillez recharger la page'}
        />
      </TraderSettingsConfigCard>
    )
  }
}

export default withStyles(styles)(ExecutionModesSettings)
