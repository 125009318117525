import axios from '../config/axios'
import OfferDisplaySetting from '../models/OfferDisplaySetting'

export const findAll = () =>
  axios.get<OfferDisplaySetting[]>('api/settings/customer')

export const findAllByUserId = (userId: number) =>
  axios.get<OfferDisplaySetting[]>('api/settings/customer', {
    params: { userId },
  })

export const save = (settings: OfferDisplaySetting[]) =>
  axios.post<OfferDisplaySetting[]>('api/settings/customer', settings)

export default { findAll, findAllByUserId, save }
