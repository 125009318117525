import {
  createStyles,
  IconButton,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import React, { Component } from 'react'

import Flex from '../../../components/Flex'
import Editor from '../../../components/Form/Editor'
import { t } from '../../../i18n'
import ContractTemplate from '../../../models/ContractTemplate'
import Tags from '../../../models/Tags'
import { Omit } from '../../../utils/Omit'

const styles = createStyles({
  dialog: {
    height: '100%',
  },
  title: {
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  dialogContent: {
    height: '100%',
  },
})
interface Props extends WithStyles<typeof styles> {
  open: boolean
  onChange?: (newTemplate: string) => void
  onClose?: () => void
  contractTemplate?: Omit<ContractTemplate, 'id'>
}
interface State {
  template: string
}

class ContractTemplateDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      template: props.contractTemplate ? props.contractTemplate.template : '',
    }
  }

  onSave = () => {
    const { onChange, onClose } = this.props
    const { template } = this.state
    if (onChange) {
      onChange(template)
    }
    if (onClose) {
      onClose()
    }
  }

  onChange = (template: string) => {
    this.setState({ template })
  }

  render() {
    const { open, onClose, contractTemplate, classes } = this.props
    if (!contractTemplate) {
      return null
    }

    return (
      <Dialog
        open={open}
        onClose={onClose}
        classes={{ paper: classes.dialog }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle disableTypography classes={{ root: classes.title }}>
          <Typography variant="h5">{contractTemplate.name}</Typography>
          <Flex grow />
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Editor
            defaultValue={contractTemplate.template}
            onChange={this.onChange}
            tags={Tags.TransactionContract}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('Annuler')}
          </Button>
          <Button
            onClick={this.onSave}
            color="primary"
            variant="contained"
            autoFocus
          >
            {t('Sauvegarder')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ContractTemplateDialog)
