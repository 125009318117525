import { Theme, Typography, Hidden, Omit } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button/Button'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'
import MuiLink from '@material-ui/core/Link'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import AnnouncementOutlined from '@material-ui/icons/AnnouncementOutlined'
import HelpOutline from '@material-ui/icons/HelpOutline'
import BackButton from '@material-ui/icons/KeyboardArrowLeft'
import format from 'date-fns/esm/format/index'
import parse from 'date-fns/parse'
import { Field, Formik, FormikActions, FormikProps } from 'formik'
import uniqBy from 'lodash/uniqBy'
import memoizeOne from 'memoize-one'
import React, { useState, useContext, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ObjectSchema } from 'yup'
import { useDebouncedCallback } from 'use-debounce'
import { Switch } from '../../components/Form/Switch'

import ErrorContext from '../../contexts/ErrorContext'
import TimerContext from '../../contexts/TimerContext'
import { User } from '../../contexts/UserContext'
import { t } from '../../i18n'
import Campaign from '../../models/Campaign'
import CustomerStock from '../../models/CustomerStock'
import ExecutionMode from '../../models/ExecutionMode'
import Offer from '../../models/Offer'
import Product from '../../models/Product'
import SaleSettings from '../../models/SaleSettings'
import Transaction from '../../models/Transaction'
import TargetOrder from '../../models/TargetOrder'
import transactionService, {
  TransactionRequest,
} from '../../services/transactionService'
import { Effect, EffectState } from '../../utils/Effect'
import monthToDate from '../../utils/monthToDate'
import CardContentColored from '../CardContentColored'
import Flex from '../Flex'
import FieldWarning from '../Form/FieldWarning'
import { Select } from '../Form/Select'
import TextField from '../Form/TextField'
import Margin from '../Margin'
import SaleCardValidationSchema from './SaleCardValidationSchema'
import SaleForcingPriceDialog from './SaleForcingPriceDialog'
import useRouter from '../../hooks/useRouter'
import Timer from './Timer'
import { SaleTargetPriceDialog } from './SaleTargetPriceDialog'
import targetOrderService from '../../services/targetOrderService'
import offerService, { OfferExecutionView } from '../../services/offerService'
import strikeService from '../../services/strikeService'
import { StrikeValue } from '../../models/StrikeSetting'
import DeferredPaymentOption from '../../models/DeferredPaymentOption'

const useStyles = makeStyles((theme: Theme) => ({
  leftPanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  input: {
    minWidth: '12rem',
    maxWidth: '20rem',
  },
  card: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    color: theme.palette.common.white,
  },
  formLine: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  back: {
    marginLeft: -8,
    paddingTop: 0,
    paddingRight: 4,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  bold: {
    fontSize: '2.37rem',
    fontWeight: 700,
  },
  nextStep: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      padding: '2rem',
    },
  },
  saleOption: {
    flex: 2,
  },
  help: {
    flex: 1,
    borderLeftStyle: 'solid',
    borderLeftWidth: 2,
    borderLeftColor: theme.palette.grey[300],
  },
  executionModeHelp: {
    color: theme.palette.primary.main,
  },
  priceActions: {
    ...theme.typography.caption,
    textTransform: 'none',
    textDecoration: 'underline',
    color: 'inherit',
    paddingLeft: 0,
    paddingRight: 0,
  },
  inputContext: {
    display: 'flex',
    flex: '40%',
    flexFlow: 'row wrap',
    alignItems: 'baseline',
  },
  overrides: {
    MuiFormControl: {
      root: {
        minWidth: 'auto',
      },
    },
  },
  colorSwitchBase: {
    color: theme.palette.primary.main,
  },
  colorSwitchBar: {
    backgroundColor: theme.palette.primary.light,
  },
}))

const DEBOUNCE_TIME = 800

type FormValues = {
  tonnage: number
  executionMode: ExecutionMode
  executionMonth?: string
  payment?: Date
  modePMG: boolean
  strike?: StrikeValue
}

export type StrikeMap = {
  [key in StrikeValue]: string
}

type RequiredFormValues = Required<Omit<FormValues, 'strike'>> &
  Pick<FormValues, 'strike'>

type Props = {
  executionMode: ExecutionMode
  offerExecutions: OfferExecutionView[]
  executionMonth: string
  campaign: Campaign
  product: Product
  offers: Offer[]
  saleSettings: SaleSettings
  customerStocks: CustomerStock[]
  tonnage?: number
  payment?: Date
  strike?: StrikeValue
  defaultTransaction?: Transaction
  defaultToken?: string
  customer?: User
  validationSchema?: (
    remainingToSell: number,
    saleSettings: SaleSettings,
    remainingVolume?: number
  ) => ObjectSchema
  tonnageRemainingVolumeWarning?: boolean
  canForcePrice: (order: Transaction) => boolean
}

export const SaleCard = ({
  defaultToken,
  defaultTransaction,
  executionMode,
  executionMonth,
  offerExecutions,
  customerStocks,
  campaign,
  product,
  offers,
  saleSettings,
  tonnage,
  payment,
  strike,
  customer,
  validationSchema,
  tonnageRemainingVolumeWarning,
  canForcePrice,
}: Props) => {
  const errorContext = useContext(ErrorContext)
  const timerContext = useContext(TimerContext)
  const { history } = useRouter()
  const classes = useStyles()
  const [serverErrorReason, setServerErrorReason] = useState<
    string | undefined
  >()

  const getOfferFromExecutionModeAndDate = memoizeOne(
    (executionMode: ExecutionMode, executionDate: string): Optional<Offer> => {
      const offerExecution = offerExecutions
        .filter(
          oe =>
            oe.executionMode.type === executionMode.type &&
            oe.executionDate === executionDate
        )
        .pop()
      if (offerExecution) {
        return offers.filter(o => o.id === offerExecution.offerId).pop()
      }
      return
    }
  )

  const [token, setToken] = useState<Optional<string>>(defaultToken)
  const [order, setOrder] = useState<Optional<Transaction | TargetOrder>>(
    defaultTransaction
  )
  const [strikes, setStrikes] = useState<StrikeMap>({
    ATM: 'ATM',
    PLUS_FIVE: '+5',
    PLUS_TEN: '+10',
  })
  const [offer, setOffer] = useState<Optional<Offer>>()
  const [targetPrice, setTargetPrice] = useState<Optional<number>>()
  const [transactionFetching, setTransactionFetching] = useState<boolean>(false)
  const [remainingVolume, setRemainingVolume] = useState<Optional<number>>()
  const [showForcingPriceDialog, setShowForcingPriceDialog] = useState<boolean>(
    false
  )
  const [showTargetPriceDialog, setShowTargetPriceDialog] = useState<boolean>(
    false
  )
  const [tonnageModuloWarning, setTonnageModuloWarning] = useState<boolean>(
    false
  )
  const [deferredPaymentOptions, setDeferredPaymentOptions] = useState<
    DeferredPaymentOption[]
  >([])

  const getInitialValues: () => FormValues = memoizeOne(() => {
    return {
      tonnage: tonnage || 0,
      executionMode,
      executionMonth: format(monthToDate(executionMonth), 'yyyy-MM-dd'),
      payment:
        payment ||
        (deferredPaymentOptions.length > 0
          ? deferredPaymentOptions[0].date
          : undefined),
      modePMG: !!strike,
      strike,
    }
  })

  const offerIsPMG = useMemo(() => offer && offer.modePMG, [offer])

  const getExecutionModes: (
    offerExecutions: OfferExecutionView[]
  ) => ExecutionMode[] = memoizeOne((offerExecutions: OfferExecutionView[]) => {
    const executionModes: ExecutionMode[] = offerExecutions.map(
      oe => oe.executionMode
    )
    return uniqBy(executionModes, em => em.id)
  })

  const getStrikeLabels = async () => {
    const { data } = await strikeService.findAll()
    const sSettings = { ...strikes }
    data.forEach(strike => {
      sSettings[strike.strike] = strike.label
    })
    setStrikes(sSettings)
  }

  const getExecutionMonths: (
    offerExecutions: OfferExecutionView[],
    executionMode: ExecutionMode
  ) => string[] = memoizeOne(
    (offerExecutions: OfferExecutionView[], executionMode: ExecutionMode) => {
      return offerExecutions
        .filter(oe => oe.executionMode.id === executionMode.id)
        .map(oe => oe.executionDate)
        .sort()
    }
  )

  const getRemainingToSell: (
    executionModes: ExecutionMode[],
    customerStocks: CustomerStock[],
    product: Product,
    campaign: Campaign
  ) => number = memoizeOne(
    (
      executionModes: ExecutionMode[],
      customerStocks: CustomerStock[],
      product: Product
    ) => {
      const customerStock = customerStocks.find(
        cs => cs.product.id === product.id && cs.campaign.id === campaign.id
      )
      if (
        !customerStock ||
        !executionModes.some(em => em.type === 'DEPOSIT_DELIVERY')
      ) {
        return 0
      }
      return customerStock.remainingToSell
    }
  )

  const fromValuesToTransactionRequest = (
    values: RequiredFormValues
  ): TransactionRequest => {
    return {
      campaign,
      product,
      executionMode: values.executionMode.type,
      executionMonth: parse(
        values.executionMonth,
        'yyyy-MM-dd',
        new Date(Date.UTC(96, 11, 1, 0, 0, 0))
      ),
      paymentDate: values.payment,
      tons: values.tonnage,
      strike: offerIsPMG ? values.strike : undefined,
    }
  }

  const getTransaction = async (nextValues: RequiredFormValues) => {
    let getTransaction
    const transactionRequest = fromValuesToTransactionRequest(nextValues)
    if (customer) {
      getTransaction = transactionService.getOneForCustomer(
        customer.id!,
        transactionRequest
      )
    } else {
      getTransaction = transactionService.getOne(transactionRequest)
    }
    const { token, transaction, exp } = await getTransaction
    timerContext.setEndDate(exp)
    setToken(token)
    setOrder(transaction)
  }

  const getTargetOrder = async (
    nextValues: RequiredFormValues,
    orderTargetPrice: number
  ) => {
    let getOrder
    const request = {
      ...fromValuesToTransactionRequest(nextValues),
      targetPrice: orderTargetPrice,
    }
    if (customer) {
      getOrder = targetOrderService.getOneForCustomer(customer.id!, request)
    } else {
      getOrder = targetOrderService.getOne(request)
    }
    const { data: targetOrder } = await getOrder
    setOrder(targetOrder)
  }

  const [getOrder] = useDebouncedCallback(
    async (nextValues: RequiredFormValues, orderTargetPrice?: number) => {
      try {
        if (orderTargetPrice === undefined) {
          await getTransaction(nextValues)
        } else {
          await getTargetOrder(nextValues, orderTargetPrice)
        }
        setServerErrorReason(undefined)
      } catch (error) {
        setToken(undefined)
        setOrder(undefined)
        setServerErrorReason(error.response.data.message)
        errorContext.displayError(
          t('Impossible de générer un prix pour cette offre')
        )
      } finally {
        setTransactionFetching(false)
      }
    },
    DEBOUNCE_TIME
  )

  const getDeferredPaymentOptions = async (
    executionMode: ExecutionMode,
    executionMonth: string,
    strike?: StrikeValue
  ) => {
    setDeferredPaymentOptions([])
    if (executionMode && executionMonth) {
      let getDeferredPaymentOptions
      if (customer) {
        getDeferredPaymentOptions = transactionService.fetchDeferredPaymentOptionsForCustomer(
          customer.id!,
          {
            campaign,
            product,
            executionMode: executionMode.type,
            executionMonth: parse(
              executionMonth,
              'yyyy-MM-dd',
              new Date(Date.UTC(96, 11, 1, 0, 0, 0))
            ),
            strike,
          }
        )
      } else {
        getDeferredPaymentOptions = transactionService.fetchDeferredPaymentOptions(
          {
            campaign,
            product,
            executionMode: executionMode.type,
            executionMonth: parse(
              executionMonth,
              'yyyy-MM-dd',
              new Date(Date.UTC(96, 11, 1, 0, 0, 0))
            ),
            strike,
          }
        )
      }
      try {
        const { data: deferredPaymentOptions } = await getDeferredPaymentOptions
        setDeferredPaymentOptions(deferredPaymentOptions)
      } catch (e) {
        errorContext.displayError(
          'Impossible de générer la liste des mois de paiement possibles'
        )
      }
    }
  }

  // TODO use offer prop to get remaining volume
  const getRemainingOfferVolume = async (
    nextExecutionMode: ExecutionMode,
    nextExecutionMonth: string
  ) => {
    try {
      let getRemainingOfferVolumePromise
      if (customer) {
        getRemainingOfferVolumePromise = offerService.getRemainingOfferVolumeForCustomer(
          campaign,
          product,
          nextExecutionMode.type,
          nextExecutionMonth,
          customer.id!
        )
      } else {
        getRemainingOfferVolumePromise = offerService.getRemainingOfferVolume(
          campaign,
          product,
          nextExecutionMode.type,
          nextExecutionMonth
        )
      }
      const { data: remainingVolume } = await getRemainingOfferVolumePromise
      setRemainingVolume(remainingVolume)
    } catch (e) {
      setRemainingVolume(undefined)
    }
  }

  useEffect(() => {
    setOffer(
      getOfferFromExecutionModeAndDate(
        executionMode,
        format(monthToDate(executionMonth), 'yyyy-MM-dd')
      )
    )
    getDeferredPaymentOptions(
      executionMode,
      format(monthToDate(executionMonth), 'yyyy-MM-dd'),
      strike
    )
    getRemainingOfferVolume(
      executionMode,
      format(monthToDate(executionMonth), 'yyyy-MM-dd')
    )
    // eslint-disable-next-line
  }, [executionMode, executionMonth])

  useEffect(() => {
    getStrikeLabels()
    // eslint-disable-next-line
  }, [])

  const onFormikStateChange = async (
    currentFormikState: EffectState<FormValues>,
    nextFormikState: EffectState<FormValues>
  ) => {
    const {
      executionMode: currentExecutionMode,
      executionMonth: currentExecutionMonth,
      modePMG,
    } = currentFormikState.values
    const {
      executionMode: nextExecutionMode,
      tonnage: nextTonnage,
      modePMG: nextModePMG,
      strike: nextStrike,
    } = nextFormikState.values
    let {
      executionMonth: nextExecutionMonth,
      payment: nextPayment,
    } = nextFormikState.values

    if (currentFormikState.values === nextFormikState.values) {
      return
    }

    if (!nextModePMG) {
      nextFormikState.setFieldValue('strike', undefined)
    } else if (nextModePMG && !modePMG) {
      const defaultStrike: StrikeValue = 'ATM'
      nextFormikState.setFieldValue('strike', defaultStrike)
    }

    if (nextExecutionMode && currentExecutionMode !== nextExecutionMode) {
      nextFormikState.setFieldValue('executionMonth', undefined)
      nextExecutionMonth = undefined
      nextFormikState.setFieldValue('payment', undefined)
      nextPayment = undefined
    } else if (
      nextExecutionMonth &&
      nextExecutionMonth !== currentExecutionMonth
    ) {
      getDeferredPaymentOptions(
        nextExecutionMode,
        nextExecutionMonth,
        nextStrike
      )
      getRemainingOfferVolume(nextExecutionMode, nextExecutionMonth)
      nextFormikState.setFieldValue('payment', undefined)
      nextPayment = undefined
    }

    if (nextExecutionMode !== undefined && nextExecutionMonth !== undefined) {
      setOffer(
        getOfferFromExecutionModeAndDate(nextExecutionMode, nextExecutionMonth)
      )
    }

    if (nextExecutionMode && nextExecutionMonth && nextPayment && nextTonnage) {
      setToken(undefined)
      setOrder(undefined)
      setTransactionFetching(true)
      getOrder(
        {
          executionMode: nextExecutionMode,
          executionMonth: nextExecutionMonth,
          payment: nextPayment,
          tonnage: nextTonnage,
          modePMG: nextModePMG,
          strike: nextStrike,
        },
        targetPrice
      )
    } else {
      setToken(undefined)
      setOrder(undefined)
      setTransactionFetching(false)
    }

    if (
      currentExecutionMode.type === 'BUSHEL_DEPARTURE' ||
      nextExecutionMode.type === 'BUSHEL_DEPARTURE'
    ) {
      setTonnageModuloWarning(true)
    } else {
      setTonnageModuloWarning(false)
    }
  }

  const handleDeposit = (
    actions: FormikActions<FormValues>,
    executionModes: ExecutionMode[],
    executionMonths: string[],
    remainingToSell: number
  ) => () => {
    actions.setFieldValue('tonnage', remainingToSell)
    actions.setFieldValue(
      'executionMode',
      executionModes.find(em => em.type === 'DEPOSIT_DELIVERY')
    )
    actions.setFieldValue('executionMonth', executionMonths[0])
    return false
  }

  const closeTimer = () => {
    timerContext.setEndDate(undefined)
  }

  const setForcedTransaction = (token: string, transaction: Transaction) => {
    setToken(token)
    setOrder(transaction)
  }

  const showTargetPrice = () => {
    setShowTargetPriceDialog(true)
  }

  const hideTargetPrice = () => {
    setShowTargetPriceDialog(false)
  }

  const turnIntoTargetPrice = (values: FormValues) => (targetPrice: number) => {
    setTargetPrice(targetPrice)
    setOrder(undefined)
    setToken(undefined)
    closeTimer()
    if (values.executionMonth !== undefined && values.payment !== undefined) {
      setTransactionFetching(true)
      getOrder(
        {
          ...values,
          executionMonth: values.executionMonth,
          payment: values.payment,
        },
        targetPrice
      )
    }
  }

  const removeTargetPrice = (values: FormValues) => () => {
    setTargetPrice(undefined)
    setToken(undefined)
    setOrder(undefined)
    if (values.executionMonth !== undefined && values.payment !== undefined) {
      setTransactionFetching(true)
      getOrder({
        ...values,
        executionMonth: values.executionMonth,
        payment: values.payment,
      })
    }
  }

  const showForcingPrice = () => {
    setShowForcingPriceDialog(true)
  }

  const hideForcingPrice = () => {
    setShowForcingPriceDialog(false)
  }

  const removeForcedPrice = (values: FormValues) => () => {
    setToken(token)
    setOrder(undefined)
    if (values.executionMonth !== undefined && values.payment !== undefined) {
      setTransactionFetching(true)
      getOrder({
        ...values,
        executionMonth: values.executionMonth,
        payment: values.payment,
      })
    }
  }

  const isSuperiorTo = (volume?: number) => (x: number) =>
    !!volume && x > volume

  const isATransaction = (order: TargetOrder | Transaction) => {
    return (order as Transaction).appliedManeuver !== undefined
  }

  const isNotModulo30 = () => (volume?: number) => !volume || volume % 30 !== 0

  const renderEmptyOrder = () => (
    <>
      <Typography variant="body2" color="inherit">
        {t('Vente de :')}
      </Typography>
      <Flex direction="row" alignItems="center">
        <Margin right={2}>
          <Typography className={classes.bold} color="inherit">
            {'-'}t
          </Typography>
        </Margin>
        <Typography variant="body1" color="inherit">
          à {targetPrice !== undefined ? targetPrice : '--'}€/t
        </Typography>
      </Flex>
      <Margin top={3} />
      <Typography variant="body2" color="inherit">
        {t('soit un total de :')}
      </Typography>
      <Typography className={classes.bold} color="inherit">
        {'--'}€
      </Typography>
      <Typography variant="caption" display="block" color="inherit">
        {t('brut hors TVA')}
      </Typography>
    </>
  )

  const renderTransaction = (values: FormValues, order: Transaction) => (
    <>
      <Typography variant="body2" color="inherit">
        {t('Vente de :')}
      </Typography>
      <Flex direction="row" alignItems="center">
        <Margin right={2}>
          <Typography className={classes.bold} color="inherit">
            {order.tons}t
          </Typography>
        </Margin>
        <Typography variant="body1" color="inherit">
          à {order.rawPrice}€/t
        </Typography>
      </Flex>
      {canForcePrice(order) && order.appliedManeuver === 0 && (
        <Button
          size="small"
          className={classes.priceActions}
          onClick={showForcingPrice}
        >
          {t('Forcer le prix')}
        </Button>
      )}
      {order.appliedManeuver !== 0 && (
        <Button
          size="small"
          className={classes.priceActions}
          onClick={removeForcedPrice(values)}
        >
          {t('Supprimer le prix forcé')}
        </Button>
      )}
      {offer && offer.type === 'INDEXED' && !values.modePMG && (
        <Button
          size="small"
          className={classes.priceActions}
          onClick={showTargetPrice}
        >
          {t("Définir un prix d'objectif")}
        </Button>
      )}
      {!!order.storageFee && (
        <Margin top={3}>
          <Typography variant="body2" color="inherit">
            {t('avec')} -{order.storageFee} €/t
          </Typography>
          <Typography variant="body2" color="inherit">
            <b>{t('Frais de stockage')}</b>
          </Typography>
        </Margin>
      )}
      <Margin top={3} />
      <Typography variant="body2" color="inherit">
        {t('soit un total de :')}
      </Typography>
      <Typography className={classes.bold} color="inherit">
        {order.totalPrice}€
      </Typography>
      <Typography variant="caption" display="block" color="inherit">
        {t('brut hors TVA')}
      </Typography>
    </>
  )

  const renderTargetOrder = (values: FormValues, order: TargetOrder) => (
    <>
      <Typography variant="body2" color="inherit">
        {t('Vente de :')}
      </Typography>
      <Flex direction="row" alignItems="center">
        <Margin right={2}>
          <Typography className={classes.bold} color="inherit">
            {order.tons}t
          </Typography>
        </Margin>
        <Typography variant="body1" color="inherit">
          à {targetPrice}€/t
        </Typography>
      </Flex>
      <Button
        size="small"
        className={classes.priceActions}
        onClick={removeTargetPrice(values)}
      >
        {t("Supprimer le prix d'objectif")}
      </Button>
      <Margin top={3} />
      <Typography variant="body2" color="inherit">
        {t('soit un total de :')}
      </Typography>
      <Typography className={classes.bold} color="inherit">
        {order.totalPrice}€
      </Typography>
      <Typography variant="caption" display="block" color="inherit">
        {t('brut hors TVA')}
      </Typography>
    </>
  )

  const renderBackLink = () => (
    <Link to={customer ? `/customers/${customer.id}` : '/home'}>
      <Button
        color="inherit"
        size="small"
        className={classes.back}
        onClick={closeTimer}
      >
        <BackButton fontSize="large" />
        {t('Retour')}
      </Button>
    </Link>
  )

  const renderOrder = (values: FormValues) => {
    if (transactionFetching) {
      return (
        <Flex fullWidth grow justify="center" alignItems="center">
          <CircularProgress color="inherit" />
        </Flex>
      )
    }
    if (order === undefined) {
      return renderEmptyOrder()
    }
    return targetPrice === undefined
      ? renderTransaction(values, order as Transaction)
      : renderTargetOrder(values, order as TargetOrder)
  }

  const renderSubmitButton = ({
    isSubmitting,
    isValid,
  }: {
    isSubmitting: boolean
    isValid: boolean
  }) => (
    <Button
      type="submit"
      size="large"
      variant="contained"
      disabled={!order || isSubmitting || !isValid}
      className={classes.nextStep}
    >
      {t('Continuer la vente')}
    </Button>
  )

  const renderForm = (offerExecutions: OfferExecutionView[]) => (
    formikProps: FormikProps<FormValues>
  ) => {
    const { values, handleSubmit, isSubmitting, isValid } = formikProps
    const executionModes = getExecutionModes(offerExecutions)
    const executionMonths = getExecutionMonths(
      offerExecutions,
      values.executionMode
    )
    const offerComment = offer && offer.comments
    const remainingToSell = getRemainingToSell(
      executionModes,
      customerStocks,
      product,
      campaign
    )

    return (
      <>
        {token && <Timer />}
        <form onSubmit={handleSubmit}>
          <Effect onChange={onFormikStateChange} />
          <Card className={classes.card}>
            <CardContentColored className={classes.leftPanel} color="primary">
              <Hidden smUp>{renderBackLink()}</Hidden>
              <Hidden xsDown>
                <Margin bottom={2}>{renderBackLink()}</Margin>
                {renderOrder(values)}
                <Flex grow />
                <Margin top={3} bottom>
                  {renderSubmitButton({ isSubmitting, isValid })}
                </Margin>
              </Hidden>
            </CardContentColored>
            <CardContent className={classes.saleOption}>
              <Margin bottom={2}>
                <Typography variant="body1" color="textPrimary">
                  {t('Je souhaite vendre :')}
                </Typography>
                <Flex direction="row" alignItems="center" flexFlow="wrap">
                  <Field
                    component={TextField}
                    className={classes.input}
                    name="tonnage"
                    type="number"
                    autoFocus={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">t</InputAdornment>
                      ),
                    }}
                  />
                  <div className={classes.inputContext}>
                    <Margin left={0.5} right={0.5}>
                      <Typography variant="body2" align="center">
                        {t('de')}
                      </Typography>
                    </Margin>
                    <Margin left={0.5} right={0.5}>
                      <Typography variant="h6" color="primary" align="center">
                        {product.name}
                      </Typography>
                    </Margin>
                    <Margin left={0.5} right={0.5}>
                      <Typography variant="body2" align="center">
                        {t('campagne')}
                      </Typography>
                    </Margin>
                    <Margin left={0.5} right={0.5}>
                      <Typography variant="h6" color="primary" align="center">
                        {campaign.name}
                      </Typography>
                    </Margin>
                  </div>
                </Flex>
                {tonnageRemainingVolumeWarning && (
                  <FieldWarning
                    value={values.tonnage}
                    message={t(
                      `Le tonnage entré dépasse le tonnage restant de l'offre (${remainingVolume} t)`
                    )}
                    condition={isSuperiorTo(remainingVolume)}
                  />
                )}
                {tonnageModuloWarning && (
                  <FieldWarning
                    value={values.tonnage}
                    message={t(
                      `Attention ce n’est pas un multiple de 30 tonnes, êtes vous certain du montant entré ?`
                    )}
                    condition={isNotModulo30()}
                  />
                )}
                (
                <FieldWarning
                  value={values.tonnage}
                  message={serverErrorReason!}
                  condition={() => !!serverErrorReason}
                />
                )
                {remainingToSell > 0 && (
                  <Margin top={1}>
                    <Flex direction="row" alignItems="center" flexFlow="wrap">
                      <AnnouncementOutlined color="primary" fontSize="small" />
                      <Typography
                        variant="caption"
                        display="block"
                        color="primary"
                      >
                        Vous avez du stock en dépôt.{' '}
                      </Typography>
                      <Margin left={0.5} />
                      <MuiLink
                        variant="caption"
                        component="button"
                        underline="always"
                        onClick={handleDeposit(
                          formikProps,
                          executionModes,
                          executionMonths,
                          remainingToSell
                        )}
                      >
                        {t('Déstocker')}
                      </MuiLink>
                    </Flex>
                  </Margin>
                )}
              </Margin>

              <Typography variant="body1" color="textPrimary">
                {t('La vente se fera en :')}
              </Typography>
              <div className={classes.formLine}>
                <Field
                  component={Select}
                  className={classes.input}
                  name="executionMode"
                  type="object"
                  options={executionModes}
                >
                  {executionModes.map((executionMode: ExecutionMode) => (
                    <MenuItem key={executionMode.id} value={executionMode.id}>
                      {t(executionMode.name)}
                    </MenuItem>
                  ))}
                </Field>
                <Margin left right>
                  <Typography variant="body2" align="center">
                    {t('pendant le mois')}
                  </Typography>
                </Margin>
                <Field
                  component={Select}
                  className={classes.input}
                  name="executionMonth"
                >
                  {executionMonths.map((executionMonth: string) => (
                    <MenuItem
                      key={executionMonth}
                      value={format(executionMonth, 'yyyy-MM-dd')}
                    >
                      {format(executionMonth, 'MM/yy')}
                    </MenuItem>
                  ))}
                </Field>
              </div>
              {values.executionMode && (
                <Flex direction="row" alignItems="center">
                  <HelpOutline color="primary" fontSize="small" />
                  <Typography variant="caption" display="block" color="primary">
                    {values.executionMode.definition}
                  </Typography>
                </Flex>
              )}

              {saleSettings.hasDeferredPayment && (
                <Margin top={2}>
                  <Typography variant="body1" color="textPrimary">
                    {t('Le paiement se fera le :')}
                  </Typography>
                  <Field
                    component={Select}
                    className={classes.input}
                    name="payment"
                  >
                    {deferredPaymentOptions &&
                      deferredPaymentOptions.map(
                        (
                          deferredPaymentOption: DeferredPaymentOption,
                          idx: number
                        ) => (
                          <MenuItem
                            key={idx}
                            value={format(
                              deferredPaymentOption.date,
                              'yyyy-MM-dd'
                            )}
                          >
                            {format(deferredPaymentOption.date, 'dd/MM/yyyy')} -{' '}
                            {deferredPaymentOption.value} €
                          </MenuItem>
                        )
                      )}
                  </Field>
                </Margin>
              )}
              {offerIsPMG && !targetPrice && (
                <>
                  <Margin top={2}>
                    <Margin bottom>
                      <Typography variant="body1" color="textPrimary">
                        {t(
                          "Cette offre permet l'option PMG, voulez-vous l'activer pour cette vente ?"
                        )}
                      </Typography>
                    </Margin>
                    <Flex direction="row" alignItems="center">
                      <Typography
                        variant="subtitle2"
                        color={values.modePMG ? 'textSecondary' : 'primary'}
                      >
                        {t('Non')}
                      </Typography>
                      <Field
                        name="modePMG"
                        component={Switch}
                        color="primary"
                        classes={{
                          switchBase: classes.colorSwitchBase,
                          track: classes.colorSwitchBar,
                        }}
                        value={true}
                      />
                      <Typography
                        variant="subtitle2"
                        color={values.modePMG ? 'primary' : 'textSecondary'}
                      >
                        {t('Oui')}
                      </Typography>
                    </Flex>
                  </Margin>
                  {values.modePMG && (
                    <Margin top={2}>
                      <Typography variant="body1" color="textPrimary">
                        {t('Strike :')}
                      </Typography>
                      <Field
                        component={Select}
                        className={classes.input}
                        name="strike"
                      >
                        {Object.keys(strikes).map(
                          (strikeValue: string, idx: number) => (
                            <MenuItem key={idx} value={strikeValue}>
                              {t(strikes[strikeValue] || strikeValue)}
                            </MenuItem>
                          )
                        )}
                      </Field>
                    </Margin>
                  )}
                </>
              )}
            </CardContent>
            {offerComment && (
              <CardContent className={classes.help}>
                <Margin bottom>
                  <Typography variant="body1" color="textPrimary">
                    {t('Compléments d’informations sur cette offre :')}
                  </Typography>
                </Margin>
                <Typography variant="body2">{offerComment}</Typography>
              </CardContent>
            )}
            <Hidden smUp>
              <CardContentColored className={classes.leftPanel} color="primary">
                {renderOrder(values)}
                <Flex grow />
                <Margin top={3} bottom>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    disabled={!order || isSubmitting || !isValid}
                    className={classes.nextStep}
                  >
                    {t('Continuer la vente')}
                  </Button>
                </Margin>
              </CardContentColored>
            </Hidden>
          </Card>
        </form>
        {order &&
          isATransaction(order) &&
          token &&
          offer &&
          showForcingPriceDialog && (
            <SaleForcingPriceDialog
              onClose={hideForcingPrice}
              transaction={order as Transaction}
              token={token}
              maneuver={offer.maneuver || 0}
              onForced={setForcedTransaction}
            />
          )}
        {showTargetPriceDialog && (
          <SaleTargetPriceDialog
            initialValue={order ? (order as Transaction).rawPrice : undefined}
            onClose={hideTargetPrice}
            onSave={turnIntoTargetPrice(values)}
          />
        )}
      </>
    )
  }

  const onSubmit = (
    values: FormValues,
    { setSubmitting }: FormikActions<FormValues>
  ) => {
    setSubmitting(false)
    const pathname = customer
      ? `/customers/${customer.id}/sale/recap`
      : '/sale/recap'
    history.replace(pathname, {
      order,
      token,
      campaign,
      customer,
      strikeSettings: strikes,
    })
  }
  const executionModes = getExecutionModes(offerExecutions)
  const remainingToSell = getRemainingToSell(
    executionModes,
    customerStocks,
    product,
    campaign
  )
  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={
        validationSchema
          ? validationSchema(remainingToSell, saleSettings, remainingVolume)
          : SaleCardValidationSchema(
              remainingToSell,
              saleSettings,
              remainingVolume
            )
      }
      onSubmit={onSubmit}
    >
      {renderForm(offerExecutions)}
    </Formik>
  )
}

export default SaleCard
