import axios from '../config/axios'
import Product from '../models/Product'

export const findAll = (active: boolean = true) =>
  axios.get<Product[]>(`api/products?${active ? 'active=true' : ''}`)
export const findAllWithNbOffers = (
  active: boolean = true,
  activeAt: Date | undefined = undefined
) =>
  axios.get<Product[]>(`api/products?`, {
    params: { active, activeAt, withNbOffers: true },
  })
export const updateOilPercentage = (products: Product[]) =>
  axios.put<Product>('api/products/', products)
export const update = (product: Product) =>
  axios.put<Product>('api/products/' + product.id, product)
export const importCsv = (csv: File) => {
  const formData = new FormData()
  formData.append('csv', csv)
  return axios.post<string>('api/products/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: parseInt(process.env.REACT_APP_SLOW_API_TIMEOUT || '3000'),
  })
}

export default {
  findAll,
  findAllWithNbOffers,
  importCsv,
  update,
  updateOilPercentage,
}
