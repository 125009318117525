import axios from '../config/axios'
import Merchant from '../models/Merchant'

export type CreateMerchantView = Merchant & { mailTrader: string }

const BASE_URL = 'api/merchants'

export const findAll = () => axios.get<Merchant[]>(BASE_URL)
export const update = (merchant: Merchant) =>
  axios.put<Merchant>(`${BASE_URL}/${merchant.id}`, merchant)
export const create = (merchant: CreateMerchantView) =>
  axios.post<Merchant>(`${BASE_URL}/`, merchant)
export const testApiConnection = (merchant: Merchant) =>
  axios.post<never>(`${BASE_URL}/test-api`, merchant)
export const getCurrent = () => axios.get<Merchant>(`${BASE_URL}/current`)

export default { findAll, update, create, testApiConnection, getCurrent }
