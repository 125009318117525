import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

import TraderSettingsConfigCard from '../TraderSettingsConfigCard'
import Fetcher from '../../../../components/Fetcher'
import adviceService from '../../../../services/adviceService'
import profileService from '../../../../services/profileService'
import UploadLogo from '../../../../components/UploadLogo'
import Flex from '../../../../components/Flex'

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    paddingRight: 15,
    width: '100%',
  },
}))

export const TraderSettingsProfile = () => {
  const classes = useStyles()

  return (
    <TraderSettingsConfigCard
      cardTitle={'Votre profil'}
      adviceMessage={
        <Fetcher fetch={adviceService.findOne} fetchProps={['PROFILE']}>
          {advice => (
            <div dangerouslySetInnerHTML={{ __html: advice.template }} />
          )}
        </Fetcher>
      }
    >
      <Flex
        justify="center"
        alignItems="center"
        className={classes.cardContent}
      >
        <UploadLogo size="large" action={profileService.importLogo} />
      </Flex>
    </TraderSettingsConfigCard>
  )
}

export default TraderSettingsProfile
