import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { t } from '../../i18n'

interface Props {
  open: boolean
  onClose: () => void
}

class SaleConfirmationDialog extends React.Component<Props> {
  render() {
    const { open, onClose } = this.props

    return (
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle>{t('Votre vente est confirmée.')}</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {t(
              'Consultez vos e-mails, vous allez recevoir votre document de confirmation.'
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Link to={'/sales/list'}>
            <Button color={'primary'}>{t('Voir mes ventes')}</Button>
          </Link>
          <Button color={'primary'} onClick={onClose}>
            {t('Voir mes offres')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default SaleConfirmationDialog
