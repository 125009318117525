import queryString from 'query-string'
import axios from '../config/axios'
import ContractPMG, { ContractPMGStatus } from '../models/ContractPMG'

const BASE_URL = 'api/contracts-pmg'

export type ContractPmgListItemView = {
  contractPMGId: number
  transactionId: number
  customerSocialReason: string
  contractId: string
  creationDate: Date
  productName: string
  campaignYear: string
  tonnage: number
  executionMode: string
  price: string
  status: ContractPMGStatus
  supplement?: number
  totalPrice: number
  executionDate: Date
  paymentDate: Date
  comments: string
  isModePMG: boolean
  coverageOperations: Array<{ code: string; tonsCovered: number }>
  covered: 'COVERED' | 'NOT_COVERED' | 'PARTIALLY_COVERED'
  totalTonsCovered: number
  expirationDate: Date
  strikeValue: number
  createdBy: string
}

const countByOffer = (offerId: number) =>
  axios.get<number>(`${BASE_URL}/count-pending`, {
    params: { offerId },
  })

const findAll = (statuses: string[]) =>
  axios.get<ContractPmgListItemView[]>(
    `${BASE_URL}?${queryString.stringify({ statuses })}`
  )

const computeSupplement = (pmgContractId: number) =>
  axios.post<number>(`${BASE_URL}/${pmgContractId}/supplement`)

const secure = (pmgContractId: number) =>
  axios.put<ContractPMG>(`${BASE_URL}/${pmgContractId}/secure`)

export default {
  countByOffer,
  findAll,
  computeSupplement,
  secure,
}
