import {
  CardContent,
  createStyles,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import differenceWith from 'lodash/differenceWith'
import memoize from 'memoize-one'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import CardContentColored from '../../../components/CardContentColored'
import Error from '../../../components/Error'
import Fetcher from '../../../components/Fetcher'
import Loading from '../../../components/Loading'
import Margin from '../../../components/Margin'
import VerticalSelect from '../../../components/VerticalSelect'
import { User } from '../../../contexts/UserContext'
import { t } from '../../../i18n'
import Campaign from '../../../models/Campaign'
import ExecutionMode from '../../../models/ExecutionMode'
import Offer from '../../../models/Offer'
import OfferDisplayPreference from '../../../models/OfferDisplayPreference'
import OfferDisplaySetting from '../../../models/OfferDisplaySetting'
import Product from '../../../models/Product'
import Sector from '../../../models/Sector'
import Strike, { StrikeValue } from '../../../models/StrikeSetting'
import offerService from '../../../services/offerService'
import getCurrentCampaign from '../../../utils/getCurrentCampaign'
import sortCampaigns from '../../../utils/sortCampaigns'

import CustomerOffersCardCalendar from './CustomerOffersCardCalendar'
import Flex from '../../../components/Flex'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      minHeight: 400,
    },
    offers: {
      display: 'flex',
      flexDirection: 'column',
      flex: 5,
      overflow: 'hidden',
    },
    selectors: {
      flex: 2,
    },
    cardContent: {
      padding: '0 !important',
      flex: 1,
    },
  })

interface OutterProps {
  campaigns: Campaign[]
  products: Product[]
  executionModes: ExecutionMode[]
  strikes: Strike[]
  customerSector: Sector
  settings?: OfferDisplaySetting[]
  offerDisplayPreference?: OfferDisplayPreference
  customer?: User
  fromCustomer?: boolean
}

interface InnerProps
  extends OutterProps,
    WithStyles<typeof styles>,
    RouteComponentProps<any> {}

interface State {
  selectedCampaign: Campaign
  filteredProducts: Product[]
  selectedProduct?: Product
}

class CustomerOffersCard extends React.Component<InnerProps, State> {
  filterOffers = memoize(
    (
      selectedCampaign: Campaign,
      offers: Offer[],
      selectedProduct?: Product
    ) => {
      if (!selectedProduct) {
        return []
      }

      return offers.filter(offer => {
        const hasCampaign = offer.campaign.id === selectedCampaign.id
        const hasProduct = offer.product.id === selectedProduct.id
        return hasCampaign && hasProduct
      })
    }
  )

  filterProducts = memoize(
    (
      products: Product[],
      selectedCampaign: Campaign,
      settings?: OfferDisplaySetting[],
      offerDisplayPreference?: OfferDisplayPreference
    ): Product[] => {
      let filteredProducts = products
      if (offerDisplayPreference === OfferDisplayPreference.Sell) {
        filteredProducts = this.filterSellProducts(
          products,
          selectedCampaign,
          settings
        )
      }
      if (offerDisplayPreference === OfferDisplayPreference.Watch) {
        filteredProducts = this.filterWatchProducts(
          products,
          selectedCampaign,
          settings
        )
      }
      return filteredProducts
    }
  )

  constructor(props: InnerProps) {
    super(props)
    const { products, settings, offerDisplayPreference } = props
    const selectedCampaign = getCurrentCampaign(
      sortCampaigns(this.props.campaigns)
    )
    const filteredProducts = this.filterProducts(
      products,
      selectedCampaign,
      settings,
      offerDisplayPreference
    )
    this.state = {
      selectedCampaign,
      filteredProducts,
      selectedProduct: this.getSelectedProduct(filteredProducts),
    }
  }

  handleCalendarClick = (
    executionMode: ExecutionMode,
    executionMonth: string,
    strike?: StrikeValue
  ) => {
    const { history, customer } = this.props
    const { selectedCampaign: campaign, selectedProduct: product } = this.state
    if (customer) {
      history.push(`/customers/${customer.id}/sale`, {
        campaign,
        product,
        executionMode,
        executionMonth,
        strike,
        customer,
      })
    } else {
      history.push('/sale', {
        campaign,
        product,
        executionMode,
        executionMonth,
        strike,
      })
    }
  }

  filterSellProducts = (
    products: Product[],
    selectedCampaign: Campaign,
    settings?: OfferDisplaySetting[]
  ) => {
    const others = settings!
      .filter(
        (s: OfferDisplaySetting) =>
          this.props.fromCustomer &&
          (s.preference === OfferDisplayPreference.Watch ||
            s.preference === OfferDisplayPreference.DontCare)
      )
      .map(
        (s: OfferDisplaySetting): Partial<OfferDisplaySetting> => ({
          product: s.product,
          campaign: s.campaign,
        })
      )
    return differenceWith<Product, Partial<OfferDisplaySetting>>(
      products,
      others,
      (a: Product, b: Partial<OfferDisplaySetting>): boolean =>
        a.id === (b.product && b.product.id) &&
        selectedCampaign.id === (b.campaign && b.campaign.id)
    )
  }

  filterWatchProducts = (
    products: Product[],
    selectedCampaign: Campaign,
    settings?: OfferDisplaySetting[]
  ) => {
    return settings!
      .filter(
        (s: OfferDisplaySetting) =>
          s.preference === OfferDisplayPreference.Watch &&
          s.campaign.id === selectedCampaign.id
      )
      .map((s: OfferDisplaySetting) => s.product)
  }

  getSelectedProduct = (
    filteredProducts: Product[],
    prevSelectedProduct?: Product
  ): Product | undefined => {
    const isProductPresent = !!(
      prevSelectedProduct &&
      filteredProducts.find(product => product.id === prevSelectedProduct.id)
    )
    if (isProductPresent) {
      return prevSelectedProduct
    } else if (filteredProducts.length > 0) {
      return filteredProducts[0]
    } else {
      return undefined
    }
  }

  onCampaignChange = (selectedCampaign: Campaign) => {
    const { products, settings, offerDisplayPreference } = this.props
    const { selectedProduct } = this.state
    const filteredProducts = this.filterProducts(
      products,
      selectedCampaign,
      settings,
      offerDisplayPreference
    )
    this.setState({
      selectedCampaign,
      filteredProducts,
      selectedProduct: this.getSelectedProduct(
        filteredProducts,
        selectedProduct
      ),
    })
  }
  campaignsKeyExtractor = (c: Campaign) => c.id
  campaignsRenderItem = (c: Campaign) => t(c.name)
  onProductChange = (selectedProduct: Product) =>
    this.setState({ selectedProduct })
  productsKeyExtractor = (p: Product) => p.id
  productsRenderItem = (p: Product) => t(p.name)

  render() {
    const {
      classes,
      campaigns,
      customerSector,
      executionModes,
      strikes,
      customer,
    } = this.props
    const { selectedCampaign, selectedProduct, filteredProducts } = this.state
    return (
      <Card className={classes.root} data-cy="offersCard">
        <Flex
          direction="row"
          alignItems="initial"
          className={classes.selectors}
        >
          <CardContentColored color="primary" className={classes.cardContent}>
            <Margin left={3} right={3} top={2} bottom={2}>
              <Typography
                variant="subtitle2"
                align="center"
                color="inherit"
                noWrap
              >
                {t('CAMPAGNE')}
              </Typography>
            </Margin>
            <VerticalSelect
              values={sortCampaigns(campaigns)}
              defaultValue={selectedCampaign}
              onChange={this.onCampaignChange}
              keyExtractor={this.campaignsKeyExtractor}
              renderItem={this.campaignsRenderItem}
              data-cy="campaigns"
            />
          </CardContentColored>
          <CardContentColored
            color="primary-400"
            className={classes.cardContent}
          >
            <Margin left={3} right={3} top={2} bottom={2}>
              <Typography
                variant="subtitle2"
                align="center"
                color="inherit"
                noWrap
              >
                {t('PRODUIT')}
              </Typography>
            </Margin>
            <VerticalSelect
              values={filteredProducts}
              value={selectedProduct}
              onChange={this.onProductChange}
              keyExtractor={this.productsKeyExtractor}
              renderItem={this.productsRenderItem}
              data-cy="products"
            />
          </CardContentColored>
        </Flex>
        <CardContent className={classes.offers}>
          <Fetcher
            fetch={offerService.findAllActive}
            loadingRender={Loading}
            errorRender={Error}
          >
            {(offers, refresh, lastUpdated) => (
              <CustomerOffersCardCalendar
                offers={this.filterOffers(
                  selectedCampaign,
                  offers,
                  selectedProduct
                )}
                sector={customerSector}
                executionModes={executionModes}
                strikes={strikes}
                handleRefreshClicked={refresh}
                lastUpdatedDate={lastUpdated}
                onClick={this.handleCalendarClick}
                selectedCampaign={selectedCampaign}
                customer={customer}
              />
            )}
          </Fetcher>
        </CardContent>
      </Card>
    )
  }
}

const enhancer = compose<InnerProps, OutterProps>(
  withRouter,
  withStyles(styles)
)
export default enhancer(CustomerOffersCard)
