import { Theme } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { t } from '../../../../i18n'

import TraderSettingsConfigCard from '../../../trader/Settings/TraderSettingsConfigCard'
import merchantService, {
  CreateMerchantView,
} from '../../../../services/merchantService'
import Loading from '../../../../components/Loading'
import Fetcher from '../../../../components/Fetcher'
import adviceService from '../../../../services/adviceService'
import SuperAdminSettingsMerchantsList from './SuperAdminSettingsMerchantsList'

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    paddingRight: 15,
    width: '100%',
  },
  select: {
    padding: 10 + '!important',
  },
  loading: {
    padding: 30,
  },
  table: {
    '& td:last-child': {
      paddingRight: 0,
    },
  },
  tableRow: {
    height: theme.spacing(5),
  },
  tableButtonCell: {
    paddingRight: 0,
  },
  headerLabel: {
    textTransform: 'uppercase',
    letterSpacing: '0.1rem',
  },
  modal: {
    minWidth: '400px',
  },
  modalContent: {
    paddingBottom: '4px',
  },
  formControl: {
    minWidth: 270,
  },
}))

export const SuperAdminSettingsMerchants = () => {
  const classes = useStyles()

  const renderContent = (
    merchants: CreateMerchantView[],
    refresh: () => void
  ) => {
    return (
      <SuperAdminSettingsMerchantsList
        merchants={merchants}
        refresh={refresh}
      />
    )
  }

  return (
    <TraderSettingsConfigCard
      cardTitle={t('Vos négociants')}
      adviceMessage={
        <Fetcher fetch={adviceService.findOne} fetchProps={['MERCHANT']}>
          {advice => (
            <div dangerouslySetInnerHTML={{ __html: advice.template }} />
          )}
        </Fetcher>
      }
    >
      <div className={classes.cardContent}>
        <Fetcher fetch={merchantService.findAll} loadingRender={Loading}>
          {renderContent}
        </Fetcher>
      </div>
    </TraderSettingsConfigCard>
  )
}

export default SuperAdminSettingsMerchants
