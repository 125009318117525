import React, { useState, useContext, useEffect } from 'react'
import memoizeOne from 'memoize-one'
import { MUIDataTableColumnDef } from 'mui-datatables'
import orange from '@material-ui/core/colors/orange'
import green from '@material-ui/core/colors/green'

import {
  IconButton,
  Tooltip,
  makeStyles,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Theme,
} from '@material-ui/core'
import PreviewIcon from '@material-ui/icons/VisibilityOutlined'
import RefreshIcon from 'mdi-material-ui/Sync'
import CloseIcon from '@material-ui/icons/Close'
import Warning from '@material-ui/icons/Warning'
import classNames from 'classnames'

import { t } from '../i18n'
import useRouter from '../hooks/useRouter'
import DataTable from './DataTable'
import Flex from './Flex'
import targetOrderService, {
  TargetOrderView,
} from '../services/targetOrderService'
import ErrorContext from '../contexts/ErrorContext'
import transactionService, {
  TransactionRequest,
} from '../services/transactionService'

const useStyles = makeStyles((theme: Theme) => ({
  iconBtn: {
    padding: 4,
  },
  warningCanceledBtn: {
    color: orange[600],
  },
  warningCreatedBtn: {
    color: green[600],
  },
  icon: {
    fontSize: '2rem',
    [theme.breakpoints.only('xs')]: {
      fontSize: '3.5rem',
    },
  },
}))

interface Props {
  targetOrders: TargetOrderView[]
  columns: MUIDataTableColumnDef[]
  tableDataMapper: (targetOrder: TargetOrderView) => any
  refresh: () => void
  status: 'CREATED' | 'CANCELED'
}

export const TargetOrderList = ({
  targetOrders,
  refresh,
  columns,
  tableDataMapper,
  status,
}: Props) => {
  const classes = useStyles()
  const { history } = useRouter()
  const errorContext = useContext(ErrorContext)

  const [targetOrderToCancel, setTargetOrderToCancel] = useState<
    Optional<number>
  >(undefined)
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false)
  const [data, setData] = useState<TargetOrderView[]>(targetOrders)

  useEffect(() => {
    if (status === 'CREATED') {
      const fetchData = async (views: TargetOrderView[]) => {
        const transactions = views.map(orderView => {
          const transaction: TransactionRequest = {
            executionMode: orderView.executionMode,
            executionMonth: orderView.executionDate,
            campaign: { id: orderView.campaignId },
            product: { id: orderView.productId },
            tons: orderView.tons,
            paymentDate: orderView.paymentDate,
          }
          return transaction
        })

        for (let idxData = 0; idxData < views.length; idxData++) {
          try {
            const { transaction } = await transactionService.getOneForCustomer(
              views[idxData].customerId,
              transactions[idxData]
            )
            views[idxData] = {
              ...views[idxData],
              actualPrice: transaction.rawPrice,
            }
            setData([...views])
          } catch (e) {
            // Error during getting customer transaction
          }
        }
      }
      fetchData(data)
    }
    // eslint-disable-next-line
  }, [targetOrders, status])

  const handlePreviewTargetOrder = (value: TargetOrderView) => () => {
    history.push({ pathname: `/target-order/detail/${value.id}` })
  }

  const onCancelOpen = (targetOrder: TargetOrderView) => () => {
    setCancelDialogOpen(true)
    setTargetOrderToCancel(targetOrder.id)
  }

  const onCancelClose = () => {
    setCancelDialogOpen(false)
  }

  const onCancel = async () => {
    try {
      if (targetOrderToCancel) {
        await targetOrderService.cancelById(targetOrderToCancel)
      }
      setCancelDialogOpen(false)
      refresh()
    } catch (e) {
      errorContext.displayError("Impossible de retirer l'ordre")
    }
  }

  const dataTableOptions = {
    customToolbar: () => {
      return (
        <>
          {refresh && (
            <Tooltip title={t('Rafraîchir les données')}>
              <IconButton
                color="default"
                className={classes.iconBtn}
                onClick={refresh}
              >
                <RefreshIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </>
      )
    },
  }

  const getTableColumns: () => MUIDataTableColumnDef[] = memoizeOne(() => {
    return columns.concat({
      name: '',
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value: TargetOrderView) => (
          <Flex direction="row">
            <Tooltip title={t('Voir les détails de la vente')}>
              {value.tonnageExecuted !== 0 ? (
                <IconButton
                  onClick={handlePreviewTargetOrder(value)}
                  className={classNames(
                    classes.iconBtn,
                    status === 'CREATED'
                      ? classes.warningCreatedBtn
                      : classes.warningCanceledBtn
                  )}
                >
                  <Warning className={classes.icon} />
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  onClick={handlePreviewTargetOrder(value)}
                  className={classes.iconBtn}
                >
                  <PreviewIcon className={classes.icon} />
                </IconButton>
              )}
            </Tooltip>
            {status === 'CREATED' && (
              <Tooltip title={t("Retirer l'ordre de vente")}>
                <IconButton
                  key="remove"
                  aria-label="Remove"
                  color="primary"
                  onClick={onCancelOpen(value)}
                  className={classes.iconBtn}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            )}
          </Flex>
        ),
      },
    })
  })

  const targetOrdersToTableData = memoizeOne((data: TargetOrderView[]) =>
    data.map(targetOrder => tableDataMapper(targetOrder).concat(targetOrder))
  )

  return (
    <>
      <DataTable
        columns={getTableColumns()}
        title={
          status === 'CREATED'
            ? t('Vos ordres de vente en cours')
            : t('Vos ordres de vente annulés')
        }
        data={targetOrdersToTableData(data)}
        emptyContent={t('Aucun ordre de vente')}
        options={dataTableOptions}
      />
      <Dialog
        open={cancelDialogOpen}
        onClose={onCancelClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            {t('Êtes-vous sûr de vouloir retirer cet ordre de vente ?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={onCancelClose}>
            {t('Annuler')}
          </Button>
          <Button color={'primary'} onClick={onCancel}>
            {t('Accepter')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TargetOrderList
