import {
  Button,
  Card,
  CardContent,
  createStyles,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import * as React from 'react'

import { t } from '../i18n'

import Margin from './Margin'

const styles = createStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 200,
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

interface Props extends WithStyles<typeof styles> {
  className?: string
  children: string
  onRefreshClicked?: () => void
}

const Error: React.FunctionComponent<Props> = ({
  classes,
  children,
  onRefreshClicked,
}) => (
  <Card className={classes.card}>
    <CardContent className={classes.cardContent}>
      <Margin right>
        <ErrorIcon color="error" />
      </Margin>
      <Typography variant="subtitle1">{children}</Typography>
    </CardContent>
    {onRefreshClicked && (
      <CardContent className={classes.cardContent}>
        <Button variant="contained" color="primary" onClick={onRefreshClicked}>
          {t('Réessayer')}
        </Button>
      </CardContent>
    )}
  </Card>
)

export default withStyles(styles)(Error)
