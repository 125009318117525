import {
  Dialog,
  Typography,
  Button,
  DialogContentText,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { capitalize } from '@material-ui/core/utils/helpers'
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import green from '@material-ui/core/colors/green'
import Check from 'mdi-material-ui/Check'
import Close from 'mdi-material-ui/Close'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { AccessTime } from '@material-ui/icons'
import Fetcher from '../../../components/Fetcher'
import SalesList from '../../../components/Sale/SalesList'
import TargetOrderList from '../../../components/TargetOrderList'
import SalesListDetails from '../../../components/Sale/SalesListDetails'
import { t } from '../../../i18n'
import { format } from '../../../i18n/format'
import transactionService, {
  TransactionListItemView,
} from '../../../services/transactionService'
import targetOrderService, {
  TargetOrderView,
} from '../../../services/targetOrderService'
import Margin from '../../../components/Margin'
import ErrorContext from '../../../contexts/ErrorContext'
import transactionTransform from '../../../transform/transactionTransform'

const useStyles = makeStyles(() => ({
  cell: {
    fontWeight: 'bold',
    color: green[600],
  },
}))

const csvDataMap = (separator: string) => (tr: TransactionListItemView) =>
  `${tr.customerSocialReason}${separator}${tr.contractId}${separator}${tr.creationDate}${separator}${tr.productName}${separator}${tr.campaignYear}${separator}${tr.tonnage}${separator}${tr.executionMode}${separator}${tr.covered}${separator}${tr.price}`

export const TraderSalesList = () => {
  const classes = useStyles()
  const [showDetails, setShowDetails] = useState<
    Optional<TransactionListItemView>
  >()
  const [abortConfirmModal, setAbortConfirmModal] = useState<
    Optional<TransactionListItemView>
  >()

  const errorContext = useContext(ErrorContext)

  const getTableColumns = (): Array<string | MUIDataTableColumnDef> => [
    t('Client'),
    t('Numéro contrat'),
    t('Date de la vente'),
    t('Produit'),
    t('Campagne'),
    { name: 'tons', label: t('Tonnage'), options: { filter: false } },
    t('Modalité'),
    {
      name: 'coverage',
      label: t('Couverture'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          switch (value) {
            case 'COVERED':
              return <Check color="primary" />
            case 'NOT_COVERED':
              return <Close color="error" />
            case 'PARTIALLY_COVERED':
              return <AccessTime />
            default:
              return
          }
        },
      },
    },
    { name: 'price', label: t('Prix (€/t)'), options: { filter: false } },
  ]

  const getTableTargetOrderColumns = (): Array<
    string | MUIDataTableColumnDef
  > => [
    t('Client'),
    t("Date de l'ordre"),
    t('Produit'),
    t('Campagne'),
    { name: 'tons', label: t('Tonnage souscrit'), options: { filter: false } },
    {
      name: 'tonnageExecuted',
      label: t('Tonnage exécuté'),
      options: {
        filter: false,
        customBodyRender: (value: number) =>
          value !== 0 ? (
            <span className={classes.cell}>{value}</span>
          ) : (
            <span>{value}</span>
          ),
      },
    },
    t('Modalité'),
    t('Execution'),
    {
      name: 'actualPrice',
      label: t('Prix actuel (€/t)'),
      options: { filter: false },
    },
    {
      name: 'customerTargetPrice',
      label: t('Prix objectif (€/t)'),
      options: { filter: false },
    },
  ]

  const transactionToTableData = (transaction: TransactionListItemView) => [
    capitalize(transaction.customerSocialReason || '-'),
    transaction.contractId,
    transaction.creationDate,
    capitalize(transaction.productName),
    transaction.campaignYear,
    transaction.tonnage,
    capitalize(transaction.executionMode),
    transaction.covered,
    transaction.price,
  ]

  const targetOrderToTableData = (targetOrder: TargetOrderView) => [
    capitalize(targetOrder.customerSocialReason || '-'),
    format(targetOrder.builtAt, 'dd/MM/yyyy'),
    capitalize(targetOrder.productName),
    targetOrder.campaignName,
    targetOrder.tons,
    targetOrder.tonnageExecuted,
    capitalize(targetOrder.executionModeLabel),
    format(targetOrder.executionDate, 'MMMM yyyy'),
    targetOrder.actualPrice ? targetOrder.actualPrice : '--',
    targetOrder.customerTargetPrice,
  ]

  const handlePreview = (value: TransactionListItemView) => {
    setShowDetails(value)
  }

  const handleOnAbort = (value: TransactionListItemView) => {
    setAbortConfirmModal(value)
  }

  const handleAbortContract = async () => {
    try {
      await transactionService.abortContract(abortConfirmModal!.transactionId)
      setAbortConfirmModal(undefined)
      window.location.reload()
    } catch (e) {
      errorContext.displayError(
        t('Une erreur est survenue, veuillez rafraîchir la page')
      )
    }
  }

  const handleCloseDetails = () => {
    setShowDetails(undefined)
  }

  const fetchTargetOrder = () => {
    return targetOrderService.findAll(['CREATED'])
  }

  const renderListTargetOrder = (
    targetOrders: TargetOrderView[],
    refresh: () => void
  ) => {
    return (
      <TargetOrderList
        targetOrders={targetOrders}
        columns={getTableTargetOrderColumns()}
        tableDataMapper={targetOrderToTableData}
        refresh={refresh}
        status="CREATED"
      />
    )
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t('Listes de vos ventes')}
      </Typography>
      <Link to={'/target-order/cancel'}>
        <Button color="primary">
          {t('Voir les ordres annulés et/ou partiellement exécutés')}
        </Button>
      </Link>
      {
        <Margin bottom={2}>
          <Fetcher fetch={fetchTargetOrder}>{renderListTargetOrder}</Fetcher>
        </Margin>
      }
      <SalesList
        apiCall={transactionTransform.getAllTransactionsCreationDateFormatted}
        columns={getTableColumns()}
        tableDataMapper={transactionToTableData}
        handlePreview={handlePreview}
        handleOnAbort={handleOnAbort}
        title="Ventes réalisées"
        toBackendColumnsNames={
          transactionTransform.saleListToBackendColumnsNames
        }
        csvDataMap={csvDataMap}
        defaultSort={{ name: 'Date de la vente', direction: 'desc' }}
      />
      {showDetails && (
        <Dialog
          onClose={handleCloseDetails}
          open={true}
          maxWidth="lg"
          fullWidth
        >
          <SalesListDetails transaction={showDetails} />
        </Dialog>
      )}
      {abortConfirmModal && (
        <Dialog
          onClose={() => setAbortConfirmModal(undefined)}
          open={true}
          maxWidth="lg"
          fullWidth
        >
          <DialogContentText>
            <DialogContent>
              <Typography>
                {t(
                  'Etes vous sûr de vouloir annuler ce contrat manuellement ? Cette action est irreversible.'
                )}
              </Typography>
            </DialogContent>
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={() => setAbortConfirmModal(undefined)}
              color="default"
              autoFocus
            >
              {t('Annuler')}
            </Button>
            <Button onClick={handleAbortContract} color="primary">
              {t('Confirmer')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default TraderSalesList
