import {
  createStyles,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  DialogActions,
  TextField,
} from '@material-ui/core'
import Card from '@material-ui/core/Card/Card'
import * as React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import CreateOutlined from '@material-ui/icons/CreateOutlined'
import Flex from '../../../../components/Flex'
import Loading from '../../../../components/Loading'
import SnackbarsExtended from '../../../../components/SnackbarsExtended'
import { t } from '../../../../i18n'

import SettingsConflictErrorDialog from '../../../trader/Settings/SettingsConflictErrorDialog'
import TraderSettingsConfigCard from '../../../trader/Settings/TraderSettingsConfigCard'
import ExecutionMode from '../../../../models/ExecutionMode'
import executionModeService from '../../../../services/executionModeService'

const styles = (theme: Theme) =>
  createStyles({
    cardContent: {
      paddingRight: 15,
      width: '100%',
    },
    select: {
      padding: 10 + '!important',
    },
    loading: {
      padding: 30,
    },
    table: {
      '& td:last-child': {
        paddingRight: 0,
      },
    },
    tableRow: {
      height: theme.spacing(5),
    },
    tableButtonCell: {
      paddingRight: 0,
    },
    headerLabel: {
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
    },
    modal: {
      minWidth: '400px',
    },
    modalContent: {
      paddingBottom: '4px',
    },
    formControl: {
      minWidth: 270,
    },
  })

type Props = WithStyles<typeof styles> & {}

type State = {
  error: boolean
  isLoading: boolean
  executionModes: ExecutionMode[]
  executionModeToEdit: ExecutionMode
  dialogOpen: boolean
  conflictError: boolean
}

class SuperAdminSettingsExecutionModeLabels extends React.Component<
  Props,
  State
> {
  readonly state: State = {
    error: false,
    isLoading: true,
    executionModes: [],
    dialogOpen: false,
    conflictError: false,
    executionModeToEdit: {
      id: 9999,
      name: '',
      definition: '',
      type: 'STORED_SILO_DELIVERY',
      active: false,
      byDefault: false,
    },
  }

  componentDidMount(): void {
    this.loadExecutionModes()
  }

  loadExecutionModes = async () => {
    try {
      const { data: executionModes } = await executionModeService.findAll()
      this.setState({ executionModes, isLoading: false })
    } catch (e) {
      this.setState({ error: true })
    }
  }

  closeConflictDialog = () => {
    this.setState({ conflictError: false })
  }

  onCloseError = () => {
    this.setState({ error: false })
  }

  onModalOpen = (executionMode: ExecutionMode) => async () => {
    this.setState({ dialogOpen: true, executionModeToEdit: executionMode })
  }

  onModifyExecModeCancel = () => {
    this.setState({ dialogOpen: false })
  }

  onTextModify = (event: React.ChangeEvent<HTMLInputElement>) => {
    const executionModeToEdit: ExecutionMode = {
      ...this.state.executionModeToEdit,
      name: event.target.value,
    }
    this.setState({ executionModeToEdit })
  }

  setExecMode = (executionMode: ExecutionMode) => async () => {
    this.setState(({ executionModes }) => ({
      executionModes: executionModes.map(em =>
        em.id === executionMode.id
          ? {
              ...em,
              name: executionMode.name,
            }
          : em
      ),
    }))
    try {
      await executionModeService.updateLabel(executionMode)
      this.setState({ dialogOpen: false })
    } catch (error) {
      this.setState({ error: true })
    }
  }

  render() {
    const { classes } = this.props
    const {
      error,
      isLoading,
      executionModes,
      dialogOpen,
      conflictError,
      executionModeToEdit,
    } = this.state

    if (isLoading) {
      return (
        <Card className={classes.loading}>
          <Loading />
        </Card>
      )
    }

    return (
      <>
        <TraderSettingsConfigCard
          cardTitle={"Gestion des libellés de modes d'"}
          adviceMessage={
            "Gestion des libellés de modes d'éxécution pour tous vos traders"
          }
        >
          <div className={classes.cardContent}>
            <Table padding="default" className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell>
                    <Typography color="primary" className={classes.headerLabel}>
                      {t('Label')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="primary"
                      className={classes.headerLabel}
                      align={'right'}
                    >
                      {t('Modifier')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {executionModes.map((executionMode: ExecutionMode) => (
                  <TableRow
                    key={`${executionMode.id}`}
                    className={classes.tableRow}
                  >
                    <TableCell>
                      <Typography color="textPrimary">
                        {executionMode.name}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableButtonCell}>
                      <Flex direction="row" justify="flex-end">
                        <Button
                          color="primary"
                          onClick={this.onModalOpen(executionMode)}
                          title={t(
                            "Modifier le libéllé de la modalité d'éxécution"
                          )}
                        >
                          <CreateOutlined />
                        </Button>
                      </Flex>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </TraderSettingsConfigCard>
        <Dialog
          open={dialogOpen}
          onClose={this.onModifyExecModeCancel}
          aria-labelledby="form-dialog-title"
          className={classes.modal}
        >
          <DialogContent>
            <DialogContentText className={classes.headerLabel}>
              {executionModeToEdit.name}
            </DialogContentText>
            <FormControl className={classes.modalContent}>
              <TextField
                className={classes.formControl}
                variant="filled"
                margin="dense"
                defaultValue={executionModeToEdit.name}
                onChange={this.onTextModify}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onModifyExecModeCancel} color="primary">
              Annuler
            </Button>
            <Button
              onClick={this.setExecMode(executionModeToEdit)}
              color="primary"
            >
              Modifier
            </Button>
          </DialogActions>
        </Dialog>
        <SnackbarsExtended
          onClose={this.onCloseError}
          open={error}
          variant={'error'}
          message={
            'Une erreur est survenue, si le problème persiste veuillez consulter un administrateur.'
          }
        />
        <SettingsConflictErrorDialog
          open={conflictError}
          errorTitle={'Impossible de modifier ce label'}
          errorContent={
            'Une erreur est survenue, si le problème persiste veuillez consulter un administrateur.'
          }
          onClose={this.closeConflictDialog}
        />
      </>
    )
  }
}

export default withStyles(styles)(SuperAdminSettingsExecutionModeLabels)
