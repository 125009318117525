import {
  CircularProgress,
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton/IconButton'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import Typography from '@material-ui/core/Typography/Typography'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Create'
import PreviewIcon from '@material-ui/icons/VisibilityOutlined'
import * as React from 'react'

import Margin from '../../../../components/Margin'
import ErrorContext from '../../../../contexts/ErrorContext'
import ContractTemplate, {
  NewContractTemplate,
} from '../../../../models/ContractTemplate'
import ExecutionMode from '../../../../models/ExecutionMode'
import contractTemplateService from '../../../../services/contractTemplateService'

import TraderSettingsContractTemplatesDialog from './TraderSettingsContractTemplatesDialog'

const styles = (theme: Theme) =>
  createStyles({
    list: {
      width: '100%',
    },
    title: {
      marginLeft: theme.spacing(2),
    },
  })

interface Props extends WithStyles<typeof styles> {
  executionMode: ExecutionMode
  contractTemplates: ContractTemplate[]
  refresh: () => void
}

type ModalType = 'EDIT' | 'ADD'

interface State {
  open: boolean
  contractIdLoading?: number
  modalType?: ModalType
  currentContractTemplate?: NewContractTemplate
}

class TraderSettingsContractTemplatesList extends React.Component<
  Props,
  State
> {
  static contextType = ErrorContext

  state: State = {
    open: false,
  }

  handleClose = () => {
    this.setState({
      open: false,
      modalType: undefined,
      currentContractTemplate: undefined,
    })
  }

  handleSave = async (contractTemplate: NewContractTemplate) => {
    this.setState({
      open: false,
      modalType: undefined,
      currentContractTemplate: undefined,
    })
    switch (this.state.modalType) {
      case 'EDIT':
        await contractTemplateService.update(
          contractTemplate as ContractTemplate
        )
        break
      case 'ADD':
        await contractTemplateService.create(contractTemplate)
        break
    }
    this.props.refresh()
  }

  handleEdit = (contractTemplate: ContractTemplate) => () => {
    this.setState({
      open: true,
      modalType: 'EDIT',
      currentContractTemplate: contractTemplate,
    })
  }

  handleAdd = () => {
    const { executionMode } = this.props
    const newContractTemplate: NewContractTemplate = {
      name: '',
      executionMode,
      template: '',
      contractType: 'STANDARD',
    }
    this.setState({
      open: true,
      modalType: 'ADD',
      currentContractTemplate: newContractTemplate,
    })
  }

  getSubmitLabel: () => string = () => {
    switch (this.state.modalType) {
      case 'ADD':
        return 'Ajouter'
      case 'EDIT':
        return 'Éditer'
    }
    return ''
  }

  render() {
    const { classes, contractTemplates, executionMode } = this.props
    const { open, currentContractTemplate, contractIdLoading } = this.state

    return (
      <Margin bottom={3}>
        <Typography
          variant="overline"
          color="primary"
          className={classes.title}
        >
          {executionMode.name}
        </Typography>
        <List className={classes.list}>
          {contractTemplates.map(contractTemplate => (
            <ListItem key={contractTemplate.id} role={undefined} dense button>
              <ListItemText primary={contractTemplate.name} />
              <ListItemSecondaryAction>
                {contractTemplate.editable && (
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleEdit(contractTemplate)}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                )}
                {contractIdLoading !== contractTemplate.id && (
                  <IconButton
                    target={'_blank'}
                    href={contractTemplateService.getPreviewUrl(
                      contractTemplate
                    )}
                    aria-label="Preview"
                    disabled={contractIdLoading !== undefined}
                  >
                    <PreviewIcon color="primary" />
                  </IconButton>
                )}
                {contractIdLoading === contractTemplate.id && (
                  <IconButton aria-label="Preview" disabled={true}>
                    <CircularProgress color="primary" size={24} />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <IconButton aria-label="Add" onClick={this.handleAdd}>
          <AddIcon color="primary" fontSize="small" />
        </IconButton>
        <TraderSettingsContractTemplatesDialog
          onClose={this.handleClose}
          contractTemplate={currentContractTemplate}
          open={open}
          onSave={this.handleSave}
          submitLabel={this.getSubmitLabel()}
        />
      </Margin>
    )
  }
}

export default withStyles(styles)(TraderSettingsContractTemplatesList)
