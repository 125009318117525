import * as React from 'react'

export interface TimerContextType {
  endDate?: number
  setEndDate: (endDate?: number) => void
}

const TimerContext = React.createContext<TimerContextType>({
  setEndDate: () => null,
})

export class TimerContextProvider extends React.Component<
  {},
  TimerContextType
> {
  constructor(props: any) {
    super(props)
    this.state = {
      endDate: undefined,
      setEndDate: this.setEndDate,
    }
  }

  setEndDate = (endDate?: number) => {
    this.setState({ endDate })
  }

  render() {
    const { children } = this.props

    return (
      <TimerContext.Provider value={this.state}>
        {children}
      </TimerContext.Provider>
    )
  }
}

export default TimerContext
