import { Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { capitalize } from '@material-ui/core/utils/helpers'
import * as React from 'react'

import { t } from '../i18n'
import { format } from '../i18n/format'
import Offer from '../models/Offer'
import Sector from '../models/Sector'
import UserTag from '../models/UserTag'

import CardContentColored from './CardContentColored'
import Margin from './Margin'
import ContractTypes from '../models/ContractTypesEnum'

interface Props {
  offer: Offer
  cardContentColoredClasses?: any
}

class OfferRecapData extends React.Component<Props> {
  render() {
    const { offer, children, cardContentColoredClasses } = this.props

    return (
      <CardContentColored color="primary" className={cardContentColoredClasses}>
        {children}
        <Typography variant="subtitle1" color="inherit" data-cy="product">
          {capitalize(offer.product.name)} {offer.campaign.name.toString()}
        </Typography>
        {offer.product.oleaginous && (
          <Typography
            display="block"
            variant="caption"
            color="inherit"
            data-cy="oleaginous"
          >
            {offer.oilPercent} {t('% de teneur en huile')}
          </Typography>
        )}

        <Margin top={1}>
          <Typography
            variant="subtitle1"
            color="inherit"
            data-cy="deliverySite"
          >
            {t('Destination')} {offer.deliverySite.name}
          </Typography>
        </Margin>

        <Margin top={1} bottom={1}>
          <Divider style={{ backgroundColor: 'white' }} />
        </Margin>

        <Typography
          variant="subtitle1"
          color="inherit"
          data-cy="sectorsRestriction"
        >
          {t('Secteurs')}&nbsp;
          {offer.sectorsRestriction.length === 0 && t('Tous')}
          {offer.sectorsRestriction.length > 0 &&
            offer.sectorsRestriction
              .map((sector: Sector) => sector.id)
              .join(t(' et '))}
        </Typography>

        {offer.userTagsRestriction && (
          <Typography
            variant="subtitle1"
            color="inherit"
            data-cy="userTagsRestriction"
          >
            {capitalize(
              offer.userTagsRestriction
                .map((userTag: UserTag) => userTag.name)
                .join(t(' et '))
            )}
          </Typography>
        )}

        <Margin top={1} bottom={1}>
          <Divider style={{ backgroundColor: 'white' }} />
        </Margin>

        <Typography variant="subtitle1" color="inherit" data-cy="type">
          {t('Offre')} {offer.type === 'INDEXED' ? t('Indexée') : t('Fixe')}
        </Typography>

        {offer.type === 'INDEXED' && offer.instrument && (
          <Margin top={1}>
            <Typography variant="subtitle1" color="inherit" data-cy="market">
              {t('Marché Euronext')} {offer.instrument.market}
            </Typography>
            <Typography
              display="block"
              variant="caption"
              color="inherit"
              data-cy="maturity"
            >
              {capitalize(format(offer.instrument.maturity, 'MMMM yyyy'))}
            </Typography>
          </Margin>
        )}

        <Margin top={1}>
          <Typography variant="subtitle1" color="inherit">
            {t('Prime')}
          </Typography>
          <Typography
            variant="caption"
            display="block"
            color="inherit"
            data-cy="prime"
          >
            {offer.type === 'FIXED'
              ? offer.priceToDestination
              : offer.destinationFee}{' '}
            €
          </Typography>
        </Margin>

        <Margin top={1}>
          <Typography variant="subtitle1" color="inherit">
            {t('Référence de majoration')}
          </Typography>
          <Typography
            variant="caption"
            display="block"
            color="inherit"
            data-cy="majorationTable"
          >
            <strong>{t('Table:')}</strong>{' '}
            {capitalize(offer.majorationTable.name)}
          </Typography>
          <Typography
            variant="caption"
            display="block"
            color="inherit"
            data-cy="majorationMonth"
          >
            <strong>{t('Mois:')}</strong>{' '}
            {capitalize(format(offer.majorationMonth, 'MMMM yyyy'))}
          </Typography>
        </Margin>

        {!!offer.collectFee && (
          <Margin top={1}>
            <Typography variant="subtitle1" color="inherit">
              {t('Marge collecteur')}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              color="inherit"
              data-cy="collectFee"
            >
              {capitalize(offer.collectFee.toString())} €
            </Typography>
          </Margin>
        )}

        {!!offer.maneuver && (
          <Margin top={1}>
            <Typography variant="subtitle1" color="inherit">
              {t('Manoeuvre commerciale')}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              color="inherit"
              data-cy="maneuver"
            >
              {capitalize(offer.maneuver.toString())} €
            </Typography>
          </Margin>
        )}

        {offer.modePMG && (
          <>
            <Margin top={1} bottom={1}>
              <Divider style={{ backgroundColor: 'white' }} />
            </Margin>
            <Margin top={1}>
              <Typography variant="subtitle1" color="inherit">
                {t('Option PMG')}
              </Typography>
            </Margin>
            <Margin top={1}>
              <Typography variant="subtitle1" color="inherit" data-cy="market">
                {t("Marché de l'option")} {offer.instrumentPMG!.market}
              </Typography>
              <Typography
                display="block"
                variant="caption"
                color="inherit"
                data-cy="maturity"
              >
                {capitalize(format(offer.instrumentPMG!.maturity, 'MMMM yyyy'))}
              </Typography>
            </Margin>
            <Margin top={1}>
              <Typography variant="subtitle1" color="inherit">
                {t('Marge sur option')}
              </Typography>
              <Typography
                variant="caption"
                display="block"
                color="inherit"
                data-cy="collectFee"
              >
                {capitalize(offer.optionFee!.toString())} €
              </Typography>
            </Margin>
          </>
        )}

        <Margin top={1} bottom={1}>
          <Divider style={{ backgroundColor: 'white' }} />
        </Margin>

        {offer.maximalVolume && (
          <Margin top={1}>
            <Typography variant="subtitle1" color="inherit">
              {t('Volume max.')}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              color="inherit"
              data-cy="maximalVolume"
            >
              {offer.maximalVolume.toString()} t
            </Typography>
          </Margin>
        )}

        {offer.publicationDatetime && (
          <Margin top={1}>
            <Typography variant="subtitle1" color="inherit">
              {t('Date de publication')}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              color="inherit"
              data-cy="publicationDatetime"
            >
              {format(offer.publicationDatetime, 'dd/MM/yyyy HH:mm')}
            </Typography>
          </Margin>
        )}

        {offer.expirationDatetime && (
          <Margin top={1}>
            <Typography variant="subtitle1" color="inherit">
              {t('Date de clôture')}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              color="inherit"
              data-cy="expirationDate"
            >
              {format(offer.expirationDatetime, 'dd/MM/yyyy HH:mm:ss')}
            </Typography>
          </Margin>
        )}

        <Margin top={1}>
          <Typography variant="subtitle1" color="inherit">
            {t('Type de contrat')}
          </Typography>
          {offer.contractTemplates.map(contractTemplate => (
            <React.Fragment key={contractTemplate.name}>
              <Typography variant="subtitle2" color="inherit">
                {t(contractTemplate.executionMode.name)} -{' '}
                {t(ContractTypes[contractTemplate.contractType])}
              </Typography>
              <Typography
                variant="caption"
                display="block"
                color="inherit"
                data-cy="contractTemplate"
              >
                {contractTemplate.name}
              </Typography>
            </React.Fragment>
          ))}
        </Margin>
        {offer.comments && (
          <Margin top={1}>
            <Typography variant="subtitle1" color="inherit">
              {t('Commentaires')}
            </Typography>
            <Typography
              component="pre"
              variant="caption"
              display="block"
              color="inherit"
              data-cy="comments"
              style={{ whiteSpace: 'pre-line' }}
            >
              {capitalize(offer.comments)}
            </Typography>
          </Margin>
        )}
      </CardContentColored>
    )
  }
}

export default OfferRecapData
