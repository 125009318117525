import {
  createStyles,
  IconButton,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import { ModalProps } from '@material-ui/core/Modal'
import CloseIcon from '@material-ui/core/SvgIcon/SvgIcon'
import TextField from '@material-ui/core/TextField/TextField'
import isEmpty from 'lodash/isEmpty'
import React, { Component } from 'react'

import Flex from '../../../../components/Flex'
import Editor from '../../../../components/Form/Editor'
import { t } from '../../../../i18n'
import { NewContractTemplate } from '../../../../models/ContractTemplate'
import Tags from '../../../../models/Tags'
import { Omit } from '../../../../utils/Omit'

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: '100%',
    },
    title: {
      paddingBottom: 0,
      display: 'flex',
      flexDirection: 'row',
    },
    dialogContent: {
      height: '100%',
    },
    name: {
      width: '50%',
    },
  })

interface Props
  extends WithStyles<typeof styles>,
    Omit<ModalProps, 'classes' | 'onClose' | 'children'> {
  contractTemplate?: NewContractTemplate
  open: boolean
  onClose: () => any
  onSave: (contractTemplate: NewContractTemplate) => any
  submitLabel?: string
}

interface State {
  contractTemplate?: NewContractTemplate
}

class SuperAdminSettingsContractTemplatesDialog extends Component<
  Props,
  State
> {
  state: State = {
    contractTemplate: undefined,
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (
      !prevProps.contractTemplate &&
      prevProps.contractTemplate !== this.props.contractTemplate
    ) {
      this.setState({ contractTemplate: this.props.contractTemplate })
    }
  }

  handleClose = () => {
    const { onClose } = this.props
    this.setState({ contractTemplate: undefined })
    if (onClose) {
      onClose()
    }
  }

  handleSubmit = async () => {
    const { onSave } = this.props
    const { contractTemplate } = this.state

    if (onSave) {
      await onSave(contractTemplate!)
    }
  }

  handleTemplateChange = (template: string) => {
    this.setState(({ contractTemplate }) => ({
      contractTemplate: { ...contractTemplate!, template },
    }))
  }

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value
    this.setState(({ contractTemplate }) => ({
      contractTemplate: { ...contractTemplate!, name },
    }))
  }

  renderContent() {
    const { classes } = this.props
    const { contractTemplate } = this.state

    if (!contractTemplate) {
      return <div />
    }

    return (
      <>
        <DialogTitle disableTypography classes={{ root: classes.title }}>
          <TextField
            label={t('Nom du contrat')}
            name="name"
            value={contractTemplate.name}
            onChange={this.handleNameChange}
            className={classes.name}
            error={isEmpty(contractTemplate.name)}
            helperText={isEmpty(contractTemplate.name) && t('Requis')}
          />
          <Flex grow />
          <IconButton onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Editor
            defaultValue={contractTemplate.template}
            onChange={this.handleTemplateChange}
            tags={Tags.TransactionContract}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            {t('Annuler')}
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            disabled={isEmpty(contractTemplate.name)}
          >
            {t('Sauvegarder')}
          </Button>
        </DialogActions>
      </>
    )
  }

  render() {
    const { open } = this.props

    return (
      <Dialog open={open} onClose={this.handleClose} fullWidth maxWidth="xl">
        {this.renderContent()}
      </Dialog>
    )
  }
}

export default withStyles(styles)(SuperAdminSettingsContractTemplatesDialog)
