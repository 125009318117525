import {
  Button,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import ClassNames from 'classnames'
import identity from 'lodash/identity'
import * as React from 'react'

import { t } from '../i18n'

export const ALL = 'ALL'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      position: 'relative',
      [theme.breakpoints.only('xs')]: {
        '&:after': {
          position: 'absolute',
          fontFamily: 'Material Icons',
          content: "' keyboard_arrow_down '",
          right: '8px',
          top: '8px',
          fontSize: '2rem',
        },
      },
    },
    bouton: {
      width: '100%',
      borderRadius: 0,
      color: theme.palette.text.disabled + ' !important',
      borderBottomWidth: 2,
      borderBottomStyle: 'solid',
      borderBottomColor: 'transparent',
      paddingTop: `${theme.spacing(2)}px !important`,
      paddingBottom: `${theme.spacing(2)}px !important`,
      [theme.breakpoints.only('xs')]: {
        display: 'none',
      },
    },
    activeBouton: {
      borderBottomColor: theme.palette.common.white,
      color: theme.palette.common.white + ' !important',
      [theme.breakpoints.only('xs')]: {
        display: 'block',
      },
    },
    displayAllButtons: {
      display: 'block',
    },
  })

type Key = string | number

interface Props extends WithStyles<typeof styles> {
  keyExtractor: (value: any) => Key
  renderItem: (value: any) => string
  onChange: (value: any) => void
  values: any[]
  allItem?: boolean
  defaultValue?: any
  value?: any
}

interface State {
  selectedKey?: Key
  focus?: boolean
}

class VerticalSelect extends React.Component<Props, State> {
  static defaultProps = {
    keyExtractor: identity,
    renderItem: identity,
  }

  static getDerivedStateFromProps(props: Props) {
    return props.value ? { selectedKey: props.keyExtractor(props.value) } : {}
  }

  state = {
    selectedKey: undefined,
    focus: false,
  }

  onItemClicked = (key: Key, value: any) => () => {
    const { focus } = this.state
    this.setState({ selectedKey: key })
    this.setState({ focus: !focus })
    this.props.onChange(key === ALL ? ALL : value)
  }

  render() {
    const {
      classes,
      values,
      keyExtractor,
      renderItem,
      allItem,
      defaultValue,
      ...props
    } = this.props
    const { selectedKey: selectKeyProps, focus } = this.state

    const selectedKey =
      selectKeyProps ||
      (defaultValue === ALL ? ALL : defaultValue && keyExtractor(defaultValue))

    return (
      <div className={classes.root} {...props}>
        {allItem && (
          <Button
            className={ClassNames(classes.bouton, {
              [classes.activeBouton]: ALL === selectedKey,
            })}
            onClick={this.onItemClicked(ALL, null)}
          >
            {t('TOUS')}
          </Button>
        )}
        {values.map(value => {
          const key = keyExtractor(value)
          return (
            <Button
              key={key}
              className={ClassNames(classes.bouton, {
                [classes.activeBouton]: key === selectedKey,
                [classes.displayAllButtons]: focus,
              })}
              onClick={this.onItemClicked(key, value)}
            >
              {renderItem(value)}
            </Button>
          )
        })}
      </div>
    )
  }
}

export default withStyles(styles)(VerticalSelect)
