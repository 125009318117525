import * as React from 'react'

import Add from '@material-ui/icons/Add'
import {
  createStyles,
  Typography,
  WithStyles,
  withStyles,
  Button,
  FilledInput,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core'
import Card from '@material-ui/core/Card/Card'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Switch from '@material-ui/core/Switch'
import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Loading from '../../../components/Loading'
import SettingsConflictErrorDialog from './SettingsConflictErrorDialog'
import SnackbarsExtended from '../../../components/SnackbarsExtended'
import Campaign from '../../../models/Campaign'
import TraderSettingsConfigCard from './TraderSettingsConfigCard'
import adviceService from '../../../services/adviceService'
import campaignService from '../../../services/campaignService'
import { t } from '../../../i18n'

const styles = () =>
  createStyles({
    cardContent: {
      paddingRight: 15,
      width: '100%',
    },
    yearBlock: {
      borderBottom: '1px solid #ebebeb',
      paddingLeft: 10,
    },
    addButton: {
      minWidth: 36,
    },
    select: {
      padding: 10 + '!important',
    },
    loading: {
      padding: 30,
    },
    years: {
      paddingLeft: 70,
      marginBottom: 10,
    },
  })

type Props = WithStyles<typeof styles> & {}

type State = {
  error: boolean
  isLoading: boolean
  campaigns: Campaign[]
  dialogOpen: boolean
  addableCampaigns: string[]
  campaignToAdd: string
  conflictError: boolean
}

class TraderSettingsCampaign extends React.Component<Props, State> {
  state: State = {
    error: false,
    isLoading: true,
    campaigns: [],
    dialogOpen: false,
    addableCampaigns: [],
    campaignToAdd: '',
    conflictError: false,
  }

  componentDidMount(): void {
    this.loadCampaigns()
  }

  loadCampaigns = async () => {
    try {
      const { data: campaigns } = await campaignService.findAll()
      this.setState({ campaigns, isLoading: false })
    } catch (e) {
      this.setState({ error: true })
    }
  }

  closeConflictDialog = () => {
    this.setState({ conflictError: false })
  }

  setAddableCampaigns = () => {
    const currentYear = new Date().getFullYear()
    const addableCampaignsAdjusted: string[] = []

    for (let i = currentYear - 4; i <= currentYear + 5; i++) {
      if (!this.state.campaigns.find(c => c.name === i.toString())) {
        addableCampaignsAdjusted.push(i.toString())
      }
    }
    this.setState({
      addableCampaigns: addableCampaignsAdjusted.sort(),
      campaignToAdd: addableCampaignsAdjusted[0],
    })
  }

  onChangeActiveCampaign = (campaign: Campaign) => async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target

    this.setState(({ campaigns }) => ({
      campaigns: campaigns.map(cp =>
        cp.id === campaign.id
          ? {
              ...cp,
              active: checked,
            }
          : cp
      ),
    }))

    try {
      campaign.active = checked
      await campaignService.update(campaign)
    } catch (error) {
      switch (error.response.status) {
        case 409:
          campaign.active = !checked
          this.setState(({ campaigns }) => ({
            campaigns: campaigns.map(cp =>
              cp.id === campaign.id
                ? {
                    ...cp,
                    active: !checked,
                  }
                : cp
            ),
            conflictError: true,
          }))
          break
        default:
          this.setState({ error: true })
          break
      }
    }
  }

  onCloseError = () => {
    this.setState({ error: false })
  }

  onAddCampaignOpen = () => {
    this.setState({ dialogOpen: true })
    this.setAddableCampaigns()
  }

  onAddCampaignCancel = () => {
    this.setState({ dialogOpen: false })
  }

  onAddCampaignConfirm = async () => {
    const { campaignToAdd } = this.state
    try {
      const { data: campaign } = await campaignService.create(campaignToAdd)
      this.setState(({ campaigns }) => ({
        campaigns: [...campaigns, campaign],
      }))
    } catch (e) {
      this.setState({ error: true })
    }
    this.setState({ dialogOpen: false })
  }

  onChangeAddableCampaign = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    this.setState({ campaignToAdd: String(event.target.value) })
  }

  render() {
    const { classes } = this.props
    const {
      error,
      isLoading,
      campaigns,
      dialogOpen,
      addableCampaigns,
      campaignToAdd,
      conflictError,
    } = this.state

    if (isLoading) {
      return (
        <Card className={classes.loading}>
          <Loading />
        </Card>
      )
    }

    return (
      <>
        <TraderSettingsConfigCard
          cardTitle={'Vos campagnes'}
          adviceMessage={
            <Fetcher fetch={adviceService.findOne} fetchProps={['CAMPAIGN']}>
              {advice => (
                <div dangerouslySetInnerHTML={{ __html: advice.template }} />
              )}
            </Fetcher>
          }
        >
          <div className={classes.cardContent}>
            <Typography
              data-cy={'cardTitle'}
              variant="button"
              className={classes.years}
            >
              {t('Années')}
            </Typography>
            {campaigns.map((campaign: Campaign) => (
              <Flex
                key={campaign.id}
                className={classes.yearBlock}
                direction={'row'}
                alignItems={'center'}
                justify={'space-between'}
              >
                <Typography variant={'body2'}>{campaign.name}</Typography>
                <Typography variant={'body2'}>
                  <Switch
                    color={'primary'}
                    checked={campaign.active}
                    onChange={this.onChangeActiveCampaign(campaign)}
                  />
                </Typography>
              </Flex>
            ))}
            <Button
              color="primary"
              className={classes.addButton}
              onClick={this.onAddCampaignOpen}
            >
              <Add />
            </Button>
          </div>
        </TraderSettingsConfigCard>
        <SnackbarsExtended
          onClose={this.onCloseError}
          open={error}
          variant={'error'}
          message={
            'Une erreur est survenue, si le problème persiste veuillez consulter un administrateur.'
          }
        />
        <SettingsConflictErrorDialog
          open={conflictError}
          errorTitle={'Impossible de désactiver cette campagne'}
          errorContent={
            'Des offres ou des ordres de vente sont en cours sur cette campagne.\nVeuillez les clôturer avant de la désactiver.'
          }
          onClose={this.closeConflictDialog}
        />
        <Dialog
          open={dialogOpen}
          onClose={this.onAddCampaignCancel}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <DialogContentText>
              Quelle est l’année de début de cette campagne ?
            </DialogContentText>

            <FormControl className={classes.select}>
              <Select
                name="campaignToAdd"
                value={campaignToAdd}
                onChange={this.onChangeAddableCampaign}
                input={<FilledInput />}
              >
                {addableCampaigns.map((addableCampaign: string) => (
                  <MenuItem key={addableCampaign} value={addableCampaign}>
                    {addableCampaign}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onAddCampaignCancel} color="primary">
              Annuler
            </Button>
            <Button onClick={this.onAddCampaignConfirm} color="primary">
              Ajouter
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withStyles(styles)(TraderSettingsCampaign)
