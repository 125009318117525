import { Theme, makeStyles } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import LockIcon from '@material-ui/icons/LockOutlined'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import UserContext, { UserContextType } from '../contexts/UserContext'
import { t } from '../i18n'
import * as authService from '../services/authService'
import Margin from '../components/Margin'
import useRouter from '../hooks/useRouter'

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}))

const SignIn = () => {
  const { history, location } = useRouter()
  const classes = useStyles()

  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [username, setUsername] = useState<string>(
    process.env.REACT_APP_TEST_USERNAME || ''
  )
  const [password, setPassword] = useState<string>(
    process.env.REACT_APP_TEST_PASSWORD || ''
  )

  const onSubmit = (setUser: UserContextType['set']) => async (
    e: React.SyntheticEvent
  ) => {
    e.preventDefault()

    setLoading(true)
    try {
      const user = await authService.login(username, password)
      user.username = username
      setUser(user)
      setUsername('')
      setPassword('')
      if (
        !location.state ||
        !location.state.from ||
        location.state.from === 'sign-in'
      ) {
        history.push('/')
      } else {
        history.push(location.state.from)
      }
    } catch (e) {
      setError(true)
      setLoading(false)
      setPassword('')
    }
  }

  const onChange = (key: 'username' | 'password') => (
    e: React.SyntheticEvent
  ) => {
    const target = e.target as HTMLInputElement
    if (key === 'username') {
      setUsername(target.value)
    } else if (key === 'password') {
      setPassword(target.value)
    }
  }

  return (
    <UserContext.Consumer>
      {({ set: setUser }) => (
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography variant="h5">{t('Connexion')}</Typography>
            <form className={classes.form} onSubmit={onSubmit(setUser)}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">{t('E-mail')}</InputLabel>
                <Input
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={username}
                  onChange={onChange('username')}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">{t('Mot de passe')}</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={onChange('password')}
                />
              </FormControl>
              <Margin top={2}>
                <Link
                  variant="body2"
                  align="right"
                  color="textPrimary"
                  underline="always"
                  component="div"
                >
                  <RouterLink to="/forgot-password">
                    {t('Mot de passe oublié ?')}
                  </RouterLink>
                </Link>
              </Margin>
              {error && (
                <Typography color="error">
                  {t('E-mail et/ou mot de passe incorrect(s)')}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
                disabled={loading}
              >
                {t('Connexion')}
              </Button>
            </form>
          </Paper>
        </main>
      )}
    </UserContext.Consumer>
  )
}

export default SignIn
