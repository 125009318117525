import axios from '../config/axios'
import ContractTemplate, {
  NewContractTemplate,
} from '../models/ContractTemplate'

export const findAll = () =>
  axios.get<ContractTemplate[]>('api/contract-templates')

export const getPreviewUrl = (contractTemplate: ContractTemplate) =>
  `${axios.defaults.baseURL}/api/contract-templates/${
    contractTemplate.id
  }/preview?access_token=${localStorage.getItem('access_token')}`

export const create = (contractTemplate: NewContractTemplate) =>
  axios.post<ContractTemplate>('api/contract-templates', contractTemplate)

export const update = (contractTemplate: ContractTemplate) =>
  axios.put<ContractTemplate>(
    `api/contract-templates/${contractTemplate.id}`,
    contractTemplate
  )

export default { findAll, getPreviewUrl, create, update }
