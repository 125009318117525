import { Dialog, DialogContent } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid/Grid'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'
import Typography from '@material-ui/core/Typography'
import { Field, Formik, FormikActions, FormikProps } from 'formik'
import memoizeOne from 'memoize-one'
import * as React from 'react'

import ErrorContext from '../../contexts/ErrorContext'
import { t } from '../../i18n'
import Transaction from '../../models/Transaction'
import transactionService from '../../services/transactionService'
import { Effect, EffectState } from '../../utils/Effect'
import Flex from '../Flex'
import TextField from '../Form/TextField'
import Margin from '../Margin'

import SaleForcingPriceValidationSchema from './SaleForcingPriceValidationSchema'

interface Props {
  onClose: () => void
  onForced: (token: string, transaction: Transaction) => void
  transaction: Transaction
  token: string
  maneuver: number
}

interface State {
  error?: string
  editableField?: 'RAW_PRICE' | 'STORAGE_FEE' | 'NET_PRICE'
}

interface FormValues {
  rawPrice: number
  storageFee?: number
  netPrice: number
}

class SaleForcingPriceDialog extends React.Component<Props, State> {
  static contextType = ErrorContext

  readonly state: State = {
    error: undefined,
    editableField: undefined,
  }

  getInitialValues: () => FormValues = memoizeOne(() => {
    const { transaction } = this.props
    return {
      rawPrice: transaction.rawPrice,
      storageFee: transaction.storageFee,
      netPrice: transaction.netPrice,
    }
  })

  onFormikStateChange = async (
    currentFormikState: EffectState<FormValues>,
    nextFormikState: EffectState<FormValues>
  ) => {
    const {
      rawPrice: nextRawPrice,
      storageFee: nextStorageFee,
      netPrice: nextNetPrice,
    } = nextFormikState.values

    if (currentFormikState.values === nextFormikState.values) {
      return
    }

    const {
      rawPrice: initialRawPrice,
      storageFee: initialStorageFee,
      netPrice: initialNetPrice,
    } = this.getInitialValues()

    if (initialRawPrice !== nextRawPrice) {
      this.setState({ editableField: 'RAW_PRICE' })
    } else if (initialStorageFee !== nextStorageFee) {
      this.setState({ editableField: 'STORAGE_FEE' })
    } else if (initialNetPrice !== nextNetPrice) {
      this.setState({ editableField: 'NET_PRICE' })
    } else {
      this.setState({ editableField: undefined })
    }
  }

  onSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    const { transaction, token, onForced, onClose } = this.props
    try {
      const {
        token: forcedToken,
        transaction: forcedTransaction,
      } = await transactionService.forceForCustomer(transaction.customerId, {
        forcedRawPrice: values.rawPrice,
        forcedNetPrice: values.netPrice,
        forcedStorageFee: values.storageFee,
        transactionJwt: token,
      })
      onForced(forcedToken, forcedTransaction)
      onClose()
    } catch (error) {
      actions.setSubmitting(false)
      if (error.response) {
        switch (error.response.status) {
          case 400:
            this.context.displayError('Modification du prix non autorisée')
            break
          default:
            this.context.displayError(
              "Erreur lors de l'enregistrement du prix forcé"
            )
            break
        }
      } else if (error.request) {
        this.context.displayError('Impossible de forcer le prix')
      } else {
        this.context.displayError('Erreur inconnue')
      }
    }
  }

  onReset = (values: FormValues, { resetForm }: FormikActions<FormValues>) => {
    const formValues = this.getInitialValues()
    resetForm(formValues)
  }

  addFiftyCents = (
    formikProps: FormikProps<FormValues>,
    field: string
  ) => () => {
    formikProps.setFieldValue(field, formikProps.values[field] + 0.5)
  }

  substractFiftyCents = (
    formikProps: FormikProps<FormValues>,
    field: string
  ) => () => {
    formikProps.setFieldValue(field, formikProps.values[field] - 0.5)
  }

  renderForm = () => (formikProps: FormikProps<FormValues>) => {
    const { values, handleSubmit, handleReset, isSubmitting } = formikProps
    const { onClose, maneuver } = this.props
    const { editableField } = this.state

    return (
      <Dialog open={true} fullWidth maxWidth="sm">
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Effect onChange={this.onFormikStateChange} />
          <DialogContent>
            <Typography variant="h6">{t('Manoeuvre commercial')}</Typography>
            <Typography variant="caption" display="block">
              {t('Vous avez une marge de manoeuvre commerciale de ')} -/+{' '}
              {maneuver}€
            </Typography>
            <Grid item xs={12} sm={4}>
              <Margin bottom={1} top={1}>
                {values.storageFee !== null && (
                  <>
                    <Margin bottom={1}>
                      <Typography variant="body1" color="textPrimary">
                        {t('Prix Brut')}
                      </Typography>
                      <Field
                        component={TextField}
                        name="rawPrice"
                        type="number"
                        disabled={
                          editableField && editableField !== 'RAW_PRICE'
                        }
                        fullWidth={true}
                        autoFocus={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">€/t</InputAdornment>
                          ),
                        }}
                      />
                      <Flex
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                      >
                        <Button
                          color={'primary'}
                          onClick={this.substractFiftyCents(
                            formikProps,
                            'rawPrice'
                          )}
                          disabled={
                            editableField && editableField !== 'RAW_PRICE'
                          }
                        >
                          - 0.50€
                        </Button>
                        <Button
                          color={'primary'}
                          onClick={this.addFiftyCents(formikProps, 'rawPrice')}
                          disabled={
                            editableField && editableField !== 'RAW_PRICE'
                          }
                        >
                          + 0.50€
                        </Button>
                      </Flex>
                    </Margin>
                    <Margin bottom={1}>
                      <Typography variant="body1" color="textPrimary">
                        {t('Frais de stockage')}
                      </Typography>
                      <Field
                        component={TextField}
                        name="storageFee"
                        type="number"
                        disabled={
                          editableField && editableField !== 'STORAGE_FEE'
                        }
                        fullWidth={true}
                      />
                      <Flex
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                      >
                        <Button
                          color={'primary'}
                          onClick={this.substractFiftyCents(
                            formikProps,
                            'storageFee'
                          )}
                          disabled={
                            editableField && editableField !== 'STORAGE_FEE'
                          }
                        >
                          - 0.50€
                        </Button>
                        <Button
                          color={'primary'}
                          onClick={this.addFiftyCents(
                            formikProps,
                            'storageFee'
                          )}
                          disabled={
                            editableField && editableField !== 'STORAGE_FEE'
                          }
                        >
                          + 0.50€
                        </Button>
                      </Flex>
                    </Margin>
                  </>
                )}
                <Margin bottom={1}>
                  <Typography variant="body1" color="textPrimary">
                    {t('Prix net')}
                  </Typography>
                  <Field
                    component={TextField}
                    name="netPrice"
                    type="number"
                    disabled={editableField && editableField !== 'NET_PRICE'}
                    fullWidth={true}
                  />
                  <Flex
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                  >
                    <Button
                      color={'primary'}
                      onClick={this.substractFiftyCents(
                        formikProps,
                        'netPrice'
                      )}
                      disabled={editableField && editableField !== 'NET_PRICE'}
                    >
                      - 0.50€
                    </Button>
                    <Button
                      color={'primary'}
                      onClick={this.addFiftyCents(formikProps, 'netPrice')}
                      disabled={editableField && editableField !== 'NET_PRICE'}
                    >
                      + 0.50€
                    </Button>
                  </Flex>
                </Margin>
              </Margin>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color={'primary'} type="reset" disabled={isSubmitting}>
              {t('RAZ')}
            </Button>
            <Button color={'primary'} onClick={onClose} disabled={isSubmitting}>
              {t('Annuler')}
            </Button>
            <Button color={'primary'} type="submit" disabled={isSubmitting}>
              {t('Appliquer le prix forcé')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }

  render() {
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validationSchema={SaleForcingPriceValidationSchema}
        onSubmit={this.onSubmit}
        onReset={this.onReset}
      >
        {this.renderForm()}
      </Formik>
    )
  }
}

export default SaleForcingPriceDialog
