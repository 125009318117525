import {
  createStyles,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  Button,
} from '@material-ui/core'
import Card from '@material-ui/core/Card/Card'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Add from '@material-ui/icons/Add'
import CreateOutlined from '@material-ui/icons/CreateOutlined'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik'
import * as React from 'react'

import Flex from '../../../../components/Flex'
import TextField from '../../../../components/Form/TextField'
import Loading from '../../../../components/Loading'
import ErrorContext from '../../../../contexts/ErrorContext'
import { t } from '../../../../i18n'
import Market from '../../../../models/Market'
import marketService from '../../../../services/marketService'
import TraderSettingsConfigCard from '../../../trader/Settings/TraderSettingsConfigCard'

import SuperAdminSettingsValidationSchema from './SuperAdminSettingsValidationSchema'

const styles = (theme: Theme) =>
  createStyles({
    cardContent: {
      width: '100%',
      paddingRight: theme.spacing(1),
    },
    table: {
      '& td:last-child': {
        paddingRight: 0,
      },
    },
    tableRow: {
      height: theme.spacing(5),
    },
    tableButtonCell: {
      paddingRight: 0,
    },
    headerLabel: {
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
    },
    loading: {
      padding: 30,
    },
    modalContent: {
      paddingBottom: '4px',
    },
    modalInput: {
      paddingRight: theme.spacing(1),
    },
    modalButtonsContainer: {
      width: '100%',
    },
    modalButton: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  })

type Props = WithStyles<typeof styles>

type State = {
  isLoading: boolean
  error: boolean
  markets: Market[]
  addDialogOpen: boolean
  editDialogOpen: boolean
  deleteDialogOpen: boolean
  marketToEdit?: Market
  marketId: number
}

class MarketSettings extends React.Component<Props, State> {
  static contextType = ErrorContext

  readonly state: State = {
    isLoading: false,
    error: false,
    markets: [],
    addDialogOpen: false,
    editDialogOpen: false,
    deleteDialogOpen: false,
    marketId: 0,
  }

  getInitialFormValues = (): Market => {
    return {
      id: 0,
      code: '',
      label: '',
    }
  }

  componentDidMount(): void {
    this.loadMarkets()
  }

  loadMarkets = async () => {
    try {
      const { data: markets } = await marketService.findAll()
      this.setState({ markets, isLoading: false })
    } catch (e) {
      this.context.displayError(
        'Erreur au chargement, rechargez la page et si le problème persiste contactez un administrateur.'
      )
    }
  }

  onAddOpen = () => {
    this.setState({ addDialogOpen: true })
  }

  onAddClose = () => {
    this.setState({ addDialogOpen: false })
  }

  onEditOpen = (market: Market) => () => {
    this.setState({ editDialogOpen: true, marketToEdit: market })
  }

  onEditClose = () => {
    this.setState({ editDialogOpen: false })
  }

  onDeleteOpen = (market: Market) => () => {
    this.setState({ deleteDialogOpen: true, marketId: market.id })
  }

  onDeleteClose = () => {
    this.setState({ deleteDialogOpen: false })
  }

  onAddSubmit = async (
    market: Market,
    { setSubmitting }: FormikActions<Market>
  ) => {
    try {
      await marketService.create(market)
      await this.loadMarkets()
      this.setState({ addDialogOpen: false })
    } catch (error) {
      if (error.response && error.response.status === 409) {
        this.context.displayError(
          "Le code ou le label existe déjà. Veuillez le(s) modifier avant d'enregistrer."
        )
      } else {
        this.context.displayError(
          'Erreur lors de la création du label ou du code, réessayez et si le problème persiste contactez un administrateur.'
        )
      }
    } finally {
      setSubmitting(false)
    }
  }

  onEditSubmit = async (
    market: Market,
    { setSubmitting }: FormikActions<Market>
  ) => {
    try {
      await marketService.update(market)
      await this.loadMarkets()
      this.setState({ editDialogOpen: false, marketToEdit: undefined })
    } catch (error) {
      if (error.response && error.response.status === 409) {
        this.context.displayError(
          "Le code ou le label existe déjà sur la plateforme. Veuillez le(s) modifier avant d'enregistrer."
        )
      } else {
        this.context.displayError(
          'Erreur lors de la modification du label ou du code, réessayez et si le problème persiste contactez un administrateur.'
        )
      }
    } finally {
      setSubmitting(false)
    }
  }

  deleteMarket = async () => {
    try {
      await marketService.deleteById(this.state.marketId)
      this.setState(({ markets }) => ({
        deleteDialogOpen: false,
        markets: markets.filter(m => m.id !== this.state.marketId),
      }))
    } catch (e) {
      this.context.displayError(
        'Une erreur est survenue, veuillez recharger la page.'
      )
    }
  }

  renderMarketForm = (onDialogClose: React.MouseEventHandler) => ({
    isSubmitting,
  }: FormikProps<Market>) => {
    const { classes } = this.props
    return (
      <Form>
        <Flex direction="row">
          <FormControl>
            <Field
              component={TextField}
              name="code"
              label={t('Code')}
              variant="filled"
              className={classes.modalInput}
            />
          </FormControl>
          <FormControl>
            <Field
              component={TextField}
              name="label"
              label={t('Label')}
              variant="filled"
              className={classes.modalInput}
            />
          </FormControl>
        </Flex>
        <Flex
          direction="row"
          justify="flex-end"
          className={classes.modalButtonsContainer}
        >
          <Button
            onClick={onDialogClose}
            color="primary"
            className={classes.modalButton}
          >
            {t('Annuler')}
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={isSubmitting}
            className={classes.modalButton}
          >
            {t('Enregistrer')}
          </Button>
        </Flex>
      </Form>
    )
  }

  render() {
    const { classes } = this.props
    const {
      addDialogOpen,
      isLoading,
      editDialogOpen,
      deleteDialogOpen,
      marketToEdit,
      markets,
    } = this.state

    if (isLoading) {
      return (
        <Card className={classes.loading}>
          <Loading />
        </Card>
      )
    }

    return (
      <>
        <TraderSettingsConfigCard
          cardTitle={'Vos libellés'}
          adviceMessage={'Désactiver/modifier ou ajouter un libellé'}
        >
          <div className={classes.cardContent}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell>
                    <Typography color="primary" className={classes.headerLabel}>
                      {t('Code')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="primary" className={classes.headerLabel}>
                      {t('Label')}
                    </Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {markets.map((market: Market) => (
                  <TableRow key={`${market.id}`} className={classes.tableRow}>
                    <TableCell>
                      <Typography color="textPrimary">{market.code}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary">
                        {market.label}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableButtonCell}>
                      <Flex direction="row" justify="flex-end">
                        <Button
                          color="primary"
                          onClick={this.onEditOpen(market)}
                          title={t('Modifier le label et son code')}
                        >
                          <CreateOutlined />
                        </Button>
                        <Button
                          color="primary"
                          onClick={this.onDeleteOpen(market)}
                          title={t('Supprimer le label et son code')}
                        >
                          <DeleteOutline />
                        </Button>
                      </Flex>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button
              color="primary"
              onClick={this.onAddOpen}
              className={classes.tableRow}
            >
              <Add />
              <span>{t('Ajouter un libellé')}</span>
            </Button>
          </div>
        </TraderSettingsConfigCard>
        <Dialog
          open={addDialogOpen}
          onClose={this.onAddClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle>{t('Quel est le nom du nouveau libellé ?')}</DialogTitle>
          <DialogContent className={classes.modalContent}>
            <Formik
              initialValues={this.getInitialFormValues()}
              onSubmit={this.onAddSubmit}
              render={this.renderMarketForm(this.onAddClose)}
              validationSchema={SuperAdminSettingsValidationSchema}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={editDialogOpen}
          onClose={this.onEditClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle>{t('Modifier un libellé')}</DialogTitle>
          <DialogContent className={classes.modalContent}>
            <Formik
              initialValues={marketToEdit || this.getInitialFormValues()}
              onSubmit={this.onEditSubmit}
              render={this.renderMarketForm(this.onEditClose)}
              validationSchema={SuperAdminSettingsValidationSchema}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={deleteDialogOpen}
          onClose={this.onDeleteClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <DialogContentText>
              {t('Êtes-vous sûr de vouloir supprimer ce libellé ?')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color={'primary'} onClick={this.onDeleteClose}>
              {t('Annuler')}
            </Button>
            <Button color={'primary'} onClick={this.deleteMarket}>
              {t('Accepter')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withStyles(styles)(MarketSettings)
