import {
  createStyles,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import HelpOutline from '@material-ui/icons/HelpOutline'
import classNames from 'classnames'
import * as React from 'react'

import { CalendarContext } from './Calendar'

const styles = (theme: Theme) =>
  createStyles({
    title: {
      paddingLeft: `${theme.spacing(1)}px`,
      paddingRight: `${theme.spacing(1)}px`,
      '& > *': {
        lineHeight: '48px',
      },
    },
    dark: {
      backgroundColor: theme.palette.grey[100],
    },
    td: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(0.6),
      paddingBottom: theme.spacing(0.6),
    },
    value: {
      paddingTop: theme.spacing(1.4),
      paddingBottom: theme.spacing(1.4),
      borderRadius: theme.shape.borderRadius * 2.5,
      textAlign: 'center',
      color: 'white',
      width: '100%',
    },
    tooltip: {
      marginLeft: 5,
      fontSize: 15,
    },
    clickable: {
      cursor: 'pointer',
    },
  })

interface Props extends WithStyles<typeof styles> {
  title: string
  values: Array<{
    value: string | number
    color: string
    published: boolean
  } | null>
  dark?: boolean
  tooltip?: string
  forcedColor?: string
  onClick?: (month: string) => void
}

class CalendarLine extends React.Component<Props> {
  handleClick = (month: string) => () => {
    const { onClick } = this.props
    if (onClick) {
      onClick(month)
    }
  }

  render() {
    const {
      classes,
      title,
      values,
      dark,
      tooltip,
      forcedColor,
      onClick,
    } = this.props
    return (
      <CalendarContext.Consumer>
        {({ months }) => (
          <tr className={classNames(dark && classes.dark)}>
            <th className={classes.title}>
              <Typography color="textSecondary" noWrap>
                {title}
                {tooltip && (
                  <Tooltip
                    title={tooltip}
                    className={classes.tooltip}
                    placement="right"
                  >
                    <HelpOutline />
                  </Tooltip>
                )}
              </Typography>
            </th>
            {months!.map((month, index) => {
              if (values[index]) {
                return (
                  <td
                    key={month}
                    className={classNames(classes.td, {
                      [classes.clickable]: !!onClick,
                    })}
                    onClick={this.handleClick(month)}
                  >
                    <div
                      className={classes.value}
                      style={{
                        backgroundColor: forcedColor || values[index]!.color,
                        opacity: values[index]!.published ? 1 : 0.5,
                      }}
                    >
                      <Typography variant="button" color="inherit">
                        {values[index]!.value || '-'}
                      </Typography>
                    </div>
                  </td>
                )
              }
              return <td key={month} className={classes.td} />
            })}
          </tr>
        )}
      </CalendarContext.Consumer>
    )
  }
}

export default withStyles(styles)(CalendarLine)
