import * as React from 'react'

import { WithStyles, createStyles, withStyles } from '@material-ui/core'

import Loading from '../../../components/Loading'
import MailTemplate from '../../../models/MailTemplate'

import { t } from '../../../i18n'

import TraderSettingsConfigCard from '../../trader/Settings/TraderSettingsConfigCard'
import mailTemplateService from '../../../services/mailTemplateService'
import TraderSettingsMailTemplatesList from '../../trader/Settings/MailTemplates/TraderSettingsMailTemplatesList'
import Fetcher from '../../../components/Fetcher'
const styles = () =>
  createStyles({
    cardContent: {
      paddingRight: 15,
      width: '100%',
    },
  })

interface Props extends WithStyles<typeof styles> {}

class SuperAdminSettingsMailtemplate extends React.Component<Props> {
  renderContent = (mailTemplates: MailTemplate[], refresh: () => void) => {
    return (
      <TraderSettingsMailTemplatesList
        mailTemplates={mailTemplates}
        refresh={refresh}
      />
    )
  }

  render() {
    const { classes } = this.props

    return (
      <TraderSettingsConfigCard
        cardTitle={t('Vos templates email')}
        adviceMessage={
          'Modifiez les templates de mail par défaut pour vos traders.'
        }
      >
        <div className={classes.cardContent}>
          <Fetcher fetch={mailTemplateService.findAll} loadingRender={Loading}>
            {this.renderContent}
          </Fetcher>
        </div>
      </TraderSettingsConfigCard>
    )
  }
}

export default withStyles(styles)(SuperAdminSettingsMailtemplate)
