import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core/Checkbox'
import { FieldProps } from 'formik'
import * as React from 'react'

import { Omit } from '../../utils/Omit'

export interface CheckboxProps
  extends FieldProps,
    Omit<
      MuiCheckboxProps,
      'form' | 'checked' | 'defaultChecked' | 'name' | 'onChange' | 'value'
    > {}

export const fieldToCheckbox = ({
  field,
  form: { isSubmitting },
  disabled = false,
  ...props
}: CheckboxProps): MuiCheckboxProps => {
  return {
    disabled: isSubmitting || disabled,
    ...field,
    ...props,
    checked: !!field.value,
    value: field.value ? 'checked' : '',
    title: undefined,
  }
}

export const Checkbox: React.ComponentType<CheckboxProps> = (
  props: CheckboxProps
) => <MuiCheckbox {...fieldToCheckbox(props)} />

Checkbox.displayName = 'FormikMaterialUICheckbox'
