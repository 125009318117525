import axios from '../config/axios'
import CustomerStock from '../models/CustomerStock'

export type CustomerStockData = {
  id: number
  campaignName: string
  productName: string
  socialReason: string
  totalTonnage: number
  tonnageSold: number
  remainingToSell: number
  percentageSold: number
}

export const findAll = (userId?: number) =>
  axios.get<CustomerStock[]>('api/customer-stocks', {
    params: {
      userId,
    },
  })

export const listData = () =>
  axios.get<CustomerStockData[]>('api/customer-stocks/data')

export default { findAll, listData }
