import axios from '../config/axios'
import Campaign from '../models/Campaign'

export const findAllActive = () =>
  axios.get<Campaign[]>('api/campaigns?active=true')
export const findAll = () => axios.get<Campaign[]>('api/campaigns')
export const findAllAndStartDate = (startDate: Date) =>
  axios.get<Campaign[]>('api/campaigns', { params: { startDate } })
export const update = (campaign: Campaign) =>
  axios.put<Campaign>('api/campaigns/' + campaign.id, campaign)
export const create = (name: string) =>
  axios.post<Campaign>('api/campaigns/', { name })

export default { findAll, findAllActive, update, create, findAllAndStartDate }
