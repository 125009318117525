import {
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'

import UserContext from '../../contexts/UserContext'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    name: {
      marginTop: theme.spacing(1),
      fontSize: '1.4em',
      transition: `height ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}, opacity ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
    },
    nameClose: {
      height: 0,
      opacity: 0,
    },
  })

interface Props extends WithStyles<typeof styles> {
  open: boolean
}

class MenuHeader extends React.Component<Props> {
  static contextType = UserContext

  render() {
    const { classes, open } = this.props
    return (
      <div className={classes.root}>
        <Typography
          className={classNames(classes.name, !open && classes.nameClose)}
        >
          {this.context.user.firstname} {this.context.user.lastname}
        </Typography>
      </div>
    )
  }
}

export default withStyles(styles)(MenuHeader)
