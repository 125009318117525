import addMonths from 'date-fns/addMonths'
import format from 'date-fns/format'

import Campaign from '../models/Campaign'

const getCampaignMonths = (campaign: Campaign): string[] => {
  let startDate = campaign.startDateCalendar
  const campaignMonths = []

  for (let i = 0; i < 12; i++) {
    campaignMonths.push(format(startDate, 'MM/yy'))
    startDate = addMonths(startDate, 1)
  }
  return campaignMonths
}

export default getCampaignMonths
