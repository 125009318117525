import * as Yup from 'yup'

export default Yup.object().shape({
  lastname: Yup.string().required(),
  firstname: Yup.string().required(),
  email: Yup.string()
    .email()
    .required(),
  phone: Yup.string().nullable(true),
  mobile: Yup.string().nullable(true),
  address: Yup.string().nullable(true),
  postalCode: Yup.string().nullable(true),
  city: Yup.string().nullable(true),
  externalId: Yup.string().nullable(true),
  roles: Yup.array(Yup.string())
    .required()
    .min(1)
    .max(1),
  enabled: Yup.boolean().required(),
})
