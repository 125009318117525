import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  WithStyles,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { t } from '../../../i18n'

const styles = () =>
  createStyles({
    content: {
      whiteSpace: 'pre-line',
    },
  })

interface Props extends WithStyles<typeof styles> {
  open: boolean
  onClose: () => any
  errorTitle: string
  errorContent: string
}

class SettingsConflictErrorDialog extends React.Component<Props> {
  render() {
    const { open, onClose, errorTitle, errorContent, classes } = this.props

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>{errorTitle}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" className={classes.content}>
            {errorContent}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Link to="/home">
            <Button color="primary">{t('Accéder aux offres')}</Button>
          </Link>
          <Button color={'primary'} onClick={onClose}>
            Compris
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(SettingsConflictErrorDialog)
