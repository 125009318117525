import axios from '../config/axios'
import StrikeSetting from '../models/StrikeSetting'

const findAll = () => axios.get<StrikeSetting[]>('api/strikes')

const update = (strikes: StrikeSetting[]) =>
  axios.put<StrikeSetting[]>('api/strikes', strikes)

export default {
  findAll,
  update,
}
