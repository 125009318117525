import * as Yup from 'yup'

import { DatePickerMinDate } from '../../../components/Form/DatePicker'
import { DateTimePickerMinDate } from '../../../components/Form/DateTimePicker'

export default Yup.object({
  maximalVolume: Yup.number(),
  expirationDatetime: Yup.date()
    .notRequired()
    .min(DatePickerMinDate),
  publicationDatetime: Yup.date()
    .notRequired()
    .min(DateTimePickerMinDate),

  comments: Yup.string(),
})
