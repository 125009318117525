import axios from '../config/axios'
import StorageSettings from '../models/StorageSettings'

export const findAll = () =>
  axios.get<StorageSettings[]>('api/storage-settings')

export const update = (storageSetting: StorageSettings[]) =>
  axios.put<StorageSettings[]>('api/storage-settings', storageSetting)

export default {
  findAll,
  update,
}
