import React from 'react'
import { Theme, Typography, Card, CardContent, Button } from '@material-ui/core'
import BackButton from '@material-ui/icons/KeyboardArrowLeft'
import { makeStyles } from '@material-ui/styles'
import { t } from '../i18n'
import Margin from './Margin'
import CardContentColored from './CardContentColored'
import useRouter from '../hooks/useRouter'
import { TargetOrderView } from '../services/targetOrderService'
import { format } from '../i18n/format'

const useStyles = makeStyles((theme: Theme) => ({
  leftPanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  card: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    color: theme.palette.common.white,
  },
  back: {
    marginLeft: -8,
    paddingTop: 0,
    paddingRight: 4,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  bold: {
    fontSize: '2.37rem',
    fontWeight: 700,
  },
  saleOption: {
    flex: 2,
  },
  help: {
    flex: 1,
    borderLeftStyle: 'solid',
    borderLeftWidth: 2,
    borderLeftColor: theme.palette.grey[300],
  },
  action: {
    width: 235,
  },
}))

interface Props {
  targetOrder: TargetOrderView
}

export const TargetOrderRecapCard = ({ targetOrder }: Props) => {
  const classes = useStyles()
  const { history } = useRouter()

  const onBack = () => {
    history.goBack()
  }

  return (
    <Card className={classes.card}>
      <CardContentColored className={classes.leftPanel} color="primary">
        <Margin bottom={2}>
          <Button
            color="inherit"
            size="small"
            onClick={onBack}
            className={classes.back}
          >
            <BackButton fontSize="large" />
            {t('Retour')}
          </Button>
        </Margin>
        <Typography variant="body2" color="inherit">
          {t('Vente de :')}
        </Typography>
        <div>
          <Typography
            className={classes.bold}
            color="inherit"
            display="inline"
            component="span"
          >
            {targetOrder.tons}t
          </Typography>{' '}
          à {targetOrder.customerTargetPrice}€/t
          <Typography variant="body2" color="inherit" gutterBottom={false}>
            <b style={{ fontSize: '12px' }}>{t("Vente à prix d'objectif")}</b>
          </Typography>
        </div>
        <Margin top={3} />
        <Typography variant="body2" color="inherit">
          {t('soit un total de :')}
        </Typography>
        <Typography className={classes.bold} color="inherit">
          {targetOrder.totalPrice}€
        </Typography>
      </CardContentColored>
      <CardContent className={classes.saleOption}>
        <Margin bottom={2}>
          <Typography variant="body1" color="textPrimary">
            {t('Je souhaite vendre :')}
          </Typography>
          <Typography variant="body2">
            <Typography
              variant="h6"
              color="primary"
              display="inline"
              component="span"
            >
              {targetOrder.tons}t
            </Typography>{' '}
            {t('de')}{' '}
            <Typography
              variant="h6"
              color="primary"
              display="inline"
              component="span"
            >
              {targetOrder.productName}
            </Typography>{' '}
            {t('campagne')}{' '}
            <Typography
              variant="h6"
              color="primary"
              display="inline"
              component="span"
            >
              {targetOrder.campaignName}
            </Typography>
          </Typography>
        </Margin>
        <Typography variant="body1" color="textPrimary">
          {t('La livraison se fera en :')}
        </Typography>
        <Typography variant="body2">
          <Typography
            variant="h6"
            color="primary"
            display="inline"
            component="span"
          >
            {targetOrder.executionModeLabel}
          </Typography>{' '}
          {t('pendant le mois')}{' '}
          <Typography
            variant="h6"
            color="primary"
            display="inline"
            component="span"
          >
            {format(targetOrder.executionDate, 'MM/yy')}
          </Typography>
        </Typography>
        <Margin top={2}>
          <Typography variant="body1" color="textPrimary">
            {t('Le paiement se fera le :')}
          </Typography>
          <Typography
            variant="h6"
            color="primary"
            display="inline"
            component="span"
          >
            {format(targetOrder.paymentDate, 'dd/MM/yy')}
          </Typography>
        </Margin>
        {targetOrder.status === 'CANCELED' && (
          <Margin top={2}>
            <Typography variant="body1" color="textPrimary">
              {t('Annulé le ')}
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              display="inline"
              component="span"
            >
              {format(targetOrder.updatedAt, 'dd/MM/yyyy HH:mm')}{' '}
              {targetOrder.updatedBy && <>par {targetOrder.updatedBy}</>}
            </Typography>
          </Margin>
        )}
      </CardContent>
      <CardContent className={classes.help}>
        <Margin bottom>
          <Typography variant="body1" color="textPrimary">
            {t('Compléments d’informations sur cette offre :')}
          </Typography>
        </Margin>
        <Typography variant="body2">{targetOrder.comments}</Typography>
      </CardContent>
    </Card>
  )
}

export default TargetOrderRecapCard
