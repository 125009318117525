import { Button, Grid, Typography } from '@material-ui/core'
import BackButton from '@material-ui/icons/KeyboardArrowLeft'
import LockReset from 'mdi-material-ui/LockReset'
import * as React from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

import CustomerDetails from '../../../components/CustomerDetails'
import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Margin from '../../../components/Margin'
import { User } from '../../../contexts/UserContext'
import { t } from '../../../i18n'
import Campaign from '../../../models/Campaign'
import CustomerStock from '../../../models/CustomerStock'
import ExecutionMode from '../../../models/ExecutionMode'
import OfferDisplayPreference from '../../../models/OfferDisplayPreference'
import OfferDisplaySetting from '../../../models/OfferDisplaySetting'
import Product from '../../../models/Product'
import UserTag from '../../../models/UserTag'
import authService from '../../../services/authService'
import campaignService from '../../../services/campaignService'
import customerSettingsService from '../../../services/customerSettingsService'
import customerStockService from '../../../services/customerStockService'
import executionModeService from '../../../services/executionModeService'
import productService from '../../../services/productService'
import userService from '../../../services/userService'
import userTagsService from '../../../services/userTagsService'
import CustomerOffersCard from '../../customer/Home/CustomerOffersCard'
import CustomerStocksCard from '../../customer/Home/CustomerStocksCard'
import TargetOrdersInProgress from '../../../components/TargetOrdersInProgress'
import targetOrderService, {
  TargetOrderView,
} from '../../../services/targetOrderService'
import SnackbarsExtended from '../../../components/SnackbarsExtended'
import strikeService from '../../../services/strikeService'
import StrikeSetting from '../../../models/StrikeSetting'
import ErrorContext from '../../../contexts/ErrorContext'

interface Props extends RouteComponentProps<any> {}

interface State {
  successToReset: boolean
}

class SalesmanCustomerDetails extends React.Component<Props> {
  state: State = {
    successToReset: false,
  }

  static contextType = ErrorContext
  context!: React.ContextType<typeof ErrorContext>

  fetchCustomerStock = (id: number) => () => {
    return customerStockService.findAll(id)
  }

  fetchCustomer = (id: number) => () => {
    return userService.findCustomer(id)
  }

  fetchCustomerSettings = (id: number) => () => {
    return customerSettingsService.findAllByUserId(id)
  }

  resetPassword = (customer: User, refresh: () => void) => async () => {
    if (customer.email && customer.login) {
      try {
        await authService.resetPasswordInit(customer.login)
        this.setState({ successToReset: true })
      } catch (e) {
        this.context.displayError(
          "L'email de réinitialisation n'a pas été envoyé."
        )
      }
    }
  }

  renderCustomerStock = ([campaigns, customerStocks]: [
    Campaign[],
    CustomerStock[]
  ]) => {
    return (
      <CustomerStocksCard
        campaigns={campaigns}
        customerStocks={customerStocks}
      />
    )
  }

  renderCustomerDetails = (
    customer: User,
    customerStocks: CustomerStock[],
    refresh: () => void
  ) => ([userTags, executionModes]: [UserTag[], ExecutionMode[]]) => {
    return (
      <CustomerDetails
        customer={customer}
        userTags={userTags}
        executionModes={executionModes}
        onCustomerChanged={refresh}
      />
    )
  }

  renderCustomerOffers = (customer: User) => ([
    products,
    campaigns,
    executionModes,
    strikes,
    settings,
  ]: [
    Product[],
    Campaign[],
    ExecutionMode[],
    StrikeSetting[],
    OfferDisplaySetting[]
  ]) => {
    return (
      <>
        <Margin bottom={2}>
          <Typography variant="h6">
            {t('Offres actives pour ce client')}
          </Typography>
        </Margin>

        <CustomerOffersCard
          products={products}
          customerSector={customer.sector!}
          campaigns={campaigns}
          executionModes={executionModes}
          strikes={strikes}
          settings={settings}
          offerDisplayPreference={OfferDisplayPreference.Sell}
          customer={customer}
        />
      </>
    )
  }

  fetchTargetOrders = (customerId?: number) => () => {
    if (customerId) {
      return targetOrderService.findByCustomer(customerId, ['CREATED'])
    }
    return targetOrderService.findAll(['CREATED'])
  }

  renderTargetOrdersInProgress = (
    targetOrders: TargetOrderView[],
    refresh: () => void
  ) => {
    return (
      <TargetOrdersInProgress targetOrders={targetOrders} refresh={refresh} />
    )
  }

  onClose = () => {
    this.setState({ successToReset: false })
  }

  renderContent = (
    [customer, customerStocks]: [User, CustomerStock[]],
    refresh: () => void
  ) => {
    return (
      <>
        <Margin bottom={2}>
          <Flex direction="row" fullWidth>
            <Link to="/customers">
              <Button color="primary" size="small">
                <BackButton fontSize="large" />
                {t('Retour à la liste')}
              </Button>
            </Link>
            <Flex grow />
            <Button
              color="primary"
              onClick={this.resetPassword(customer, refresh)}
            >
              <LockReset /> {t('REGÉNÉRER LE MOT DE PASSE')}
            </Button>
          </Flex>
        </Margin>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Fetcher
              fetch={[
                userTagsService.findAll,
                executionModeService.findActives,
              ]}
            >
              {this.renderCustomerDetails(customer, customerStocks, refresh)}
            </Fetcher>
          </Grid>
          <Grid item md={6} xs={12}>
            <Margin bottom={2}>
              <Fetcher
                fetch={[
                  campaignService.findAllActive,
                  this.fetchCustomerStock(customer.id!),
                ]}
              >
                {this.renderCustomerStock}
              </Fetcher>
            </Margin>
            <Fetcher fetch={this.fetchTargetOrders(customer.id)}>
              {this.renderTargetOrdersInProgress}
            </Fetcher>
          </Grid>
          <Grid item xs={12}>
            <Fetcher
              fetch={[
                productService.findAll,
                campaignService.findAllActive,
                executionModeService.findActives,
                strikeService.findAll,
                this.fetchCustomerSettings(customer.id!),
              ]}
            >
              {this.renderCustomerOffers(customer)}
            </Fetcher>
          </Grid>
        </Grid>
      </>
    )
  }

  render() {
    const { id: customerId } = this.props.match.params
    const { successToReset } = this.state
    return (
      <>
        <Fetcher
          fetch={[this.fetchCustomer(customerId), customerStockService.findAll]}
        >
          {this.renderContent}
        </Fetcher>
        <SnackbarsExtended
          onClose={this.onClose}
          open={successToReset}
          variant={'success'}
          message={t("L'email de réinitialisation a été envoyé.")}
        />
      </>
    )
  }
}

export default withRouter<Props>(SalesmanCustomerDetails)
