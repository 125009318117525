import * as Yup from 'yup'

export default Yup.object().shape({
  maesysAppsLogin: Yup.string().required(),
  maesysAppsPassword: Yup.string().required(),
  socialReason: Yup.string().required(),
  broker: Yup.string().required(),
  clearerAccount: Yup.string().required(),
  mailTrader: Yup.string()
    .email()
    .required(),
  mailsReplyTo: Yup.string().email(),
  contractIdPrefix: Yup.string().required(),
})
