import {
  createStyles,
  IconButton,
  Theme,
  WithStyles,
  withStyles,
  MenuItem,
  Select,
  InputLabel,
} from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import { ModalProps } from '@material-ui/core/Modal'
import CloseIcon from '@material-ui/core/SvgIcon/SvgIcon'
import TextField from '@material-ui/core/TextField/TextField'
import isEmpty from 'lodash/isEmpty'
import React, { Component } from 'react'

import Flex from '../../../../components/Flex'
import Editor from '../../../../components/Form/Editor'
import { t } from '../../../../i18n'
import {
  NewContractTemplate,
  ContractType,
} from '../../../../models/ContractTemplate'
import Tags from '../../../../models/Tags'
import { Omit } from '../../../../utils/Omit'
import ContractTypes from '../../../../models/ContractTypesEnum'

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: '100%',
    },
    title: {
      paddingBottom: 0,
    },
    titleFlex: {
      display: 'flex',
      flexDirection: 'row',
    },
    dialogContent: {
      height: '100%',
    },
    name: {
      flex: 2,
      width: '50%',
      '& > div': {
        marginRight: 25,
      },
    },
    type: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      '& > label': {
        lineHeight: 1,
      },
    },
    typeFlex: {
      flex: 1,
      '& > div': {
        width: '100%',
      },
    },
  })

interface Props
  extends WithStyles<typeof styles>,
    Omit<ModalProps, 'classes' | 'onClose' | 'children'> {
  contractTemplate?: NewContractTemplate
  open: boolean
  onClose: () => any
  onSave: (contractTemplate: NewContractTemplate) => any
  submitLabel?: string
}

interface State {
  contractTemplate?: NewContractTemplate
}

class TraderSettingsContractTemplatesDialog extends Component<Props, State> {
  state: State = {
    contractTemplate: undefined,
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (
      !prevProps.contractTemplate &&
      prevProps.contractTemplate !== this.props.contractTemplate
    ) {
      this.setState({ contractTemplate: this.props.contractTemplate })
    }
  }

  handleClose = () => {
    const { onClose } = this.props
    this.setState({ contractTemplate: undefined })
    if (onClose) {
      onClose()
    }
  }

  handleSubmit = async () => {
    const { onSave } = this.props
    const { contractTemplate } = this.state

    if (onSave) {
      await onSave(contractTemplate!)
    }
  }

  handleTemplateChange = (template: string) => {
    this.setState(({ contractTemplate }) => ({
      contractTemplate: { ...contractTemplate!, template },
    }))
  }

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value
    this.setState(({ contractTemplate }) => ({
      contractTemplate: { ...contractTemplate!, name },
    }))
  }

  handleTypeChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const contractType = event.target.value as ContractType
    this.setState(({ contractTemplate }) => ({
      contractTemplate: { ...contractTemplate!, contractType },
    }))
  }

  renderContent() {
    const { classes } = this.props
    const { contractTemplate } = this.state

    if (!contractTemplate) {
      return <div />
    }

    return (
      <>
        <DialogTitle disableTypography classes={{ root: classes.title }}>
          <Flex direction="row" className={classes.titleFlex}>
            <TextField
              label={t('Nom du contrat')}
              name="name"
              value={contractTemplate.name}
              onChange={this.handleNameChange}
              className={classes.name}
              error={isEmpty(contractTemplate.name)}
              helperText={isEmpty(contractTemplate.name) && t('Requis')}
            />
            <div className={classes.type}>
              <Flex className={classes.typeFlex}>
                <InputLabel htmlFor="contractType" shrink={true}>
                  {t('Type du contrat')}
                </InputLabel>
                <Select
                  name="contractType"
                  value={contractTemplate.contractType}
                  onChange={this.handleTypeChange}
                >
                  [
                  <MenuItem value={Object.keys(ContractTypes)[0]} key={0}>
                    {t(Object.values(ContractTypes)[0])}
                  </MenuItem>
                  <MenuItem value={Object.keys(ContractTypes)[1]} key={1}>
                    {t(Object.values(ContractTypes)[1])}
                  </MenuItem>
                  <MenuItem value={Object.keys(ContractTypes)[2]} key={2}>
                    {t(Object.values(ContractTypes)[2])}
                  </MenuItem>
                  ]
                </Select>
              </Flex>
              <IconButton onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </Flex>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Editor
            defaultValue={contractTemplate.template}
            onChange={this.handleTemplateChange}
            tags={Tags.TransactionContract}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            {t('Annuler')}
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            disabled={isEmpty(contractTemplate.name)}
          >
            {t('Sauvegarder')}
          </Button>
        </DialogActions>
      </>
    )
  }

  render() {
    const { open } = this.props

    return (
      <Dialog open={open} onClose={this.handleClose} fullWidth maxWidth="xl">
        {this.renderContent()}
      </Dialog>
    )
  }
}

export default withStyles(styles)(TraderSettingsContractTemplatesDialog)
