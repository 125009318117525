import { createStyles, withStyles, WithStyles } from '@material-ui/core'
import orange from '@material-ui/core/colors/orange'
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText'
import isFunction from 'lodash/isFunction'
import * as React from 'react'

const styles = () =>
  createStyles({
    root: {
      color: orange['900'],
    },
  })

interface Props extends WithStyles<typeof styles> {
  value: any
  message: ((value: any) => string) | string
  condition: (value: any) => boolean
}

const FieldWarning: React.FunctionComponent<Props> = ({
  classes,
  message,
  value,
  condition,
}) => {
  if (!condition(value)) {
    return null
  }

  return (
    <FormHelperText className={classes.root}>
      {isFunction(message) ? message(value) : message}
    </FormHelperText>
  )
}

export default withStyles(styles)(FieldWarning)
