import { createStyles, Theme, Typography, WithStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import BackButton from '@material-ui/icons/KeyboardArrowLeft'
import format from 'date-fns/esm/format'
import * as React from 'react'

import { t } from '../../i18n'
import ExecutionMode from '../../models/ExecutionMode'
import Offer from '../../models/Offer'
import Transaction from '../../models/Transaction'
import CardContentColored from '../CardContentColored'
import Flex from '../Flex'
import Margin from '../Margin'
import Timer from './Timer'
import TargetOrder from '../../models/TargetOrder'
import { StrikeMap } from './SaleCard'

const styles = (theme: Theme) =>
  createStyles({
    leftPanel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    card: {
      display: 'flex',
      flexDirection: 'row',
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    back: {
      marginLeft: -8,
      paddingTop: 0,
      paddingRight: 4,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    bold: {
      fontSize: '2.37rem',
      fontWeight: 700,
    },
    saleOption: {
      flex: 2,
    },
    help: {
      flex: 1,
      borderLeftStyle: 'solid',
      borderLeftWidth: 2,
      borderLeftColor: theme.palette.grey[300],
    },
    action: {
      width: 235,
    },
  })

interface Props extends WithStyles<typeof styles> {
  order: Transaction | TargetOrder
  offer: Offer
  executionMode: ExecutionMode
  isWaitingValidation: boolean
  strikeSettings: StrikeMap
  onConfirmSale: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onBack: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

class SaleRecapCard extends React.Component<Props> {
  render() {
    const {
      classes,
      order,
      offer,
      executionMode,
      isWaitingValidation,
      onConfirmSale,
      onBack,
      strikeSettings,
    } = this.props

    const displayNumberSign = (value: number): string => {
      return `${value >= 0 ? '+' : ''}${value}`
    }

    const renderTransactionDetails = (order: Transaction) => (
      <>
        <Typography variant="body1" color="inherit">
          <Typography
            className={classes.bold}
            color="inherit"
            display="inline"
            component="span"
          >
            {order.tons}t
          </Typography>{' '}
          à {order.rawPrice}€/t
        </Typography>
        {order.storageFee && (
          <Margin top={3}>
            <Typography variant="body2" color="inherit">
              {t('dont')} -{(order as Transaction).storageFee} €/t
            </Typography>
            <Typography variant="body2" color="inherit">
              <b>{t('Frais de stockage')}</b>
            </Typography>
          </Margin>
        )}
      </>
    )

    const renderTargetOrderDetails = (order: TargetOrder) => (
      <>
        <Typography variant="body1" color="inherit">
          <Typography
            className={classes.bold}
            color="inherit"
            display="inline"
            component="span"
          >
            {order.tons}t
          </Typography>{' '}
          à {order.customerTargetPrice}€/t
        </Typography>
        <Typography variant="caption" color="inherit">
          <b>{t("Vente à prix d'objectif")}</b>
        </Typography>
      </>
    )

    return (
      <>
        <Timer />
        <Card className={classes.card}>
          <CardContentColored className={classes.leftPanel} color="primary">
            <Margin bottom={2}>
              <Button
                color="inherit"
                size="small"
                onClick={onBack}
                className={classes.back}
              >
                <BackButton fontSize="large" />
                {t('Retour')}
              </Button>
            </Margin>
            <Typography variant="body2" color="inherit">
              {t('Vente de :')}
            </Typography>
            {(order as Transaction).appliedManeuver !== undefined
              ? renderTransactionDetails(order as Transaction)
              : renderTargetOrderDetails(order as TargetOrder)}
            <Margin top={3} />
            <Typography variant="body2" color="inherit">
              {t('soit un total de :')}
            </Typography>
            <Typography className={classes.bold} color="inherit">
              {order.totalPrice}€
            </Typography>
            <Typography variant="caption" display="block" color="inherit">
              {t('brut hors TVA')}
            </Typography>
            {(order as Transaction).appliedManeuver !== undefined &&
              (order as Transaction).appliedManeuver !== 0 && (
                <Typography
                  variant="caption"
                  color="inherit"
                  style={{ marginTop: 15 }}
                >
                  <b>{t('Vente à prix forcé')}</b> (
                  {displayNumberSign((order as Transaction).appliedManeuver!)}
                  €/t)
                </Typography>
              )}
          </CardContentColored>
          <CardContent className={classes.saleOption}>
            <Margin bottom={2}>
              <Typography variant="body1" color="textPrimary">
                {t('Je souhaite vendre :')}
              </Typography>
              <Typography variant="body2">
                <Typography
                  variant="h6"
                  color="primary"
                  display="inline"
                  component="span"
                >
                  {order.tons}t
                </Typography>{' '}
                {t('de')}{' '}
                <Typography
                  variant="h6"
                  color="primary"
                  display="inline"
                  component="span"
                >
                  {offer.product.name}
                </Typography>{' '}
                {t('campagne')}{' '}
                <Typography
                  variant="h6"
                  color="primary"
                  display="inline"
                  component="span"
                >
                  {offer.campaign.name}
                </Typography>
              </Typography>
            </Margin>
            <Typography variant="body1" color="textPrimary">
              {t('La vente se fera en :')}
            </Typography>
            <Typography variant="body2">
              <Typography
                variant="h6"
                color="primary"
                display="inline"
                component="span"
              >
                {executionMode.name}
              </Typography>{' '}
              {t('pendant le mois')}{' '}
              <Typography
                variant="h6"
                color="primary"
                display="inline"
                component="span"
              >
                {format(order.executionDate, 'MM/yy')}
              </Typography>
            </Typography>
            <Margin top={2}>
              <Typography variant="body1" color="textPrimary">
                {t('Le paiement se fera le :')}
              </Typography>
              <Typography
                variant="h6"
                color="primary"
                display="inline"
                component="span"
              >
                {format(order.paymentDate, 'dd/MM/yy')}
              </Typography>
            </Margin>
            {(order as Transaction).strike && (
              <Margin top={2}>
                <Typography variant="body1" color="textPrimary">
                  {t(
                    `Prix minimum garanti avec strike ${
                      strikeSettings[(order as Transaction).strike!]
                    }`
                  )}
                </Typography>
              </Margin>
            )}
            <Flex direction="row" justify="flex-end">
              <div className={classes.action}>
                {isWaitingValidation ? (
                  <Flex fullWidth grow justify="center" alignItems="center">
                    <CircularProgress color="primary" />
                  </Flex>
                ) : (
                  <>
                    <Margin top={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={onConfirmSale}
                      >
                        {t('CONFIRMER LA VENTE')}
                      </Button>
                      <Typography
                        variant="caption"
                        display="block"
                        color="primary"
                      >
                        {t(
                          'En confirmant la vente, vous acceptez les conditions générales de vente.'
                        )}
                      </Typography>
                    </Margin>
                  </>
                )}
              </div>
            </Flex>
          </CardContent>
          {offer.comments && (
            <CardContent className={classes.help}>
              <Margin bottom>
                <Typography variant="body1" color="textPrimary">
                  {t('Compléments d’informations sur cette offre:')}
                </Typography>
              </Margin>
              <Typography variant="body2">{offer.comments}</Typography>
            </CardContent>
          )}
        </Card>
      </>
    )
  }
}

export default withStyles(styles)(SaleRecapCard)
