import { Typography } from '@material-ui/core'
import * as React from 'react'

import { t } from '../i18n'

const NotFound = () => {
  return <Typography variant="h5">{t('Page introuvable')}</Typography>
}

export default NotFound
