import {
  CardContent,
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { CardContentProps } from '@material-ui/core/CardContent'
import classNames from 'classnames'
import * as React from 'react'

import { Omit } from '../utils/Omit'

const styles = (theme: Theme) =>
  createStyles({
    primary: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    primary400: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary[400],
    },
    primary300: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary[300],
    },
    secondary: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
  })

interface Props
  extends WithStyles<typeof styles>,
    Omit<CardContentProps, 'classes'> {
  color:
    | 'inherit'
    | 'primary'
    | 'primary-400'
    | 'primary-300'
    | 'secondary'
    | 'default'
}

const CardContentColored: React.FunctionComponent<Props> = ({
  classes,
  color,
  className: classNameProp,
  children,
  ...other
}) => {
  const className = classNames(
    {
      [classes.primary]: color === 'primary',
      [classes.primary400]: color === 'primary-400',
      [classes.primary300]: color === 'primary-300',
      [classes.secondary]: color === 'secondary',
    },
    classNameProp
  )

  return (
    <CardContent className={className} {...other}>
      {children}
    </CardContent>
  )
}

export default withStyles(styles)(CardContentColored)
