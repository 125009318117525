import { Formik, FormikActions, FormikProps, Form, Field } from 'formik'
import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Theme,
  Typography,
  FormControl,
  FormHelperText,
  Button,
} from '@material-ui/core'
import TraderSettingsConfigCard from '../../../trader/Settings/TraderSettingsConfigCard'
import Flex from '../../../../components/Flex'
import Margin from '../../../../components/Margin'
import TextField from '../../../../components/Form/TextField'
import { t } from '../../../../i18n'
import ErrorContext from '../../../../contexts/ErrorContext'
import strikeService from '../../../../services/strikeService'
import StrikeSetting from '../../../../models/StrikeSetting'
import Loading from '../../../../components/Loading'
import adviceService from '../../../../services/adviceService'
import Fetcher from '../../../../components/Fetcher'

const useStyles = makeStyles((theme: Theme) => ({
  formMain: {
    width: '100%',
    paddingRight: 20,
  },
  formControl: {
    marginRight: theme.spacing(8),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  submit: {
    width: '100%',
  },
  loading: {
    padding: 30,
  },
  form: {
    width: '100%',
  },
  field: {
    width: '30%',
  },
  fieldDefinition: {
    width: '75%',
  },
  sectionTitle: {
    marginTop: theme.spacing(1),
  },
}))
const StrikeSettingsScene = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [strikeLabels, setStrikeLabels] = useState<StrikeSetting[]>([])

  const errorContext = useContext(ErrorContext)
  const classes = useStyles()

  const getStrikeSettings = async () => {
    try {
      const { data } = await strikeService.findAll()
      setStrikeLabels(data)
      setIsLoading(false)
    } catch (e) {
      errorContext.displayError(
        t('Une erreur est survenue, veuillez recharger la page')
      )
    }
  }

  useEffect(() => {
    getStrikeSettings()
    // eslint-disable-next-line
  }, [])

  const renderStrikeSettings = ({
    values,
    isSubmitting,
  }: FormikProps<StrikeSetting[]>) => {
    return (
      <Form className={classes.form}>
        <Flex>
          <Margin bottom={2} className={classes.formMain}>
            {values.map((strike, index) => (
              <React.Fragment key={index}>
                <Flex
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                  className={classes.sectionTitle}
                >
                  <Typography color="primary" variant="overline">
                    {t(`Strike`)} {strike.strike}
                  </Typography>
                </Flex>
                <Margin>
                  <FormControl className={classes.formControl}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      name={`[${index}].label`}
                      label="Libellé"
                    />
                    <FormHelperText>{t('Max. 30 caractères')}</FormHelperText>
                  </FormControl>
                </Margin>
              </React.Fragment>
            ))}
          </Margin>
          <Flex alignItems="center" className={classes.submit}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
            >
              {t('Valider')}
            </Button>
          </Flex>
        </Flex>
      </Form>
    )
  }

  const handleSubmit = async (
    values: StrikeSetting[],
    { setSubmitting }: FormikActions<StrikeSetting[]>
  ) => {
    setSubmitting(true)
    try {
      await strikeService.update(values)
    } catch (e) {
      errorContext.displayError(
        t('Une erreur est survenue, veuillez recharger la page')
      )
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <TraderSettingsConfigCard
      cardTitle="Configurez les libellés pour les strike de l'option PMG"
      adviceMessage={
        <Fetcher fetch={adviceService.findOne} fetchProps={['STRIKES']}>
          {advice => (
            <div dangerouslySetInnerHTML={{ __html: advice.template }} />
          )}
        </Fetcher>
      }
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Formik
          initialValues={strikeLabels}
          onSubmit={handleSubmit}
          render={renderStrikeSettings}
        />
      )}
    </TraderSettingsConfigCard>
  )
}

export default StrikeSettingsScene
