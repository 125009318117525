import * as React from 'react'
import { WithStyles, createStyles, withStyles } from '@material-ui/core'
import Error from '../../../../components/Error'
import Fetcher from '../../../../components/Fetcher'
import Loading from '../../../../components/Loading'
import MailTemplate from '../../../../models/MailTemplate'
import TraderSettingsConfigCard from '../TraderSettingsConfigCard'
import TraderSettingsMailTemplatesList from './TraderSettingsMailTemplatesList'
import adviceService from '../../../../services/adviceService'
import mailTemplateService from '../../../../services/mailTemplateService'
import { t } from '../../../../i18n'

const styles = () =>
  createStyles({
    cardContent: {
      paddingRight: 15,
      width: '100%',
    },
  })

type Props = WithStyles<typeof styles>

class TraderSettingsMailTemplates extends React.Component<Props> {
  renderContent = (mailTemplates: MailTemplate[], refresh: () => void) => {
    return (
      <TraderSettingsMailTemplatesList
        mailTemplates={mailTemplates}
        refresh={refresh}
      />
    )
  }

  render() {
    const { classes } = this.props

    return (
      <TraderSettingsConfigCard
        cardTitle={t('Vos templates email')}
        adviceMessage={
          <Fetcher fetch={adviceService.findOne} fetchProps={['MAIL_TEMPLATE']}>
            {advice => (
              <div dangerouslySetInnerHTML={{ __html: advice.template }} />
            )}
          </Fetcher>
        }
      >
        <div className={classes.cardContent}>
          <Fetcher
            fetch={mailTemplateService.findAll}
            loadingRender={Loading}
            errorRender={Error}
          >
            {this.renderContent}
          </Fetcher>
        </div>
      </TraderSettingsConfigCard>
    )
  }
}

export default withStyles(styles)(TraderSettingsMailTemplates)
