import {
  withWidth,
  AppBar,
  Drawer,
  Toolbar,
  Typography,
  Hidden,
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'
import { WithWidth } from '@material-ui/core/withWidth'
import DashboardIcon from '@material-ui/icons/HomeOutlined'
import EuroSymbol from '@material-ui/icons/EuroSymbol'
import SettingsIcon from '@material-ui/icons/Tune'
import classNames from 'classnames'
import * as React from 'react'
import { Switch } from 'react-router'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'

import UserContext from '../../contexts/UserContext'
import { t } from '../../i18n'
import Flex from '../Flex'

import CustomerUserMenu from './CustomerMenu'
import MenuHeader from './MenuHeader'
import MenuItems from './MenuItems'
import SalesmanMenu from './SalesmanMenu'
import SuperAdminMenu from './SuperAdminMenu'
import TraderUserMenu from './TraderMenu'
import ControllerMenu from './ControllerMenu'
import merchantService from '../../services/merchantService'
import Merchant from '../../models/Merchant'

const drawerWidth = 240
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8080'

const styles = (theme: Theme) =>
  createStyles({
    toolbar: {
      overflowX: 'hidden',
      paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
      overflowX: 'hidden',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    },
    appBarSpacer: theme.mixins.toolbar,
    menuButton: {
      marginLeft: 0,
      marginRight: 16,
      [theme.breakpoints.up('sm')]: {
        marginRight: 24,
      },
    },
    menuButtonHidden: {
      display: 'none',
    },
    logo: {
      marginRight: 6,
      maxWidth: 64,
      maxHeight: 64,
      [theme.breakpoints.up('sm')]: {
        maxWidth: 180,
      },
    },
    flexEllipsis: {
      minWidth: 0,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3, 3, 8, 3),
      height: '100vh',
      overflow: 'auto',
      position: 'relative',
    },
    root: {
      display: 'none',
      [theme.breakpoints.only('xs')]: {
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        width: '100%',
      },
    },
  })

interface OwnProps {
  children: React.ReactElement<any>
}

interface State {
  error?: boolean
  merchant?: Merchant
  value: string
}

interface Props extends WithStyles<typeof styles>, WithWidth, OwnProps {}

class Layout extends React.Component<Props, State> {
  static contextType = UserContext

  readonly state: State = {
    error: false,
    merchant: undefined,
    value: 'home',
  }

  componentDidMount() {
    this.loadMerchant()
  }

  loadMerchant = async () => {
    try {
      const { data: merchant } = await merchantService.getCurrent()
      this.setState({ merchant })
    } catch (e) {
      this.setState({ error: true })
    }
  }

  handleChange = (event: any, newValue: string) => {
    this.setState({ value: newValue })
  }

  render() {
    const { classes, width, children } = this.props
    const { user, hasRole, logoLastModifiedAt } = this.context
    const { error, merchant, value } = this.state
    const TOKEN = localStorage.getItem('access_token')

    const open = width === 'lg' || width === 'xl'

    return (
      <>
        <AppBar position="absolute" className={classes.appBar} color="default">
          <Toolbar color="default">
            <Flex
              grow
              direction="row"
              alignItems="center"
              className={classes.flexEllipsis}
            >
              <img
                className={classes.logo}
                src={`${BASE_URL}/api/merchants/logo?access_token=${TOKEN}&version=${logoLastModifiedAt}`}
                alt={t('Logo')}
              />
              <Typography component="h1" variant="h6" noWrap>
                {error ? 'Tradecast' : merchant && merchant.socialReason}
              </Typography>
            </Flex>
            <Switch>
              {hasRole('ROLE_TRADER') && <TraderUserMenu />}
              {hasRole('ROLE_CUSTOMER') && <CustomerUserMenu user={user} />}
              {hasRole('ROLE_SALESMAN') && <SalesmanMenu />}
              {hasRole('ROLE_SUPERADMIN') && <SuperAdminMenu />}
              {hasRole('ROLE_CONTROLLER') && <ControllerMenu />}
            </Switch>
          </Toolbar>
        </AppBar>
        <Hidden xsDown>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            <div className={classes.appBarSpacer} />
            <MenuHeader open={open} />
            <MenuItems open={open} />
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {children}
        </main>
        <BottomNavigation
          value={value}
          onChange={this.handleChange}
          className={classes.root}
        >
          <BottomNavigationAction
            label="Offres"
            value="home"
            icon={<DashboardIcon />}
            component={Link}
            to="/home"
          />
          <BottomNavigationAction
            label="Ventes"
            value="sales"
            icon={<EuroSymbol />}
            component={Link}
            to="/sales/list"
          />
          <BottomNavigationAction
            label="Ventes PMG"
            value="sales pmg"
            icon={<EuroSymbol />}
            component={Link}
            to="/sales/list-pmg"
          />
          <BottomNavigationAction
            label="Paramétrage"
            value="settings"
            icon={<SettingsIcon />}
            component={Link}
            to="/settings"
          />
        </BottomNavigation>
      </>
    )
  }
}

const enhancer = compose<Props, OwnProps>(
  withWidth(),
  withStyles(styles)
)
export default enhancer(Layout)
