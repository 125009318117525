import { Grid, Typography } from '@material-ui/core'
import memoizeOne from 'memoize-one'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import Fetcher from '../../../components/Fetcher'
import Margin from '../../../components/Margin'
import SaleCard from '../../../components/Sale/SaleCard'
import { User } from '../../../contexts/UserContext'
import { t } from '../../../i18n'
import Campaign from '../../../models/Campaign'
import CustomerStock from '../../../models/CustomerStock'
import ExecutionMode from '../../../models/ExecutionMode'
import Offer from '../../../models/Offer'
import Product from '../../../models/Product'
import SaleSettings from '../../../models/SaleSettings'
import Transaction from '../../../models/Transaction'
import { StrikeValue } from '../../../models/StrikeSetting'
import campaignService from '../../../services/campaignService'
import customerStockService from '../../../services/customerStockService'
import offerService, {
  OfferExecutionView,
} from '../../../services/offerService'
import saleSettingsService from '../../../services/saleSettingsService'
import formatCustomerName from '../../../utils/formatCustomerName'
import CustomerStocksCard from '../../customer/Home/CustomerStocksCard'

import SalesmanSaleCardValidationSchema from './SalesmanSaleCardValidationSchema'

interface RouteParams {
  executionMode?: ExecutionMode
  executionMonth?: string
  campaign?: Campaign
  product?: Product
  tonnage?: number
  payment?: Date
  strike?: StrikeValue
  transaction?: Transaction
  token?: string
  customer?: User
}

interface OffersAndOfferExecution {
  offers: Offer[]
  offerExecutions: OfferExecutionView[]
}

interface Props extends RouteComponentProps<any, any, RouteParams> {}

class SalesmanCustomerSale extends React.Component<Props> {
  formatCustomerName = memoizeOne(formatCustomerName)

  componentDidMount(): void {
    const { location, history } = this.props
    if (
      !location.state ||
      !location.state.executionMode ||
      !location.state.executionMonth ||
      !location.state.campaign ||
      !location.state.product ||
      !location.state.customer
    ) {
      history.replace('/customers')
    }
  }

  renderSaleCard = ([
    { offerExecutions, offers },
    saleSettings,
    customerStocks,
  ]: [OffersAndOfferExecution, SaleSettings, CustomerStock[]]) => {
    const {
      executionMode,
      executionMonth,
      campaign,
      product,
      tonnage,
      payment,
      strike,
      transaction,
      token,
      customer,
    } = this.props.location.state

    const canForcePrice = (order: Transaction) => {
      return offers.find(offer => offer.id === order.offerId)!.maneuver != null
    }

    return (
      <SaleCard
        campaign={campaign!}
        product={product!}
        executionMode={executionMode!}
        offerExecutions={offerExecutions || []}
        executionMonth={executionMonth!}
        offers={offers || []}
        saleSettings={saleSettings}
        customerStocks={customerStocks}
        tonnage={tonnage}
        payment={payment}
        strike={strike}
        defaultTransaction={transaction}
        defaultToken={token}
        customer={customer}
        validationSchema={SalesmanSaleCardValidationSchema}
        tonnageRemainingVolumeWarning
        canForcePrice={canForcePrice}
      />
    )
  }

  renderCustomerStock = ([campaigns, customerStocks]: [
    Campaign[],
    CustomerStock[]
  ]) => {
    return (
      <CustomerStocksCard
        campaigns={campaigns}
        customerStocks={customerStocks}
      />
    )
  }

  fetchOffersAndOfferExecution = async () => {
    const { campaign, product, customer } = this.props.location.state
    const { data: offers } = await offerService.findAllByCampaignAndProduct(
      campaign!,
      product!
    )
    const offerIds = offers.map(o => o.id!)
    const {
      data: offerExecutions,
    } = await offerService.getOfferExecutionsForCustomer(
      customer!.id!,
      offerIds,
      campaign!.startDateCalendar
    )

    return { data: { offers, offerExecutions } }
  }

  fetchCustomerStockService = () => {
    const { customer } = this.props.location.state
    return customerStockService.findAll(customer!.id)
  }

  render() {
    const { location } = this.props
    if (
      !location.state ||
      !location.state.executionMode ||
      !location.state.executionMonth ||
      !location.state.campaign ||
      !location.state.product ||
      !location.state.customer
    ) {
      return null
    }
    const { customer } = location.state

    return (
      <>
        <Margin bottom={2} top={2}>
          <Typography variant="h6">
            {t('Vente pour le compte de ')}
            {this.formatCustomerName(customer)}
          </Typography>
        </Margin>
        <Fetcher
          fetch={[
            this.fetchOffersAndOfferExecution,
            saleSettingsService.getOne,
            this.fetchCustomerStockService,
          ]}
        >
          {this.renderSaleCard}
        </Fetcher>
        <Margin top={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Fetcher
                fetch={[
                  campaignService.findAllActive,
                  this.fetchCustomerStockService,
                ]}
              >
                {this.renderCustomerStock}
              </Fetcher>
            </Grid>
          </Grid>
        </Margin>
      </>
    )
  }
}

export default withRouter<Props>(SalesmanCustomerSale)
