import { getDate } from 'date-fns'
import { addMethod, date, DateSchema, object, ObjectSchema } from 'yup'

/***** This file adds new Yup validation methods **/

/* New date validation : if the day is selectionable or not */
function withinSelectableDays(this: DateSchema, selectableDays: number[]) {
  /** Transform is important to prevent schema mutation  */
  return this.transform((value: Date) => {
    return selectableDays.includes(getDate(value))
      ? value
      : 'month day must be of one of the following : ' + selectableDays
  })
}

function noUndefinedValues(this: ObjectSchema<any>) {
  return this.test('noUndefinedValues', 'Requis', (value: object) => {
    if (Object.values(value).some(o => !o)) {
      return false
    }
    return true
  })
}

/**** And here we add the methods to Yup (equivalent to "yup.date.prototype.withinSelectableDays = withinSelectableDays")
 * /!\ You also have to add its typescript declaration in "types/yup/index.d.ts", through the corresponding interface /!\ */
addMethod(date, 'withinSelectableDays', withinSelectableDays)
addMethod(object, 'noUndefinedValues', noUndefinedValues)
