import { Tooltip } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { TypographyProps } from '@material-ui/core/Typography'
import * as React from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

interface Props extends RouteComponentProps {
  Icon: React.ComponentType<SvgIconProps>
  showTooltip?: boolean
  href: string
  text: string
}

const activeTypographyProps: Partial<TypographyProps> = { color: 'primary' }

const MenuItem: React.FunctionComponent<Props> = ({
  Icon,
  text,
  href,
  location,
  showTooltip,
}) => {
  const active = location.pathname.startsWith(href)
  return (
    <Link to={href}>
      <ListItem button>
        <ListItemIcon>
          <Tooltip
            title={text}
            disableFocusListener={!showTooltip}
            disableHoverListener={!showTooltip}
            disableTouchListener={!showTooltip}
          >
            <Icon color={active ? 'primary' : undefined} />
          </Tooltip>
        </ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={active ? activeTypographyProps : undefined}
        />
      </ListItem>
    </Link>
  )
}

export default withRouter<Props>(MenuItem)
