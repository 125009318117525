import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import * as React from 'react'

import { t } from '../../i18n'

interface Props {
  open: boolean
  message?: string
  onClose: () => void
}

class SaleErrorDialog extends React.Component<Props> {
  render() {
    const { open, message, onClose } = this.props

    return (
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          {t('Erreur lors de la confirmation de la vente.')}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {message || t('Erreur inconnue')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={onClose}>
            {t('Revenir à ma vente')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default SaleErrorDialog
