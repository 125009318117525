import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import UserIcon from 'mdi-material-ui/AccountCircleOutline'
import * as React from 'react'

import { t } from '../../i18n'
import authService from '../../services/authService'

interface State {
  anchorEl?: Element
}

class SalesmanMenu extends React.Component<{}, State> {
  state = {
    anchorEl: undefined,
  }

  handleUserMenuOpen = (event: React.MouseEvent) =>
    this.setState({ anchorEl: event.currentTarget })

  handleUserMenuClose = () => this.setState({ anchorEl: undefined })

  handleLogout = () => {
    this.handleUserMenuClose()
    sessionStorage.clear()
    authService.logout()
  }

  render() {
    const { anchorEl } = this.state
    const userMenuOpened = Boolean(anchorEl)

    return (
      <>
        <IconButton
          aria-owns={userMenuOpened ? 'user-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleUserMenuOpen}
        >
          <UserIcon />
        </IconButton>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={userMenuOpened}
          onClose={this.handleUserMenuClose}
        >
          <MenuItem onClick={this.handleLogout}>{t('Déconnexion')}</MenuItem>
        </Menu>
      </>
    )
  }
}

export default SalesmanMenu
