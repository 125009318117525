import * as React from 'react'

import {
  Button,
  Divider,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core'

import { Link } from 'react-router-dom'
import Fetcher from '../../../../components/Fetcher'
import Flex from '../../../../components/Flex'
import TraderSettingsConfigCard from '../TraderSettingsConfigCard'
import UploadFileButton from '../../../../components/UploadFileButton'
import adviceService from '../../../../services/adviceService'
import { t } from '../../../../i18n'
import userService from '../../../../services/userService'

const styles = (theme: Theme) =>
  createStyles({
    cardContent: {
      paddingRight: 15,
      width: '100%',
    },
    divider: {
      margin: theme.spacing(2),
    },
  })

interface Props extends WithStyles<typeof styles> {}

class TraderSettingsCustomers extends React.Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <TraderSettingsConfigCard
        cardTitle={'Vos clients'}
        adviceMessage={
          <>
            <Fetcher fetch={adviceService.findOne} fetchProps={['CUSTOMER']}>
              {advice => (
                <div dangerouslySetInnerHTML={{ __html: advice.template }} />
              )}
            </Fetcher>
            <Divider variant="middle" className={classes.divider} />
            <Link to="/settings/users">
              <Button>{t('Gestion des utilisateurs')}</Button>
            </Link>
            <br />
            <br />
            {t("Format du template d'import à respecter : ")}
            <br />
            <br />
            <Button
              download="import_customers.csv"
              href={`${process.env.PUBLIC_URL}/examples/import_customers.csv`}
            >
              {t('Exemple de fichier')}
            </Button>
          </>
        }
      >
        <Flex
          justify="center"
          alignItems="center"
          className={classes.cardContent}
        >
          <UploadFileButton size="large" action={userService.importCsv} />
        </Flex>
      </TraderSettingsConfigCard>
    )
  }
}

export default withStyles(styles)(TraderSettingsCustomers)
