import { connect, FormikActions, FormikContext, FormikState } from 'formik'
import * as React from 'react'

export type EffectState<Values> = FormikState<Values> &
  Pick<FormikActions<Values>, 'setFieldValue' | 'setValues' | 'setTouched'>

export interface EffectProps<Values> {
  onChange(
    currentState: EffectState<Values>,
    nextState: EffectState<Values>
  ): void
}

class EffectInner<Values = {}> extends React.Component<
  EffectProps<Values> & { formik: FormikContext<Values> },
  {}
> {
  componentDidUpdate(
    prevProps: EffectProps<Values> & { formik: FormikContext<Values> }
  ) {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      isValidating,
      submitCount,
      setFieldValue,
      setValues,
      setTouched,
    } = prevProps.formik
    const {
      values: nextValues,
      touched: nextTouched,
      errors: nextErrors,
      isSubmitting: nextIsSubmitting,
      isValidating: nextIsValidating,
      submitCount: nextSubmitCount,
      setFieldValue: nextSetFieldValue,
      setValues: nextSetValues,
      setTouched: nextSetTouched,
    } = this.props.formik

    if (this.props.formik !== prevProps.formik) {
      this.props.onChange(
        {
          values,
          touched,
          errors,
          isSubmitting,
          isValidating,
          submitCount,
          setFieldValue,
          setValues,
          setTouched,
        },
        {
          values: nextValues,
          touched: nextTouched,
          errors: nextErrors,
          isSubmitting: nextIsSubmitting,
          isValidating: nextIsValidating,
          submitCount: nextSubmitCount,
          setFieldValue: nextSetFieldValue,
          setValues: nextSetValues,
          setTouched: nextSetTouched,
        }
      )
    }
  }

  render() {
    return null
  }
}

export const Effect = connect<EffectProps<any>, any>(EffectInner)
