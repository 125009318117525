import { Button, CardContent } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import BackButton from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/styles'
import * as React from 'react'

import Calendar from '../../../components/Calendar/Calendar'
import CalendarHeader from '../../../components/Calendar/CalendarHeader'
import TraderCalendarReadOnlyLines from '../../../components/Calendar/TraderCalendarReadOnlyLines'
import Flex from '../../../components/Flex'
import Margin from '../../../components/Margin'
import OfferRecapData from '../../../components/OfferRecapData'
import { t } from '../../../i18n'
import Campaign from '../../../models/Campaign'
import ExecutionMode from '../../../models/ExecutionMode'
import Offer from '../../../models/Offer'
import OffersPricesView from '../../../models/OffersPricesView'
import Sector from '../../../models/Sector'

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
    marginBottom: 15,
  },
  cardContentColored: {
    width: 360,
  },
  cardContent: {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    height: 40,
  },
}))

type Props = {
  offer: Offer
  executionModes: ExecutionMode[]
  sectors: Sector[]
  campaign: Campaign
  calendar: OffersPricesView
  onBack: () => void
}

const TraderOffersListOfferRecap = ({
  offer,
  executionModes,
  sectors,
  campaign,
  calendar,
  onBack,
}: Props) => {
  const classes = useStyles()

  return (
    <>
      <Card className={classes.card}>
        <OfferRecapData
          offer={offer}
          cardContentColoredClasses={classes.cardContentColored}
        >
          <Margin bottom={2}>
            <Button color="inherit" onClick={onBack}>
              <BackButton />
              {t('Retour')}
            </Button>
          </Margin>
        </OfferRecapData>
        <CardContent className={classes.cardContent}>
          <Flex
            direction="row"
            justify="space-between"
            className={classes.title}
          >
            <Typography variant="h6" color="textSecondary">
              {t('Détails de l’offre')}
            </Typography>
          </Flex>
          <Margin top={2} bottom={2}>
            <Calendar campaign={campaign}>
              <CalendarHeader />
              <TraderCalendarReadOnlyLines
                sectors={sectors}
                executionModes={executionModes}
                calendar={calendar}
              />
            </Calendar>
          </Margin>
        </CardContent>
      </Card>
    </>
  )
}

export default TraderOffersListOfferRecap
