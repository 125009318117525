import {
  createStyles,
  FormControl,
  FormLabel,
  Theme,
  WithStyles,
} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import MuiTextField, {
  OutlinedTextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField'
import classNames from 'classnames'
import { FieldProps, getIn } from 'formik'
import * as React from 'react'

import { Omit } from '../../utils/Omit'

import FieldError from './FieldError'

const styles = (theme: Theme) =>
  createStyles({
    noMarginBottom: {
      marginBottom: 0,
    },
    progressBar: {
      height: `${theme.spacing(1 / 2)}px`,
    },
  })

interface TextFieldProps
  extends FieldProps,
    Omit<MuiTextFieldProps, 'error' | 'onChange' | 'value' | 'classes'>,
    WithStyles<typeof styles> {
  className?: string
  progressBarEnabled?: boolean
  max?: string
}

const fieldToTextField = ({
  field,
  form,
  disabled = false,
  progressBarEnabled,
  classes,
  variant,
  margin,
  max,
  className,
  type,
  ...props
}: TextFieldProps): MuiTextFieldProps => {
  const { touched, errors, isSubmitting } = form
  const fieldError = getIn(errors, field.name)
  const error = getIn(touched, field.name) && !!fieldError

  return {
    ...props,
    ...field,
    type,
    value: field.value !== null && field.value !== undefined ? field.value : '',
    variant: variant || 'filled',
    margin: margin || 'dense',
    error,
    disabled: isSubmitting || disabled,
    className: classNames(className, {
      [classes.noMarginBottom]: progressBarEnabled,
    }),
    inputProps: { step: type === 'number' ? 'any' : undefined, max },
  }
}

const TextField: React.ComponentType<TextFieldProps> = ({
  label,
  progressBarEnabled,
  classes,
  ...others
}: TextFieldProps) => (
  <FormControl>
    {label && <FormLabel htmlFor={others.field.name}>{label}</FormLabel>}
    <MuiTextField
      id={others.field.name}
      name={others.field.name}
      {...fieldToTextField({ progressBarEnabled, classes, ...others })}
    />
    <FieldError name={others.field.name} />
    {progressBarEnabled && <LinearProgress className={classes.progressBar} />}
  </FormControl>
)

TextField.displayName = 'FormikMaterialUITextField'

export default withStyles(styles)(TextField)
