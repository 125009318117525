import React from 'react'
import { Typography } from '@material-ui/core'
import { capitalize } from '@material-ui/core/utils/helpers'
import Fetcher from '../../../../components/Fetcher'

import { t } from '../../../../i18n'
import ControllerDataCustomerStockList from './ControllerDataCustomerStockList'
import customerStockService, {
  CustomerStockData,
} from '../../../../services/customerStockService'

const ControllerDataCustomerStock = () => {
  const getTableColumns = () => [
    t('Client'),
    t('Campagne'),
    t('Produit'),
    t('Quantité totale (t)'),
    t('Quantité vendue (t)'),
    t('Pourcentage vendu'),
    t('Reste à vendre (t)'),
  ]

  const dataToTable = (customerStockData: CustomerStockData) => [
    capitalize(customerStockData.socialReason),
    customerStockData.campaignName,
    capitalize(customerStockData.productName),
    customerStockData.totalTonnage,
    customerStockData.tonnageSold,
    customerStockData.percentageSold,
    customerStockData.remainingToSell,
  ]

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t('Listes des données des stocks')}
      </Typography>
      <Fetcher fetch={customerStockService.listData}>
        {(customerStockData, refresh) => (
          <ControllerDataCustomerStockList
            customerStockData={customerStockData}
            columns={getTableColumns()}
            tableDataMapper={dataToTable}
            refresh={refresh}
            title=""
          />
        )}
      </Fetcher>
    </>
  )
}

export default ControllerDataCustomerStock
