import qs from 'qs'

import { User } from '../contexts/UserContext'

export const getMapEmbedUrl = (user: User) => {
  const key =
    process.env.REACT_APP_GOOGLE_MAP_EMBED_API_KEY ||
    'AIzaSyAa5J_hSkAt9DN1uCCvz4jhBoszEa9Sf40'
  const q = [user.address, user.address2, user.postalCode, user.city]
    .filter(x => x)
    .join(' ')
  const params = qs.stringify({ key, q })
  return `https://www.google.com/maps/embed/v1/search?${params}`
}

export default { getMapEmbedUrl }
