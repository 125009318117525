import {
  Card,
  CardContent,
  Theme,
  Typography,
  Tooltip,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import React, { useContext } from 'react'
import PreviewIcon from '@material-ui/icons/VisibilityOutlined'

import { t } from '../../i18n'
import { format } from '../../i18n/format'
import { TransactionListItemView } from '../../services/transactionService'
import CardContentColored from '../CardContentColored'
import Margin from '../Margin'
import useRouter from '../../hooks/useRouter'
import Flex from '../Flex'
import UserContext from '../../contexts/UserContext'
import { ContractPmgListItemView } from '../../services/contractPMGService'

const useStyles = makeStyles((theme: Theme) => ({
  leftPanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.common.white,
    minHeight: 350,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  bold: {
    fontSize: '2.37rem',
    fontWeight: 700,
  },
  saleOption: {
    flex: 2,
  },
  help: {
    flex: 1,
    borderLeftStyle: 'solid',
    borderLeftWidth: 2,
    borderLeftColor: theme.palette.grey[300],
  },
}))

interface Props {
  transaction: TransactionListItemView | ContractPmgListItemView
}

export const SalesListDetails = ({ transaction }: Props) => {
  const { history } = useRouter()
  const { hasRole } = useContext(UserContext)
  const classes = useStyles()
  const handlePreviewTargetOrder = (targetOrderId: number) => () => {
    history.push({ pathname: `/target-order/detail/${targetOrderId}` })
  }

  const isContractPmg = (
    transaction: TransactionListItemView | ContractPmgListItemView
  ): transaction is ContractPmgListItemView => {
    return (transaction as ContractPmgListItemView).isModePMG !== undefined
  }

  const getOperator = () => {
    switch (transaction.createdBy) {
      case 'salesman':
        return 'Atc'
      case 'customer':
        return 'Agri'
      case 'trader':
        return 'Trader'
      default:
        return transaction.createdBy
    }
  }

  return (
    <Card className={classes.card}>
      <CardContentColored className={classes.leftPanel} color="primary">
        <div>
          <Typography variant="body2" color="inherit">
            {t('Vente de :')}
          </Typography>
          <Typography variant="body1" color="inherit">
            <Typography
              className={classes.bold}
              color="inherit"
              display="inline"
              component="span"
            >
              {transaction.tonnage}t
            </Typography>{' '}
            à {transaction.price}€/t
          </Typography>
          <Margin top={3} />
          <Typography variant="body2" color="inherit">
            {t('soit un total de :')}
          </Typography>
          <Typography className={classes.bold} color="inherit">
            {transaction.totalPrice}€
          </Typography>
          <Typography variant="caption" display="block" color="inherit">
            {t('brut hors TVA')}
          </Typography>
        </div>
        {(transaction as TransactionListItemView).targetOrderId && (
          <Flex flexFlow="wrap" alignItems="center">
            <hr style={{ width: '100%' }} />
            <Typography variant="caption" display="block" color="inherit">
              {t('Vente issue d’un ordre de vente à prix d’objectif')}
            </Typography>
            <Tooltip title={t("Voir le détail de l'ordre de vente")}>
              <IconButton
                key="detail"
                aria-label="Detail"
                color="inherit"
                onClick={handlePreviewTargetOrder(
                  (transaction as TransactionListItemView).targetOrderId
                )}
              >
                <PreviewIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Flex>
        )}
      </CardContentColored>
      <CardContent className={classes.saleOption}>
        <Margin bottom={2}>
          <Typography variant="body1" color="textPrimary">
            {t("J'ai vendu :")}
          </Typography>
          <Typography variant="body2">
            <Typography
              variant="h6"
              color="primary"
              display="inline"
              component="span"
            >
              {transaction.tonnage}t
            </Typography>{' '}
            {t('de')}{' '}
            <Typography
              variant="h6"
              color="primary"
              display="inline"
              component="span"
            >
              {transaction.productName}
            </Typography>{' '}
            {t('campagne')}{' '}
            <Typography
              variant="h6"
              color="primary"
              display="inline"
              component="span"
            >
              {transaction.campaignYear}
            </Typography>
          </Typography>
        </Margin>
        <Typography variant="body1" color="textPrimary">
          {t('Modalités et périodes d’exécution :')}
        </Typography>
        <Typography variant="body2">
          <Typography
            variant="h6"
            color="primary"
            display="inline"
            component="span"
          >
            {transaction.executionMode}
          </Typography>{' '}
          {t('Modalités et périodes d’exécution :')}{' '}
          <Typography
            variant="h6"
            color="primary"
            display="inline"
            component="span"
          >
            {format(transaction.executionDate, 'MM/yy')}
          </Typography>
        </Typography>
        <Margin top={2}>
          <Typography variant="body1" color="textPrimary">
            {t('Paiement le :')}
          </Typography>
          <Typography
            variant="h6"
            color="primary"
            display="inline"
            component="span"
          >
            {format(transaction.paymentDate, 'dd/MM/yy')}
          </Typography>
        </Margin>
        <Margin top={2} />
        {isContractPmg(transaction) && (
          <>
            <Typography variant="body1" color="textPrimary">
              {t('Status : ')}
            </Typography>
            <Typography color="primary" variant="h6">
              {transaction.status}
            </Typography>
            {transaction.status !== 'CREATED' && (
              <>
                <Typography variant="body1" color="textPrimary">
                  {t('Complément : ')}
                </Typography>
                <Typography color="primary" variant="h6">
                  {transaction.supplement} €
                </Typography>
              </>
            )}
          </>
        )}
        <Margin top={2} />
        {isContractPmg(transaction) && (
          <>
            <Typography variant="body1" color="textPrimary">
              {t('Option : ')}
            </Typography>
            <Typography variant="body2">
              {t('Valeur du strike : ')}{' '}
              <Typography color="primary" variant="h6">
                {transaction.strikeValue} €/t
              </Typography>
              {t("Date d'expiration: ")}
              <Typography color="primary" variant="h6">
                {transaction.expirationDate}
              </Typography>
            </Typography>
          </>
        )}
        <Margin top={2}>
          {transaction.createdBy && (
            <Margin top={2}>
              <Typography variant="body1" color="textPrimary">
                {t('Opérateur :')}
              </Typography>
              <Typography
                variant="h6"
                color="primary"
                display="inline"
                component="span"
              >
                {transaction.createdBy}
              </Typography>
            </Margin>
          )}
          {hasRole!('ROLE_TRADER') && transaction.covered != null && (
            <Margin top={2}>
              <Typography variant="body1" color="textSecondary">
                {transaction.covered === 'COVERED' &&
                  t('Vente couverte sur le marché')}
                {transaction.covered === 'PARTIALLY_COVERED' &&
                  t('Vente en partie couverte sur le marché')}
                {transaction.covered === 'NOT_COVERED' &&
                  t('Vente non-couverte sur le marché')}
              </Typography>
              {transaction.coverageOperations &&
                transaction.coverageOperations.map((co, index) => (
                  <Typography
                    key={index}
                    variant="h6"
                    color="primary"
                    display="block"
                    component="span"
                  >
                    {co.code} : {co.tonsCovered}t
                  </Typography>
                ))}
              {(transaction.covered === 'PARTIALLY_COVERED' ||
                transaction.covered === 'COVERED') &&
                transaction.coverageOperations &&
                transaction.coverageOperations.length > 0 && (
                  <Typography
                    variant="h6"
                    color="primary"
                    display="block"
                    component="span"
                  >
                    Total couverture : {transaction.totalTonsCovered}t
                  </Typography>
                )}
            </Margin>
          )}
        </Margin>
      </CardContent>
      {transaction.comments && (
        <CardContent className={classes.help}>
          <Margin bottom>
            <Typography variant="body1" color="textPrimary">
              {t('Compléments d’informations sur cette offre :')}
            </Typography>
          </Margin>
          <Typography variant="body2">{transaction.comments}</Typography>
        </CardContent>
      )}
    </Card>
  )
}

export default SalesListDetails
