import * as React from 'react'

import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core'

import memoizeOne from 'memoize-one'
import ContractTemplate from '../../../../models/ContractTemplate'
import Error from '../../../../components/Error'
import ExecutionMode from '../../../../models/ExecutionMode'
import Fetcher from '../../../../components/Fetcher'
import Loading from '../../../../components/Loading'
import SuperAdminSettingsContractTemplatesList from './SuperAdminSettingsContractTemplatesList'
import adviceService from '../../../../services/adviceService'
import contractTemplateService from '../../../../services/contractTemplateService'
import executionModeService from '../../../../services/executionModeService'
import TraderSettingsConfigCard from '../../../trader/Settings/TraderSettingsConfigCard'

const styles = (theme: Theme) =>
  createStyles({
    cardContent: {
      paddingRight: 15,
      width: '100%',
    },
  })

interface ContractTemplatesByExecutionMode {
  [type: string]: {
    executionMode: ExecutionMode
    contractTemplates: ContractTemplate[]
  }
}

interface Props extends WithStyles<typeof styles> {}

interface State {
  campaignIndex: number
}

class SuperAdminSettingsContractTemplates extends React.Component<
  Props,
  State
> {
  getContractTemplatesByExecutionMode: (
    contractTemplates: ContractTemplate[],
    executionModes: ExecutionMode[]
  ) => ContractTemplatesByExecutionMode = memoizeOne(
    (
      contractTemplates: ContractTemplate[],
      executionModes: ExecutionMode[]
    ) => {
      return executionModes.reduce(
        (
          acc: ContractTemplatesByExecutionMode,
          executionMode: ExecutionMode
        ) => {
          return {
            ...acc,
            [executionMode.type]: {
              executionMode,
              contractTemplates: contractTemplates.filter(
                ct => ct.executionMode.type === executionMode.type
              ),
            },
          }
        },
        {}
      )
    }
  )

  renderContent = (
    fetchResults: [ContractTemplate[], ExecutionMode[]],
    refresh: () => void
  ) => {
    const contractTemplatesByExecutionMode = this.getContractTemplatesByExecutionMode(
      fetchResults[0],
      fetchResults[1]
    )
    return (
      <>
        {Object.values(contractTemplatesByExecutionMode).map(
          ({ executionMode, contractTemplates: currentContracts }) => (
            <SuperAdminSettingsContractTemplatesList
              key={executionMode.id}
              executionMode={executionMode}
              contractTemplates={currentContracts}
              refresh={refresh}
            />
          )
        )}
      </>
    )
  }

  render() {
    const { classes } = this.props

    return (
      <TraderSettingsConfigCard
        cardTitle={'Vos templates de contrats'}
        adviceMessage={
          <Fetcher fetch={adviceService.findOne} fetchProps={['CONTRACT']}>
            {advice => (
              <div dangerouslySetInnerHTML={{ __html: advice.template }} />
            )}
          </Fetcher>
        }
      >
        <div className={classes.cardContent}>
          <Fetcher
            fetch={[
              contractTemplateService.findAll,
              executionModeService.findAll,
            ]}
            loadingRender={Loading}
            errorRender={Error}
          >
            {this.renderContent}
          </Fetcher>
        </div>
      </TraderSettingsConfigCard>
    )
  }
}

export default withStyles(styles)(SuperAdminSettingsContractTemplates)
