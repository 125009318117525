import flatMap from 'lodash/flatMap'
import memoizeOne from 'memoize-one'

import axios from '../config/axios'
import DeliverySite from '../models/DeliverySite'
import ParityMatrix, {
  ParityMatrixLine,
  ParityMatrixValue,
} from '../models/ParityMatrix'
import Sector from '../models/Sector'

export const getParityMatrix = () =>
  axios.get<ParityMatrix>('api/parity-matrix')
export const updateParityMatrix = (parityMatrix: ParityMatrix) =>
  axios.put<ParityMatrix>('api/parity-matrix/' + parityMatrix.id, parityMatrix)

export const transformParityMatrixToLines = memoizeOne(
  (
    parityMatrix: ParityMatrix,
    deliverySites: DeliverySite[],
    sectors: Sector[]
  ): ParityMatrixLine[] => {
    return deliverySites.map((deliverySite: DeliverySite) => {
      const valuesPerSector = sectors.reduce((acc: {}, sector: Sector) => {
        const value = parityMatrix.values.find(
          (parityMatrixValue: ParityMatrixValue) =>
            parityMatrixValue.deliverySite.id === deliverySite.id &&
            parityMatrixValue.sector.id === sector.id
        )
        return { ...acc, [`${sector.id}`]: !value ? 0 : value.price }
      }, {})

      return { deliverySite, values: valuesPerSector }
    })
  }
)

export const transformParityMatrixLinesToValues = memoizeOne(
  (
    parityMatrixLines: ParityMatrixLine[],
    sectors: Sector[]
  ): ParityMatrixValue[] => {
    const tmp = parityMatrixLines.map((parityMatrixLine: ParityMatrixLine) => {
      const valuesBySector: Array<{
        sectorId: string
        value: number
      }> = Object.entries(parityMatrixLine.values).map(([key, value]) => {
        return { sectorId: key, value }
      })

      return valuesBySector.map(
        (valueBySector: { sectorId: string; value: number }) => {
          return {
            sector: sectors.find(
              (sector: Sector) => valueBySector.sectorId === String(sector.id)
            )!,
            deliverySite: parityMatrixLine.deliverySite,
            price: valueBySector.value,
          }
        }
      )
    })

    return flatMap(tmp, p => p)
  }
)

export default {
  getParityMatrix,
  updateParityMatrix,
  transformParityMatrixToLines,
  transformParityMatrixLinesToValues,
}
