import qs from 'qs'

import axios from '../config/axios'
import { User } from '../contexts/UserContext'

const REACT_APP_AUTH =
  process.env.REACT_APP_AUTH ||
  'Basic d2ViYXBwOmI2OWMwMWFiLWY3ZDYtNGY0OC1iMDNlLWMzNTNkN2E3NWVhYQ=='

export const getToken = (username: string, password: string) => {
  const config = {
    headers: {
      Authorization: REACT_APP_AUTH,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  const body = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    grant_type: 'password',
    password,
    username,
    scope: 'read write',
  }
  return axios.post('/oauth/token', qs.stringify(body), config)
}

export const getUserInfo = () => {
  return axios.get<User>('/api/users/current')
}

export const getAccessTokenFromJWT = (jwtToken: string) => {
  return axios.get('/authenticate/token', {
    headers: {
      Authorization: jwtToken,
    },
  })
}

export const getUserData = async (
  tokenType: string,
  accessToken: string,
  refreshToken: string
) => {
  localStorage.setItem('token_type', tokenType)
  localStorage.setItem('access_token', accessToken)
  localStorage.setItem('refresh_token', refreshToken)
  const userInfoResponse = await getUserInfo()
  if (userInfoResponse.status === 200) {
    return userInfoResponse.data
  } else {
    localStorage.removeItem('token_type')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    throw new Error("Can't user info")
  }
}

export const login = async (
  username: string,
  password: string
): Promise<User> => {
  const tokenResponse = await getToken(username, password)
  if (tokenResponse.status === 200) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const { token_type, access_token, refresh_token } = tokenResponse.data
    return getUserData(token_type, access_token, refresh_token)
  } else {
    throw new Error("Can't get token")
  }
}

export const logout = () => {
  localStorage.clear()
  axios.get('/api/logout', {})
  window.location.href = '/sign-in'
}

export const refreshAccessToken = () => {
  const config = {
    headers: {
      Authorization: REACT_APP_AUTH,
      'content-type': 'application/x-www-form-urlencoded',
    },
  }

  const user = localStorage.getItem('user')
  const body = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    grant_type: 'refresh_token',
    // eslint-disable-next-line @typescript-eslint/camelcase
    refresh_token: localStorage.getItem('refresh_token'),
    username: JSON.parse(user!).username,
    scope: 'read write',
  }

  return axios.post(`/oauth/token`, qs.stringify(body), config)
}

export const resetPasswordInit = (login: string) =>
  axios.post('api/public/reset-password/init', login, {
    headers: { 'content-type': 'text/plain' },
  })

export const resetPasswordFinish = (key: string, newPassword: string) =>
  axios.post('api/public/reset-password/finish', { key, newPassword })

export default {
  login,
  logout,
  refreshAccessToken,
  resetPasswordInit,
  resetPasswordFinish,
  getUserData,
  getAccessTokenFromJWT,
}
