import {
  Button,
  createStyles,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'

import { CalendarContext } from './Calendar'

const styles = (theme: Theme) =>
  createStyles({
    title: {
      paddingLeft: theme.spacing(2),
      '& > *': {
        lineHeight: '48px',
      },
    },
    dark: {
      backgroundColor: theme.palette.grey[100],
    },
    td: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(0.6),
      paddingBottom: theme.spacing(0.6),
    },
    btn: {
      width: '100%',
      height: '100%',
      paddingTop: theme.spacing(1.4),
      paddingBottom: theme.spacing(1.4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderRadius: theme.shape.borderRadius * 2.5,
      textAlign: 'center',
      color: 'white',
    },
    resizePointer: {
      cursor: 'ew-resize',
    },
  })

interface Values {
  [month: string]: boolean
}
export type CalendarLineValues = Values

interface Props extends WithStyles<typeof styles> {
  title: string
  value: Values
  onChange: (values: Values) => void
  dark?: boolean
  color: string
}

interface State {
  pointerDown: boolean
  newValue: boolean
}

class CalendarFormLine extends React.Component<Props, State> {
  state: State = {
    newValue: false,
    pointerDown: false,
  }

  handlePointerUpOrLeave = () => {
    const { pointerDown } = this.state
    if (pointerDown) {
      this.setState({ pointerDown: false })
    }
  }

  handlePointerDown = (month: string) => () => {
    const { value, onChange } = this.props
    const newValue = !value[month]
    onChange({ ...value, [month]: newValue })
    this.setState({
      newValue,
      pointerDown: true,
    })
  }

  handlePointerOver = (month: string) => () => {
    const { value, onChange } = this.props
    const { pointerDown, newValue } = this.state
    if (!pointerDown) {
      return
    }
    onChange({ ...value, [month]: newValue })
  }

  render() {
    const { classes, title, dark, color, value } = this.props
    const { pointerDown } = this.state
    return (
      <CalendarContext.Consumer>
        {({ months }) => (
          <tr
            className={classNames(dark && classes.dark)}
            onPointerLeave={this.handlePointerUpOrLeave}
            data-cy={'calendar-line'}
          >
            <th className={classes.title}>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
                noWrap
              >
                {title}
              </Typography>
            </th>
            {months!.map(month => (
              <td
                key={month}
                className={classNames(
                  classes.td,
                  pointerDown && classes.resizePointer
                )}
              >
                <Button
                  className={classNames(
                    classes.btn,
                    pointerDown && classes.resizePointer
                  )}
                  style={{ backgroundColor: value[month] ? color : undefined }}
                  onPointerOver={this.handlePointerOver(month)}
                  onPointerDown={this.handlePointerDown(month)}
                  onPointerUp={this.handlePointerUpOrLeave}
                >
                  &nbsp;
                </Button>
              </td>
            ))}
          </tr>
        )}
      </CalendarContext.Consumer>
    )
  }
}

export default withStyles(styles)(CalendarFormLine)
