import { setLocale } from 'yup'
import { t } from '.'

setLocale({
  mixed: {
    required: t('Requis'),
    typeError: t('Format invalide'),
    // :hankey: waiting Yup correction
    // @ts-ignore
    notType: function notType(ref) {
      switch (ref.type) {
        case 'number':
          return t('Nombre invalide')
        default:
          return t('Format invalide')
      }
    },
  },
  number: {
    required: t('Requis'),
    typeError: t('Nombre invalide'),
    // eslint-disable-next-line
    min: t('La valeur ne peut être inférieure à ${min}'),
    // eslint-disable-next-line
    max: t('La valeur ne peut être supérieure à ${max}'),
  },
  string: {
    required: t('Requis'),
  },
})
