import * as React from 'react'
import { withTheme, WithTheme } from '@material-ui/styles'

interface Props
  extends WithTheme<any>,
    React.StyleHTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  top?: number | boolean
  right?: number | boolean
  bottom?: number | boolean
  left?: number | boolean
}

const Margin: React.FunctionComponent<Props> = ({
  theme,
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
  children,
  style,
  ...others
}) => {
  return (
    <div
      style={{
        marginTop: Number(top) * theme.spacing(1),
        marginRight: Number(right) * theme.spacing(1),
        marginBottom: Number(bottom) * theme.spacing(1),
        marginLeft: Number(left) * theme.spacing(1),
        ...style,
      }}
      {...others}
    >
      {children}
    </div>
  )
}

export default withTheme(Margin)
