import { createStyles, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import React from 'react'

const styles = createStyles({
  /* Styles applied to the root element. */
  root: {
    position: 'relative',
    overflow: 'hidden',
    height: 15,
    width: '100%',
    borderRadius: '0 8px 8px 0',
  },
  /* Styles applied to the layered bar. */
  percentageBar: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
    zIndex: 1,
  },
})

interface Props extends WithStyles<typeof styles> {
  className?: string
  percentage: number
  backgroundColor: string
  barColor: string
}

const PercentageBar: React.FunctionComponent<Props> = ({
  classes,
  className: classNameProp,
  percentage,
  barColor,
  backgroundColor,
}) => {
  const className = classNames(classes.root, classNameProp)
  const inlineStyles = {
    percentageBar: { width: `${percentage}%`, backgroundColor: barColor },
    rootBar: { backgroundColor },
  }

  return (
    <div className={className} style={inlineStyles.rootBar}>
      <div
        className={classes.percentageBar}
        style={inlineStyles.percentageBar}
      />
    </div>
  )
}

export default withStyles(styles)(PercentageBar)
