import {
  createStyles,
  Typography,
  WithStyles,
  WithTheme,
  Theme,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { capitalize } from '@material-ui/core/utils/helpers'
import * as React from 'react'

import Margin from '../../../components/Margin'
import PercentageBar from '../../../components/PercentageBar'
import { t } from '../../../i18n'
import Campaign from '../../../models/Campaign'
import CustomerStock from '../../../models/CustomerStock'

const TONNAGE_SOLD_COLOR = '#1ebc97'
const styles = (theme: Theme) =>
  createStyles({
    tonnageToSell: {
      color: 'blue',
    },
    tonnageSold: {
      color: TONNAGE_SOLD_COLOR,
    },
    description: {
      paddingTop: '15px !important',
      [theme.breakpoints.up('md')]: {
        textAlign: 'right',
      },
    },
    totalTonnage: {
      display: 'inline-block',
    },
  })

interface Props extends WithStyles<typeof styles>, WithTheme {
  customerStocks: CustomerStock[]
  campaign: Campaign
}

class CustomerStocksCardDeposit extends React.Component<Props> {
  render() {
    const { customerStocks, classes, theme } = this.props

    return (
      <>
        <Typography variant="h6">{t('Stock en dépôt à vendre')}</Typography>
        <Margin top={2}>
          {customerStocks.length === 0 && (
            <Typography>Aucun produit en stock</Typography>
          )}

          {customerStocks.map((customerStock, index) => (
            <Margin top={2} key={index}>
              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  <Typography variant="subtitle1">
                    {capitalize(customerStock.product.name)}
                    <Typography
                      className={classes.totalTonnage}
                      variant="caption"
                      display="block"
                      color={'textSecondary'}
                    >
                      &nbsp;({customerStock.totalTonnage} t)
                    </Typography>
                  </Typography>
                  <PercentageBar
                    percentage={customerStock.percentageSold}
                    backgroundColor={theme.palette.primary.main}
                    barColor={TONNAGE_SOLD_COLOR}
                  />
                </Grid>
                <Grid item md={4} xs={12} className={classes.description}>
                  <Typography variant="caption" display="block" color="primary">
                    {customerStock.remainingToSell < 0
                      ? 0
                      : customerStock.remainingToSell}
                    t restant à vendre
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    className={classes.tonnageSold}
                  >
                    {customerStock.tonnageSold}t déjà vendues
                  </Typography>
                </Grid>
              </Grid>
            </Margin>
          ))}
        </Margin>
      </>
    )
  }
}

export default withStyles(styles, { withTheme: true })(
  CustomerStocksCardDeposit
)
