import { createStyles, Theme, Typography, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { capitalize } from '@material-ui/core/utils/helpers'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import classNames from 'classnames'
import * as React from 'react'

import { t } from '../../../i18n'
import OfferDisplayPreference from '../../../models/OfferDisplayPreference'
import Product from '../../../models/Product'

const BORDER_WIDTH = '1px'

const styles = (theme: Theme) =>
  createStyles({
    toggleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: 5,
    },
    button: {
      color: theme.palette.primary.main + '!important',
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
      borderTopWidth: BORDER_WIDTH,
      borderLeftWidth: BORDER_WIDTH,
      borderBottomWidth: BORDER_WIDTH,
      paddingLeft: 20,
      paddingRight: 20,
      height: 40,
      [theme.breakpoints.only('xs')]: {
        borderLeft: '1px solid' + theme.palette.primary.main + '!important',
        marginLeft: 0 + '!important',
      },
    },
    endingButton: {
      borderRightWidth: BORDER_WIDTH,
    },
    selectedButton: {
      color: 'white !important',
      backgroundColor: theme.palette.primary.main + '!important',
    },
    containerButtons: {
      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column',
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  product: Product
  preference: OfferDisplayPreference
  onProductPreferenceChange?: (
    product: Product,
    preference: OfferDisplayPreference
  ) => void
}

interface State {
  preference: OfferDisplayPreference
}

class CustomerSettingsProduct extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.preference !== props.preference) {
      return {
        preference: props.preference,
      }
    }
    return null
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      preference: props.preference,
    }
  }

  onPreferenceChange = (
    event: React.ChangeEvent<{}>,
    value: OfferDisplayPreference
  ) => {
    this.setState({ preference: value })

    const { product, onProductPreferenceChange } = this.props
    if (onProductPreferenceChange) {
      onProductPreferenceChange(product, value)
    }
  }

  render() {
    const { product, classes } = this.props
    const { preference } = this.state

    return (
      <>
        <Typography>{capitalize(product.name)}</Typography>
        <div className={classes.toggleContainer}>
          <ToggleButtonGroup
            value={preference}
            exclusive
            onChange={this.onPreferenceChange}
            selected={false}
            className={classes.containerButtons}
          >
            <ToggleButton
              data-cy={
                preference === OfferDisplayPreference.Sell ? 'active' : ''
              }
              value={OfferDisplayPreference.Sell}
              classes={{
                root: classes.button,
                selected: classes.selectedButton,
              }}
            >
              <Typography variant="button" color="inherit">
                {t('À VENDRE')}
              </Typography>
            </ToggleButton>
            <ToggleButton
              data-cy={
                preference === OfferDisplayPreference.Watch ? 'active' : ''
              }
              value={OfferDisplayPreference.Watch}
              classes={{
                root: classes.button,
                selected: classes.selectedButton,
              }}
            >
              <Typography variant="button" color="inherit">
                {t("À GARDER À L'OEIL")}
              </Typography>
            </ToggleButton>
            <ToggleButton
              data-cy={
                preference === OfferDisplayPreference.DontCare ? 'active' : ''
              }
              value={OfferDisplayPreference.DontCare}
              classes={{
                root: classNames(classes.button, classes.endingButton),
                selected: classes.selectedButton,
              }}
            >
              <Typography variant="button" color="inherit">
                {t("NE M'INTÉRESSE PAS")}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(CustomerSettingsProduct)
