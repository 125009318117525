import {
  Button,
  createStyles,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik'
import differenceBy from 'lodash/differenceBy'
import * as React from 'react'
import * as Yup from 'yup'

import TextField from '../../../components/Form/TextField'
import Loading from '../../../components/Loading'
import SnackbarsExtended from '../../../components/SnackbarsExtended'
import { t } from '../../../i18n'
import Sector from '../../../models/Sector'
import sectorService from '../../../services/sectorService'

import SettingsConflictErrorDialog from './SettingsConflictErrorDialog'
import TraderSettingsConfigCard from './TraderSettingsConfigCard'
import adviceService from '../../../services/adviceService'
import Fetcher from '../../../components/Fetcher'

const styles = (theme: Theme) =>
  createStyles({
    loading: {
      padding: 30,
    },
    table: {
      width: 'auto',
      '& th, & td': {
        paddingLeft: 0,
      },
    },
    tableRow: {
      height: theme.spacing(5),
    },
    headerLabel: {
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
    },
    submitButton: {
      marginTop: theme.spacing(1),
      display: 'block',
    },
  })

interface Props extends WithStyles<typeof styles> {}

interface State {
  error: boolean
  isLoading: boolean
  sectors: Sector[]
  conflictError: boolean
  onlyActiveSectors: boolean
}

type FormValues = {
  sectors: Sector[]
}

const validationSchema = Yup.object().shape({
  sectors: Yup.array(
    Yup.object().shape({
      id: Yup.number()
        .required()
        .moreThan(0),
      name: Yup.string().min(1, t('Le nom ne doit pas être vide')),
    })
  ),
})

class SectorsSettings extends React.Component<Props, State> {
  readonly state: State = {
    error: false,
    isLoading: true,
    sectors: [],
    conflictError: false,
    onlyActiveSectors: false,
  }

  componentDidMount(): void {
    this.setState({ onlyActiveSectors: !this.state.onlyActiveSectors })
    this.loadSectors()
  }

  loadSectors = async () => {
    try {
      this.setState({ isLoading: true })
      const { data: sectors } = await sectorService.findAll(
        this.state.onlyActiveSectors
      )
      this.setState({ sectors, isLoading: false })
    } catch (e) {
      this.setState({ error: true })
    }
  }

  toggleActiveSectors = () => {
    this.setState({ onlyActiveSectors: !this.state.onlyActiveSectors })
    this.loadSectors()
  }

  handleSnackbarClose = () => {
    this.setState({ error: false })
  }

  closeConflictDialog = () => {
    this.setState({ conflictError: false })
  }

  onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikActions<FormValues>
  ) => {
    const initialSectors = this.state.sectors
    const sectorsToUpdate = differenceBy(values.sectors, initialSectors, 'name')

    try {
      await sectorService.updateSome(sectorsToUpdate)
      const { data: sectors } = await sectorService.findAll()
      this.setState({ sectors })
    } catch (e) {
      this.setState({ error: true })
    } finally {
      setSubmitting(false)
    }
  }

  onChangeActiveSector = (sector: Sector) => async (event: any) => {
    const { checked } = event.target

    this.setState(({ sectors }) => ({
      sectors: sectors.map(s =>
        s.id === sector.id
          ? {
              ...s,
              active: checked,
            }
          : s
      ),
    }))
    try {
      sector.active = checked
      await sectorService.update(sector)
    } catch (error) {
      switch (error.response.status) {
        case 409:
          sector.active = !checked
          this.setState(({ sectors }) => ({
            sectors: sectors.map(s =>
              s.id === sector.id
                ? {
                    ...s,
                    active: !checked,
                  }
                : s
            ),
            conflictError: sector.active,
          }))
          break
        default:
          this.setState({ error: true })
          break
      }
    }
  }

  renderForm = ({ values, isSubmitting }: FormikProps<FormValues>) => {
    const { classes } = this.props
    const { onlyActiveSectors } = this.state

    return (
      <Form>
        <Button
          onClick={this.toggleActiveSectors}
          color="primary"
          className={classes.submitButton}
        >
          {onlyActiveSectors
            ? t('Voir les secteurs actifs')
            : t('Voir tous les secteurs')}
        </Button>
        <br />
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell>
                <Typography color="primary" className={classes.headerLabel}>
                  {t('Code')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="primary" className={classes.headerLabel}>
                  {t('Nom')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="primary" className={classes.headerLabel}>
                  {t('Activation du secteur')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.sectors &&
              values.sectors.length > 0 &&
              values.sectors.map((sector, index) => (
                <TableRow key={sector.id} className={classes.tableRow}>
                  <TableCell>
                    <Typography color="textPrimary">
                      {sector.externalId}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Field
                      component={TextField}
                      name={`sectors[${index}].name`}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      color="primary"
                      checked={sector.active}
                      onChange={this.onChangeActiveSector(sector)}
                      title={
                        sector.active
                          ? t('Désactiver le secteur')
                          : t('Activer le secteur')
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          className={classes.submitButton}
        >
          {t('Enregistrer')}
        </Button>
      </Form>
    )
  }

  render() {
    const { classes } = this.props
    const { error, isLoading, sectors, conflictError } = this.state

    if (isLoading) {
      return (
        <Card className={classes.loading}>
          <Loading />
        </Card>
      )
    }

    return (
      <>
        <TraderSettingsConfigCard
          cardTitle={'Vos secteurs géographiques'}
          adviceMessage={
            <Fetcher fetch={adviceService.findOne} fetchProps={['SECTORS']}>
              {advice => (
                <div dangerouslySetInnerHTML={{ __html: advice.template }} />
              )}
            </Fetcher>
          }
        >
          <Formik
            initialValues={{ sectors }}
            onSubmit={this.onSubmit}
            render={this.renderForm}
            validationSchema={validationSchema}
          />
        </TraderSettingsConfigCard>
        <SettingsConflictErrorDialog
          open={conflictError}
          errorTitle={'Impossible de désactiver ce secteur'}
          errorContent={
            'Des offres ou des ordres de vente sont en cours sur ce secteur.\nVeuillez les clôturer avant de le désactiver.'
          }
          onClose={this.closeConflictDialog}
        />

        <SnackbarsExtended
          onClose={this.handleSnackbarClose}
          open={error}
          variant={'error'}
          message={t('Une erreur est survenue, veuillez recharger la page')}
        />
      </>
    )
  }
}

export default withStyles(styles)(SectorsSettings)
