import { Button, Typography } from '@material-ui/core'
import memoizeOne from 'memoize-one'
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import DataTable from '../../../components/DataTable'
import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Margin from '../../../components/Margin'
import { User } from '../../../contexts/UserContext'
import { t } from '../../../i18n'
import userService from '../../../services/userService'

interface Props extends RouteComponentProps<any> {}

interface State {
  customersEnabled: boolean
}

const tableColumns: MUIDataTableColumnDef[] = [
  { name: 'id', options: { display: 'excluded', filter: false } },
  t('Raison sociale'),
  t('Nom'),
  t('Ville'),
  t('Secteur'),
  t('Commercial'),
]

class TraderCustomers extends React.Component<Props, State> {
  readonly state: State = {
    customersEnabled: true,
  }

  customersToTableData = memoizeOne((customers: User[]) =>
    customers.map(customer => {
      const delegatedSalesman = customer.delegatedSalesman
        ? `${customer.delegatedSalesman!.firstname} ${
            customer.delegatedSalesman!.lastname
          }`
        : ''
      return [
        customer.id,
        customer.socialReason ? customer.socialReason : ' - ',
        customer.contact ? customer.contact : ' - ',
        `${customer.city} ${customer.postalCode}`,
        customer.sector ? customer.sector.name : ' - ',
        delegatedSalesman,
      ]
    })
  )

  toggleCustomersEnabled = () => {
    this.setState(({ customersEnabled }) => ({
      customersEnabled: !customersEnabled,
    }))
  }

  onRowClick: MUIDataTableOptions['onRowClick'] = rowData => {
    const { history } = this.props
    const customerId = rowData[0]
    history.push(`/customers/${customerId}`)
  }

  renderTable = (customers: User[]) => {
    const { customersEnabled } = this.state

    return (
      <DataTable
        columns={tableColumns}
        data={this.customersToTableData(customers)}
        emptyContent={
          customersEnabled ? (
            <>
              <Typography color="primary" variant="subtitle1">
                {t('Aucun client importé')}
              </Typography>
              <Typography color="primary" variant="subtitle2">
                {t('Commençons d’abord par vous synchroniser avec votre ERP.')}
              </Typography>
              <Margin top={1} />
              <Button
                variant="contained"
                color="primary"
                href="/settings/customers"
              >
                {t('Importer mes clients')}
              </Button>
            </>
          ) : (
            t('Aucun client désactivé')
          )
        }
        options={{
          onRowClick: this.onRowClick,
        }}
        storageSaveId={'clientList'}
      />
    )
  }

  render() {
    const { customersEnabled } = this.state

    return (
      <>
        <Flex direction="row" alignItems="center" justify="center">
          <Typography variant="h5">
            {customersEnabled
              ? t('Vos clients actifs')
              : t('Vos clients inactifs')}
          </Typography>
          <Margin left={3} />
          <Button color="primary" onClick={this.toggleCustomersEnabled}>
            {customersEnabled
              ? t('Voir les clients inactifs')
              : t('Voir les clients actifs')}
          </Button>
          <Flex grow />
        </Flex>
        <Margin top={2}>
          <Fetcher
            fetch={userService.findCustomers}
            fetchProps={[customersEnabled]}
          >
            {this.renderTable}
          </Fetcher>
        </Margin>
      </>
    )
  }
}

export default withRouter<Props>(TraderCustomers)
