import { createMuiTheme } from '@material-ui/core'
import blue from '@material-ui/core/colors/blue'
import purple from '@material-ui/core/colors/purple'
import orange from '@material-ui/core/colors/orange'

const palette = {
  primary: {
    ...blue,
    main: '#4C84FF',
    400: '#57a3ff',
    300: '#6fb4ff',
    200: '#c0deff',
  },
  secondary: purple,
  disable: 'rgba(0, 0, 0, 0.38)',
  warning: orange,
}

const defaultTheme = createMuiTheme()

export default createMuiTheme({
  palette,
  typography: {
    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
    htmlFontSize: 10, // to have easier rem unit (1rem = 10px; 1.6rem = 16px)
    h1: {
      fontSize: '9.46rem',
      letterSpacing: '-0.15rem',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
    },
    h2: {
      fontSize: '5.91rem',
      letterSpacing: '-0.05rem',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
    },
    h3: {
      fontSize: '4.73rem',
      letterSpacing: '0rem',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
    },
    h4: {
      fontSize: '3.35rem',
      letterSpacing: '0.03rem',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
    },
    h5: {
      fontSize: '2.37rem',
      letterSpacing: '0rem',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1.97rem',
      letterSpacing: '0.03rem',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1.58rem',
      lineHeight: 1.78,
      letterSpacing: '0.05rem',
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 400,
    },
    body2: {
      fontSize: '1.38rem',
      lineHeight: 1.45,
      letterSpacing: '0.03rem',
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '1.58rem',
      lineHeight: 1.52,
      letterSpacing: '0.02rem',
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '1.38rem',
      lineHeight: 1.74,
      letterSpacing: '0.01rem',
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 400,
    },
    button: {
      fontSize: '1.35rem',
      lineHeight: 1.18,
      letterSpacing: '0.125rem',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 700,
    },
    caption: {
      fontSize: '1.18rem',
      lineHeight: 1.35,
      letterSpacing: '0.04rem',
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 400,
    },
    overline: {
      fontSize: '1.18rem',
      lineHeight: 1.35,
      letterSpacing: '0.2rem',
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 400,
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: defaultTheme.palette.common.white,
        color: defaultTheme.palette.text.primary,
      },
    },
    MuiFilledInput: {
      inputMarginDense: {
        paddingTop: 16,
        paddingBottom: 16,
      },
      underline: {
        '&$disabled:before': {
          borderBottomStyle: `solid`,
        },
      },
      root: {
        '&$disabled': {
          backgroundColor: '#e4e4e4',
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        color: defaultTheme.palette.common.white,
        width: '18px',
        height: '26px',
      },
      active: {
        color: defaultTheme.palette.common.white,
      },
      icon: {
        color: defaultTheme.palette.common.white + ' !important',
      },
    },
    // @ts-ignore
    MUIDataTableHeadCell: {
      root: {
        textAlign: 'center',
        paddingLeft: 12,
        paddingRight: 12,
      },
      toolButton: {
        textAlign: 'center',
        display: 'auto',
      },
      fixedHeader: {
        backgroundColor: palette.primary.main,
        color: defaultTheme.palette.common.white,
        textTransform: 'uppercase',
      },
      sortActive: {
        color: defaultTheme.palette.common.white,
      },
      sortAction: {
        color: defaultTheme.palette.common.white,
        display: 'inline-block',
      },
    },
    MUIDataTableBodyCell: {
      root: {
        textAlign: 'center',
        paddingLeft: 12,
        paddingRight: 12,
      },
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none',
      },
    },
  },
})
