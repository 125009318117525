import axios from '../config/axios'
import SaleSettings from '../models/SaleSettings'

export const findOne = () => axios.get<SaleSettings>('api/sale-settings')

export const update = (saleSetting: SaleSettings) =>
  axios.put<SaleSettings>('api/sale-settings', saleSetting)

export default {
  getOne: findOne,
  update,
}
