import { AxiosResponse } from 'axios'
import JwtDecode from 'jwt-decode'

import { Omit } from '@material-ui/core'
import axios from '../config/axios'
import Campaign from '../models/Campaign'
import { EExecutionMode } from '../models/ExecutionMode'
import Product from '../models/Product'
import Transaction from '../models/Transaction'
import { StrikeValue } from '../models/StrikeSetting'
import DeferredPaymentOption from '../models/DeferredPaymentOption'
import pageableService, { Page, Pageable } from './pageableService'

export type TransactionRequest = {
  executionMonth: Date
  campaign: Pick<Campaign, 'id'>
  executionMode: EExecutionMode
  product: Pick<Product, 'id'>
  tons: number
  paymentDate: Date
  strike?: StrikeValue
}
export type DeferredPaymentsListRequest = Omit<
  TransactionRequest,
  'tons' | 'paymentDate'
>

export type ForcedTransactionRequest = {
  transactionJwt: string
  forcedRawPrice: number
  forcedStorageFee?: number
  forcedNetPrice: number
}

export type TransactionToken = {
  exp: number
  iat: number
  order: string
}

export type TransactionListItemView = {
  transactionId: number
  creationDate: Date | string
  productName: string
  campaignYear: string
  tonnage: number
  executionMode: string
  price: string
  totalPrice: string
  executionDate: Date
  paymentDate: Date
  comments: string
  offerId: number
  customerId: number
  customerSocialReason: string
  contractId: string
  targetOrderId: number
  covered: 'COVERED' | 'NOT_COVERED' | 'PARTIALLY_COVERED'
  coverageOperations: Array<{ code: string; tonsCovered: number }>
  operationsCodes: string[]
  createdBy: string

  deferredPaymentFee: string
  appliedManeuver: string
  storageFee: string
  rawPrice: string
  computedPrice: string
  computedRawPrice: string
  computedStorageFee: string
  oilBonus: string
  deliveryCost: string
  majorationFee: string
  bushelDepartureBonus: string
  commercialManeuver: string
  totalTonsCovered?: number
}

const decodeJwt = (response: AxiosResponse<string>) => {
  const decodedToken = JwtDecode(response.data) as TransactionToken
  return {
    token: response.data,
    transaction: JSON.parse(decodedToken.order) as Transaction,
    exp: decodedToken.exp,
  }
}

const getOne = (transactionRequest: TransactionRequest) =>
  axios
    .post<string>('api/transactions/generate', transactionRequest, {
      timeout: parseInt(process.env.REACT_APP_SLOW_API_TIMEOUT || '10000'),
    })
    .then(decodeJwt)

const getOneForCustomer = (
  userId: number,
  transactionRequest: TransactionRequest
) =>
  axios
    .post<string>('api/transactions/generate', transactionRequest, {
      params: { userId },
      timeout: parseInt(process.env.REACT_APP_SLOW_API_TIMEOUT || '10000'),
    })
    .then(decodeJwt)

const forceForCustomer = (
  userId: number,
  forceRequest: ForcedTransactionRequest
) =>
  axios
    .post<string>('api/transactions/force', forceRequest, {
      params: { userId },
    })
    .then(decodeJwt)

export const getAll = (pageable?: Pageable) =>
  axios.get<Page<TransactionListItemView> | TransactionListItemView[]>(
    'api/transactions' +
      (pageable ? '?' + pageableService.pageableToQuery(pageable) : '')
  )

const save = (transactionJwt: string) =>
  axios.post<Transaction>('api/transactions', transactionJwt, {
    timeout: parseInt(process.env.REACT_APP_SLOW_API_TIMEOUT || '10000'),
  })

const saveForCustomer = (userId: number, transactionJwt: string) =>
  axios.post<Transaction>('api/transactions', transactionJwt, {
    timeout: parseInt(process.env.REACT_APP_SLOW_API_TIMEOUT || '10000'),
    headers: {
      'Content-Type': 'text/plain',
    },
    params: { userId },
  })

const fetchDeferredPaymentOptions = (request: DeferredPaymentsListRequest) =>
  axios.post<DeferredPaymentOption[]>(
    `api/transactions/deferred-payments-options`,
    request,
    {
      timeout: parseInt(process.env.REACT_APP_SLOW_API_TIMEOUT || '10000'),
    }
  )

const fetchDeferredPaymentOptionsForCustomer = (
  userId: number,
  request: DeferredPaymentsListRequest
) =>
  axios.post<DeferredPaymentOption[]>(
    `api/transactions/deferred-payments-options`,
    request,
    {
      params: { userId },
      timeout: parseInt(process.env.REACT_APP_SLOW_API_TIMEOUT || '10000'),
    }
  )

const downloadFile = async (axiosUrl: string, fileName: string) => {
  const { data } = await axios.get(axiosUrl, {
    headers: { accept: 'application/pdf' },
    responseType: 'blob',
  })
  const hiddenLink = document.createElement('a')
  const blob = new Blob([data], {
    type: 'application/pdf',
  })
  const objectURL = window.URL.createObjectURL(blob)
  hiddenLink.href = objectURL
  hiddenLink.download = fileName
  document.body.appendChild(hiddenLink)
  hiddenLink.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(objectURL)
    hiddenLink.remove()
  }, 100)
}

const downloadContract = async (id: number) => {
  await downloadFile(`api/transactions/${id}/file`, `contrat_tc_${id}.pdf`)
}

const downloadContractSupplement = async (id: number) => {
  await downloadFile(
    `api/transactions/${id}/file/supplement`,
    `contrat_tc_${id}_complement.pdf`
  )
}

const findByTargetOrderId = (id: number) =>
  axios.get<Transaction[]>(`api/transactions?targetOrderId=${id}`)

export const abortContract = (id: number) =>
  axios.put<Transaction[]>(`api/transactions/abort?transactionId=${id}`)

export const exportContracts = (body: { startDate: Date; endDate: Date }) =>
  axios.post<any>('api/transactions/exportcontracts', {
    startDate: body.startDate.toISOString(),
    endDate: body.endDate.toISOString(),
  })

export default {
  getOne,
  getOneForCustomer,
  forceForCustomer,
  save,
  saveForCustomer,
  fetchDeferredPaymentOptions,
  fetchDeferredPaymentOptionsForCustomer,
  getAll,
  downloadContract,
  findByTargetOrderId,
  downloadContractSupplement,
  abortContract,
  exportContracts,
}
