import { Dialog, DialogContent } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid/Grid'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'
import Typography from '@material-ui/core/Typography'
import { Field, Formik, FormikProps } from 'formik'
import React from 'react'
import memoizeOne from 'memoize-one'

import { t } from '../../i18n'
import TextField from '../Form/TextField'
import Margin from '../Margin'
import SaleTargetPriceValidationSchema from './SaleTargetPriceValidationSchema'

type Props = {
  initialValue?: number
  onClose: () => void
  onSave: (targetPrice: number) => void
}

type FormValues = {
  targetPrice?: number
}

export const SaleTargetPriceDialog = ({
  onClose,
  onSave,
  initialValue,
}: Props) => {
  const onSubmit = async (values: FormValues) => {
    onSave(values.targetPrice!)
    onClose()
  }

  const getInitialValues = memoizeOne(
    (): FormValues => {
      return { targetPrice: initialValue }
    }
  )

  const renderForm = () => (formikProps: FormikProps<FormValues>) => {
    const { handleSubmit, isSubmitting } = formikProps

    return (
      <Dialog open={true} fullWidth maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography variant="h6">
              {t("Veuillez définir votre prix d'objectif")}
            </Typography>
            <Typography variant="caption" display="block">
              {t(
                'La vente se fera automatiquement lorsque ce prix sera atteint par le marché.'
              )}
              {t("Vous pourrez retirer l'ordre de vente à tout moment.")}
            </Typography>
            <Grid item xs={12} sm={4}>
              <Margin bottom={1} top={1}>
                <Typography variant="body1" color="textPrimary">
                  {t("Prix d'objectif")}
                </Typography>
                <Field
                  component={TextField}
                  name="targetPrice"
                  type="number"
                  fullWidth={true}
                  autoFocus={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€/t</InputAdornment>
                    ),
                  }}
                />
              </Margin>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color={'primary'} onClick={onClose} disabled={isSubmitting}>
              {t('Annuler')}
            </Button>
            <Button color={'primary'} type="submit" disabled={isSubmitting}>
              {t("Appliquer le prix d'objectif")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={SaleTargetPriceValidationSchema(
        initialValue ? initialValue : 0
      )}
      onSubmit={onSubmit}
    >
      {renderForm()}
    </Formik>
  )
}
