import axios from '../config/axios'
import PmgSettings from '../models/PmgSettings'
const BASE_URL = 'api/pmg-settings'

const findFirst = () => axios.get<PmgSettings>(`${BASE_URL}`)

const update = (pmg: PmgSettings) => axios.put<PmgSettings>(`${BASE_URL}`, pmg)

const isInsideMarketHours = () =>
  axios.get<boolean>(`${BASE_URL}/inside-market-hours`)

export default { findFirst, update, isInsideMarketHours }
