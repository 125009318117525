import Button from '@material-ui/core/Button/Button'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import { Theme, WithStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import Typography from '@material-ui/core/Typography'
import { capitalize } from '@material-ui/core/utils/helpers'
import {
  FastField,
  Field,
  Form,
  Formik,
  FormikActions,
  FormikProps,
} from 'formik'
import pick from 'lodash/pick'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import { Omit } from '@material-ui/core'
import Error from '../../../components/Error'
import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import { DatePicker } from '../../../components/Form/DatePicker'
import { DateTimePicker } from '../../../components/Form/DateTimePicker'
import FieldError from '../../../components/Form/FieldError'
import { Select } from '../../../components/Form/Select'
import TextField from '../../../components/Form/TextField'
import Loading from '../../../components/Loading'
import Margin from '../../../components/Margin'
import { t } from '../../../i18n'
import ContractTemplate from '../../../models/ContractTemplate'
import ExecutionMode from '../../../models/ExecutionMode'
import contractTemplateService from '../../../services/contractTemplateService'
import executionModeService from '../../../services/executionModeService'

import ContractTemplateDialog from './ContractTemplateDialog'
import FormValues from './FormValues'
import ValidationSchema from './NewOfferStep2ValidationSchema'
import ContractTypes from '../../../models/ContractTypesEnum'

const styles = (theme: Theme) =>
  createStyles({
    card: {
      marginTop: 15,
      marginBottom: 15,
    },
    formControl: {
      marginRight: theme.spacing(8),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 270,
    },
    datePicker: {
      maxWidth: 270,
    },
    input: {
      maxWidth: 270,
    },
    iconButton: {
      margin: theme.spacing(1),
      position: 'absolute',
      right: -64,
      top: 18,
    },
  })

interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

interface State {
  contractTemplatesReferential: ContractTemplate[]
  contractTemplateDialogVisibility?: string
}

type SetFieldValue = FormikProps<FormValues>['setFieldValue']

class NewOfferStep2 extends React.Component<Props, State> {
  state: State = {
    contractTemplatesReferential: [],
    contractTemplateDialogVisibility: undefined,
  }

  getInitialFormValues: () => FormValues = () => {
    const formOffer: FormValues = this.props.location.state.formOffer
    const { executionDetails, contractTemplates } = formOffer

    const executionModeTypes = executionDetails.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.executionMode.type]: undefined,
      }),
      {}
    )
    const newContractTemplates = pick(
      { ...executionModeTypes, ...contractTemplates },
      Object.keys(executionModeTypes)
    )

    formOffer.contractTemplates = newContractTemplates

    return formOffer
  }

  componentDidMount() {
    if (!this.props.location.state || !this.props.location.state.formOffer) {
      this.props.history.replace({ pathname: '/home/new-offer/step1' })
      return
    }
    this.fetchContractTemplates()
  }

  fetchContractTemplates = async () => {
    const {
      data: contractTemplatesReferential,
    } = await contractTemplateService.findAll()
    this.setState({ contractTemplatesReferential })
  }

  openContractTemplateDialog = (
    executionModeType: string,
    contractType: string
  ) => () => {
    this.setState(
      {
        contractTemplateDialogVisibility:
          executionModeType + '.' + contractType,
      },
      () => {}
    )
  }

  closeContractTemplateDialog = () => {
    this.setState({ contractTemplateDialogVisibility: undefined })
  }

  onNext = (
    values: FormValues,
    { setSubmitting }: FormikActions<FormValues>
  ) => {
    const { history } = this.props
    setSubmitting(false)
    history.replace({
      pathname: '/home/new-offer/recap',
      state: { formOffer: values },
    })
  }

  handlePreviousClick = (values: FormValues) => () => {
    const { history } = this.props
    history.replace({
      pathname: '/home/new-offer/step1',
      state: { formOffer: values },
    })
  }

  onExpirationDatetimeChange = (setFieldValue: SetFieldValue) => (
    expirationDatetime?: Date
  ) => {
    if (expirationDatetime) {
      expirationDatetime.setHours(8, 0, 0, 0)
    }
    setFieldValue(`expirationDatetime`, expirationDatetime)
  }

  clearField = (setFieldValue: SetFieldValue, fieldName: string) => () => {
    setFieldValue(fieldName, undefined)
  }

  onContractTemplateChange = (setFieldValue: SetFieldValue) => (
    newTemplate: string
  ) => {
    const { contractTemplateDialogVisibility } = this.state
    setFieldValue(
      `contractTemplates.${contractTemplateDialogVisibility}.template`,
      newTemplate
    )
  }

  renderForm = ({
    values,
    touched,
    errors,
    isSubmitting,
    setFieldValue,
  }: FormikProps<FormValues>) => {
    const { classes } = this.props
    const {
      contractTemplatesReferential,
      contractTemplateDialogVisibility,
    } = this.state

    let contractTemplate: Omit<ContractTemplate, 'id'> | undefined = undefined
    if (contractTemplateDialogVisibility) {
      const contractTemplateDialogKeys = contractTemplateDialogVisibility
        ? contractTemplateDialogVisibility.split('.')
        : []
      contractTemplate =
        values.contractTemplates[contractTemplateDialogKeys[0] || 0][
          contractTemplateDialogKeys[1] || 0
        ]
    }

    return (
      <Fetcher
        fetch={executionModeService.findActives}
        loadingRender={Loading}
        errorRender={Error}
      >
        {(executionModes: ExecutionMode[]) => (
          <Form>
            <Margin bottom={2}>
              <Flex direction="row">
                <FormControl className={classes.formControl}>
                  <FastField
                    component={TextField}
                    className={classes.input}
                    name="maximalVolume"
                    label={t('Volume total maximal (optionnel)')}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">t</InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText>
                    {t(
                      'L’offre se clôturera automatiquement une fois le volume atteint.'
                    )}
                  </FormHelperText>
                </FormControl>
              </Flex>
            </Margin>

            <Margin bottom={2}>
              <Flex direction="row">
                <FormControl className={classes.formControl}>
                  <FastField
                    className={classes.datePicker}
                    component={DatePicker}
                    name="expirationDatetime"
                    label={t('Date de clôture de l’offre (optionnel)')}
                    onChange={this.onExpirationDatetimeChange(setFieldValue)}
                    onClear={this.clearField(
                      setFieldValue,
                      'expirationDatetime'
                    )}
                    clearable={true}
                  />
                  <FormHelperText>
                    {t(
                      'L’offre se clôturera automatiquement à 8h à cette date.'
                    )}
                  </FormHelperText>
                </FormControl>
              </Flex>
            </Margin>

            <Margin bottom={2}>
              <Flex direction="row">
                <FormControl className={classes.formControl}>
                  <FastField
                    className={classes.datePicker}
                    component={DateTimePicker}
                    name="publicationDatetime"
                    label={t('Date de publication différée (optionnel)')}
                    onClear={this.clearField(
                      setFieldValue,
                      'publicationDatetime'
                    )}
                    clearable={true}
                  />
                  <FormHelperText>
                    {t(
                      'L’offre se publiera automatiquement à l’heure et la date choisies.'
                    )}
                  </FormHelperText>
                </FormControl>
              </Flex>
            </Margin>

            <Margin bottom={2}>
              <FormLabel>{t('Type de confirmation')}</FormLabel>
              <FormHelperText>
                {t(
                  'Sélectionner le type de confirmation pour chaque modalités d’éxécution.'
                )}
              </FormHelperText>
            </Margin>
            <Margin bottom={2}>
              <Flex direction="column">
                {Object.keys(values.contractTemplates).map(
                  executionModeType => {
                    const contractTemplate = executionModes.find(
                      em => em.type === executionModeType
                    )
                    return (
                      <>
                        <Typography>
                          {contractTemplate && contractTemplate.name}
                        </Typography>
                        <Flex direction="row" key={executionModeType} wrap>
                          {Object.keys(ContractTypes)
                            .filter(
                              ct =>
                                ContractTypes[ct] === ContractTypes.STANDARD ||
                                values.modePMG
                            )
                            .map((contractType, index) => (
                              <FormControl
                                key={contractType}
                                className={classes.formControl}
                                variant="outlined"
                              >
                                <Field
                                  component={Select}
                                  name={`contractTemplates.${executionModeType}.${contractType}`}
                                  label={t(ContractTypes[contractType])}
                                  type="object"
                                  labelStyle={{ fontSize: '1.40rem' }}
                                  options={contractTemplatesReferential}
                                >
                                  {contractTemplatesReferential
                                    .filter(
                                      ct =>
                                        ct.executionMode.type ===
                                          executionModeType &&
                                        ct.contractType === contractType
                                    )
                                    .map(({ id, name }: ContractTemplate) => (
                                      <MenuItem key={id} value={id}>
                                        {capitalize(name)}
                                      </MenuItem>
                                    ))}
                                </Field>

                                {values.contractTemplates &&
                                  values.contractTemplates[executionModeType] &&
                                  values.contractTemplates[executionModeType][
                                    contractType
                                  ] && (
                                    <Tooltip
                                      title={t('Editer')}
                                      placement="top"
                                    >
                                      <IconButton
                                        color="primary"
                                        className={classes.iconButton}
                                        onClick={this.openContractTemplateDialog(
                                          executionModeType,
                                          contractType
                                        )}
                                      >
                                        <Icon>edit</Icon>
                                      </IconButton>
                                    </Tooltip>
                                  )}
                              </FormControl>
                            ))}
                        </Flex>
                      </>
                    )
                  }
                )}
                <p
                  style={{
                    color: 'red',
                    display:
                      Object.values(values.contractTemplates)[0] ===
                        undefined && touched.contractTemplates
                        ? 'block'
                        : 'none',
                  }}
                >
                  {t('Ces champs sont requis')}
                </p>
                <FieldError name="contractTemplates" />
              </Flex>
            </Margin>

            <Margin bottom={2}>
              <Flex direction="row">
                <FormControl className={classes.formControl}>
                  <FastField
                    component={TextField}
                    name="comments"
                    label={t('Compléments d’informations (optionnel)')}
                    type="text"
                    rows="5"
                    multiline
                    style={{ width: 602 }}
                  />
                </FormControl>
              </Flex>
            </Margin>

            <Flex direction="row" justify="center">
              <Margin right>
                <Button
                  color="primary"
                  variant="text"
                  onClick={this.handlePreviousClick(values)}
                >
                  {t('Précedent')}
                </Button>
              </Margin>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={
                  Object.values(values.contractTemplates)
                    .map(ct => {
                      if (!ct) {
                        return undefined
                      }
                      return (
                        (Object.values(ct).length === 1 && !values.modePMG) ||
                        Object.values(ct).length === 3
                      )
                    })
                    .some(ct => !ct) || isSubmitting
                }
              >
                {t('Suivant')}
              </Button>
            </Flex>

            <ContractTemplateDialog
              open={contractTemplateDialogVisibility !== null}
              onClose={this.closeContractTemplateDialog}
              onChange={this.onContractTemplateChange(setFieldValue)}
              contractTemplate={contractTemplate}
            />
          </Form>
        )}
      </Fetcher>
    )
  }

  render() {
    const { classes, location } = this.props

    if (!location.state || !location.state.formOffer) {
      return (
        <>
          <Typography variant="h5">{t('Nouvelle offre')}</Typography>
          <Loading />
        </>
      )
    }

    return (
      <>
        <Typography variant="h5">{t('Nouvelle offre')}</Typography>
        <Card className={classes.card}>
          <CardContent>
            <Formik
              initialValues={this.getInitialFormValues()}
              onSubmit={this.onNext}
              validationSchema={ValidationSchema}
              render={this.renderForm}
            />
          </CardContent>
        </Card>
      </>
    )
  }
}

export default withStyles(styles)(NewOfferStep2)
