import {
  createStyles,
  IconButton,
  TextField,
  Theme,
  WithStyles,
  withStyles,
  TableFooter,
} from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ModalProps } from '@material-ui/core/Modal'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { capitalize } from '@material-ui/core/utils/helpers'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import addDays from 'date-fns/addDays'
import endOfMonth from 'date-fns/endOfMonth'
import parse from 'date-fns/parse'
import React, { Component } from 'react'

import Flex from '../../../../components/Flex'
import Margin from '../../../../components/Margin'
import Poster from '../../../../components/Poster'
import { t } from '../../../../i18n'
import { format } from '../../../../i18n/format'
import { NewMajorationTable } from '../../../../models/MajorationTable'
import MajorationValue from '../../../../models/MajorationValue'
import { Omit } from '../../../../utils/Omit'

const styles = (theme: Theme) =>
  createStyles({
    title: {
      paddingBottom: 0,
      display: 'flex',
      flexDirection: 'row',
    },
    button: {
      marginTop: theme.spacing(1),
    },
    noBorderBottom: {
      borderBottom: 'none',
    },
  })

interface Props
  extends WithStyles<typeof styles>,
    Omit<ModalProps, 'classes' | 'onClose' | 'children'> {
  majorationTable?: NewMajorationTable
  open: boolean
  onClose: () => any
  onSave: (majorationTable: NewMajorationTable) => any
  submitLabel?: string
}

interface State {
  majorationTable?: NewMajorationTable
}

class TraderSettingsMajorationTablesDialog extends Component<Props, State> {
  state: State = {
    majorationTable: undefined,
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (
      !prevProps.majorationTable &&
      prevProps.majorationTable !== this.props.majorationTable
    ) {
      this.setState({ majorationTable: this.props.majorationTable })
    }
  }

  onFieldChange = (idx: number, name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const majorationTable: NewMajorationTable = {
      ...this.state.majorationTable!,
      majorationValues: [
        ...this.state.majorationTable!.majorationValues.map(
          (majorationValue: MajorationValue, i: number) => {
            return i === idx
              ? {
                  ...majorationValue,
                  [name]: event.target.value ? Number(event.target.value) : '',
                }
              : majorationValue
          }
        ),
      ],
    }
    this.setState({ majorationTable })
  }

  onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const majorationTable: NewMajorationTable = {
      ...this.state.majorationTable!,
      name: event.target.value,
    }
    this.setState({ majorationTable })
  }

  addPeriod = () => {
    const [
      { endDate: prevEndDate },
    ] = this.state.majorationTable!.majorationValues.slice(-1)

    const startDate = addDays(parse(prevEndDate, 'yyyy-MM-dd', new Date()), 1)
    const endDate = endOfMonth(startDate)

    const majorationTable: NewMajorationTable = {
      ...this.state.majorationTable!,
      majorationValues: [
        ...this.state.majorationTable!.majorationValues,
        {
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          value: 0,
        },
      ],
    }
    this.setState({ majorationTable })
  }

  deletePeriod = () => {
    const majorationTable: NewMajorationTable = {
      ...this.state.majorationTable!,
      majorationValues: this.state.majorationTable!.majorationValues.slice(
        0,
        -1
      ),
    }
    this.setState({ majorationTable })
  }

  onClose = () => {
    const { onClose } = this.props
    this.setState({ majorationTable: undefined })
    if (onClose) {
      onClose()
    }
  }

  handleSubmit = async () => {
    const { onSave } = this.props
    const { majorationTable } = this.state

    if (onSave) {
      await onSave(majorationTable!)
    }
  }

  renderContent() {
    const { submitLabel, classes } = this.props
    const { majorationTable } = this.state

    if (!majorationTable) {
      return <div />
    }

    return (
      <>
        <DialogTitle>
          <div className={classes.title}>
            <span>{t('Table de majoration')}</span>
            <Flex grow />
            <IconButton onClick={this.onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <TextField
            value={majorationTable.name}
            onChange={this.onNameChange}
            InputLabelProps={{ disabled: true }}
            margin="dense"
            error={!majorationTable.name}
            helperText={!majorationTable.name && t('Requis')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">.csv</InputAdornment>
              ),
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Date de début')}</TableCell>
                <TableCell>{t('Valeur (€)')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {majorationTable.majorationValues.map(
                ({ startDate, value }: MajorationValue, idx: number) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>
                        {capitalize(format(startDate, 'MMMM yyyy'))}
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={value}
                          onChange={this.onFieldChange(idx, 'value')}
                          type="number"
                          InputLabelProps={{ disabled: true }}
                          margin="dense"
                        />
                      </TableCell>
                      <TableCell padding="none">
                        {idx + 1 === majorationTable.majorationValues.length &&
                          majorationTable.majorationValues.length > 1 && (
                            <IconButton
                              onClick={this.deletePeriod}
                              color="primary"
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan={2}
                  classes={{ root: classes.noBorderBottom }}
                >
                  <Margin top={1}>
                    <Button
                      onClick={this.addPeriod}
                      variant="outlined"
                      size="small"
                      color="primary"
                    >
                      <AddIcon fontSize="small" />
                      {t('Ajouter une période')}
                    </Button>
                  </Margin>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color="primary">
            {t('Annuler')}
          </Button>
          <Poster
            onSubmit={this.handleSubmit}
            disabled={!majorationTable.name}
            errorInSnackBar={true}
            buttonText={submitLabel}
          />
        </DialogActions>
      </>
    )
  }

  render() {
    const { open } = this.props

    return (
      <Dialog open={open} onClose={this.onClose} fullWidth maxWidth="sm">
        {this.renderContent()}
      </Dialog>
    )
  }
}

export default withStyles(styles)(TraderSettingsMajorationTablesDialog)
