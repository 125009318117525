import * as React from 'react'
import * as Yup from 'yup'

import {
  Button,
  Divider,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core'
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik'

import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Fetcher from '../../../components/Fetcher'
import Loading from '../../../components/Loading'
import Product from '../../../models/Product'
import SettingsConflictErrorDialog from './SettingsConflictErrorDialog'
import SnackbarsExtended from '../../../components/SnackbarsExtended'
import TextField from '../../../components/Form/TextField'
import TraderSettingsConfigCard from './TraderSettingsConfigCard'
import UploadFileButton from '../../../components/UploadFileButton'
import adviceService from '../../../services/adviceService'
import productService from '../../../services/productService'
import { t } from '../../../i18n'

const styles = (theme: Theme) =>
  createStyles({
    loading: {
      padding: 30,
    },
    table: {
      width: 'auto',
      '& th, & td': {
        paddingLeft: 0,
      },
    },
    tableRow: {
      height: theme.spacing(5),
    },
    headerLabel: {
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
    },
    submitButton: {
      margin: theme.spacing(1),
    },
    grid: {
      paddingRight: theme.spacing(2),
    },
    divider: {
      margin: theme.spacing(2),
    },
  })

type Props = WithStyles<typeof styles> & {}

type State = {
  error: boolean
  isLoading: boolean
  products: Product[]
  conflictError: boolean
  onlyActiveProducts: boolean
}

const validationSchema = Yup.object().shape({
  products: Yup.array(
    Yup.object().shape({
      oilPercent: Yup.number()
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv
        })
        .min(0, t('Le pourcentage ne peut pas être négatif'))
        .max(100, t('Le pourcentage ne peut pas dépasser 100'))
        .required(),
    })
  ),
})

interface FormValues {
  products: Product[]
}

class ProductsSettings extends React.Component<Props, State> {
  state: {
    isLoading: boolean
    onlyActiveProducts: boolean
    conflictError: boolean
    error: boolean
    products: any[]
  } = {
    error: false,
    isLoading: true,
    products: [],
    conflictError: false,
    onlyActiveProducts: true,
  }

  componentDidMount(): void {
    this.setState({ onlyActiveProducts: !this.state.onlyActiveProducts })
    this.loadProducts()
  }

  loadProducts = async () => {
    try {
      this.setState({ isLoading: true })
      const { data: products } = await productService.findAll(
        this.state.onlyActiveProducts
      )
      this.setState({
        products: products.sort(x => (x.active ? -1 : 1)),
        isLoading: false,
      })
    } catch (e) {
      this.setState({ error: true })
    }
  }

  importProducts = async (csv: File) => {
    const importIsOk = await productService.importCsv(csv)
    await this.loadProducts()
    this.setState({ isLoading: false })
    return importIsOk
  }

  handleSnackbarClose = () => {
    this.setState({ error: false })
  }

  handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikActions<FormValues>
  ) => {
    const productsState = this.state.products

    const productsToUpdate = values.products.filter(p => {
      const match = productsState.find(ps => ps.id === p.id)
      return match && match.oilPercent !== p.oilPercent
    })

    try {
      await productService.updateOilPercentage(productsToUpdate)
      const { data: products } = await productService.findAll(
        this.state.onlyActiveProducts
      )
      this.setState({ products })
    } catch (e) {
      this.setState({ error: true })
    } finally {
      setSubmitting(false)
    }
  }

  onChangeActiveProduct = (product: Product) => async (event: any) => {
    const { checked } = event.target

    this.setState(({ products }) => ({
      products: products.map(prod =>
        prod.id === product.id
          ? {
              ...prod,
              active: checked,
            }
          : prod
      ),
    }))
    try {
      product.active = checked
      await productService.update(product)
    } catch (error) {
      switch (error.response.status) {
        case 409:
          product.active = !checked
          this.setState(({ products }) => ({
            products: products.map(prod =>
              prod.id === product.id
                ? {
                    ...prod,
                    active: !checked,
                  }
                : prod
            ),
            conflictError: product.active && true,
          }))
          break
        default:
          product.active = !checked
          this.setState({ error: true })
          break
      }
    }
  }

  toggleActiveProducts = () => {
    this.setState({ onlyActiveProducts: !this.state.onlyActiveProducts })
    this.loadProducts()
  }

  closeConflictDialog = () => {
    this.setState({ conflictError: false })
  }

  renderForm = ({ values, isSubmitting }: FormikProps<FormValues>) => {
    const { classes } = this.props
    const { isLoading, onlyActiveProducts } = this.state

    return (
      <Form>
        <Grid container spacing={1} className={classes.grid}>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="stretch"
            direction="column"
          >
            <Button
              onClick={this.toggleActiveProducts}
              color="primary"
              className={classes.submitButton}
            >
              {onlyActiveProducts
                ? t('Voir les produits actifs')
                : t('Voir tous les produits')}
            </Button>
            <UploadFileButton size="medium" action={this.importProducts} />
          </Grid>
          {!isLoading && (
            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell>
                      <Typography
                        color="primary"
                        className={classes.headerLabel}
                      >
                        {t('Code')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="primary"
                        className={classes.headerLabel}
                      >
                        {t('Produit')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="primary"
                        className={classes.headerLabel}
                      >
                        {t('Teneur standard en huile (%)')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="primary"
                        className={classes.headerLabel}
                      >
                        {t('Activation du produit')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.products &&
                    values.products.length > 0 &&
                    values.products.map((product: Product, index: number) => (
                      <TableRow key={product.id} className={classes.tableRow}>
                        <TableCell>
                          <Typography color="textPrimary">
                            {product.externalId}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="textPrimary">
                            {product.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Field
                            component={TextField}
                            type="number"
                            name={`products[${index}].oilPercent`}
                            variant="standard"
                            disabled={!product.active}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography variant={'body2'}>
                            <Switch
                              color={'primary'}
                              checked={product.active}
                              onChange={this.onChangeActiveProduct(product)}
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="stretch"
            direction="column"
          >
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              className={classes.submitButton}
            >
              {t('Enregistrer')}
            </Button>
          </Grid>
        </Grid>
      </Form>
    )
  }

  render() {
    const { classes } = this.props
    const { error, isLoading, products, conflictError } = this.state

    if (isLoading) {
      return (
        <Card className={classes.loading}>
          <Loading />
        </Card>
      )
    }

    return (
      <TraderSettingsConfigCard
        cardTitle={t('Vos produits')}
        adviceMessage={
          <>
            <Fetcher fetch={adviceService.findOne} fetchProps={['PRODUCT']}>
              {advice => (
                <div dangerouslySetInnerHTML={{ __html: advice.template }} />
              )}
            </Fetcher>
            <Divider variant="middle" className={classes.divider} />
            {t("Format du template d'import à respecter : ")}
            <br />
            <br />
            <Button
              href={`${process.env.PUBLIC_URL}/examples/import_produits.csv`}
            >
              {t('Exemple de fichier')}
            </Button>
          </>
        }
      >
        <Formik
          initialValues={{ products }}
          onSubmit={this.handleSubmit}
          validationSchema={validationSchema}
          render={this.renderForm}
        />
        <SettingsConflictErrorDialog
          open={conflictError}
          errorTitle={'Impossible de désactiver ce produit'}
          errorContent={
            'Impossible de désactiver ce produit. \nSoit il existe des offres ou des ordres de vente sont en cours sur ce produit ou il existe peut-être encore du stock disponible pour un agriculteur.\nVeuillez vérifier avant de le désactiver.'
          }
          onClose={this.closeConflictDialog}
        />
        <SnackbarsExtended
          onClose={this.handleSnackbarClose}
          open={error}
          variant={'error'}
          message={t('Une erreur est survenue, veuillez recharger la page')}
        />
      </TraderSettingsConfigCard>
    )
  }
}

export default withStyles(styles)(ProductsSettings)
