import React, { useEffect, useContext, useState } from 'react'

import Add from '@material-ui/icons/Add'
import CreateOutlined from '@material-ui/icons/CreateOutlined'
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik'
import { makeStyles } from '@material-ui/styles'

import { Theme, Typography, Button } from '@material-ui/core'
import Card from '@material-ui/core/Card/Card'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormControl from '@material-ui/core/FormControl'
import LockReset from 'mdi-material-ui/LockReset'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import SnackbarsExtended from '../../../components/SnackbarsExtended'
import { Select } from '../../../components/Form/Select'
import Role from '../../../models/Role'
import Margin from '../../../components/Margin'
import Loading from '../../../components/Loading'
import Flex from '../../../components/Flex'
import Fetcher from '../../../components/Fetcher'
import ErrorContext from '../../../contexts/ErrorContext'
import { CheckboxWithLabel } from '../../../components/Form/CheckboxWithLabel'
import TextField from '../../../components/Form/TextField'
import TraderSettingsConfigCard from './TraderSettingsConfigCard'
import UserContext, { User } from '../../../contexts/UserContext'
import UsersSettingsValidationSchema from './UsersSettingsValidationSchema'
import adviceService from '../../../services/adviceService'
import authService from '../../../services/authService'
import { t } from '../../../i18n'
import userService from '../../../services/userService'

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    width: '100%',
    paddingRight: theme.spacing(1),
  },
  table: {
    '& th, & td': {
      paddingLeft: 0,
      paddingRight: theme.spacing(2),
    },
  },
  tableRow: {
    height: theme.spacing(5),
  },
  tableButtonCell: {
    paddingRight: 0,
  },
  headerLabel: {
    textTransform: 'uppercase',
    letterSpacing: '0.1rem',
  },
  select: {
    padding: 10 + '!important',
  },
  loading: {
    padding: 30,
  },
  modalButtonsContainer: {
    width: '100%',
  },
  modalButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  modalInput: {
    paddingRight: theme.spacing(1),
  },
}))

type State = {
  sendSuccess: boolean
  isLoading: boolean
  users: User[]
  addDialogOpen: boolean
  userToEdit?: User
  editDialogOpen: boolean
}

const mapRoleToDisplay = (role: Role) => {
  switch (role) {
    case 'ROLE_CUSTOMER':
      return t('Client')
    case 'ROLE_TRADER':
      return t('Trader')
    case 'ROLE_SALESMAN':
      return t('Commercial')
    case 'ROLE_SUPERADMIN':
      return t('Super administrateur')
    case 'ROLE_CONTROLLER':
      return t('Contrôleur')
    default:
      return ''
  }
}

const allowedRoles: Role[] = ['ROLE_SALESMAN', 'ROLE_TRADER', 'ROLE_CONTROLLER']

const UsersSettings = () => {
  const errorContext = useContext(ErrorContext)
  const userContext = useContext(UserContext)

  const classes = useStyles()

  const [sendSuccess, setSendSuccess] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [users, setUsers] = useState<User[]>([])
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)
  const [userToEdit, setUserToEdit] = useState<Optional<User>>()
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)

  const loadUsers = async () => {
    try {
      const { data: users } = await userService.findAll()
      setUsers(users)
      setIsLoading(false)
    } catch (e) {
      errorContext.displayError(
        'Erreur au chargement des utilisateurs, rechargez la page et si le problème persiste contactez un administrateur.'
      )
    }
  }

  useEffect(() => {
    loadUsers()
    // eslint-disable-next-line
  }, [])

  const toggleUserEnabled = (user: User) => async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target

    try {
      user.enabled = checked
      await userService.update(user)

      setUsers(
        users.map(us =>
          us.id === user.id
            ? {
                ...us,
                enabled: checked,
              }
            : us
        )
      )
    } catch (e) {
      errorContext.displayError(
        "Erreur lors de la modification de l'utilisateur, réessayez et si le problème persiste contactez un administrateur."
      )
    }
  }

  const onCloseSendSuccess = () => {
    setSendSuccess(false)
  }

  const onAddOpen = () => {
    setAddDialogOpen(true)
  }

  const onAddClose = () => {
    setAddDialogOpen(false)
  }

  const onEditOpen = (user: User) => () => {
    setEditDialogOpen(true)
    setUserToEdit(user)
  }

  const onEditClose = () => {
    setEditDialogOpen(false)
    setUserToEdit(undefined)
  }

  const onAddSubmit = async (
    user: User,
    { setSubmitting }: FormikActions<User>
  ) => {
    try {
      await userService.create(user)
      await loadUsers()
      setAddDialogOpen(false)
    } catch (error) {
      if (error.response && error.response.status === 409) {
        errorContext.displayError(
          "L'email ou le code externe existe déjà sur la plateforme. Veuillez le(s) modifier avant d'enregistrer."
        )
      } else {
        errorContext.displayError(
          "Erreur lors de la création de l'utilisateur, réessayez et si le problème persiste contactez un administrateur."
        )
      }
    } finally {
      setSubmitting(false)
    }
  }

  const onEditSubmit = async (
    user: User,
    { setSubmitting }: FormikActions<User>
  ) => {
    try {
      await userService.update(user)
      await loadUsers()
      setEditDialogOpen(false)
      setUserToEdit(undefined)
    } catch (error) {
      if (error.response && error.response.status === 409) {
        errorContext.displayError(
          "L'email ou le code externe existe déjà sur la plateforme. Veuillez le(s) modifier avant d'enregistrer."
        )
      } else {
        errorContext.displayError(
          "Erreur lors de la modification de l'utilisateur, réessayez et si le problème persiste contactez un administrateur."
        )
      }
    } finally {
      setSubmitting(false)
    }
  }

  const resetPassword = (login?: string) => () => {
    if (login) {
      try {
        // eslint-disable-next-line import/no-named-as-default-member
        authService.resetPasswordInit(login)
        setSendSuccess(true)
      } catch (e) {
        errorContext.displayError(
          "Erreur lors de l'envoi de l'email, réessayez et si le problème persiste contactez un administrateur."
        )
      }
    }
  }

  const getInitialFormValues = (): User => {
    return {
      id: undefined,
      firstname: undefined,
      lastname: undefined,
      email: undefined,
      phone: undefined,
      mobile: undefined,
      address: undefined,
      postalCode: undefined,
      city: undefined,
      externalId: undefined,
      roles: [allowedRoles[0]],
      enabled: false,
      tags: [],
      executionModes: [],
    }
  }

  const renderUserForm = (onDialogClose: React.MouseEventHandler) => ({
    isSubmitting,
  }: FormikProps<User>) => {
    return (
      <Form>
        <Margin top={2} />
        <Flex direction="row">
          <FormControl>
            <Field
              component={TextField}
              name="lastname"
              label={t('Nom')}
              variant="filled"
              className={classes.modalInput}
            />
          </FormControl>
          <FormControl>
            <Field
              component={TextField}
              name="firstname"
              label={t('Prénom')}
              variant="filled"
              className={classes.modalInput}
            />
          </FormControl>
        </Flex>
        <Margin top={1} bottom={1} />
        <FormControl>
          <Field
            component={TextField}
            name="email"
            type="email"
            label={t('Email')}
            variant="filled"
            className={classes.modalInput}
          />
        </FormControl>
        <Margin top={1} bottom={1} />
        <Flex direction="row">
          <FormControl>
            <Field
              component={TextField}
              name="phone"
              label={t('Téléphone fixe')}
              variant="filled"
              className={classes.modalInput}
            />
          </FormControl>
          <FormControl>
            <Field
              component={TextField}
              name="mobile"
              label={t('Téléphone mobile')}
              variant="filled"
              className={classes.modalInput}
            />
          </FormControl>
        </Flex>
        <Margin top={1} bottom={1} />
        <FormControl>
          <Field
            component={TextField}
            name="address"
            label={t('Adresse')}
            variant="filled"
            className={classes.modalInput}
          />
        </FormControl>
        <Margin bottom={1} />
        <FormControl>
          <Field
            component={TextField}
            name="postalCode"
            label={t('Code postal')}
            variant="filled"
            className={classes.modalInput}
          />
        </FormControl>
        <Margin bottom={1} />
        <FormControl>
          <Field
            component={TextField}
            name="city"
            label={t('Ville')}
            variant="filled"
            className={classes.modalInput}
          />
        </FormControl>
        <Margin bottom={1} />
        <FormControl>
          <Field
            component={TextField}
            name="externalId"
            label={t('Code externe (ERP)')}
            variant="filled"
            className={classes.modalInput}
          />
        </FormControl>
        <Margin bottom={1} />
        <Flex direction="row">
          <FormControl>
            <Field
              component={Select}
              name="roles[0]"
              label={t('Role')}
              options={allowedRoles}
              className={classes.modalInput}
            >
              {allowedRoles.map((allowedRole: Role) => (
                <MenuItem
                  key={allowedRole}
                  id={allowedRole}
                  value={allowedRole}
                >
                  {mapRoleToDisplay(allowedRole)}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
          <FormControl>
            <Field
              component={CheckboxWithLabel}
              name="enabled"
              color="primary"
              label={{
                labelPlacement: 'top',
                label: t('Activé'),
              }}
              className={classes.modalInput}
            />
          </FormControl>
        </Flex>
        <Flex
          direction="row"
          justify="flex-end"
          className={classes.modalButtonsContainer}
        >
          <Button
            onClick={onDialogClose}
            color="primary"
            className={classes.modalButton}
          >
            {t('Annuler')}
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={isSubmitting}
            className={classes.modalButton}
          >
            {t('Enregistrer')}
          </Button>
        </Flex>
      </Form>
    )
  }

  if (isLoading) {
    return (
      <Card className={classes.loading}>
        <Loading />
      </Card>
    )
  }

  return (
    <>
      <TraderSettingsConfigCard
        cardTitle={'Vos utilisateurs'}
        adviceMessage={
          <Fetcher fetch={adviceService.findOne} fetchProps={['USERS']}>
            {advice => (
              <div dangerouslySetInnerHTML={{ __html: advice.template }} />
            )}
          </Fetcher>
        }
      >
        <div className={classes.cardContent}>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell>
                  <Typography color="primary" className={classes.headerLabel}>
                    {t('Role')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="primary" className={classes.headerLabel}>
                    {t('Nom')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="primary" className={classes.headerLabel}>
                    {t('Prénom')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="primary" className={classes.headerLabel}>
                    {t('Email')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="primary" className={classes.headerLabel}>
                    {t('Identifiant')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="primary" className={classes.headerLabel}>
                    {t('N° Mobile')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="primary" className={classes.headerLabel}>
                    {t('Code')}
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user: User) => (
                <TableRow key={`${user.id}`} className={classes.tableRow}>
                  <TableCell>
                    <Typography color="textPrimary">
                      {mapRoleToDisplay(user.roles[0])}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary">{user.lastname}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary">
                      {user.firstname}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary">{user.email}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary">{user.login}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary">{user.mobile}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary">
                      {user.externalId}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableButtonCell}>
                    <Flex direction="row" alignItems="center">
                      <Button
                        color="primary"
                        onClick={onEditOpen(user)}
                        title={t("Modifier l'utilisateur")}
                      >
                        <CreateOutlined />
                      </Button>
                      <Button
                        color="primary"
                        onClick={resetPassword(user.login)}
                        title={t('Réinitialiser le mot de passe')}
                        disabled={!user.enabled}
                      >
                        <LockReset />
                      </Button>
                      {userContext &&
                      userContext.user &&
                      userContext.user.id !== user.id ? (
                        <Switch
                          color="primary"
                          checked={user.enabled}
                          onChange={toggleUserEnabled(user)}
                          title={
                            user.enabled
                              ? t("Désactiver l'utilisateur")
                              : t("Activer l'utilisateur")
                          }
                        />
                      ) : (
                        ''
                      )}
                    </Flex>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            color="primary"
            onClick={onAddOpen}
            className={classes.tableRow}
          >
            <Add />
            <span>{t('Ajouter un utilisateur')}</span>
          </Button>
        </div>
      </TraderSettingsConfigCard>
      <SnackbarsExtended
        onClose={onCloseSendSuccess}
        open={sendSuccess}
        variant={'success'}
        message={t("L'email de réinitialisation a été envoyé.")}
      />
      <Dialog
        open={addDialogOpen}
        onClose={onAddClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>{t("Ajout d'un utilisateur")}</DialogContentText>
          <Formik
            initialValues={getInitialFormValues()}
            onSubmit={onAddSubmit}
            render={renderUserForm(onAddClose)}
            validationSchema={UsersSettingsValidationSchema}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={editDialogOpen}
        onClose={onEditClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>{t('Modifier un utilisateur')}</DialogContentText>
          <Formik
            initialValues={userToEdit || getInitialFormValues()}
            onSubmit={onEditSubmit}
            render={renderUserForm(onEditClose)}
            validationSchema={UsersSettingsValidationSchema}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default UsersSettings
