import * as React from 'react'

import { Field, Form, Formik, FormikActions, FormikProps } from 'formik'
import { Theme, Typography, WithStyles } from '@material-ui/core'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import { capitalize } from '@material-ui/core/utils/helpers'
import memoizeOne from 'memoize-one'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Loading from '../../../components/Loading'
import Margin from '../../../components/Margin'
import SaleSettings from '../../../models/SaleSettings'
import SalesSettingsValidationSchema from './SalesSettingsValidationSchema'
import { Select } from '../../../components/Form/Select'
import SnackbarsExtended from '../../../components/SnackbarsExtended'
import { Switch } from '../../../components/Form/Switch'
import TextField from '../../../components/Form/TextField'
import TraderSettingsConfigCard from './TraderSettingsConfigCard'
import adviceService from '../../../services/adviceService'
import saleSettingService from '../../../services/saleSettingsService'
import { t } from '../../../i18n'

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      marginRight: theme.spacing(8),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: 200,
    },
    submit: {
      width: '100%',
    },
    loading: {
      padding: 30,
    },
    form: {
      width: '100%',
    },
  })

interface Props extends WithStyles<typeof styles> {}

interface State {
  saleSettings?: SaleSettings
  isLoading: boolean
  error: boolean
}

const deferredPaymentDays = Array(31)
  .fill(0)
  .map((a: number, idx: number) => 1 + idx)
const deferredPaymentMonths = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]

function getDefaultFormValues(): SaleSettings {
  return {
    maximalVolume: 0,
    maximalOperationDateIndexedOffer: 0,
    maximalOperationDateCurrentMonth: 0,
    hasDeferredPayment: true,
    deferredPaymentDay: 1,
    deferredPaymentMonth: 1,
    timeLimit: 0,
    interestRate: 0.02 * 100,
  }
}

class SaleSettingsScene extends React.Component<Props, State> {
  state: State = {
    saleSettings: undefined,
    isLoading: true,
    error: false,
  }

  getInitialFormValues: () => SaleSettings = memoizeOne(() => {
    const { saleSettings } = this.state

    if (saleSettings) {
      saleSettings.interestRate = saleSettings.interestRate * 100
    }

    return saleSettings || getDefaultFormValues()
  })

  componentDidMount(): void {
    this.getSaleSettings()
  }

  getSaleSettings = async () => {
    try {
      const { data: saleSettings } = await saleSettingService.getOne()
      this.setState({ saleSettings, isLoading: false })
    } catch (error) {
      switch (error.response.status) {
        case 404:
          this.setState({ saleSettings: undefined, isLoading: false })
          break
        default:
          this.setState({ error: true })
          break
      }
    }
  }

  handleSubmit = async (
    values: SaleSettings,
    { setSubmitting }: FormikActions<SaleSettings>
  ) => {
    try {
      values.interestRate = values.interestRate / 100
      await saleSettingService.update(values)
      values.interestRate = values.interestRate * 100
    } catch (e) {
      this.setState({ error: true })
    } finally {
      setSubmitting(false)
    }
  }

  handleSnackbarClose = () => {
    this.setState({ error: false })
  }

  renderSaleSettings = ({ values, isSubmitting }: FormikProps<any>) => {
    const { classes } = this.props

    return (
      <Form className={classes.form}>
        <Flex>
          <Margin bottom={2}>
            <Typography color="primary" variant="overline">
              Volume maximal pour une vente
            </Typography>
            <Margin top={1}>
              <Typography variant="caption" display="block">
                Vos clients agriculteurs ne pourront pas dépasser le volume
                maximal lors d’une vente.
              </Typography>
            </Margin>
            <FormControl className={classes.formControl}>
              <Flex justify="center" alignItems="center">
                <Field
                  component={TextField}
                  name="maximalVolume"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">t</InputAdornment>
                    ),
                  }}
                  type="number"
                />
                <FormHelperText>{t('0 = Pas de limite')}</FormHelperText>
              </Flex>
            </FormControl>
          </Margin>
          <Margin bottom={2}>
            <Typography color="primary" variant="overline">
              Date maximale d’opération sur une offre indéxée
            </Typography>
            <Margin top={1}>
              <Typography variant="caption" display="block">
                L’offre se clôturera automatiquement X jours ouvrés avant la fin
                de l’échéance du marché.
              </Typography>
            </Margin>
            <FormControl className={classes.formControl}>
              <Flex justify="center" alignItems="center">
                <Field
                  component={TextField}
                  name="maximalOperationDateIndexedOffer"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">j</InputAdornment>
                    ),
                  }}
                  type="number"
                />
                <FormHelperText>{t('0 = Pas de limite')}</FormHelperText>
              </Flex>
            </FormControl>
          </Margin>
          <Margin bottom={2}>
            <Typography color="primary" variant="overline">
              Date maximale d’opération pour le mois en cours
            </Typography>
            <Margin top={1}>
              <Typography variant="caption" display="block">
                Vos clients ne pourront plus réaliser d’opérations pour le mois
                en cours, X jours ouvrés avant la fin du mois.
              </Typography>
            </Margin>
            <FormControl className={classes.formControl}>
              <Flex justify="center" alignItems="center">
                <Field
                  component={TextField}
                  name="maximalOperationDateCurrentMonth"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">j</InputAdornment>
                    ),
                  }}
                  type="number"
                />
                <FormHelperText>{t('0 = Pas de limite')}</FormHelperText>
              </Flex>
            </FormControl>
          </Margin>
          <Margin bottom={2}>
            <Flex direction="row" alignItems="center" justify="space-between">
              <div>
                <Typography color="primary" variant="overline">
                  Différé de paiement
                </Typography>
                <Margin top={1}>
                  <Typography variant="caption" display="block">
                    Activer/désactiver l’option de différé de paiement,
                    sélectionnez la date limite pour une campagne et modifiez le
                    taux d’intérêt annuel.
                  </Typography>
                </Margin>
              </div>
              <FormControl>
                <Field
                  component={Switch}
                  name="hasDeferredPayment"
                  color="primary"
                />
              </FormControl>
            </Flex>
            {values.hasDeferredPayment && (
              <>
                <FormControl className={classes.formControl}>
                  <Field
                    component={Select}
                    label="jour"
                    name="deferredPaymentDay"
                  >
                    {deferredPaymentDays.map((day: number, i: number) => (
                      <MenuItem key={i} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Field
                    component={Select}
                    label="mois"
                    options={deferredPaymentMonths}
                    name="deferredPaymentMonth"
                  >
                    {deferredPaymentMonths.map((month: string, i: number) => (
                      <MenuItem key={month} value={i + 1}>
                        {capitalize(month)}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <Margin top={1}>
                  <Field
                    label="taux d’intérêt annuel"
                    component={TextField}
                    name="interestRate"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    type="number"
                    max="100"
                  />
                </Margin>
              </>
            )}
          </Margin>
          <Margin bottom={2}>
            <Typography color="primary" variant="overline">
              Temps maximal pour une vente
            </Typography>
            <Margin top={1}>
              <Typography variant="caption" display="block">
                Définissez la limite de temps dont vos clients disposent pour
                réaliser une vente avant la mise à jour du prix.
              </Typography>
            </Margin>
            <FormControl className={classes.formControl}>
              <Flex justify="center" alignItems="center">
                <Field
                  component={TextField}
                  name="timeLimit"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">min</InputAdornment>
                    ),
                  }}
                  type="number"
                />
                <FormHelperText>{t('0 = Pas de limite')}</FormHelperText>
              </Flex>
            </FormControl>
          </Margin>
          <Flex alignItems="center" className={classes.submit}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
            >
              {t('Valider')}
            </Button>
          </Flex>
        </Flex>
      </Form>
    )
  }

  render() {
    const { classes } = this.props
    const { isLoading, error } = this.state

    if (isLoading) {
      return (
        <Card className={classes.loading}>
          <Loading />
        </Card>
      )
    }

    return (
      <TraderSettingsConfigCard
        cardTitle="Vos paramètres de vente"
        adviceMessage={
          <Fetcher fetch={adviceService.findOne} fetchProps={['SALES']}>
            {advice => (
              <div dangerouslySetInnerHTML={{ __html: advice.template }} />
            )}
          </Fetcher>
        }
      >
        <Formik
          initialValues={this.getInitialFormValues()}
          onSubmit={this.handleSubmit}
          validationSchema={SalesSettingsValidationSchema}
          render={this.renderSaleSettings}
        />
        <SnackbarsExtended
          onClose={this.handleSnackbarClose}
          open={error}
          variant={'error'}
          message={'Une erreur est survenue, veuillez recharger la page'}
        />
      </TraderSettingsConfigCard>
    )
  }
}

export default withStyles(styles)(SaleSettingsScene)
