import {
  Theme,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
} from '@material-ui/core'
import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import Table from '@material-ui/core/Table'
import green from '@material-ui/core/colors/green'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CreateOutlined from '@material-ui/icons/CreateOutlined'
import Add from '@material-ui/icons/Add'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'
import { Formik, FormikProps, Form, Field, FormikActions } from 'formik'
import { t } from '../../../../i18n'

import Flex from '../../../../components/Flex'
import TextField from '../../../../components/Form/TextField'
import merchantService, {
  CreateMerchantView,
} from '../../../../services/merchantService'
import ErrorContext from '../../../../contexts/ErrorContext'
import SuperAdminSettingsMerchantsCreateValidationSchema from './SuperAdminSettingsMerchantsCreateValidationSchema'
import SuperAdminSettingsMerchantsEditValidationSchema from './SuperAdminSettingsMerchantsEditValidationSchema'
import Merchant from '../../../../models/Merchant'

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& td:last-child': {
      paddingRight: 0,
    },
  },
  tableRow: {
    height: theme.spacing(5),
    '& th': {
      padding: 0,
    },
  },
  tableButtonCell: {
    paddingRight: 0,
  },
  headerLabel: {
    textTransform: 'uppercase',
    letterSpacing: '0.1rem',
  },
  modalContent: {
    paddingBottom: '4px',
  },
  formControl: {
    padding: '0.5rem 0',
  },
  modalInput: {
    paddingRight: theme.spacing(1),
  },
  modalButtonsContainer: {
    width: '100%',
  },
  modalButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  buttonConnexion: {
    height: '52px',
    width: '100%',
  },
  progress: {
    margin: 0,
  },
  buttonError: {
    backgroundColor: theme.palette.error.dark,
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  buttonSuccess: {
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[500],
    },
  },
}))

type Props = {
  merchants: Merchant[]
  refresh: () => void
}

export const SuperAdminSettingsMerchantsList = ({
  merchants,
  refresh,
}: Props) => {
  const errorContext = useContext(ErrorContext)
  const classes = useStyles()

  const [merchantToEdit, setMerchantToEdit] = useState<Optional<Merchant>>(
    undefined
  )
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)
  const [loadingConnection, setLoadingConnection] = useState<boolean>(false)
  const [resultConnection, setResultConnection] = useState<
    Optional<'FAILED' | 'SUCCESS'>
  >(undefined)

  const getInitialFormValues = (): CreateMerchantView => {
    return {
      id: 0,
      maesysAppsLogin: '',
      maesysAppsPassword: '',
      mailsReplyTo: '',
      broker: '',
      clearerAccount: '',
      socialReason: '',
      mailTrader: '',
      contractIdPrefix: '',
    }
  }

  const onAddClose = () => {
    setAddDialogOpen(false)
  }

  const onAddOpen = () => {
    setResultConnection(undefined)
    setAddDialogOpen(true)
  }

  const onEditClose = () => {
    setEditDialogOpen(false)
  }

  const onEditOpen = (merchant: Merchant) => () => {
    setResultConnection(undefined)
    setEditDialogOpen(true)
    setMerchantToEdit(merchant)
  }

  const onTestingConnection = async (
    merchant: Merchant | CreateMerchantView
  ) => {
    setLoadingConnection(true)
    try {
      await merchantService.testApiConnection(merchant)
      setLoadingConnection(false)
      setResultConnection('SUCCESS')
    } catch (error) {
      errorContext.displayError(
        'Erreur lors de la tentative à la connexion, veuillez vérifier les champs saisies'
      )
      setLoadingConnection(false)
      setResultConnection('FAILED')
    }
  }

  const onAddSubmit = async (
    merchant: CreateMerchantView,
    { setSubmitting }: FormikActions<CreateMerchantView>
  ) => {
    try {
      await merchantService.create(merchant)
      refresh()
      setAddDialogOpen(false)
    } catch (error) {
      errorContext.displayError('Erreur lors de la création du négociant.')
    } finally {
      setSubmitting(false)
    }
  }

  const onEditSubmit = async (
    merchant: Merchant,
    { setSubmitting }: FormikActions<Merchant>
  ) => {
    try {
      await merchantService.update(merchant)
      refresh()
      setEditDialogOpen(false)
      setMerchantToEdit(undefined)
    } catch (error) {
      errorContext.displayError('Erreur lors de la modification du négociant.')
    } finally {
      setSubmitting(false)
    }
  }

  const renderMerchantForm = (onDialogClose: React.MouseEventHandler) => ({
    values,
    isSubmitting,
  }: FormikProps<CreateMerchantView | Merchant>) => {
    return (
      <Form>
        <Flex direction="column" alignItems="stretch">
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="maesysAppsLogin"
              label={t('Maesys Identifiant')}
              variant="filled"
              className={classes.modalInput}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="maesysAppsPassword"
              label={t('Maesys mot de passe')}
              variant="filled"
              className={classes.modalInput}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="mailsReplyTo"
              label={t('E-mail')}
              variant="filled"
              className={classes.modalInput}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="socialReason"
              label={t('Raison sociale')}
              variant="filled"
              className={classes.modalInput}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="broker"
              label={t('Identifiant broker')}
              variant="filled"
              className={classes.modalInput}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="clearerAccount"
              label={t('Identifiant clearer')}
              variant="filled"
              className={classes.modalInput}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="contractIdPrefix"
              label={t('Contract Id Prefix')}
              variant="filled"
              className={classes.modalInput}
            />
          </FormControl>
          {(values as CreateMerchantView).mailTrader !== undefined && (
            <FormControl className={classes.formControl}>
              <Field
                component={TextField}
                name="mailTrader"
                label={t('E-mail du trader')}
                variant="filled"
                className={classes.modalInput}
              />
            </FormControl>
          )}
        </Flex>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onTestingConnection(values)}
          className={classNames(classes.buttonConnexion, {
            [classes.buttonError]:
              resultConnection !== undefined && resultConnection === 'FAILED',
            [classes.buttonSuccess]:
              resultConnection !== undefined && resultConnection === 'SUCCESS',
          })}
        >
          {loadingConnection ? (
            <CircularProgress color="inherit" className={classes.progress} />
          ) : resultConnection === undefined ? (
            t('Tester la connexion')
          ) : resultConnection === 'SUCCESS' ? (
            t('Connexion OK')
          ) : (
            t('Réessayer')
          )}
        </Button>
        <Flex
          direction="row"
          justify="flex-end"
          className={classes.modalButtonsContainer}
        >
          <Button
            onClick={onDialogClose}
            color="primary"
            className={classes.modalButton}
          >
            {t('Annuler')}
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={isSubmitting}
            className={classes.modalButton}
          >
            {t('Enregistrer')}
          </Button>
        </Flex>
      </Form>
    )
  }

  return (
    <>
      <Table padding="default" className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography color="primary" className={classes.headerLabel}>
                {t('Login')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="primary" className={classes.headerLabel}>
                {t('Mot de passe')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="primary" className={classes.headerLabel}>
                {t('Mail')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="primary" className={classes.headerLabel}>
                {t('Raison sociale')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="primary" className={classes.headerLabel}>
                {t('Broker')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="primary" className={classes.headerLabel}>
                {t('Account')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="primary" className={classes.headerLabel}>
                {t('Contract Id Prefix')}
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {merchants.map((merchant: Merchant) => (
            <TableRow key={`${merchant.id}`} className={classes.tableRow}>
              <TableCell>
                <Typography color="textPrimary">
                  {merchant.maesysAppsLogin}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textPrimary">
                  {merchant.maesysAppsPassword}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textPrimary">
                  {merchant.mailsReplyTo}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textPrimary">
                  {merchant.socialReason}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textPrimary">{merchant.broker}</Typography>
              </TableCell>
              <TableCell>
                <Typography color="textPrimary">
                  {merchant.clearerAccount}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textPrimary">
                  {merchant.contractIdPrefix}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableButtonCell}>
                <Button
                  color="primary"
                  onClick={onEditOpen(merchant)}
                  title={t('Modifier le négociant')}
                >
                  <CreateOutlined />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button color="primary" onClick={onAddOpen} className={classes.tableRow}>
        <Add />
        <span>{t('Ajouter un négociant')}</span>
      </Button>
      <Dialog
        open={addDialogOpen}
        onClose={onAddClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>{t('Ajouter un négociant')}</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <Formik
            initialValues={getInitialFormValues()}
            onSubmit={onAddSubmit}
            render={renderMerchantForm(onAddClose)}
            validationSchema={SuperAdminSettingsMerchantsCreateValidationSchema}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={editDialogOpen}
        onClose={onEditClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>{t('Modifier un négociant')}</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <Formik
            initialValues={merchantToEdit || getInitialFormValues()}
            onSubmit={onEditSubmit}
            render={renderMerchantForm(onEditClose)}
            validationSchema={SuperAdminSettingsMerchantsEditValidationSchema}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SuperAdminSettingsMerchantsList
