import * as React from 'react'

import authService from '../services/authService'

class Logout extends React.Component {
  componentDidMount() {
    authService.logout()
  }

  render() {
    return null
  }
}

export default Logout
