import {
  Paper,
  Tabs,
  makeStyles,
  Tab,
  Tooltip,
  withStyles,
  Theme,
} from '@material-ui/core'
import * as React from 'react'

import { t } from '../../i18n'
import StrikeSetting, { StrikeValue } from '../../models/StrikeSetting'
import pmgSettingsService from '../../services/pmgSettingsService'

const useStyles = makeStyles(() => ({
  tab: {
    minWidth: 0,
    border: '1px solid #e9e7e7',
  },
  strike: {
    backgroundColor: '#f9f9f9',
  },
  block: {
    display: 'block',
  },
}))

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
  },
}))(Tooltip)

type Props = {
  strikes: StrikeSetting[]
  handleChange: (strikeValue?: StrikeValue) => void
  enableMarketHoursCheck?: boolean
}

type TabValue = StrikeValue | '-'

const StrikesTabs = ({
  handleChange,
  strikes,
  enableMarketHoursCheck,
}: Props) => {
  const VALUE_NO_STRIKE = '-'
  const classes = useStyles()
  const [strikeValue, setStrikeValue] = React.useState<TabValue>(
    VALUE_NO_STRIKE
  )
  const [strikesEnabled, setStrikesEnabled] = React.useState<boolean>(true)

  const isInsideMarketHours = async () => {
    const { data: res } = await pmgSettingsService.isInsideMarketHours()
    setStrikesEnabled(res)
  }

  React.useEffect(() => {
    if (enableMarketHoursCheck) {
      setStrikesEnabled(false)
      isInsideMarketHours()
    }
  }, [strikes, enableMarketHoursCheck])

  const a11yProps = (idx: number) => ({
    id: `simple-tab-${idx}`,
    'aria-controls': `simple-tabpanel-${idx}`,
  })

  const handleStrikeChange = (
    event: React.ChangeEvent<{}>,
    strike: TabValue
  ) => {
    setStrikeValue(strike)
    handleChange(strike === VALUE_NO_STRIKE ? undefined : strike)
  }

  return (
    <Paper square>
      <Tabs
        value={strikeValue}
        onChange={handleStrikeChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          value={VALUE_NO_STRIKE}
          label={
            <LightTooltip title={t('Sans PMG')} placement="top">
              <span>{t('Std')}</span>
            </LightTooltip>
          }
          key={`simple-tab-std`}
          {...a11yProps(0)}
          className={`${classes.tab}`}
        />
        {strikes.map((strike, idx) => {
          if (strikesEnabled) {
            return (
              <Tab
                value={strike.strike}
                label={<span>{strike.label}</span>}
                key={`simple-tab-${idx + 1}`}
                {...a11yProps(idx + 1)}
                className={`${classes.tab} ${classes.strike}`}
              />
            )
          }
          return (
            <LightTooltip
              title={t('Marché fermé')}
              placement="top"
              key={`simple-tab-${idx + 1}`}
            >
              <span>
                <Tab
                  value={strike.strike}
                  label={<span>{strike.label}</span>}
                  {...a11yProps(idx + 1)}
                  className={`${classes.tab} ${classes.strike} ${classes.block}`}
                  disabled={true}
                />
              </span>
            </LightTooltip>
          )
        })}
      </Tabs>
    </Paper>
  )
}

export default StrikesTabs
