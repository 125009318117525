import React from 'react'
import memoizeOne from 'memoize-one'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { t } from '../../../../i18n'
import DataTable from '../../../../components/DataTable'
import { TransactionListItemView } from '../../../../services/transactionService'
import transactionTransform from '../../../../transform/transactionTransform'

interface Props {
  columns: MUIDataTableColumnDef[]
  tableDataMapper: (
    transactionsData: TransactionListItemView
  ) => Array<string | object | number | TransactionListItemView>
  title: string
}

const csvDataMap = (separator: string) => (tr: TransactionListItemView) =>
  // eslint-disable-next-line
  `${tr.customerSocialReason}${separator}${tr.contractId}${separator}${tr.creationDate}${separator}${tr.productName}${separator}${tr.campaignYear}${separator}${tr.tonnage}${separator}${tr.executionMode}${separator}${tr.price}${separator}${tr.totalPrice}${separator}${tr.executionDate}${separator}${tr.paymentDate}${separator}${tr.deferredPaymentFee}${separator}${tr.appliedManeuver}${separator}${tr.storageFee}${separator}${tr.rawPrice}${separator}${tr.computedPrice}${separator}${tr.computedRawPrice}${separator}${tr.computedStorageFee}${separator}${tr.oilBonus}${separator}${tr.deliveryCost}${separator}${tr.majorationFee}${separator}${tr.bushelDepartureBonus}${separator}${tr.commercialManeuver}${separator}${tr.totalTonsCovered || 0}${separator}${tr.coverageOperations.map(oc => oc.code).join(', ')}`

export const ControllerDataTransactionList = ({
  columns,
  tableDataMapper,
  title,
}: Props) => {
  const dataTableOptions = {
    download: true,
    downloadOptions: {
      filename: 'Mes ventes.csv',
      separator: ';',
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    serverSide: true,
  }

  const dataToTable = memoizeOne(
    (transactionsData: TransactionListItemView[]) =>
      transactionsData.map(data => tableDataMapper(data).concat(data))
  )

  return (
    <DataTable
      columns={columns}
      title={title}
      emptyContent={t('Aucune donnée')}
      options={dataTableOptions}
      dataConverter={dataToTable}
      apiCall={transactionTransform.getAllTransactionsCreationDateFormatted}
      toBackendColumnsNames={
        transactionTransform.controllerSaleListToBackendColumnsNames
      }
      csvDataMap={csvDataMap}
      defaultSort={{ name: 'Date de la vente', direction: 'desc' }}
    />
  )
}

export default ControllerDataTransactionList
