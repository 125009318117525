import { Typography } from '@material-ui/core'
import memoizeOne from 'memoize-one'
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import DataTable from '../../../components/DataTable'
import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Margin from '../../../components/Margin'
import { User } from '../../../contexts/UserContext'
import { t } from '../../../i18n'
import userService from '../../../services/userService'

const tableColumns: MUIDataTableColumnDef[] = [
  { name: 'id', options: { display: 'excluded' } },
  t('Raison sociale'),
  t('Nom'),
  t('Ville'),
  t('Secteur'),
]

interface Props extends RouteComponentProps<any> {}

class SalesmanCustomers extends React.Component<Props> {
  customersToTableData = memoizeOne((customers: User[]) =>
    customers.map(customer => [
      customer.id,
      customer.socialReason ? customer.socialReason : '',
      customer.contact ? customer.contact : '',
      `${customer.city} ${customer.postalCode}`,
      customer.sector ? customer.sector.name : '',
    ])
  )

  onRowClick: MUIDataTableOptions['onRowClick'] = rowData => {
    const { history } = this.props
    const customerId = rowData[0]
    history.push(`/customers/${customerId}`)
  }

  renderTable = (customers: User[]) => (
    <DataTable
      columns={tableColumns}
      data={this.customersToTableData(customers)}
      emptyContent={t('Aucun client')}
      options={{
        onRowClick: this.onRowClick,
      }}
      storageSaveId={'clientList'}
    />
  )

  render() {
    return (
      <>
        <Flex direction="row" alignItems="center" justify="center">
          <Typography variant="h5">{t('Vos clients')}</Typography>
          <Flex grow />
        </Flex>
        <Margin top={2} />
        <Fetcher fetch={userService.findCustomers}>{this.renderTable}</Fetcher>
      </>
    )
  }
}

export default withRouter<Props>(SalesmanCustomers)
