import * as Yup from 'yup'

export default Yup.object().shape({
  campaign: Yup.object().required(),
  product: Yup.object().required(),
  oilPercent: Yup.number().when('product.oleaginous', {
    is: true,
    then: Yup.number().required(),
  }),
  deliverySite: Yup.object().required(),
  sectorsRestriction: Yup.array(),
  type: Yup.string().required(),
  market: Yup.string().when('type', {
    is: val => val === 'INDEXED',
    then: Yup.string().required(),
  }),
  maturity: Yup.string().when('type', {
    is: val => val === 'INDEXED',
    then: Yup.string().required(),
  }),
  destinationFee: Yup.number().when('type', {
    is: val => val === 'INDEXED',
    then: Yup.number().required(),
  }),
  priceToDestination: Yup.number().when('type', {
    is: val => val === 'FIXED',
    then: Yup.number().required(),
  }),
  majorationTable: Yup.string().required(),
  majorationMonth: Yup.string().required(),
  collectFee: Yup.number().required(),
  maneuver: Yup.number(),
  executionDetails: Yup.array().required(),
  modePMG: Yup.boolean(),
  marketPMG: Yup.string().when(['type', 'modePMG'], {
    is: (type, modePMG) => type === 'INDEXED' && modePMG,
    then: Yup.string().required(),
  }),
  maturityPMG: Yup.string().when(['type', 'modePMG'], {
    is: (type, modePMG) => type === 'INDEXED' && modePMG,
    then: Yup.string().required(),
  }),
  optionFee: Yup.number().when(['type', 'modePMG'], {
    is: (type, modePMG) => type === 'INDEXED' && modePMG,
    then: Yup.number().required(),
  }),
})
