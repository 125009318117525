import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { useState } from 'react'

import Poster from '../../../../components/Poster'
import { t } from '../../../../i18n'
import MajorationTable, {
  DefaultMajorationTable,
} from '../../../../models/MajorationTable'
import MajorationValue from '../../../../models/MajorationValue'
import majorationTableService from '../../../../services/majorationTableService'
import Margin from '../../../../components/Margin'

type Props = {
  majorationTable: DefaultMajorationTable
}

export const SuperAdminSettingsMajorationTableForm = ({
  majorationTable,
}: Props) => {
  const [currentMajorationTable, setCurrentMajorationTable] = useState<
    Optional<DefaultMajorationTable>
  >(majorationTable)

  const onFieldChange = (idx: number, name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const majorationTable: DefaultMajorationTable = {
      ...currentMajorationTable!,
      majorationValues: [
        ...currentMajorationTable!.majorationValues.map(
          (majorationValue: MajorationValue, i: number) => {
            return i === idx
              ? {
                  ...majorationValue,
                  [name]: event.target.value ? Number(event.target.value) : '',
                }
              : majorationValue
          }
        ),
      ],
    }
    setCurrentMajorationTable(majorationTable)
  }

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const majorationTable: DefaultMajorationTable = {
      ...currentMajorationTable!,
      name: event.target.value,
    }
    setCurrentMajorationTable(majorationTable)
  }

  const handleSubmit = async () => {
    await majorationTableService.update(
      currentMajorationTable as MajorationTable
    )
  }

  if (!currentMajorationTable) {
    return null
  }

  return (
    <>
      <TextField
        value={currentMajorationTable.name}
        onChange={onNameChange}
        InputLabelProps={{ disabled: true }}
        margin="dense"
        error={!currentMajorationTable.name}
        helperText={!currentMajorationTable.name && t('Requis')}
        InputProps={{
          endAdornment: <InputAdornment position="end">.csv</InputAdornment>,
        }}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Date de début')}</TableCell>
            <TableCell>{t('Valeur (€)')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {currentMajorationTable.majorationValues.map(
            ({ value }: MajorationValue, idx: number) => {
              return (
                <TableRow key={idx}>
                  <TableCell>M{idx + 1}</TableCell>
                  <TableCell>
                    <TextField
                      value={value}
                      onChange={onFieldChange(idx, 'value')}
                      type="number"
                      InputLabelProps={{ disabled: true }}
                      margin="dense"
                    />
                  </TableCell>
                </TableRow>
              )
            }
          )}
        </TableBody>
      </Table>
      <Margin top={2}>
        <Poster
          onSubmit={handleSubmit}
          disabled={!currentMajorationTable.name}
          errorInSnackBar={true}
          buttonText="Enregistrer"
        />
      </Margin>
    </>
  )
}

export default SuperAdminSettingsMajorationTableForm
