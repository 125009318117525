import axios from '../config/axios'
import MajorationTable, {
  NewMajorationTable,
  DefaultMajorationTable,
} from '../models/MajorationTable'

export const findAllByCampaign = (campaignId: number) =>
  axios.get<MajorationTable[]>('api/majoration-tables?campaign=' + campaignId)

export const create = (majorationTable: NewMajorationTable) =>
  axios.post<MajorationTable>('api/majoration-tables', majorationTable)

export const update = (majorationTable: MajorationTable) =>
  axios.put<MajorationTable>(
    `api/majoration-tables/${majorationTable.id}`,
    majorationTable
  )

export const findDefaultMajorationTable = () =>
  axios.get<DefaultMajorationTable>('api/majoration-tables/default')

export default { findDefaultMajorationTable, findAllByCampaign, create, update }
