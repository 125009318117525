import { Typography } from '@material-ui/core'
import PlaceIcon from '@material-ui/icons/PlaceOutlined'
import memoize from 'memoize-one'
import * as React from 'react'

import ExecutionMode from '../../models/ExecutionMode'
import OffersPricesView from '../../models/OffersPricesView'
import Sector from '../../models/Sector'
import Flex from '../Flex'

import { CalendarContext } from './Calendar'
import CalendarReadOnlyLine from './CalendarReadOnlyLine'
import CalendarSeparator from './CalendarSeparator'

interface Props {
  sectors: Sector[]
  executionModes: ExecutionMode[]
  calendar: OffersPricesView
  onClick?: (offerId: number) => void
}

class TraderCalendarReadOnlyLines extends React.Component<Props> {
  getValues = memoize(
    (
      sector: Sector,
      executionMode: ExecutionMode,
      months: string[],
      calendar: OffersPricesView
    ) => {
      return months.map(month =>
        calendar.executionModePerSector[sector.id] &&
        calendar.executionModePerSector[sector.id][executionMode.id] &&
        calendar.executionModePerSector[sector.id][executionMode.id][month]
          ? calendar.executionModePerSector[sector.id][executionMode.id][month]
          : null
      )
    }
  )

  handleClick = (executionMode: ExecutionMode, sector: Sector) => (
    month: string
  ) => {
    const { onClick, calendar } = this.props
    onClick!(
      this.getValues(sector, executionMode, [month], calendar).pop()!.offerId
    )
  }

  render() {
    const { sectors, executionModes, calendar, onClick } = this.props

    return (
      <tbody>
        <CalendarContext.Consumer>
          {({ months }) =>
            sectors.map(sector => (
              <React.Fragment key={sector.id}>
                <CalendarSeparator>
                  <Flex direction="row" alignItems="center">
                    <PlaceIcon />
                    <Typography variant="subtitle1">{sector.name}</Typography>
                  </Flex>
                </CalendarSeparator>
                {executionModes.map((executionMode, index) => (
                  <CalendarReadOnlyLine
                    key={executionMode.id}
                    title={executionMode.name}
                    onClick={onClick && this.handleClick(executionMode, sector)}
                    values={this.getValues(
                      sector,
                      executionMode,
                      months,
                      calendar
                    )}
                    dark={index % 2 === 0}
                  />
                ))}
              </React.Fragment>
            ))
          }
        </CalendarContext.Consumer>
      </tbody>
    )
  }
}

export default TraderCalendarReadOnlyLines
