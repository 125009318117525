import MuiSwitch, {
  SwitchProps as MuiSwitchProps,
} from '@material-ui/core/Switch'
import { FieldProps } from 'formik'
import * as React from 'react'

import { Omit } from '../../utils/Omit'

export interface SwitchProps
  extends FieldProps,
    Omit<
      MuiSwitchProps,
      'form' | 'name' | 'onChange' | 'value' | 'defaultChecked'
    > {
  true: string | number | boolean
  false: string | number | boolean
  onChange: (newValue: string | number | boolean) => void
}

export const fieldToSwitch = ({
  field,
  form: { isSubmitting },
  disabled = false,
  ...props
}: SwitchProps): MuiSwitchProps => {
  return {
    disabled: isSubmitting || disabled,
    ...props,
    ...field,
    defaultChecked: field.value === (props.true || true),
    value: field.value === (props.true || true) ? 'checked' : '',
  }
}

const onChange = (props: SwitchProps) => (
  event: React.ChangeEvent,
  checked: boolean
) => {
  const { form, field } = props
  const newValue = checked ? props.true || true : props.false || false
  form.setFieldValue(field.name, newValue)

  if (props.onChange) {
    props.onChange(newValue)
  }
}

export const Switch: React.ComponentType<SwitchProps> = props => (
  <MuiSwitch {...fieldToSwitch(props)} onChange={onChange(props)} />
)

Switch.displayName = 'FormikMaterialUISwitch'
