import { createStyles, WithStyles, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'
const styles = createStyles({
  root: {
    display: 'flex',
  },
  directionRow: {
    flexDirection: 'row',
  },
  directionColumn: {
    flexDirection: 'column',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  alignItemsEnd: {
    alignItems: 'flex-end',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  alignItemsStretch: {
    alignItems: 'stretch',
  },
  alignItemsInitial: {
    alignItems: 'initial',
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  justifySpaceEvenly: {
    justifyContent: 'space-evenly',
  },
  justifySpaceAround: {
    justifyContent: 'space-around',
  },
  grow: {
    flexGrow: 1,
  },
  wrap: {
    flexWrap: 'wrap',
  },
  fullWidth: {
    width: '100%',
  },
  flexFlow: {
    flexFlow: 'wrap',
  },
})

interface Props extends WithStyles<typeof styles> {
  className?: string
  direction?: 'row' | 'column'
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'initial'
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-evenly'
    | 'space-around'
  flexFlow?: 'wrap'
  grow?: boolean
  wrap?: boolean
  fullWidth?: boolean
}

const Flex: React.FunctionComponent<Props> = ({
  classes,
  className: classNameProp,
  direction,
  alignItems,
  justify,
  grow,
  wrap,
  flexFlow,
  fullWidth,
  children,
  ...other
}) => {
  const className = classNames(
    classes.root,
    {
      [classes.directionRow]: direction === 'row',
      [classes.directionColumn]: direction === 'column',
      [classes.alignItemsStart]: alignItems === 'flex-start',
      [classes.alignItemsEnd]: alignItems === 'flex-end',
      [classes.alignItemsCenter]: alignItems === 'center',
      [classes.alignItemsStretch]: alignItems === 'stretch',
      [classes.alignItemsInitial]: alignItems === 'initial',
      [classes.justifyStart]: justify === 'flex-start',
      [classes.justifyEnd]: justify === 'flex-end',
      [classes.justifyCenter]: justify === 'center',
      [classes.justifySpaceBetween]: justify === 'space-between',
      [classes.justifySpaceAround]: justify === 'space-around',
      [classes.justifySpaceEvenly]: justify === 'space-evenly',
      [classes.flexFlow]: flexFlow === 'wrap',
      [classes.grow]: grow,
      [classes.wrap]: wrap,
      [classes.fullWidth]: fullWidth,
    },
    classNameProp
  )

  return (
    <div className={className} {...other}>
      {children}
    </div>
  )
}

Flex.defaultProps = {
  direction: 'column',
  alignItems: 'flex-start',
  justify: 'flex-start',
}

export default withStyles(styles)(Flex)
