import { Theme, Typography, makeStyles } from '@material-ui/core'
import Button, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import { AxiosPromise } from 'axios'
import UploadIcon from 'mdi-material-ui/Upload'
import React, { useState } from 'react'

import Flex from './Flex'
import { t } from '../i18n'
import Margin from './Margin'

const useStyles = makeStyles((theme: Theme) => ({
  fileInput: {
    opacity: 0,
    height: 0,
    width: 0,
  },
  errorText: {
    overflow: 'auto',
    color: theme.palette.error.light,
    whiteSpace: 'pre-line',
    // :hankey: Safari bug
    '-webkit-transform': 'translate3d(0, 0, 0)',
  },
}))

type Props = {
  lastImport?: Date
  size?: ButtonProps['size']
  action?: (csv: File) => AxiosPromise<string>
}

type Status = 'DEFAULT' | 'UPLOADING' | 'SUCCESS' | 'ERROR'

export const UploadFileButton = ({ lastImport, size, action }: Props) => {
  const [status, setStatus] = useState<Status>('DEFAULT')
  const [errors, setErrors] = useState<Optional<string>>()
  const classes = useStyles()

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus('UPLOADING')
    const files = e.target.files
    if (action && files && files.length === 1) {
      const file = files[0]
      try {
        await action(file)
        setStatus('SUCCESS')
      } catch (e) {
        setStatus('ERROR')
        if (e.response) {
          if (e.response && e.response.status === 400) {
            setErrors(e.response && e.response.data && e.response.data.message)
          } else if (e.response && e.response.status === 413) {
            setErrors(t('Le fichier est trop volumineux'))
          } else {
            setErrors(t('Erreur lors du traitement du fichier'))
          }
        } else if (e.request) {
          setErrors(
            t(
              "Erreur lors de la récupération du statut de l'opération d'import"
            )
          )
        } else {
          setErrors(t('Erreur à la réception du fichier'))
        }
      }
    }
  }

  const handleModalClose = () => setStatus('DEFAULT')

  const renderModalContent = () => {
    switch (status) {
      case 'UPLOADING':
        return (
          <>
            <DialogTitle>{t('Import en cours … 🤓')}</DialogTitle>
            <DialogContent>
              <Margin top={1} bottom={1}>
                <Flex fullWidth justify={'center'} alignItems={'center'}>
                  <CircularProgress />
                </Flex>
              </Margin>
            </DialogContent>
          </>
        )
      case 'SUCCESS':
        return (
          <>
            <DialogTitle>{t('Import fini ! 🙂')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('La liste a été correctement importée.')}
              </DialogContentText>
              <DialogActions>
                <Button onClick={handleModalClose} color="primary">
                  {t('Fermer')}
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        )
      case 'ERROR':
        return (
          <>
            <DialogTitle>
              {t('🙁 Oups, un problème est survenu durant l’import … ')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.errorText}>
                {errors}
              </DialogContentText>
              <DialogActions>
                <Button onClick={handleModalClose} color="primary">
                  {t('Fermer')}
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        )
    }
    return <div />
  }

  return (
    <>
      <input
        id="upload-button"
        accept=".csv"
        type="file"
        className={classes.fileInput}
        value=""
        onChange={handleFileUpload}
      />
      {
        <Button
          component="label"
          htmlFor="upload-button"
          size={size}
          color={'primary'}
        >
          <UploadIcon /> {t('Importer')}
        </Button>
      }
      {lastImport && (
        <Typography color={'primary'} variant="caption" display="block">
          {t('Dernier import le')} {lastImport}
        </Typography>
      )}
      <Dialog fullWidth open={status !== 'DEFAULT'} onClose={handleModalClose}>
        {renderModalContent()}
      </Dialog>
    </>
  )
}

export default UploadFileButton
