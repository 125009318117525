import * as Yup from 'yup'

export default Yup.object().shape({
  secureAmountLimit: Yup.number()
    .required()
    .min(0),
  secondsForCallTimeout: Yup.number()
    .required()
    .min(0),
  pmgParams: Yup.object()
    .shape({
      openingTime: Yup.string().required(),
      closureTime: Yup.string().required(),
      daysOfClosure: Yup.array(Yup.date().required()),
    })
    .required(),
})
