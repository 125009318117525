import { Typography } from '@material-ui/core'
import * as React from 'react'

import Calendar from '../../../components/Calendar/Calendar'
import CalendarHeader from '../../../components/Calendar/CalendarHeader'
import CustomerCalendarReadOnlyLines from '../../../components/Calendar/CustomerCalendarReadOnlyLines'
import CalendarError from '../../../components/CalendarError'
import CalendarLoading from '../../../components/CalendarLoading'
import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Margin from '../../../components/Margin'
import { User } from '../../../contexts/UserContext'
import { t } from '../../../i18n'
import Campaign from '../../../models/Campaign'
import ExecutionMode from '../../../models/ExecutionMode'
import Offer from '../../../models/Offer'
import Sector from '../../../models/Sector'
import offerService from '../../../services/offerService'
import StrikeSetting, { StrikeValue } from '../../../models/StrikeSetting'
import StrikesTabs from '../../../components/Calendar/StrikesTabs'

type Props = {
  offers: Offer[]
  sector: Sector
  executionModes: ExecutionMode[]
  strikes: StrikeSetting[]
  handleRefreshClicked: () => void
  lastUpdatedDate: Date
  onClick?: (
    executionMode: ExecutionMode,
    month: string,
    strike?: StrikeValue
  ) => void
  selectedCampaign: Campaign
  customer?: User
}

const CustomerOffersCardCalendar = ({
  sector,
  executionModes,
  strikes,
  offers,
  onClick,
  selectedCampaign,
  customer,
}: Props) => {
  const [strikeValue, setStrikeValue] = React.useState<Optional<StrikeValue>>()
  const [hasPmg, setHasPmg] = React.useState<boolean>()

  const offerIds = offers.map(o => o.id!)
  const fetchProps = [
    offerIds,
    selectedCampaign.startDateCalendar,
    selectedCampaign.endDateCalendar,
    strikeValue,
  ]

  const handleOnClick = (executionMode: ExecutionMode, month: string) => {
    if (onClick) {
      onClick(executionMode, month, strikeValue)
    }
  }

  React.useEffect(() => {
    setHasPmg(offers.some(o => o.modePMG))
  }, [offers])

  if (offers.length === 0) {
    return (
      <>
        <Flex grow alignItems="center" justify="flex-start">
          <Margin top={10}>
            <Typography variant="h6" color="primary" align="center">
              {t('Aucune offre créée')}
            </Typography>
          </Margin>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Margin bottom>
        <Flex direction="row" alignItems="center" wrap={false}>
          <Margin right={2}>
            <Typography variant="h6" color="textSecondary">
              {t('Type de prix')}
            </Typography>
          </Margin>
          {hasPmg && (
            <StrikesTabs
              strikes={strikes}
              handleChange={setStrikeValue}
              enableMarketHoursCheck={true}
            />
          )}
        </Flex>
      </Margin>
      <Fetcher
        fetch={offerService.getCalendarPrices}
        fetchProps={customer ? [...fetchProps, customer.id] : fetchProps}
        loadingRender={CalendarLoading}
        errorRender={CalendarError}
      >
        {calendar => (
          <>
            <Calendar campaign={selectedCampaign}>
              <CalendarHeader />
              <CustomerCalendarReadOnlyLines
                executionModes={executionModes}
                sector={sector}
                calendar={calendar}
                onClick={handleOnClick}
              />
            </Calendar>
          </>
        )}
      </Fetcher>
    </>
  )
}

export default CustomerOffersCardCalendar
