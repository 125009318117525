import {
  Card,
  CardContent,
  CircularProgress,
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import * as React from 'react'

const styles = createStyles({
  card: {},
  cardContent: {
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

interface Props extends WithStyles<typeof styles> {
  className?: string
}

const Loading: React.FunctionComponent<Props> = ({ classes }) => (
  <Card className={classes.card}>
    <CardContent className={classes.cardContent}>
      <CircularProgress color="primary" size={64} />
    </CardContent>
  </Card>
)

export default withStyles(styles)(Loading)
