import * as Yup from 'yup'

import { t } from '../../../i18n'

export default Yup.object().shape({
  maximalVolume: Yup.number()
    .required()
    .min(0, t('Le volume doit être un nombre positif')),
  maximalOperationDateIndexedOffer: Yup.number()
    .required()
    .min(0, t('La date doit être un nombre positif'))
    .max(31, t('La date ne peut pas être supérieure à 31')),
  maximalOperationDateCurrentMonth: Yup.number()
    .required()
    .min(0, t('La date doit être un nombre positif'))
    .max(31, t('La date ne peut pas être supérieure à 31')),
  timeLimit: Yup.number()
    .required()
    .min(0, t('La temps doit être un nombre positif')),
  interestRate: Yup.number()
    .required()
    .max(100, t('La valeur ne peut pas être supérieure à 100')),
})
