import {
  CardContent,
  createStyles,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import memoize from 'memoize-one'
import * as React from 'react'

import CardContentColored from '../../../components/CardContentColored'
import Error from '../../../components/Error'
import Fetcher from '../../../components/Fetcher'
import Loading from '../../../components/Loading'
import Margin from '../../../components/Margin'
import VerticalSelect, { ALL } from '../../../components/VerticalSelect'
import { t } from '../../../i18n'
import Campaign from '../../../models/Campaign'
import ExecutionMode from '../../../models/ExecutionMode'
import Offer from '../../../models/Offer'
import OffersPricesView from '../../../models/OffersPricesView'
import Product from '../../../models/Product'
import Sector from '../../../models/Sector'
import offerService from '../../../services/offerService'
import getCurrentCampaign from '../../../utils/getCurrentCampaign'
import sortCampaigns from '../../../utils/sortCampaigns'

import TraderHomeOfferRecap from './TraderHomeOfferRecap'
import TraderOffersCardCalendar from './TraderOffersCardCalendar'
import StrikeSetting from '../../../models/StrikeSetting'

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 400,
  },
  offers: {
    display: 'flex',
    flexDirection: 'column',
    flex: 14,
    overflow: 'hidden',
  },
  cardContent: {
    padding: 0,
    flex: 2,
  },
})

type Props = WithStyles<typeof styles> & {
  campaigns: Campaign[]
  products: Product[]
  sectors: Sector[]
  executionModes: ExecutionMode[]
  strikes: StrikeSetting[]
}

type SelectPreview = {
  nbItems?: number
}

type State = {
  selectedCampaign: Campaign
  selectedProduct: Product
  selectedSector: Sector | typeof ALL
  selectedOffer?: { calendar: OffersPricesView; offer: Offer }
  campaignsFilters: Array<Campaign & SelectPreview>
  productsFilters: Array<Product & SelectPreview>
  sectorsFilters: Array<Sector & SelectPreview>
}

class TraderOffersCard extends React.Component<Props, State> {
  readonly state: State = {
    selectedCampaign: getCurrentCampaign(sortCampaigns(this.props.campaigns)),
    selectedProduct: this.props.products[0],
    selectedSector: ALL,
    selectedOffer: undefined,
    campaignsFilters: this.props.campaigns,
    productsFilters: this.props.products,
    sectorsFilters: this.props.sectors,
  }

  filterOffers = memoize(
    (
      selectedCampaign: Campaign,
      selectedProduct: Product,
      selectedSector: Sector | typeof ALL,
      offers: Offer[]
    ) => {
      return offers.filter(offer => {
        const hasCampaign = offer.campaign.id === selectedCampaign.id
        const hasProduct = offer.product.id === selectedProduct.id
        let hasSector = true
        if (
          !(offer.sectorsRestriction.length === 0 || selectedSector === ALL)
        ) {
          hasSector = offer.sectorsRestriction.some(
            sector => sector.id === selectedSector.id
          )
        }
        return hasCampaign && hasProduct && hasSector
      })
    }
  )

  onCampaignChange = (selectedCampaign: Campaign) =>
    this.setState({ selectedCampaign })
  filterRenderItem = (i: (Campaign | Sector) & SelectPreview) =>
    t(i.name) + (i.nbItems ? ` (${i.nbItems})` : '')
  filterRenderProduct = (campaignId: number | null) => (
    i: Product & SelectPreview
  ) => {
    if (campaignId === null) {
      return ''
    }
    return (
      t(i.name) +
      (i.nbOffersPerCampaign[campaignId]
        ? ` (${i.nbOffersPerCampaign[campaignId]})`
        : '')
    )
  }

  onProductChange = (selectedProduct: Product) =>
    this.setState({ selectedProduct })
  filterKeyExtractor = (i: Campaign | Sector | Product) => i.id
  onSectorChange = (selectedSector: Sector) => this.setState({ selectedSector })

  getSelectedSectors = (
    sectors: Sector[],
    selectedSector: Sector | typeof ALL
  ): Sector[] => {
    if (selectedSector === ALL) {
      return sectors
    }
    return [selectedSector]
  }

  handleOfferClick = (offer: Offer, calendar: OffersPricesView) => {
    this.setState({ selectedOffer: { offer, calendar } })
  }

  handleReturnClick = () => {
    this.setState({ selectedOffer: undefined })
  }

  render() {
    const { classes, executionModes, sectors, strikes } = this.props
    const {
      selectedCampaign,
      selectedProduct,
      selectedSector,
      selectedOffer,
      campaignsFilters,
      productsFilters,
      sectorsFilters,
    } = this.state

    if (selectedOffer) {
      return (
        <TraderHomeOfferRecap
          calendar={selectedOffer.calendar}
          offer={selectedOffer.offer}
          sectors={sectors}
          executionModes={executionModes}
          campaign={selectedCampaign}
          onBack={this.handleReturnClick}
        />
      )
    } else {
      return (
        <Card className={classes.root} data-cy={'offers'}>
          <CardContentColored
            color="primary"
            className={classes.cardContent}
            data-cy={'campaigns'}
          >
            <Margin left={3} right={3} top={2} bottom={2}>
              <Typography
                variant="button"
                align="center"
                display="block"
                color="inherit"
                noWrap
              >
                {t('CAMPAGNE')}
              </Typography>
            </Margin>
            <VerticalSelect
              values={sortCampaigns(campaignsFilters)}
              defaultValue={selectedCampaign}
              onChange={this.onCampaignChange}
              keyExtractor={this.filterKeyExtractor}
              renderItem={this.filterRenderItem}
            />
          </CardContentColored>
          <CardContentColored
            color="primary-400"
            className={classes.cardContent}
            data-cy={'products'}
          >
            <Margin left={3} right={3} top={2} bottom={2}>
              <Typography
                variant="button"
                align="center"
                color="inherit"
                display="block"
                noWrap
              >
                {t('PRODUIT')}
              </Typography>
            </Margin>
            <VerticalSelect
              values={productsFilters}
              defaultValue={selectedProduct}
              onChange={this.onProductChange}
              keyExtractor={this.filterKeyExtractor}
              renderItem={this.filterRenderProduct(
                selectedCampaign ? selectedCampaign.id : null
              )}
            />
          </CardContentColored>
          <CardContentColored
            color="primary-300"
            className={classes.cardContent}
            data-cy={'sectors'}
          >
            <Margin left={3} right={3} top={2} bottom={2}>
              <Typography
                variant="button"
                align="center"
                display="block"
                color="inherit"
                noWrap
              >
                {t('SECTEUR')}
              </Typography>
            </Margin>
            <VerticalSelect
              values={sectorsFilters}
              defaultValue={selectedSector}
              onChange={this.onSectorChange}
              keyExtractor={this.filterKeyExtractor}
              renderItem={this.filterRenderItem}
              allItem
            />
          </CardContentColored>
          <CardContent className={classes.offers}>
            <Fetcher
              fetch={offerService.findAllActive}
              loadingRender={Loading}
              errorRender={Error}
            >
              {(offers, refresh, lastUpdated) => (
                <TraderOffersCardCalendar
                  offers={this.filterOffers(
                    selectedCampaign,
                    selectedProduct,
                    selectedSector,
                    offers
                  )}
                  selectedSectors={this.getSelectedSectors(
                    sectors,
                    selectedSector
                  )}
                  onClick={this.handleOfferClick}
                  executionModes={executionModes}
                  strikes={strikes}
                  handleRefreshClicked={refresh}
                  lastUpdatedDate={lastUpdated}
                  selectedCampaign={selectedCampaign}
                />
              )}
            </Fetcher>
          </CardContent>
        </Card>
      )
    }
  }
}

export default withStyles(styles)(TraderOffersCard)
