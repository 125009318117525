import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import capitalize from 'lodash/capitalize'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Link } from 'react-router-dom'

import { Dialog } from '@material-ui/core'
import SalesPmgList from '../../../../components/SalesPmgList'
import { t } from '../../../../i18n'
import { format } from '../../../../i18n/format'
import contractPMGService, {
  ContractPmgListItemView,
} from '../../../../services/contractPMGService'
import Fetcher from '../../../../components/Fetcher'
import SalesListDetails from '../../../../components/Sale/SalesListDetails'

export const TraderPmgSalesList = () => {
  const [showDetails, setShowDetails] = useState<
    Optional<ContractPmgListItemView>
  >()

  const fetchContractPMG = () => {
    return contractPMGService.findAll(['CREATED'])
  }

  const getTableColumns = (): Array<string | MUIDataTableColumnDef> => [
    t('Client'),
    t('Numéro contrat'),
    t('Date de la vente'),
    t('Produit'),
    t('Campagne'),
    { name: 'tons', label: t('Tonnage'), options: { filter: false } },
    t('Modalité'),
    { name: 'price', label: t('Prix PMG (€/t)'), options: { filter: false } },
  ]

  const contractPmgToTableData = (contractPMG: ContractPmgListItemView) => [
    capitalize(contractPMG.customerSocialReason),
    contractPMG.contractId,
    format(contractPMG.creationDate, 'dd/MM/yyyy HH:mm'),
    capitalize(contractPMG.productName),
    contractPMG.campaignYear,
    contractPMG.tonnage,
    capitalize(contractPMG.executionMode),
    contractPMG.price,
  ]

  const handlePreview = (value: ContractPmgListItemView) => {
    setShowDetails(value)
  }

  const handleCloseDetails = () => {
    setShowDetails(undefined)
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t('Listes de vos ventes PMG')}
      </Typography>
      <Link to={'pmg-list/closed'}>
        <Button color="primary">
          {t('Voir les ventes PMG fixées et/ou cloturées')}
        </Button>
      </Link>
      <Fetcher fetch={fetchContractPMG}>
        {(contractsPmg, refresh) => (
          <SalesPmgList
            contractsPmg={contractsPmg}
            columns={getTableColumns()}
            tableDataMapper={contractPmgToTableData}
            handlePreview={handlePreview}
            refresh={refresh}
            status="CREATED"
          />
        )}
      </Fetcher>
      {showDetails && (
        <Dialog
          onClose={handleCloseDetails}
          open={true}
          maxWidth="lg"
          fullWidth
        >
          <SalesListDetails transaction={showDetails} />
        </Dialog>
      )}
    </>
  )
}

export default TraderPmgSalesList
