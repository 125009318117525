import axios from '../config/axios'
import DeliverySite from '../models/DeliverySite'

export const findAll = () => axios.get<DeliverySite[]>('api/delivery-sites')
export const findAllActives = () =>
  axios.get<DeliverySite[]>('api/delivery-sites?active=true')
export const updateOne = (dv: DeliverySite) =>
  axios.put<DeliverySite>('api/delivery-sites/' + dv.id, dv)
export const create = (name: string) =>
  axios.post<DeliverySite>('api/delivery-sites', { name })

export default { findAll, findAllActives, updateOne, create }
