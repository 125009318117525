import { Button, Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import * as React from 'react'
import { Link } from 'react-router-dom'

import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Margin from '../../../components/Margin'
import { t } from '../../../i18n'
import campaignService from '../../../services/campaignService'
import executionModeService from '../../../services/executionModeService'
import productService from '../../../services/productService'
import sectorService from '../../../services/sectorService'
import Product from '../../../models/Product'

import TraderOffersListCard from './TraderOffersListCard'
import TraderOffersListFiltersCard, {
  FilterValues,
} from './TraderOffersListFiltersCard'
import CardLoading from '../../../components/CardLoading'
import Campaign from '../../../models/Campaign'
import strikeService from '../../../services/strikeService'

type State = {
  filterValues: FilterValues
  products: Product[]
  campaigns: Campaign[]
  isLoading: boolean
}

class TraderOffersList extends React.Component<{}, State> {
  readonly state: State = {
    filterValues: {
      startDate: new Date(),
    },
    products: [],
    campaigns: [],
    isLoading: true,
  }

  async componentDidMount() {
    const { filterValues } = this.state
    await Promise.all([
      this.getProducts(filterValues.startDate),
      this.getCampaigns(filterValues.startDate),
    ])
    this.setState({ isLoading: false })
  }

  getProducts = async (startDate: Date) => {
    const { data: products } = await productService.findAllWithNbOffers(
      true,
      startDate
    )
    this.setState({ products })
  }

  getCampaigns = async (startDate: Date) => {
    const { data: campaigns } = await campaignService.findAllAndStartDate(
      startDate
    )
    this.setState({ campaigns })
  }

  private handleFilterChange = async (filterValues: FilterValues) => {
    this.setState({ isLoading: true, filterValues })
    await Promise.all([
      this.getProducts(filterValues.startDate),
      this.getCampaigns(filterValues.startDate),
    ])
    this.setState({ isLoading: false })
  }

  render() {
    const { filterValues, products, campaigns, isLoading } = this.state

    return (
      <>
        <Flex direction="row" alignItems="center">
          <Typography variant="h5">{t('Vos offres archivées')}</Typography>
          <Flex grow />
          <Margin right={1}>
            <Link to="/home">
              <Button color="primary">{t('Offres en cours')}</Button>
            </Link>
          </Margin>
          <Link to="/home/new-offer/step1">
            <Button color="primary" variant="contained">
              <AddIcon />
              {t('Créer une offre')}
            </Button>
          </Link>
        </Flex>
        <Margin top={2} bottom={2}>
          <Grid container>
            <Grid item xs={12}>
              <TraderOffersListFiltersCard
                values={filterValues}
                onChange={this.handleFilterChange}
              />
            </Grid>
          </Grid>
        </Margin>

        <Fetcher
          fetch={[
            sectorService.findAll,
            executionModeService.findAll,
            strikeService.findAll,
          ]}
        >
          {([sectors, executionModes, strikes]) => (
            <>
              {isLoading ? (
                <CardLoading />
              ) : (
                <TraderOffersListCard
                  backInTime={filterValues.startDate}
                  sectors={sectors}
                  products={products}
                  campaigns={campaigns}
                  executionModes={executionModes}
                  strikes={strikes}
                />
              )}
            </>
          )}
        </Fetcher>
      </>
    )
  }
}

export default TraderOffersList
