import * as Yup from 'yup'
import mapValues from 'lodash/mapValues'

export default Yup.object().shape({
  parityMatrixLines: Yup.array().of(
    Yup.object().shape({
      deliverySite: Yup.object(),
      values: Yup.lazy((obj: any) =>
        Yup.object(
          mapValues(obj, () => {
            return Yup.number().required()
          })
        )
      ),
    })
  ),
})
