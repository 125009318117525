import axios from '../config/axios'

export const importLogo = (image: File) => {
  const formData = new FormData()
  formData.append('image', image)
  return axios.post<string>('api/merchants/logo', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export default { importLogo }
