import { Grid, Typography } from '@material-ui/core'
import * as React from 'react'

import UserContext from '../../../contexts/UserContext'
import { t } from '../../../i18n'
import Role from '../../../models/Role'
import configSettingsOptions from '../../trader/Settings/configSettingsOptions'

import SuperAdminSettingsCard from './SuperAdminSettingsCard'

class SuperAdminSettings extends React.Component {
  static contextType = UserContext

  render() {
    const { hasRole } = this.context

    return (
      <>
        <Typography variant="h5">{t('Paramétrage du service')}</Typography>
        <Typography variant="subtitle1">
          {t('Que souhaitez-vous paramétrer ?')}
        </Typography>
        <Grid container spacing={2}>
          {configSettingsOptions.map(
            ({ title, subtitle, link, roles }) =>
              this.hasAnyRoles(hasRole!, roles) && (
                <Grid item lg={3} sm={6} xs={12} key={title}>
                  <SuperAdminSettingsCard
                    title={title}
                    subtitle={subtitle}
                    link={link}
                  />
                </Grid>
              )
          )}
        </Grid>
      </>
    )
  }

  private hasAnyRoles(
    hasRole: (role: Role) => boolean,
    roles: Role[]
  ): boolean {
    return roles.reduce<boolean>(
      (previousValue, currentValue) => previousValue || hasRole(currentValue),
      false
    )
  }
}

export default SuperAdminSettings
