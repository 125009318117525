import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import TextField from '@material-ui/core/TextField'
import Downshift from 'downshift'
import { FieldProps } from 'formik'
import keycode from 'keycode'
import capitalize from 'lodash/capitalize'
import deburr from 'lodash/deburr'
import React from 'react'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 250,
    },
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    chip: {
      margin: `${theme.spacing(1 / 2)}px ${theme.spacing(1 / 4)}px`,
    },
    inputRoot: {
      flexWrap: 'wrap',
    },
    inputInput: {
      width: 'auto',
      flexGrow: 1,
      paddingLeft: 5,
      paddingRight: 5,
    },
    divider: {
      height: theme.spacing(2),
    },
  })

interface Props extends FieldProps, WithStyles<typeof styles> {
  suggestions?: object[]
  suggestionLabel: string
  placeholder?: string
}

interface RenderSuggestionProps {
  highlightedIndex?: number
  index?: number
  itemProps?: object
  selectedItems?: object[]
  suggestion: object
}

interface State {
  inputValue: string
  selectedItems: object[]
}

class DownshiftMultiplePayPal extends React.Component<Props, State> {
  state = {
    inputValue: '',
    selectedItems: [],
  }

  renderSuggestion = ({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItems,
  }: RenderSuggestionProps) => {
    const { suggestionLabel } = this.props

    const isHighlighted = highlightedIndex === index
    const isSelected =
      (selectedItems || []).indexOf(suggestion[suggestionLabel]) > -1

    return (
      <MenuItem
        {...itemProps}
        key={suggestion[suggestionLabel]}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {capitalize(suggestion[suggestionLabel])}
      </MenuItem>
    )
  }

  getSuggestions = (value: string) => {
    const { suggestions, suggestionLabel } = this.props
    const { selectedItems } = this.state

    if (!value || !suggestions) {
      return []
    }

    const inputValue = deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length
    let count = 0

    if (inputLength === 0) {
      return []
    }

    return suggestions.filter(suggestion => {
      const keep =
        count < 5 &&
        !selectedItems.includes(suggestion[suggestionLabel] as never) &&
        suggestion[suggestionLabel].includes(inputValue)

      if (keep) {
        count += 1
      }

      return keep
    })
  }

  handleKeyDown = (event: KeyboardEvent) => {
    const { inputValue, selectedItems } = this.state
    const { form, field } = this.props

    if (
      selectedItems &&
      selectedItems.length &&
      !inputValue.length &&
      keycode(event) === 'backspace'
    ) {
      const newItems = selectedItems.slice(0, selectedItems.length - 1)
      form.setFieldValue(field.name, newItems)
      this.setState({
        selectedItems: newItems,
      })
    }
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ inputValue: event.target.value })
  }

  componentDidMount() {
    const { field } = this.props
    this.setState({
      selectedItems: field.value,
    })
  }

  handleChange = (item: object) => {
    const { selectedItems } = this.state
    const { form, field, suggestions, suggestionLabel } = this.props

    let newItems: object[] = [...selectedItems]

    const newItem = suggestions!.find(
      suggestion => suggestion[suggestionLabel] === item
    )
    if (
      newItem &&
      !newItems.find(i => i[suggestionLabel] === newItem[suggestionLabel])
    ) {
      newItems = [...newItems, newItem]
    }

    form.setFieldValue(field.name, newItems)
    this.setState({
      inputValue: '',
      selectedItems: newItems,
    })
  }

  handleDelete = (item: object) => () => {
    const { form, field, suggestionLabel } = this.props

    this.setState(state => {
      const selectedItems = [...state.selectedItems]
      const newSelectedItems = selectedItems.filter(
        selectedItem => selectedItem[suggestionLabel] !== item[suggestionLabel]
      )
      form.setFieldValue(field.name, newSelectedItems)
      return { selectedItems: newSelectedItems }
    })
  }

  render() {
    const { classes, suggestionLabel, placeholder } = this.props
    const { inputValue, selectedItems } = this.state

    return (
      <Downshift
        inputValue={inputValue}
        onChange={this.handleChange}
        selectedItem={selectedItems}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItems2,
          highlightedIndex,
        }) => (
          <div className={classes.container}>
            <TextField
              // @ts-ignore
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  input: classes.inputInput,
                },
                ...getInputProps({
                  // @ts-ignore
                  startAdornment: selectedItems
                    ? selectedItems.map(item => (
                        <Chip
                          key={item[suggestionLabel]}
                          tabIndex={-1}
                          label={capitalize(item[suggestionLabel])}
                          className={classes.chip}
                          onDelete={this.handleDelete(item)}
                        />
                      ))
                    : undefined,
                  onChange: this.handleInputChange,
                  onKeyDown: this.handleKeyDown,
                  placeholder,
                }),
              }}
              fullWidth={true}
            />
            {isOpen && inputValue2 ? (
              <Paper className={classes.paper} square>
                {this.getSuggestions(inputValue2).map(
                  (suggestion: object, index: number) =>
                    this.renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({
                        item: suggestion[suggestionLabel],
                      }),
                      highlightedIndex: highlightedIndex || undefined,
                      selectedItems: selectedItems2,
                    })
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    )
  }
}

const DownshiftMultipleStyled = withStyles(styles)(DownshiftMultiplePayPal)

export const DownshiftMultiple: React.FunctionComponent<Props> = (
  props: Props
) => <DownshiftMultipleStyled {...props} />

DownshiftMultiple.displayName = 'FormikDownshiftMultiple'
