import flatMap from 'lodash/flatMap'
import Campaign from '../../../models/Campaign'
import ContractTemplate, {
  ContractType,
} from '../../../models/ContractTemplate'
import DeliverySite from '../../../models/DeliverySite'
import Instrument from '../../../models/Instrument'
import MajorationTable from '../../../models/MajorationTable'
import Offer from '../../../models/Offer'
import OfferExecution from '../../../models/OfferExecution'
import Product from '../../../models/Product'
import Sector from '../../../models/Sector'
import UserTag from '../../../models/UserTag'
import generateRandomColor from '../../../utils/generateRandomColor'
import { Omit } from '../../../utils/Omit'

export default interface FormValues {
  campaign?: Campaign
  product?: Product
  oilPercent?: number
  deliverySite?: DeliverySite
  sectorsRestriction: boolean[]
  userTagsRestriction: UserTag[]
  type: 'FIXED' | 'INDEXED'
  market?: string
  maturity?: string
  priceToDestination?: number
  destinationFee?: number
  majorationTable?: MajorationTable
  majorationMonth?: string
  collectFee?: number
  maneuver?: number
  executionDetails: OfferExecution[]
  color: string
  instrument?: Instrument
  sectors?: Sector[]

  maximalVolume?: number
  expirationDate?: string
  publicationDatetime?: string
  contractTemplates: {
    [executionModeId: number]: {
      [contractType in keyof ContractType]:
        | Omit<ContractTemplate, 'id'>
        | undefined
    }
  }
  comments?: string

  modePMG: boolean
  optionFee?: number
  marketPMG?: string
  maturityPMG?: string
  instrumentPMG?: Instrument
}

export function formToOffer({ sectors, ...values }: FormValues): Offer {
  const contracts = flatMap(
    Object.values(values.contractTemplates).map(ct => Object.values(ct))
  )
  return {
    ...values,
    campaign: values.campaign!,
    product: values.product!,
    deliverySite: values.deliverySite!,
    priceToDestination: values.priceToDestination!,
    majorationTable: values.majorationTable!,
    majorationMonth: values.majorationMonth!,
    contractTemplates: contracts as Array<Omit<ContractTemplate, 'id'>>,
    userTagsRestriction: values.userTagsRestriction,
    executionDetails: values.executionDetails,
    sectorsRestriction: sectors!,
  }
}

export function getDefaultFormValues(currentCampaign?: Campaign): FormValues {
  return {
    campaign: currentCampaign,
    product: undefined,
    oilPercent: undefined,
    deliverySite: undefined,
    sectorsRestriction: [],
    userTagsRestriction: [],
    type: 'FIXED',
    market: undefined,
    maturity: undefined,
    destinationFee: undefined,
    priceToDestination: undefined,
    majorationTable: undefined,
    majorationMonth: undefined,
    collectFee: undefined,
    maneuver: undefined,
    executionDetails: [],
    color: generateRandomColor(),
    maximalVolume: undefined,
    expirationDate: undefined,
    publicationDatetime: undefined,
    comments: undefined,
    contractTemplates: {},
    modePMG: false,
  }
}
