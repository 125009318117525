import {
  Button,
  CardContent,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import BackButton from '@material-ui/icons/ArrowBack'
import * as React from 'react'

import Calendar from '../../../components/Calendar/Calendar'
import CalendarHeader from '../../../components/Calendar/CalendarHeader'
import TraderCalendarReadOnlyLines from '../../../components/Calendar/TraderCalendarReadOnlyLines'
import Flex from '../../../components/Flex'
import Margin from '../../../components/Margin'
import OfferRecapData from '../../../components/OfferRecapData'
import ErrorContext from '../../../contexts/ErrorContext'
import { t } from '../../../i18n'
import Campaign from '../../../models/Campaign'
import ExecutionMode from '../../../models/ExecutionMode'
import Offer from '../../../models/Offer'
import OffersPricesView from '../../../models/OffersPricesView'
import Sector from '../../../models/Sector'
import offerService from '../../../services/offerService'
import targetOrderService from '../../../services/targetOrderService'
import contractPMGService from '../../../services/contractPMGService'

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
    marginBottom: 15,
  },
  cardContentColored: {
    width: 360,
  },
  cardContent: {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    height: 40,
  },
}))

type Props = {
  offer: Offer
  executionModes: ExecutionMode[]
  sectors: Sector[]
  campaign: Campaign
  calendar: OffersPricesView
  onBack: () => void
}

const TraderHomeOfferRecap = ({
  offer,
  executionModes,
  sectors,
  campaign,
  calendar,
  onBack,
}: Props) => {
  const errorContext = React.useContext(ErrorContext)
  const classes = useStyles()
  const [confirmClose, setConfirmClose] = React.useState<boolean>(false)
  const [nbPendingOrders, setNbPendingOrders] = React.useState<
    Optional<number>
  >()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [nbPendingOptions, setNbPendingOptions] = React.useState<
    Optional<number>
  >()

  const countPendingOrdersOnOffer = async (offerId: number) => {
    try {
      const { data } = await targetOrderService.countByOffer(offerId)
      setNbPendingOrders(data)
    } catch (e) {
      errorContext.displayError(
        "Erreur lors de la recherche d'ordre de vente en cours sur cette offre. Veuillez contacter un administrateur."
      )
    }
  }

  const countPendingcontractPMGOnOffer = async (offerId: number) => {
    try {
      const { data } = await contractPMGService.countByOffer(offerId)
      setNbPendingOptions(data)
    } catch (e) {
      errorContext.displayError(
        "Erreur lors de la recherche d'options PMG en cours sur cette offre. Veuillez contacter un administrateur."
      )
    }
  }

  const showCloseConfirm = async () => {
    setIsLoading(true)
    setConfirmClose(true)
    const promises = [countPendingOrdersOnOffer(offer.id!)]
    if (offer.type === 'INDEXED' && offer.modePMG) {
      promises.push(countPendingcontractPMGOnOffer(offer.id!))
    }
    await Promise.all(promises)
    setIsLoading(false)
  }

  const hideCloseConfirm = () => {
    setConfirmClose(false)
  }

  const handleCloseOffer = async () => {
    try {
      await offerService.close(offer.id!)
      hideCloseConfirm()
      onBack()
    } catch (e) {
      errorContext.displayError(
        "Impossible de clôturer l'offre. Veuillez contacter un administrateur."
      )
    }
  }

  return (
    <>
      <Card className={classes.card}>
        <OfferRecapData
          offer={offer}
          cardContentColoredClasses={classes.cardContentColored}
        >
          <Margin bottom={2}>
            <Button color="inherit" onClick={onBack}>
              <BackButton />
              {t('Retour')}
            </Button>
          </Margin>
        </OfferRecapData>
        <CardContent className={classes.cardContent}>
          <Flex
            direction="row"
            justify="space-between"
            className={classes.title}
          >
            <Typography variant="h6" color="textSecondary">
              {t('Détails de l’offre')}
            </Typography>
            <span>
              <Button color="primary" onClick={showCloseConfirm}>
                <RemoveCircle />
                {t('Clôturer')}
              </Button>
            </span>
          </Flex>
          <Margin top={2} bottom={2}>
            <Calendar campaign={campaign}>
              <CalendarHeader />
              <TraderCalendarReadOnlyLines
                sectors={sectors}
                executionModes={executionModes}
                calendar={calendar}
              />
            </Calendar>
          </Margin>
        </CardContent>
      </Card>
      <Dialog
        open={confirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Êtes-vous sûr de vouloir clôturer l'offre ?")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isLoading && (
              <Margin bottom={2}>
                <Typography variant="caption">
                  <CircularProgress color="primary" />
                  {t(
                    'Vérification des ordres de vente en cours sur cette offre.'
                  )}
                </Typography>
              </Margin>
            )}
            {nbPendingOrders !== undefined && nbPendingOrders > 0 && (
              <Margin bottom={2}>
                <Typography variant="subtitle2">
                  {t(
                    `Attention, ${nbPendingOrders} ordre(s) de vente sont actuellement en cours sur cette offre.`
                  )}
                  <br />
                  {t('Ces ordres seront automatiquement annulés.')}
                </Typography>
              </Margin>
            )}
            {nbPendingOptions !== undefined && nbPendingOptions > 0 && (
              <Margin bottom={2}>
                <Typography variant="subtitle2">
                  {t(
                    `Attention, ${nbPendingOptions} contrat(s) PMG sont actuellement en cours sur cette offre.`
                  )}
                  <br />
                  {t('Ce(s) contrat(s) ne seront pas annulé(s).')}
                </Typography>
              </Margin>
            )}
            <Typography variant="subtitle2">
              {t("L'offre sera immédiatement cloturée.")}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideCloseConfirm} color="default" autoFocus>
            {t('Annuler')}
          </Button>
          <Button
            onClick={handleCloseOffer}
            color="primary"
            disabled={nbPendingOrders === undefined}
          >
            {t('Confirmer')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TraderHomeOfferRecap
