import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import isString from 'lodash/isString'
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableProps,
  MUIDataTableState,
  MUIDataTableColumn,
  MUIDataTableColumnDef,
} from 'mui-datatables'
import * as React from 'react'

import memoizeOne from 'memoize-one'
import { isValid, format, parse } from 'date-fns'

import { makeStyles } from '@material-ui/styles'
import { useDebouncedCallback } from 'use-debounce/lib'
import { t } from '../i18n'
import { Omit } from '../utils/Omit'
import pageableService from '../services/pageableService'
import CardLoading from './CardLoading'
import ErrorContext from '../contexts/ErrorContext'

const useStyles = makeStyles({
  emptyCard: {
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyCardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    zIndex: 1000,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  spinner: {
    top: 8,
    position: 'absolute',
    left: '50%',
  },
  relative: {
    position: 'relative',
  },
})

type Props = Omit<MUIDataTableProps, 'title' | 'data'> & {
  title?: string
  emptyContent?: JSX.Element | string
  noInternalState?: boolean
  storageSaveId?: string
  noAlphanumericSort?: boolean
  apiCall?: (...params: any[]) => any[] | any
  apiParams?: any | any[]
  dataConverter?: (...params: any) => any
  data?: any[]
  toBackendColumnsNames?: { [columnName: string]: string } // required for serverside sort
  csvDataMap?: (separator: string) => (data: any) => string // required for "serverside" export
  defaultSort?: SortOrder
}

export type SortOrder = {
  name?: string
  direction?: 'asc' | 'desc'
}

let timeoutRef: NodeJS.Timer

// Never mutate this, override with options (from props, which also should not be muted here) or internalOptions
const defaultOptions: MUIDataTableOptions = {
  download: false,
  pagination: false,
  serverSide: false,
  resizableColumns: false,
  print: false,
  selectableRows: 'none',
  viewColumns: false,
  responsive: 'scroll',
  rowsPerPageOptions: [10, 15, 20, 50],
  textLabels: {
    body: {
      noMatch: t("Désolé, nous n'avons rien trouvé"),
      toolTip: t('Trier'),
    },
    pagination: {
      next: t('Page suivante'),
      previous: t('Page précédente'),
      rowsPerPage: t('lignes par page: '),
      displayRows: t('de'),
    },
    toolbar: {
      search: t('Recherche'),
      downloadCsv: t('Télécharger en CSV'),
      print: t('Imprimer'),
      viewColumns: t('Afficher colonnes'),
      filterTable: t('Filtrer Tableau'),
    },
    filter: {
      all: t('Tous'),
      title: t('FILTRES'),
      reset: t('Réinitialiser'),
    },
    viewColumns: {
      title: t('Afficher Colonnes'),
      titleAria: t('Afficher/Cacher colonnes'),
    },
    selectedRows: {
      text: t('ligne(s) séléctionnée(s)'),
      delete: t('Supprimer'),
      deleteAria: t('Supprimer lignes séléctionnées'),
    },
  },
}

type DatatableState = {
  searchText?: string | null
  filterList?: string[][]
  columns?: MUIDataTableColumn[]
  sortOrder: SortOrder
  searchOpen?: boolean
}

type CustomSortArray = { data: string[] }

const DataTable: React.FunctionComponent<Props> = ({
  noInternalState,
  storageSaveId,
  noAlphanumericSort,
  apiCall,
  apiParams,
  dataConverter,
  title,
  data,
  options,
  emptyContent,
  columns,
  toBackendColumnsNames,
  csvDataMap,
  defaultSort,
}) => {
  const classes = useStyles()

  const [datatableState, setDatatableState] = React.useState<DatatableState>({
    sortOrder: defaultSort || {},
  })

  const [hasInit, setHasInit] = React.useState<boolean>(false)

  /*** Server side pagination, not enabled by default
   * To enable it, caller component should set options.serverSide to true & give an apiCall prop.
   * Also do not forget to set the "toBackendColumnsNames" prop for the sorting to work
   * And "csvDataMap" prop for the csv export to work, along with a falsy "noInternalState" (otherwise search and sorting will be ignored in the export)
   */
  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [datatableData, setDatatableData] = React.useState<any[] | undefined>(
    data
  )
  const [serverSideEnabled, setServerSideEnabled] = React.useState<boolean>(
    false
  )
  const [internalOptions, setInternalOptions] = React.useState<
    MUIDataTableOptions
  >({})

  const errorContext = React.useContext(ErrorContext)
  const stateRef: { current?: DatatableState } = React.useRef()
  stateRef.current = datatableState

  const xhrRequest = async (
    page: number,
    sortOrder?: SortOrder,
    searchText?: string | null,
    rowsPerPage?: number
  ) => {
    setLoading(true)
    try {
      const { data: res } = await apiCall!(
        ...(apiParams || []),
        pageableService.MuiStateToPageable(
          sortOrder || {},
          page,
          rowsPerPage || 10,
          toBackendColumnsNames,
          searchText || ''
        )
      )
      setDatatableData(res.content)
      setInternalOptions(internalOptions => ({
        ...internalOptions,
        count: res.totalElements,
      }))
      setLoading(false)
    } catch (_) {
      errorContext.displayError(
        'Une erreur est survenue, veuillez recharger la page'
      )
      // reset state since it's probably broken
      if (storageSaveId) {
        sessionStorage.removeItem(storageSaveId)
      }
    }
  }

  const [onSearch] = useDebouncedCallback(
    (
      page: number,
      sortOrder: SortOrder,
      searchText: string | null,
      rowsPerPage: number
    ) => xhrRequest(page, sortOrder, searchText, rowsPerPage),
    800
  )

  React.useEffect(() => {
    if (options && options.serverSide && !!apiCall && !serverSideEnabled) {
      setServerSideEnabled(true)
    } else if (
      (!options || !options.serverSide || !apiCall) &&
      serverSideEnabled
    ) {
      setServerSideEnabled(false)
    }
    // eslint-disable-next-line
  }, [options, options && options.serverSide, apiCall])
  /** */

  React.useEffect(() => {
    setDatatableData(data)
  }, [data])

  React.useEffect(() => {
    setDatatableState(state => ({ ...state!, sortOrder: defaultSort || {} }))
  }, [defaultSort])

  const handleServerSideDownload = () => {
    setLoading(true)
    ;(async () => {
      try {
        const { data } = await apiCall!(
          ...(apiParams || []),
          pageableService.MuiStateToPageable(
            (stateRef.current && stateRef.current.sortOrder) || {},
            0,
            2147483647, // max integer
            toBackendColumnsNames,
            (stateRef.current && stateRef.current.searchText) || ''
          )
        )
        const separator =
          (options &&
            options.downloadOptions &&
            options.downloadOptions.separator) ||
          ';'
        const csv =
          columns
            .map((c: any) => c.label || c)
            .filter(c => typeof c === 'string')
            .join(separator) +
          '\n' +
          data.content.map(csvDataMap!(separator)).join('\n')
        const hiddenLink = document.createElement('a')
        hiddenLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        hiddenLink.target = '_blank'
        hiddenLink.download =
          (options &&
            options.downloadOptions &&
            options.downloadOptions.filename) ||
          `${title}.csv`
        document.body.appendChild(hiddenLink)
        hiddenLink.click()

        setTimeout(() => {
          hiddenLink.remove()
          setLoading(false)
        }, 100)
      } catch (_) {
        errorContext.displayError(
          'Une erreur est survenue, veuillez recharger la page'
        )
      }
    })()
    return false // prevent default lib action
  }

  const initMUIInternalState = async () => {
    setInternalOptions(internalOptions => ({
      ...internalOptions,
      onTableChange: async (action: string, tableState: MUIDataTableState) => {
        if (serverSideEnabled) {
          switch (action) {
            case 'changePage':
            case 'sort':
            case 'changeRowsPerPage':
              if (
                toBackendColumnsNames &&
                Object.keys(toBackendColumnsNames).includes(
                  tableState.sortOrder.name!
                )
              ) {
                xhrRequest(
                  tableState.page,
                  tableState.sortOrder,
                  tableState.searchText,
                  tableState.rowsPerPage
                )
              }
              break
            case 'search':
              onSearch(
                tableState.page,
                tableState.sortOrder,
                tableState.searchText,
                tableState.rowsPerPage
              )
              break
          }
        }

        // Save datatable current state manually because this sh*tty lib can't do it
        if (!noInternalState) {
          if (action !== 'propsUpdate') {
            if (timeoutRef) {
              clearTimeout(timeoutRef)
            }
            timeoutRef = setTimeout(() => {
              const { searchText, filterList, columns, sortOrder } = tableState
              setDatatableState({ searchText, filterList, columns, sortOrder })
              if (storageSaveId) {
                sessionStorage.setItem(
                  storageSaveId,
                  JSON.stringify({
                    filterList,
                    columns,
                    searchText,
                    sortOrder,
                    searchOpen: !!searchText,
                  })
                )
              }
            }, 200)
          }
        }
      },
    }))
    if (serverSideEnabled) {
      // @ts-ignore (due to datatable types broken and can't override for onDownload)
      setInternalOptions(internalOptions => ({
        ...internalOptions,
        onDownload: handleServerSideDownload,
        count: 1,
        page: 0,
        pagination: true,
        filter: false,
      }))
      xhrRequest(
        0,
        datatableState && datatableState.sortOrder,
        datatableState && datatableState.searchText
      )
    }
    setHasInit(true)
  }

  React.useEffect(() => {
    initMUIInternalState()
    // eslint-disable-next-line
  }, [noInternalState, serverSideEnabled])

  React.useEffect(() => {
    if (!noAlphanumericSort && !serverSideEnabled) {
      // frontside sort
      setInternalOptions(internalOptions => ({
        ...internalOptions,
        customSort: (
          array: CustomSortArray[],
          sortIndex: number,
          order: string
        ) =>
          array.sort((a: CustomSortArray, b: CustomSortArray) => {
            let first = a.data[sortIndex]
            let second = b.data[sortIndex]

            if (order === 'desc') {
              first = b.data[sortIndex]
              second = a.data[sortIndex]
            }
            const firstAsDateHour = parse(first, 'dd/MM/yyyy HH:mm', new Date())
            const secondAsDateHour = parse(
              second,
              'dd/MM/yyyy HH:mm',
              new Date()
            )
            if (isValid(firstAsDateHour) && isValid(secondAsDateHour)) {
              first = format(firstAsDateHour, 'yyyy/MM/dd HH:mm')
              second = format(secondAsDateHour, 'yyyy/MM/dd HH:mm')
            } else {
              const firstAsDate = parse(first, 'dd/MM/yyyy', new Date())
              const secondAsDate = parse(second, 'dd/MM/yyyy', new Date())
              if (isValid(firstAsDate) && isValid(secondAsDate)) {
                first = format(firstAsDate, 'yyyy/MM/dd')
                second = format(secondAsDate, 'yyyy/MM/dd')
              }
            }

            return `${first}`.localeCompare(`${second}`, 'fr', {
              numeric: true,
            })
          }),
      }))
    } else {
      setInternalOptions(internalOptions => ({
        ...internalOptions,
        customSort: undefined,
      }))
    }
  }, [noAlphanumericSort, serverSideEnabled])

  React.useEffect(() => {
    if (!storageSaveId) {
      return
    }
    const storage = JSON.parse(sessionStorage.getItem(storageSaveId)!)
    if (!storage && datatableState.sortOrder) {
      // Cas quand on arrive sur le tableau pour la première fois et qu'il y a un tri par défaut : on le laisse en enlèvant de potentiels autres states
      setDatatableState(state => ({ sortOrder: state.sortOrder }))
    } else {
      setDatatableState(storage)
    }
    // eslint-disable-next-line
  }, [storageSaveId])

  // Si un jour où il y a un problème avec les colonnes et 2 tableaux en même temps, ça viendra probablement d'ici (mutation)
  const getTableColumns: () => MUIDataTableColumnDef[] = memoizeOne(() => {
    if (
      !datatableState ||
      !datatableState.filterList ||
      !datatableState.filterList.length ||
      !datatableState.columns ||
      !datatableState.columns.length
    ) {
      return columns
    }
    return columns.map((c, i) => {
      //Assign the filter list to persist
      if (typeof c === 'string' || !c.options) {
        if (typeof c === 'string') {
          c = {
            name: c,
          }
        }
        c.options = {}
      }
      c.options.filterList = datatableState.filterList![i]
      if (datatableState.columns![i] !== undefined) {
        //If 'display' has a value in tableStatePersist, assign that, or else leave it alone
        if (
          Object.prototype.hasOwnProperty.call(
            datatableState.columns![i],
            'display'
          )
        ) {
          c.options.display = datatableState.columns![i].display
        }
        //If 'sortDirection' has a value in tableStatePersist, assign that, or else leave it alone
        if (
          Object.prototype.hasOwnProperty.call(
            datatableState.columns![i],
            'sortDirection'
          )
        ) {
          //The sortDirection prop only permits sortDirection for one column at a time
          if (datatableState.columns![i].sortDirection)
            c.options.sortDirection = datatableState.columns![i].sortDirection
        }
      }
      return c
    })
  })

  if ((!datatableData || datatableData.length === 0) && isLoading) {
    return <CardLoading />
  }

  if (
    (!datatableData || datatableData.length === 0) &&
    !datatableState.searchText
  ) {
    return (
      <Card className={classes.emptyCard}>
        <CardContent className={classes.emptyCardContent}>
          {isString(emptyContent) ? (
            <Typography color="primary" variant="subtitle1">
              {emptyContent}
            </Typography>
          ) : (
            emptyContent
          )}
        </CardContent>
      </Card>
    )
  }

  return (
    <>
      <div className={classes.relative}>
        {isLoading && (
          <div className={classes.loading}>
            <CircularProgress
              color="primary"
              size={48}
              className={classes.spinner}
            />
          </div>
        )}
        {hasInit && (
          <MUIDataTable
            data={dataConverter ? dataConverter(datatableData) : datatableData}
            columns={noInternalState ? columns : getTableColumns()}
            title={title || ''}
            options={{
              ...defaultOptions,
              ...options,
              ...internalOptions,
              searchText: datatableState && datatableState.searchText,
              sortOrder: datatableState && datatableState.sortOrder,
              // @ts-ignore
              searchOpen: datatableState && datatableState.searchOpen,
            }}
          />
        )}
      </div>
    </>
  )
}

export default DataTable
