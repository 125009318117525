import { Typography, Tooltip } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton/IconButton'
import RefreshIcon from 'mdi-material-ui/Sync'
import * as React from 'react'

import Calendar from '../../../components/Calendar/Calendar'
import CalendarHeader from '../../../components/Calendar/CalendarHeader'
import TraderCalendarReadOnlyLines from '../../../components/Calendar/TraderCalendarReadOnlyLines'
import CalendarLoading from '../../../components/CalendarLoading'
import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Margin from '../../../components/Margin'
import { t } from '../../../i18n'
import { format } from '../../../i18n/format'
import Campaign from '../../../models/Campaign'
import ExecutionMode from '../../../models/ExecutionMode'
import Offer from '../../../models/Offer'
import StrikeSetting, { StrikeValue } from '../../../models/StrikeSetting'
import OffersPricesView from '../../../models/OffersPricesView'
import Sector from '../../../models/Sector'
import offerService from '../../../services/offerService'

import TraderCalendarError from './TraderCalendarError'
import StrikesTabs from '../../../components/Calendar/StrikesTabs'

type Props = {
  offers: Offer[]
  selectedSectors: Sector[]
  executionModes: ExecutionMode[]
  strikes: StrikeSetting[]
  handleRefreshClicked: () => void
  lastUpdatedDate: Date
  selectedCampaign: Campaign
  onClick: (offer: Offer, calendar: OffersPricesView) => void
}

const TraderOffersCardCalendar = ({
  offers,
  selectedSectors,
  executionModes,
  strikes,
  handleRefreshClicked,
  lastUpdatedDate,
  selectedCampaign,
  onClick,
}: Props) => {
  const [strikeValue, setStrikeValue] = React.useState<Optional<StrikeValue>>()
  const [hasPmg, setHasPmg] = React.useState<boolean>()

  const findOneOfferPricesView = (
    calendar: OffersPricesView,
    offerId: number
  ) => {
    const filteredCalendar = { executionModePerSector: {} }
    Object.keys(calendar.executionModePerSector).forEach(executionModeKey => {
      Object.keys(calendar.executionModePerSector[executionModeKey]).forEach(
        sectorKey => {
          Object.keys(
            calendar.executionModePerSector[executionModeKey][sectorKey]
          )
            .filter(monthKey => {
              return (
                calendar.executionModePerSector[executionModeKey][sectorKey][
                  monthKey
                ].offerId === offerId
              )
            })
            .forEach(monthKey => {
              if (!filteredCalendar.executionModePerSector[executionModeKey]) {
                filteredCalendar.executionModePerSector[executionModeKey] = {}
              }
              if (
                !filteredCalendar.executionModePerSector[executionModeKey][
                  sectorKey
                ]
              ) {
                filteredCalendar.executionModePerSector[executionModeKey][
                  sectorKey
                ] = {}
              }

              filteredCalendar.executionModePerSector[executionModeKey][
                sectorKey
              ][monthKey] =
                calendar.executionModePerSector[executionModeKey][sectorKey][
                  monthKey
                ]
            })
        }
      )
    })

    return filteredCalendar
  }

  const handleClick = (calendar: OffersPricesView) => (offerId: number) => {
    const offer = offers.find(o => o.id === offerId)
    onClick(offer!, findOneOfferPricesView(calendar, offerId))
  }

  React.useEffect(() => {
    setHasPmg(offers.some(o => o.modePMG))
  }, [offers])

  const offerIds = offers.map(o => o.id!)
  if (offers.length === 0) {
    return (
      <>
        <Margin bottom>
          <Flex direction="row" alignItems="center">
            <Flex grow />
            <Typography color="primary">
              {t('Dernière mise à jour : ')}{' '}
              {format(lastUpdatedDate, 'dd/MM/yy à HH:mm')}
            </Typography>
            <Margin left>
              <IconButton onClick={handleRefreshClicked}>
                <RefreshIcon color="primary" />
              </IconButton>
            </Margin>
          </Flex>
        </Margin>
        <Flex grow alignItems="center" justify="flex-start">
          <Margin top={10}>
            <Typography variant="h6" color="primary" align="center">
              {t('Aucune offre créée')}
            </Typography>
          </Margin>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Margin bottom>
        <Flex direction="row" alignItems="center" wrap={true}>
          <Margin right={3}>
            <Typography variant="h6" color="textSecondary">
              {t('Offres actives')} ({offers.length})
            </Typography>
          </Margin>
          <Margin right={3}>
            <Flex direction="row" alignItems="center" wrap={false}>
              <Margin right={1}>
                <Tooltip title={t('Type de prix')} placement="top">
                  <HelpOutline />
                </Tooltip>
              </Margin>
              {hasPmg && (
                <StrikesTabs strikes={strikes} handleChange={setStrikeValue} />
              )}
            </Flex>
          </Margin>
          <Flex grow />
          <Flex direction="row" alignItems="center" wrap={false}>
            <Typography color="primary" variant="caption">
              {t('Dernière mise à jour : ')}{' '}
              {format(lastUpdatedDate, 'dd/MM/yy à HH:mm')}
            </Typography>
            <IconButton onClick={handleRefreshClicked}>
              <RefreshIcon color="primary" />
            </IconButton>
          </Flex>
        </Flex>
      </Margin>
      <Fetcher
        fetch={offerService.getCalendarPrices}
        fetchProps={[
          offerIds,
          selectedCampaign.startDateCalendar,
          selectedCampaign.endDateCalendar,
          strikeValue,
        ]}
        loadingRender={CalendarLoading}
        errorRender={TraderCalendarError}
      >
        {calendar => (
          <Calendar campaign={selectedCampaign}>
            <CalendarHeader />
            <TraderCalendarReadOnlyLines
              onClick={handleClick(calendar)}
              sectors={selectedSectors}
              executionModes={executionModes}
              calendar={calendar}
            />
          </Calendar>
        )}
      </Fetcher>
    </>
  )
}

export default TraderOffersCardCalendar
