import * as React from 'react'

import { Field, Form, Formik, FormikActions, FormikProps } from 'formik'
import { Grid, Theme, Typography, WithStyles } from '@material-ui/core'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import { capitalize } from '@material-ui/core/utils/helpers'
import createStyles from '@material-ui/core/styles/createStyles'
import memoizeOne from 'memoize-one'
import withStyles from '@material-ui/core/styles/withStyles'
import Error from '../../../components/Error'
import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Loading from '../../../components/Loading'
import Margin from '../../../components/Margin'
import Product from '../../../models/Product'
import { Select } from '../../../components/Form/Select'
import SnackbarsExtended from '../../../components/SnackbarsExtended'
import StorageSettings from '../../../models/StorageSettings'
import StoragesSettingsValidationSchema from './StoragesSettingsValidationSchema'
import { Switch } from '../../../components/Form/Switch'
import TextField from '../../../components/Form/TextField'
import TraderSettingsConfigCard from './TraderSettingsConfigCard'
import adviceService from '../../../services/adviceService'
import productService from '../../../services/productService'
import storageSettingService from '../../../services/storageSettingsService'
import { t } from '../../../i18n'

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      marginRight: theme.spacing(8),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: 200,
    },
    submit: {
      width: '100%',
    },
    loading: {
      padding: 30,
    },
    form: {
      width: '100%',
    },
  })

type Props = WithStyles<typeof styles> & {}

type State = {
  storageSettings?: StorageSettingsMap
  isLoading: boolean
  error: boolean
}

type StorageSettingsMap = {
  storageSettings: StorageSettings[]
}

const days = Array(31)
  .fill(0)
  .map((a: number, idx: number) => 1 + idx)
const months = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]

function getDefaultFormValues(
  products: Product[],
  fetchedStorageSettings: StorageSettingsMap | undefined
): StorageSettingsMap {
  const storageSettings = products.map<StorageSettings>(
    (product: Product, idx: number) =>
      (fetchedStorageSettings &&
        fetchedStorageSettings.storageSettings &&
        fetchedStorageSettings.storageSettings.find(
          (ss: StorageSettings) => product.id === ss.product.id
        )) || {
        product,
        id: idx,
        enabled: false,
        dailyFees: 0,
        inputFees: 0,
        startDateDay: 1,
        startDateMonth: 1,
        endDateDay: 31,
        endDateMonth: 12,
        ceiling: 0,
      }
  )
  return { storageSettings }
}

class StorageSettingsScene extends React.Component<Props, State> {
  state: State = {
    storageSettings: undefined,
    isLoading: true,
    error: false,
  }

  getInitialFormValues: (
    products: Product[]
  ) => StorageSettingsMap = memoizeOne(
    (products: Product[]): StorageSettingsMap => {
      const { storageSettings } = this.state
      return getDefaultFormValues(products, storageSettings)
    }
  )

  componentDidMount(): void {
    this.getStorageSettings()
  }

  getStorageSettings = async () => {
    const { data: storageSettings } = await storageSettingService.findAll()
    this.setState({ storageSettings: { storageSettings }, isLoading: false })
  }

  handleSubmit = async (
    values: StorageSettingsMap,
    { setSubmitting }: FormikActions<StorageSettingsMap>
  ) => {
    try {
      const filteredStorageSettings = values.storageSettings.filter(
        ss => ss.enabled
      )
      await storageSettingService.update(filteredStorageSettings)
    } catch (e) {
      this.setState({ error: true })
    } finally {
      setSubmitting(false)
    }
  }

  handleSnackbarClose = () => {
    this.setState({ error: false })
  }

  renderStorageSettings = (products: Product[]) => ({
    isSubmitting,
  }: FormikProps<StorageSettingsMap>) => {
    const { classes } = this.props
    return (
      <Form className={classes.form}>
        <Flex>
          {products.map((product: Product, idx: number) => (
            <Margin bottom={2} style={{ width: '100%' }} key={idx}>
              <Flex direction="row" alignItems="center" justify="space-between">
                <div>
                  <Typography color="primary" variant="overline">
                    {product.name}
                  </Typography>
                </div>
                <FormControl>
                  <Field
                    component={Switch}
                    name={`storageSettings[${idx}].enabled`}
                    color="primary"
                  />
                </FormControl>
              </Flex>

              <Margin bottom={2} style={{ width: '100%' }}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Flex direction="row" alignItems="center">
                      <FormControl
                        className={classes.formControl}
                        style={{ minWidth: 240 }}
                      >
                        <Field
                          component={TextField}
                          label={t('Frais de stockage journalier')}
                          name={`storageSettings[${idx}].dailyFees`}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                €/t/j
                              </InputAdornment>
                            ),
                          }}
                          type="number"
                        />
                      </FormControl>
                    </Flex>
                    <Flex>
                      <Margin top={1}>
                        <Typography variant="subtitle2">
                          <strong>{t('Début')}</strong>
                          {t(' d’application des frais de stockage')}
                        </Typography>
                      </Margin>
                      <Flex direction="row" alignItems="center">
                        <FormControl
                          className={classes.formControl}
                          style={{ width: 100, marginRight: 15 }}
                        >
                          <Field
                            component={Select}
                            label={t('Jour')}
                            name={`storageSettings[${idx}].startDateDay`}
                          >
                            {days.map((day: number, i: number) => (
                              <MenuItem key={i} value={day}>
                                {day}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={Select}
                            label={t('Mois')}
                            options={months}
                            name={`storageSettings[${idx}].startDateMonth`}
                          >
                            {months.map((month: string, i: number) => (
                              <MenuItem key={month} value={i + 1}>
                                {capitalize(month)}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Flex>
                    </Flex>
                  </Grid>
                  <Grid item xs={6}>
                    <Flex direction="row" alignItems="center">
                      <FormControl className={classes.formControl}>
                        <Field
                          component={TextField}
                          label={t('Frais d’entrée')}
                          name={`storageSettings[${idx}].inputFees`}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                          type="number"
                        />
                      </FormControl>
                    </Flex>
                    <Flex>
                      <Margin top={1}>
                        <Typography variant="subtitle2">
                          <strong>{t('Fin')}</strong>
                          {t(' d’application des frais de stockage')}
                        </Typography>
                      </Margin>
                      <Flex direction="row" alignItems="center">
                        <FormControl
                          className={classes.formControl}
                          style={{ width: 100, marginRight: 15 }}
                        >
                          <Field
                            component={Select}
                            label={t('Jour')}
                            name={`storageSettings[${idx}].endDateDay`}
                          >
                            {days.map((day: number, i: number) => (
                              <MenuItem key={i} value={day}>
                                {day}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <Field
                            component={Select}
                            label={t('Mois')}
                            options={months}
                            name={`storageSettings[${idx}].endDateMonth`}
                          >
                            {months.map((month: string, i: number) => (
                              <MenuItem key={month} value={i + 1}>
                                {capitalize(month)}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Flex>
                    </Flex>
                  </Grid>
                  <Flex direction="row" alignItems="center">
                    <FormControl
                      className={classes.formControl}
                      style={{ minWidth: 240 }}
                    >
                      <Field
                        component={TextField}
                        label={t('Plafond')}
                        name={`storageSettings[${idx}].ceiling`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                          ),
                        }}
                        type="number"
                      />
                    </FormControl>
                  </Flex>
                </Grid>
              </Margin>
            </Margin>
          ))}
          <Flex alignItems="center" className={classes.submit}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
            >
              {t('Valider')}
            </Button>
          </Flex>
        </Flex>
      </Form>
    )
  }

  render() {
    const { classes } = this.props
    const { isLoading, error } = this.state

    if (isLoading) {
      return (
        <Card className={classes.loading}>
          <Loading />
        </Card>
      )
    }

    return (
      <TraderSettingsConfigCard
        cardTitle="Vos frais de stockage"
        adviceMessage={
          <Fetcher fetch={adviceService.findOne} fetchProps={['STORAGE']}>
            {advice => (
              <div dangerouslySetInnerHTML={{ __html: advice.template }} />
            )}
          </Fetcher>
        }
      >
        <Fetcher
          fetch={[productService.findAll]}
          loadingRender={Loading}
          errorRender={Error}
        >
          {([products]) => (
            <Formik
              initialValues={this.getInitialFormValues(products)}
              onSubmit={this.handleSubmit}
              validationSchema={StoragesSettingsValidationSchema}
              render={this.renderStorageSettings(products)}
            />
          )}
        </Fetcher>
        <SnackbarsExtended
          onClose={this.handleSnackbarClose}
          open={error}
          variant={'error'}
          message={'Une erreur est survenue, veuillez recharger la page'}
        />
      </TraderSettingsConfigCard>
    )
  }
}

export default withStyles(styles)(StorageSettingsScene)
