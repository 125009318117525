import React, { useState, useContext, useEffect } from 'react'
import {
  Typography,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
  Hidden,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PreviewIcon from '@material-ui/icons/VisibilityOutlined'
import { makeStyles } from '@material-ui/styles'
import { t } from '../i18n'
import { format } from '../i18n/format'
import Flex from './Flex'
import targetOrderService, {
  TargetOrderView,
} from '../services/targetOrderService'
import ErrorContext from '../contexts/ErrorContext'
import useRouter from '../hooks/useRouter'
import transactionService, {
  TransactionRequest,
} from '../services/transactionService'

const useStyles = makeStyles(() => ({
  content: {
    border: 'solid 1px #1ebc97',
    borderRadius: '2px',
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  loading: {
    padding: 30,
  },
  header: {
    padding: '1.5rem 1rem',
    overflow: 'auto',
  },
  bottom: {
    backgroundColor: '#1ebc97',
    color: '#ffffff',
    padding: '0.5rem',
    textAlign: 'center',
  },
  size: {
    fontSize: '15px',
  },
  icon: {
    padding: '4px',
  },
  separator: {
    width: '1px',
    height: '22px',
    border: 'solid 1px white',
  },
  span: {
    padding: '0 1rem',
  },
}))

interface Props {
  targetOrders: TargetOrderView[]
  refresh: () => void
  currentUserIsCustomer?: boolean
}

export const TargetOrdersInProgress = ({
  targetOrders,
  refresh,
  currentUserIsCustomer,
}: Props) => {
  const classes = useStyles()
  const { history } = useRouter()
  const errorContext = useContext(ErrorContext)

  const [targetOrderToCancel, setTargetOrderToCancel] = useState<
    number | undefined
  >(undefined)
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false)

  const [data, setData] = useState<TargetOrderView[]>(targetOrders)

  useEffect(() => {
    const fetchData = async (views: TargetOrderView[]) => {
      const transactions = views.map(orderView => {
        const transaction: TransactionRequest = {
          executionMode: orderView.executionMode,
          executionMonth: orderView.executionDate,
          campaign: { id: orderView.campaignId },
          product: { id: orderView.productId },
          tons: orderView.tons,
          paymentDate: orderView.paymentDate,
        }
        return transaction
      })

      for (let idxData = 0; idxData < views.length; idxData++) {
        let transaction
        if (!currentUserIsCustomer) {
          transaction = (await transactionService.getOneForCustomer(
            views[idxData].customerId,
            transactions[idxData]
          )).transaction
        } else {
          transaction = (await transactionService.getOne(transactions[idxData]))
            .transaction
        }
        views[idxData] = {
          ...views[idxData],
          actualPrice: transaction.rawPrice,
        }
        setData([...views])
      }
    }
    fetchData(targetOrders)
    // eslint-disable-next-line
  }, [targetOrders])

  const onCancel = async () => {
    try {
      if (targetOrderToCancel) {
        await targetOrderService.cancelById(targetOrderToCancel)
      }
      setCancelDialogOpen(false)
      refresh()
    } catch (e) {
      errorContext.displayError("Impossible de retirer l'ordre")
    }
  }

  const onCancelOpen = (targetOrder: TargetOrderView) => () => {
    setCancelDialogOpen(true)
    setTargetOrderToCancel(targetOrder.id)
  }

  const onCancelClose = () => {
    setCancelDialogOpen(false)
  }

  const handlePreviewTargetOrder = (targetOrder: TargetOrderView) => () => {
    history.push({ pathname: `/target-order/detail/${targetOrder.id}` })
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h6">
            {t('Vos ordres de vente en cours')}
          </Typography>
          {data.length === 0 && (
            <Typography>Aucun ordre de vente en cours</Typography>
          )}

          {data.map((targetOrder: TargetOrderView) => {
            return (
              <div key={`${targetOrder.id}`} className={classes.content}>
                <div className={classes.header}>
                  <Flex
                    justify="space-between"
                    direction="row"
                    alignItems="center"
                  >
                    <span className={classes.span}>
                      {targetOrder.productName}
                    </span>
                    <span className={classes.span}>
                      {targetOrder.campaignName}
                    </span>
                    <span className={classes.span}>{targetOrder.tons}t</span>
                    <span className={classes.span}>
                      {targetOrder.executionModeLabel}
                    </span>
                    <span className={classes.span}>
                      {format(targetOrder.executionDate, 'MMMM yyyy')}
                    </span>
                    <Hidden xsDown>
                      <span className={classes.span}>
                        <Tooltip title={t("Retirer l'ordre de vente")}>
                          <IconButton
                            key="remove"
                            aria-label="Remove"
                            color="primary"
                            className={classes.icon}
                            onClick={onCancelOpen(targetOrder)}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={t("Voir le détail de l'ordre de vente")}
                        >
                          <IconButton
                            key="detail"
                            aria-label="Detail"
                            color="primary"
                            className={classes.icon}
                            onClick={handlePreviewTargetOrder(targetOrder)}
                          >
                            <PreviewIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </Hidden>
                  </Flex>
                </div>
                <div className={classes.bottom}>
                  <Flex
                    justify="space-around"
                    direction="row"
                    alignItems="center"
                  >
                    <span className={classes.span}>
                      <span className={classes.size}>
                        {targetOrder.actualPrice || '--'}€/t{' '}
                      </span>
                      {t('prix actuel')}
                    </span>
                    <span className={classes.separator} />
                    <span className={classes.span}>
                      <span className={classes.size}>
                        {targetOrder.customerTargetPrice} €/t{' '}
                      </span>
                      {t('prix objectif')}
                    </span>
                  </Flex>
                </div>
              </div>
            )
          })}
        </CardContent>
      </Card>
      <Dialog
        open={cancelDialogOpen}
        onClose={onCancelClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            {t('Êtes-vous sûr de vouloir retirer cet ordre de vente ?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={onCancelClose}>
            {t('Annuler')}
          </Button>
          <Button color={'primary'} onClick={onCancel}>
            {t('Accepter')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TargetOrdersInProgress
