import isWithinInterval from 'date-fns/isWithinInterval'
import isEmpty from 'lodash/isEmpty'

import Campaign from '../models/Campaign'

export default (campaigns: Campaign[]): Campaign => {
  const result = campaigns.filter(campaign =>
    isWithinInterval(new Date(), {
      start: campaign.startDate,
      end: campaign.endDate,
    })
  )
  if (isEmpty(result)) {
    return campaigns[0]
  }
  return result.pop() as Campaign
}
