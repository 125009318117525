import memoize from 'memoize-one'
import * as React from 'react'

import Margin from '../../../components/Margin'
import OfferDisplayPreference from '../../../models/OfferDisplayPreference'
import Product from '../../../models/Product'

import CustomerSettingsProduct from './CustomerSettingsProduct'

export interface ExpandedSettings {
  [productName: string]: OfferDisplayPreference
}

interface Props {
  products: Product[]
  settings: ExpandedSettings
  onProductPreferenceChange?: (
    product: Product,
    preference: OfferDisplayPreference
  ) => void
}

class CustomerSettingsProducts extends React.Component<Props> {
  render() {
    const { products, settings, onProductPreferenceChange } = this.props
    const sortProducts = memoize((rawProducts: Product[]) => {
      return rawProducts.sort()
    })

    return (
      <>
        {sortProducts(products).map((product: Product, index: number) => (
          <Margin key={index} top={1.25} data-cy="settings">
            <CustomerSettingsProduct
              product={product}
              preference={settings[product.name]}
              onProductPreferenceChange={onProductPreferenceChange}
            />
          </Margin>
        ))}
      </>
    )
  }
}

export default CustomerSettingsProducts
