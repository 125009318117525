import axios from '../config/axios'
import Sector from '../models/Sector'

export const findAll = (all?: boolean) =>
  axios.get<Sector[]>(`api/sectors${all ? `?active=${all}` : ''}`)
export const findAllActives = () =>
  axios.get<Sector[]>('api/sectors?active=true')
export const updateSome = (sectors: Sector[]) =>
  axios.put<Sector[]>('api/sectors', sectors)
export const update = (sector: Sector) =>
  axios.put<Sector>('api/sectors/' + sector.id, sector)

export default { findAll, findAllActives, update, updateSome }
