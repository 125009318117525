import * as React from 'react'

import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Error from '../../../../components/Error'
import Fetcher from '../../../../components/Fetcher'
import Loading from '../../../../components/Loading'
import { DefaultMajorationTable } from '../../../../models/MajorationTable'
import TraderSettingsConfigCard from '../../../trader/Settings/TraderSettingsConfigCard'
import adviceService from '../../../../services/adviceService'
import majorationTableService from '../../../../services/majorationTableService'
import SuperAdminSettingsMajorationTableForm from './SuperAdminSettingsMajorationTableForm'

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    paddingRight: 15,
    width: '100%',
  },
}))

export const SuperAdminSettingsMajorationTable = () => {
  const classes = useStyles()

  const renderContent = (majorationTable: DefaultMajorationTable) => {
    return (
      <SuperAdminSettingsMajorationTableForm
        majorationTable={majorationTable}
      />
    )
  }

  return (
    <TraderSettingsConfigCard
      cardTitle={'Votre table de majorations par défaut'}
      adviceMessage={
        <Fetcher fetch={adviceService.findOne} fetchProps={['MAJORATION']}>
          {advice => (
            <div dangerouslySetInnerHTML={{ __html: advice.template }} />
          )}
        </Fetcher>
      }
    >
      <div className={classes.cardContent}>
        <Fetcher
          fetch={majorationTableService.findDefaultMajorationTable}
          loadingRender={Loading}
          errorRender={Error}
        >
          {renderContent}
        </Fetcher>
      </div>
    </TraderSettingsConfigCard>
  )
}

export default SuperAdminSettingsMajorationTable
