import React, { useContext, useState } from 'react'
import { makeStyles, Theme, Link } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import LockIcon from '@material-ui/icons/LockOutlined'
import { Link as RouterLink } from 'react-router-dom'

import { t } from '../i18n'
import * as authService from '../services/authService'
import ErrorContext from '../contexts/ErrorContext'

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}))

export const ForgotPassword = () => {
  const classes = useStyles()
  const errorContext = useContext(ErrorContext)

  const [success, setSuccess] = useState<boolean>(false)
  const [login, setLogin] = useState<string>('')

  const onSubmit = () => async (e: React.SyntheticEvent) => {
    e.preventDefault()

    try {
      await authService.resetPasswordInit(login)
      setSuccess(true)
    } catch (e) {
      errorContext.displayError(
        "Nous sommes désolés. Nous n'avons pas pu vous identifier avec les informations fournies."
      )
    }
  }

  const onChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement
    setLogin(target.value)
  }

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        {!success ? (
          <>
            <Typography variant="h5">
              {t('Vous avez oublié votre mot de passe ?')}
            </Typography>
            <form className={classes.form} onSubmit={onSubmit()}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="login">
                  {t('Identifiant de connexion')}
                </InputLabel>
                <Input
                  name="login"
                  type="text"
                  id="login"
                  autoComplete="login"
                  value={login}
                  onChange={onChange}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
              >
                {t('Valider')}
              </Button>
            </form>
          </>
        ) : (
          <>
            <Typography variant="h5">
              {t('Réinitialisation du mot de passe ?')}
            </Typography>
            <p>
              {t(
                'Un e-mail devrait vous parvenir, il contient des indications vous permettant de redéfinir votre mot de passe. Pensez à vérifier vos spams.'
              )}
            </p>
          </>
        )}
        <br />
        <Link color="textPrimary" component="div">
          <RouterLink to="/sign-in">
            {success ? t('Retour à la page de connexion') : t('Retour')}
          </RouterLink>
        </Link>
      </Paper>
    </main>
  )
}

export default ForgotPassword
