/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { SvgIcon } from '@material-ui/core'
import FormatAlignJustify from '@material-ui/icons/FormatAlignJustify'
import FormatAlignLeft from '@material-ui/icons/FormatAlignLeft'
import FormatAlignRight from '@material-ui/icons/FormatAlignRight'
import { TextAlignProperty } from 'csstype'
import {
  ContentBlock,
  ContentState,
  DraftEditorCommand,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js'
import { Options as ExportOptions, stateToHTML } from 'draft-js-export-html'
import { Options as ImportOptions, stateFromHTML } from 'draft-js-import-html'
import { Map } from 'immutable'
import FormatAlignCenter from 'mdi-material-ui/FormatAlignCenter'

/** This adds alignments to draftjs   */
/**                                   */
/**                                   */
/**                                   */

/** All alignments with their respective buttons */
export const alignment: Array<{
  style: TextAlignProperty
  icon: typeof SvgIcon
}> = [
  { style: 'left', icon: FormatAlignLeft },
  { style: 'center', icon: FormatAlignCenter },
  { style: 'right', icon: FormatAlignRight },
  { style: 'justify', icon: FormatAlignJustify },
]

const ALIGNMENT_DATA_KEY = 'textAlignment'
export const defaultAlignment = 'left'

const getCurrentlySelectedBlock = (editorState: EditorState) => {
  const selection = editorState.getSelection()
  const startKey = selection.getStartKey()
  let endKey = selection.getEndKey()
  const content = editorState.getCurrentContent()
  let target = selection

  // Triple-click can lead to a selection that includes offset 0 of the
  // following block. The `SelectionState` for this case is accurate, but
  // we should avoid toggling block type for the trailing block because it
  // is a confusing interaction.
  if (startKey !== endKey && selection.getEndOffset() === 0) {
    const blockBefore = content.getBlockBefore(endKey)
    if (!blockBefore) {
      throw new Error('Got unexpected null or undefined')
    }

    endKey = blockBefore.getKey()
    target = target.merge({
      anchorKey: startKey,
      anchorOffset: selection.getStartOffset(),
      focusKey: endKey,
      focusOffset: blockBefore.getLength(),
      isBackward: false,
    }) as SelectionState
  }

  const hasAtomicBlock = content
    .getBlockMap()
    .skipWhile((_, k) => k !== startKey)
    .takeWhile((_, k) => k !== endKey)
    .some(v => v!.getType() === 'atomic')

  const currentBlock = content.getBlockForKey(startKey)

  return {
    content,
    currentBlock,
    hasAtomicBlock,
    target,
  }
}

/** Use now "ExtendedRichUtils" instead of "RichUtils" in Editor */
const ExtendedRichUtils: any = {
  ...RichUtils,
  // Largely copied from RichUtils' `toggleBlockType`
  toggleAlignment(editorState: EditorState, alignmentDir: string) {
    const {
      content,
      currentBlock,
      hasAtomicBlock,
      target,
    } = getCurrentlySelectedBlock(editorState)

    if (hasAtomicBlock) {
      return editorState
    }

    const blockData = currentBlock.getData()
    const alignmentToSet =
      blockData && blockData.get(ALIGNMENT_DATA_KEY) === alignmentDir
        ? defaultAlignment
        : alignmentDir

    return EditorState.push(
      editorState,
      Modifier.mergeBlockData(
        content,
        target,
        Map([[ALIGNMENT_DATA_KEY, alignmentToSet]])
      ),
      'change-block-data'
    )
  },

  handleKeyCommand(
    editorState: EditorState,
    command: DraftEditorCommand | string
  ): any {
    return command === 'split-block'
      ? splitBlock(editorState)
      : RichUtils.handleKeyCommand(editorState, command)
  },
}

/**
 * An extension of the default split block functionality, originally pulled from
 * https://github.com/facebook/draft-js/blob/master/src/component/handlers/edit/commands/keyCommandInsertNewline.js
 *
 * This version ensures that the text alignment is copied from the previously selected block.
 */
const splitBlock = (editorState: EditorState): EditorState => {
  // Original split logic
  const contentState = Modifier.splitBlock(
    editorState.getCurrentContent(),
    editorState.getSelection()
  )
  const splitState = EditorState.push(editorState, contentState, 'split-block')

  // Assign alignment if previous block has alignment. Note that `currentBlock` is the block that was selected
  // before the split.
  const { currentBlock } = getCurrentlySelectedBlock(editorState)
  const alignmentDir = currentBlock.getData().get(ALIGNMENT_DATA_KEY)
  if (alignmentDir) {
    return ExtendedRichUtils.toggleAlignment(splitState, alignmentDir)
  } else {
    return splitState
  }
}

/** This function uses "vanilla" stateToHTML with alignment taken into account */
export const stateToHTMLWithAlignment = (
  editorCurrentContent: ContentState
) => {
  const options: ExportOptions = {
    blockStyleFn(block: ContentBlock) {
      if (block.getData().get(ALIGNMENT_DATA_KEY)) {
        return {
          style: {
            textAlign: block.getData().get(ALIGNMENT_DATA_KEY),
          },
        }
      }
      return {}
    },
  }
  return stateToHTML(editorCurrentContent, options)
}

/** This function uses "vanilla" stateFromHTML with alignment taken into account */
export const stateFromHTMLWithAlignment = (html: string) => {
  const options: ImportOptions = {
    // Should return null/undefined or an object with optional: type (string); data (plain object)
    customBlockFn: (element: any) => {
      return {
        data: {
          [ALIGNMENT_DATA_KEY]: element.style.textAlign || defaultAlignment,
        },
      }
    },
  }
  return stateFromHTML(html, options)
}

/** This function affects css classes for a block, for the corresponding alignment. Call this through Editor */
export const blockStyleFn = (contentBlock: any) => {
  const textAlignStyle = contentBlock.getData().get(ALIGNMENT_DATA_KEY)

  switch (textAlignStyle) {
    case 'right':
      return `align-right`
    case 'center':
      return `align-center`
    case 'left':
      return `align-left`
    case 'justify':
      return `align-justify`
    default:
      return ''
  }
}

export default ExtendedRichUtils
