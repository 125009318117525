import DateFnsUtils from '@date-io/date-fns'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import frLocale from 'date-fns/locale/fr'
import { create } from 'jss'
// @ts-ignore
import jssPreset from 'jss-preset-default'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import * as React from 'react'
import { JssProvider } from 'react-jss'
import CustomBrowserRouter from './Routes/CustomBrowserRouter'

import GlobalCss from './components/GlobalCss'
import { ErrorContextProvider } from './contexts/ErrorContext'
import { TimerContextProvider } from './contexts/TimerContext'
import { UserContextProvider } from './contexts/UserContext'
import './i18n/validations'
import Routes from './Routes'
import theme from './theme'

const jss = create(jssPreset())

const App = () => (
  <JssProvider jss={jss}>
    <ThemeProvider theme={theme}>
      <UserContextProvider>
        <ErrorContextProvider>
          <TimerContextProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
              <GlobalCss />
              <CssBaseline />
              <CustomBrowserRouter>
                <Routes />
              </CustomBrowserRouter>
            </MuiPickersUtilsProvider>
          </TimerContextProvider>
        </ErrorContextProvider>
      </UserContextProvider>
    </ThemeProvider>
  </JssProvider>
)

export default App
