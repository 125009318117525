import { Button, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import * as React from 'react'
import { Link } from 'react-router-dom'

import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Margin from '../../../components/Margin'
import { t } from '../../../i18n'
import campaignService from '../../../services/campaignService'
import executionModeService from '../../../services/executionModeService'
import productService from '../../../services/productService'
import sectorService from '../../../services/sectorService'
import strikeService from '../../../services/strikeService'

import TraderOffersCard from './TraderOffersCard'

class TraderHome extends React.Component<{}> {
  render() {
    return (
      <Fetcher
        fetch={[
          campaignService.findAllActive,
          productService.findAllWithNbOffers,
          sectorService.findAllActives,
          executionModeService.findActives,
          strikeService.findAll,
        ]}
      >
        {([campaigns, products, sectors, executionModes, strikes]) => (
          <>
            <Flex direction="row" alignItems="center">
              <Typography variant="h5">{t('Vos offres en cours')}</Typography>
              <Flex grow />
              <Margin right={1}>
                <Link to="/offers-list">
                  <Button color="primary">{t('Historique des offres')}</Button>
                </Link>
              </Margin>
              <Link to="/home/new-offer/step1">
                <Button color="primary" variant="contained">
                  <AddIcon />
                  {t('Créer une offre')}
                </Button>
              </Link>
            </Flex>
            <Margin top={2} bottom={2}>
              <TraderOffersCard
                products={products}
                sectors={sectors}
                campaigns={campaigns}
                executionModes={executionModes}
                strikes={strikes}
              />
            </Margin>
          </>
        )}
      </Fetcher>
    )
  }
}

export default TraderHome
