import {
  Button,
  createStyles,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import * as React from 'react'

import { t } from '../i18n'

import { ErrorRenderProps } from './Fetcher'
import Flex from './Flex'
import Margin from './Margin'

const styles = createStyles({
  root: {
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

type Props = ErrorRenderProps & WithStyles<typeof styles>

const Error: React.FunctionComponent<Props> = ({
  classes,
  children,
  refresh,
}) => (
  <Flex
    justify={'center'}
    alignItems={'center'}
    fullWidth={true}
    className={classes.root}
  >
    <Margin bottom={2}>
      <div className={classes.content}>
        <Margin right>
          <ErrorIcon color="error" />
        </Margin>
        <Typography variant="subtitle1">{children}</Typography>
      </div>
    </Margin>
    {refresh && (
      <div className={classes.content}>
        <Button variant="contained" color="primary" onClick={refresh}>
          {t('Réessayer')}
        </Button>
      </div>
    )}
  </Flex>
)

export default withStyles(styles)(Error)
