import { List } from '@material-ui/core'
import EuroSymbol from '@material-ui/icons/EuroSymbol'
import DashboardIcon from '@material-ui/icons/HomeOutlined'
import CustomersIcon from '@material-ui/icons/PersonOutline'
import SettingsIcon from '@material-ui/icons/Tune'
import ListOutline from '@material-ui/icons/ListOutlined'
import PlaylistAdd from '@material-ui/icons/PlaylistAdd'
import * as React from 'react'

import UserContext from '../../contexts/UserContext'
import { t } from '../../i18n'

import MenuItem from './MenuItem'

interface Props {
  open: boolean
}

class MenuItems extends React.Component<Props> {
  static contextType = UserContext

  render = () => {
    const { open } = this.props
    const { hasRole, hasAnyRole } = this.context

    return (
      <List>
        {hasRole!('ROLE_CUSTOMER') && (
          <>
            <MenuItem
              Icon={DashboardIcon}
              text={t('Offres')}
              href="/home"
              showTooltip={!open}
            />
            <MenuItem
              Icon={EuroSymbol}
              text={t('Liste des ventes')}
              href="/sales/list"
              showTooltip={!open}
            />
            <MenuItem
              Icon={PlaylistAdd}
              text={t('Ventes PMG')}
              href="/sales/pmg-list"
              showTooltip={!open}
            />
          </>
        )}
        {hasRole!('ROLE_SALESMAN') && (
          <>
            <MenuItem
              Icon={CustomersIcon}
              text={t('Clients')}
              href="/customers"
              showTooltip={!open}
            />
            <MenuItem
              Icon={EuroSymbol}
              text={t('Liste des ventes')}
              href="/sales/list"
              showTooltip={!open}
            />
            <MenuItem
              Icon={PlaylistAdd}
              text={t('Ventes PMG')}
              href="/sales/pmg-list"
              showTooltip={!open}
            />
          </>
        )}
        {hasRole!('ROLE_TRADER') && (
          <>
            <MenuItem
              Icon={DashboardIcon}
              text={t('Offres')}
              href="/home"
              showTooltip={!open}
            />
            <MenuItem
              Icon={CustomersIcon}
              text={t('Clients')}
              href="/customers"
              showTooltip={!open}
            />
            <MenuItem
              Icon={EuroSymbol}
              text={t('Liste des ventes')}
              href="/sales/list"
              showTooltip={!open}
            />
            <MenuItem
              Icon={PlaylistAdd}
              text={t('Ventes PMG')}
              href="/sales/pmg-list"
              showTooltip={!open}
            />
          </>
        )}
        {hasAnyRole!('ROLE_TRADER', 'ROLE_CUSTOMER', 'ROLE_SUPERADMIN') && (
          <>
            <MenuItem
              Icon={SettingsIcon}
              text={t('Paramétrage')}
              href="/settings"
              showTooltip={!open}
            />
          </>
        )}
        {hasRole!('ROLE_CONTROLLER') && (
          <>
            <MenuItem
              Icon={ListOutline}
              text={t('Ventes')}
              href="/data/transaction"
              showTooltip={!open}
            />
            <MenuItem
              Icon={ListOutline}
              text={t('Opérations')}
              href="/data/market-operation"
              showTooltip={!open}
            />
            <MenuItem
              Icon={ListOutline}
              text={t('Etat des stocks')}
              href="/data/inventory"
              showTooltip={!open}
            />
          </>
        )}
      </List>
    )
  }
}

export default MenuItems
