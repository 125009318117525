import { SortOrder } from '../components/DataTable'

// Ce que le back renvoie (l'objet n'est pas exhaustif mais le reste sert vraiment à rien je pense)
export type Page<T> = {
  totalPages: number
  last: boolean
  numberOfElements: number
  totalElements: number
  number: number
  sort: { sorted: boolean }
  first: false
  pageable: { pageNumber: number }
  content: T[]
}

// Utilisé côté front pour communiquer avec le back
export type Pageable = {
  page: number
  size: number
  sort?: string // sous la forme '[colonne],desc|asc'
  search?: string
}

const pageableToQuery = (pageable: Pageable) =>
  `page=${pageable.page}&size=${pageable.size}${
    pageable.sort ? '&sort=' + pageable.sort : ''
  }${
    pageable.search && pageable.search !== ''
      ? '&search=' + pageable.search
      : ''
  }`

const MuiStateToPageable = (
  sortOrder: SortOrder,
  page?: number,
  size?: number,
  columnConverter?: { [columnKey: string]: string },
  search?: string
) => ({
  page: page || 0,
  size: size || 10,
  sort:
    sortOrder &&
    sortOrder.name &&
    `${columnConverter ? columnConverter[sortOrder.name] : sortOrder.name},${
      sortOrder.direction
    }`,
  search,
})

export default {
  pageableToQuery,
  MuiStateToPageable,
}
