const commons = [
  'Logo',
  'NomClient',
  'Civilité',
  'Adresse1',
  'Adresse2',
  'CP',
  'Ville',
  'NumeroClient',
  'SecteurClient',
  'NomNegociant',
]
const mailCommons = [...commons, 'IdentifiantConnexion']
export const Registeration = [...mailCommons, 'LienDeReinitialisation']
export const ResetPassword = [...mailCommons, 'LienDeReinitialisation']
export const TargetOrder = [
  ...mailCommons,
  'NomSouscripteur',
  'DateHeureOrdre',
  'DatePaiementOrdre',
  'Campagne',
  'NomProduit',
  'Tonnage',
  'ModaliteExecution',
  'DateExecution',
  'PrixObjectif',
  'PrixTotal',
  'PrimeFinanciere',
  'PrimeDepartDirect',
  'LienDeConnexion',
]
export const TransactionContract = [
  ...commons,
  'NomSouscripteur',
  'DateHeureVente',
  'DatePaiementVente',
  'Campagne',
  'NomProduit',
  'NumeroContrat',
  'Tonnage',
  'ModaliteExecution',
  'DateExecution',
  'PrixNet',
  'FraisStockage',
  'PrixBrut',
  'PrixTotal',
  'PrimeFinanciere',
  'PrimeDepartDirect',
  'Strike',
  'ComplementPrix',
]
export const TransactionMail = [
  ...TransactionContract,
  ...mailCommons.filter(item => !commons.includes(item)),
  'LienDeConnexion',
]
export const Error = [
  'Erreur',
  'LienDeConnexion',
  'NumeroContrat',
  'Operateur',
  'Client',
  'Campagne',
  'NomProduit',
  'Tonnage',
  'PrixNet',
  'PrixBrut',
  'ModaliteExecution',
  'DateExecution',
  'CustomerTargetPrice',
  'InstrumentTargetPrice',
]
export const TraderError = [...Error]

const TraderExportContracts = ['LienDeConnexion']

export default {
  Registeration,
  ResetPassword,
  TransactionContract,
  TransactionMail,
  Error,
  TraderError,
  TargetOrder,
  TraderExportContracts,
}
