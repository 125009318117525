import React, { useEffect, useState, useContext, useRef } from 'react'

import { makeStyles } from '@material-ui/styles'
import AccessTime from '@material-ui/icons/AccessTime'
import classNames from 'classnames'
import TimerContext from '../../contexts/TimerContext'

const useStyles = makeStyles(() => ({
  timer: {
    backgroundColor: '#1ebc97',
    padding: '1rem',
    width: '100%',
    fontSize: '12.8px',
    color: '#ffffffff',
    position: 'fixed',
    top: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    zIndex: 3000,
  },
  timerWillExpire: {
    backgroundColor: '#d88c00',
  },
  timerError: {
    backgroundColor: '#e65b60',
  },
  textContent: {
    marginLeft: '1rem',
  },
}))

type Countdown = {
  minutes: number
  seconds: number
}

export const Timer = () => {
  const { endDate } = useContext(TimerContext)
  const intervalId = useRef<NodeJS.Timeout | undefined>(undefined)
  const classes = useStyles()

  const [countdown, setCountdown] = useState<Countdown | undefined>(undefined)

  useEffect(() => {
    const calculateCountdown = (endDate: number): Countdown => {
      const timeLeft = { minutes: 0, seconds: 0 }
      let diff = Math.trunc(endDate - Date.now() / 1000)
      // clear countdown when date is reached
      if (diff <= -1) {
        return timeLeft
      }
      // calculate time difference between now and expected date
      if (diff >= 60) {
        timeLeft.minutes = Math.floor(diff / 60)
        diff -= timeLeft.minutes * 60
      }
      timeLeft.seconds = diff
      return timeLeft
    }
    const clearCountdown = () => {
      if (intervalId.current) {
        clearInterval(intervalId.current)
      }
    }
    clearCountdown()
    if (endDate !== undefined) {
      setCountdown(calculateCountdown(endDate))
      intervalId.current = setInterval(() => {
        setCountdown(calculateCountdown(endDate))
      }, 1000)
    }
    return () => {
      clearCountdown()
    }
  }, [endDate])

  const addLeadingZeros = (value: number): string => {
    let stringValue = String(value)
    while (stringValue.length < 2) {
      stringValue = '0' + stringValue
    }
    return stringValue
  }

  if (countdown === undefined) {
    return null
  }
  const timerHasReachedEnd = countdown.seconds === 0 && countdown.minutes === 0
  const classTimer = classNames(classes.timer, {
    [classes.timerWillExpire]: countdown.minutes < 1 && countdown.seconds < 30,
    [classes.timerError]: countdown.minutes < 1 && countdown.seconds < 1,
  })
  return (
    <div className={classTimer}>
      <AccessTime />
      <span className={classes.textContent}>
        {!timerHasReachedEnd
          ? `Temps restant: ${addLeadingZeros(
              countdown.minutes
            )}m${addLeadingZeros(countdown.seconds)}s`
          : `Temps écoulé, veuillez recommencer la saisie du formulaire`}
      </span>
    </div>
  )
}

export default Timer
