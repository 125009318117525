import memoize from 'memoize-one'
import * as React from 'react'

import ExecutionMode from '../../models/ExecutionMode'
import OffersPricesView from '../../models/OffersPricesView'
import Sector from '../../models/Sector'

import { CalendarContext } from './Calendar'
import CalendarReadOnlyLine from './CalendarReadOnlyLine'

interface Props {
  sector: Sector
  executionModes: ExecutionMode[]
  calendar: OffersPricesView
  onClick?: (executionMode: ExecutionMode, month: string) => void
}

class CustomerCalendarReadOnlyLines extends React.Component<Props> {
  getValues = memoize(
    (
      sector: Sector,
      executionMode: ExecutionMode,
      months: string[],
      calendar: OffersPricesView
    ) => {
      return months.map(month =>
        calendar.executionModePerSector[sector.id] &&
        calendar.executionModePerSector[sector.id][executionMode.id] &&
        calendar.executionModePerSector[sector.id][executionMode.id][month]
          ? calendar.executionModePerSector[sector.id][executionMode.id][month]
          : null
      )
    }
  )

  handleClick = (executionMode: ExecutionMode) => (month: string) => {
    const { onClick } = this.props
    if (onClick) {
      onClick(executionMode, month)
    }
  }

  render() {
    const { sector, executionModes, calendar, onClick } = this.props

    return (
      <tbody>
        <CalendarContext.Consumer>
          {({ months }) => (
            <>
              {executionModes.map((executionMode, index) => (
                <CalendarReadOnlyLine
                  key={executionMode.id}
                  title={executionMode.name}
                  values={this.getValues(
                    sector,
                    executionMode,
                    months,
                    calendar
                  )}
                  dark={index % 2 === 0}
                  tooltip={executionMode.definition}
                  onClick={onClick && this.handleClick(executionMode)}
                  forcedColor="#1ebc97"
                />
              ))}
            </>
          )}
        </CalendarContext.Consumer>
      </tbody>
    )
  }
}

export default CustomerCalendarReadOnlyLines
