import {
  Chip,
  createStyles,
  IconButton,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import FormatListBulleted from '@material-ui/icons/FormatListBulleted'
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined'
import { TextAlignProperty } from 'csstype'
import {
  DraftEditorCommand,
  Editor as DraftEditor,
  EditorState,
  Modifier,
} from 'draft-js'
import FormatHeader1Icon from 'mdi-material-ui/FormatHeader1'
import FormatHeader2Icon from 'mdi-material-ui/FormatHeader2'
import FormatHeader3Icon from 'mdi-material-ui/FormatHeader3'
import FormatHeader4Icon from 'mdi-material-ui/FormatHeader4'
import FormatHeader5Icon from 'mdi-material-ui/FormatHeader5'
import FormatHeader6Icon from 'mdi-material-ui/FormatHeader6'
import * as React from 'react'

import ExtendedRichUtils, {
  alignment,
  blockStyleFn,
  defaultAlignment,
  stateFromHTMLWithAlignment,
  stateToHTMLWithAlignment,
} from '../../config/draftjs'
import { t } from '../../i18n'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    left: {
      display: 'flex',
      flexDirection: 'column',
      flex: 2,
      marginRight: theme.spacing(1),
    },
    right: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      marginLeft: theme.spacing(1) - theme.spacing(1 / 2),
    },
    actionsContainer: {
      marginBottom: theme.spacing(1),
    },
    editorContainer: {
      flex: 1,
      padding: theme.spacing(2),
      ...theme.typography.body1,
      alignSelf: 'stretch',
      overflowY: 'auto',
      '& .DraftEditor-root, & .DraftEditor-editorContainer, & .public-DraftEditor-content': {
        height: '100%',
      },
    },
    chipsTitle: {
      marginLeft: theme.spacing(1 / 2),
      lineHeight: `${48 - theme.spacing(1 / 2)}px`,
      height: 48 - theme.spacing(1 / 2),
    },
    chip: {
      margin: theme.spacing(1 / 2),
      borderRadius: 5,
    },
  })

const inlines = [
  { style: 'BOLD', icon: FormatBoldIcon },
  { style: 'ITALIC', icon: FormatItalicIcon },
  { style: 'UNDERLINE', icon: FormatUnderlinedIcon },
]

const blocks = [
  { icon: FormatHeader1Icon, style: 'header-one' },
  { icon: FormatHeader2Icon, style: 'header-two' },
  { icon: FormatHeader3Icon, style: 'header-three' },
  { icon: FormatHeader4Icon, style: 'header-four' },
  { icon: FormatHeader5Icon, style: 'header-five' },
  { icon: FormatHeader6Icon, style: 'header-six' },
  { icon: FormatListBulleted, style: 'unordered-list-item' },
]

interface Props extends WithStyles<typeof styles> {
  defaultValue: string
  onChange: (newContractTemplate: string) => void
  tags: string[]
}

interface State {
  editorState: EditorState
}

class Editor extends React.Component<Props, State> {
  private readonly editor: React.RefObject<DraftEditor> = React.createRef()

  constructor(props: Props) {
    super(props)
    this.state = {
      editorState: props.defaultValue
        ? EditorState.createWithContent(
            stateFromHTMLWithAlignment(props.defaultValue)
          )
        : EditorState.createEmpty(),
    }
  }

  onChange = (editorState: EditorState) => {
    this.setState({ editorState })
    this.props.onChange(
      stateToHTMLWithAlignment(editorState.getCurrentContent())
    )
  }

  handleKeyCommand = (
    command: DraftEditorCommand,
    editorState: EditorState
  ) => {
    const newState = ExtendedRichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  handleAlignmentCommand = (style: TextAlignProperty) => (
    event: React.MouseEvent
  ) => {
    event.preventDefault()
    this.onChange(
      ExtendedRichUtils.toggleAlignment(this.state.editorState, style)
    )
  }

  handleBlockCommand = (style: string) => (event: React.MouseEvent) => {
    event.preventDefault()
    this.onChange(
      ExtendedRichUtils.toggleBlockType(this.state.editorState, style)
    )
  }

  handleInlineCommand = (style: string) => (event: React.MouseEvent) => {
    event.preventDefault()
    this.onChange(
      ExtendedRichUtils.toggleInlineStyle(this.state.editorState, style)
    )
  }

  handleChipClicked = (tag: string) => () => {
    const { editorState } = this.state
    const selection = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const ncs = Modifier.insertText(contentState, selection, `{{${tag}}}`)
    this.setState(
      { editorState: EditorState.push(editorState, ncs, 'insert-fragment') },
      () => {
        if (this.editor.current) {
          this.editor.current.focus()
        }
      }
    )
  }

  render() {
    const { classes, tags } = this.props
    const { editorState } = this.state
    const selection = editorState.getSelection()
    const currentBlockStyle = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType()
    const currentInlineStyles = editorState.getCurrentInlineStyle()
    const currentAlignStyle: TextAlignProperty =
      editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getData()
        .get('textAlignment') || defaultAlignment

    return (
      <div className={classes.root}>
        <div className={classes.left}>
          <div className={classes.actionsContainer}>
            {blocks.map(({ style, icon: BlockIcon }, index) => (
              <IconButton
                key={index}
                color={currentBlockStyle === style ? 'primary' : undefined}
                onMouseDown={this.handleBlockCommand(style)}
              >
                <BlockIcon />
              </IconButton>
            ))}
            {inlines.map(({ style, icon: InlineIcon }, index) => (
              <IconButton
                key={index}
                color={currentInlineStyles.has(style) ? 'primary' : undefined}
                onMouseDown={this.handleInlineCommand(style)}
              >
                <InlineIcon />
              </IconButton>
            ))}
            {alignment.map(({ style, icon: AlignmentIcon }, index) => (
              <IconButton
                key={index}
                color={currentAlignStyle === style ? 'primary' : undefined}
                onMouseDown={this.handleAlignmentCommand(style)}
              >
                <AlignmentIcon />
              </IconButton>
            ))}
          </div>
          <Paper className={classes.editorContainer}>
            <DraftEditor
              ref={this.editor}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              onChange={this.onChange}
              blockStyleFn={blockStyleFn}
            />
          </Paper>
        </div>
        <div className={classes.right}>
          <Typography variant="h5" gutterBottom className={classes.chipsTitle}>
            {t('Liste de vos variables')}
          </Typography>
          <div>
            {tags.map(tag => (
              <Chip
                key={tag}
                label={tag}
                onClick={this.handleChipClicked(tag)}
                className={classes.chip}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Editor)
