import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import UserIcon from 'mdi-material-ui/AccountCircleOutline'
import React, { useState } from 'react'

import { t } from '../../i18n'
import authService from '../../services/authService'

export const ControllerMenu = () => {
  const [anchorEl, setAnchorEl] = useState<Optional<Element>>()

  const handleUserMenuOpen = (event: React.MouseEvent) =>
    setAnchorEl(event.currentTarget)

  const handleUserMenuClose = () => setAnchorEl(undefined)

  const handleLogout = () => {
    handleUserMenuClose()
    sessionStorage.clear()
    authService.logout()
  }

  const userMenuOpened = Boolean(anchorEl)

  return (
    <>
      <IconButton
        aria-owns={userMenuOpened ? 'user-menu' : undefined}
        aria-haspopup="true"
        onClick={handleUserMenuOpen}
      >
        <UserIcon />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={userMenuOpened}
        onClose={handleUserMenuClose}
      >
        <MenuItem onClick={handleLogout}>{t('Déconnexion')}</MenuItem>
      </Menu>
    </>
  )
}

export default ControllerMenu
