import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import * as React from 'react'

import { CalendarContext } from './Calendar'

const styles = (theme: Theme) =>
  createStyles({
    tr: {},
    th: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  })

type Props = WithStyles<typeof styles> & {}

class CalendarSeparator extends React.Component<Props> {
  render() {
    const { classes, children } = this.props
    return (
      <CalendarContext.Consumer>
        {({ months }) => (
          <tr className={classes.tr}>
            <th className={classes.th} colSpan={months!.length + 1}>
              {children}
            </th>
          </tr>
        )}
      </CalendarContext.Consumer>
    )
  }
}

export default withStyles(styles)(CalendarSeparator)
