import {
  createStyles,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import * as React from 'react'
import { Link } from 'react-router-dom'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 18,
      padding: 0,
      width: '100%',
      minHeight: 100,
      height: 100,
      maxHeight: 150,
      shadow: 1,
      '&:hover': {
        opacity: 0.8,
      },
    },
    cardContent: {
      padding: 0,
    },
    title: {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      fontSize: 13.5,
      padding: theme.spacing(2, 1),
    },
    content: {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      fontSize: 12,
      minHeight: 80,
      padding: 10,
      height: 80,
    },
  })

interface Props extends WithStyles<typeof styles> {
  title: string
  subtitle: string
  link: string
}

class SuperAdminSettingsCard extends React.Component<Props> {
  render() {
    const { classes, title, subtitle, link } = this.props

    return (
      <Link to={link} data-cy={'superAdminSettingCard'}>
        <Card className={classes.root}>
          <CardContent className={classes.cardContent}>
            <Typography
              data-cy={'cardTitle'}
              className={classes.title}
              variant="button"
              display="block"
            >
              {title}
            </Typography>
            <Typography
              className={classes.content}
              variant="caption"
              display="block"
            >
              {subtitle}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    )
  }
}

export default withStyles(styles)(SuperAdminSettingsCard)
