import React from 'react'
import { Typography } from '@material-ui/core'
import { capitalize } from '@material-ui/core/utils/helpers'

import { t } from '../../../../i18n'
import { format } from '../../../../i18n/format'
import { TransactionListItemView } from '../../../../services/transactionService'
import ControllerDataTransactionList from './ControllerDataTransactionList'

const ControllerDataTransaction = () => {
  const getTableColumns = () => [
    t('Client'),
    t('Numéro de contrat'),
    t('Date de la vente'),
    t('Produit'),
    t('Campagne'),
    t('Tonnage'),
    t("Modalités d'exécution"),
    t('Prix (€/t)'),
    t('Prix total (€)'),
    t("Date d'exécution"),
    t('Date de paiement'),
    t('Différé de paiement (€/t)'),
    t('Manoeuvre appliquée (€/t)'),
    t('Frais de stockage (€/t)'),
    t('Prix brut (€/t)'),
    t('Prix calculé net (€/t)'),
    t('Prix calculé brut (€/t)'),
    t('Prix calculé frais de stockage (€/t)'),
    t('Frais de teneur en huile (€)'),
    t('Frais de livraison (€)'),
    t('Majoration de stockage (€)'),
    t('Frais de départ boisseau (€)'),
    t('Manoeuvre commerciale (€)'),
    t('Couverture (t)'),
    t('Opération(s) liée(s)'),
  ]

  const dataToTable = (transaction: TransactionListItemView) => [
    capitalize(transaction.customerSocialReason),
    transaction.contractId,
    transaction.creationDate,
    capitalize(transaction.productName),
    transaction.campaignYear,
    transaction.tonnage,
    capitalize(transaction.executionMode),
    transaction.price,
    transaction.totalPrice,
    format(transaction.executionDate, 'dd/MM/yyyy'),
    format(transaction.paymentDate, 'dd/MM/yyyy'),
    transaction.deferredPaymentFee,
    transaction.appliedManeuver,
    transaction.storageFee,
    transaction.rawPrice,
    transaction.computedPrice,
    transaction.computedRawPrice,
    transaction.computedStorageFee,
    transaction.oilBonus,
    transaction.deliveryCost,
    transaction.majorationFee,
    transaction.bushelDepartureBonus,
    transaction.commercialManeuver,
    transaction.totalTonsCovered || 0,
    transaction.coverageOperations.map(oc => oc.code).join(', '),
  ]

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t('Listes des données sur les ventes')}
      </Typography>
      <ControllerDataTransactionList
        columns={getTableColumns()}
        tableDataMapper={dataToTable}
        title=""
      />
    </>
  )
}

export default ControllerDataTransaction
