import axios from '../config/axios'

export const importCsv = (csv: File) => {
  const formData = new FormData()
  formData.append('csv', csv)
  return axios.post<string>('api/contributions/import', formData, {
    timeout: 60 * 60 * 1000,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export default { importCsv }
