import { createStyles, Omit, WithStyles, withStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton/IconButton'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Create'
import DuplicateIcon from '@material-ui/icons/FileCopyOutlined'
import endOfMonth from 'date-fns/endOfMonth'
import * as React from 'react'
import { format } from 'date-fns'

import Campaign from '../../../../models/Campaign'
import MajorationTable, {
  NewMajorationTable,
} from '../../../../models/MajorationTable'
import majorationTableService from '../../../../services/majorationTableService'

import TraderSettingsMajorationTablesDialog from './TraderSettingsMajorationTablesDialog'

const styles = () =>
  createStyles({
    list: {
      width: '100%',
    },
  })

interface Props extends WithStyles<typeof styles> {
  majorationTables: MajorationTable[]
  campaign: Campaign
  refresh: () => void
}

type ModalType = 'DUPLICATE' | 'EDIT' | 'ADD'

interface State {
  open: boolean
  modalType?: ModalType
  currentMajorationTable?: NewMajorationTable
}

class TraderSettingsMajorationTablesList extends React.Component<Props, State> {
  state: State = {
    open: false,
  }

  handleClose = () => {
    this.setState({
      open: false,
      modalType: undefined,
      currentMajorationTable: undefined,
    })
  }

  handleSave = async (majorationTable: NewMajorationTable) => {
    this.setState({
      open: false,
      modalType: undefined,
      currentMajorationTable: undefined,
    })
    switch (this.state.modalType) {
      case 'EDIT':
        await majorationTableService.update(majorationTable as MajorationTable)
        break
      case 'DUPLICATE':
      case 'ADD':
        await majorationTableService.create(majorationTable)
        break
    }
    this.props.refresh()
  }

  handleEdit = (majorationTable: MajorationTable) => () => {
    this.setState({
      open: true,
      modalType: 'EDIT',
      currentMajorationTable: majorationTable,
    })
  }

  handleDuplicate = (majorationTable: MajorationTable) => () => {
    this.setState({
      open: true,
      modalType: 'DUPLICATE',
      currentMajorationTable: majorationTable,
    })
  }

  handleAdd = () => {
    const { campaign } = this.props
    const newMajorationTable: Omit<MajorationTable, 'id' | 'editable'> = {
      name: '',
      campaign,
      majorationValues: [
        {
          startDate: format(campaign.startDate, 'yyyy-MM-dd'),
          endDate: format(endOfMonth(campaign.startDate), 'yyyy-MM-dd'),
          value: 0,
        },
      ],
    }
    this.setState({
      open: true,
      modalType: 'ADD',
      currentMajorationTable: newMajorationTable,
    })
  }

  getSubmitLabel: () => string = () => {
    switch (this.state.modalType) {
      case 'DUPLICATE':
        return 'Dupliquer'
      case 'ADD':
        return 'Ajouter'
      case 'EDIT':
        return 'Éditer'
    }
    return ''
  }

  render() {
    const { classes, majorationTables } = this.props
    const { open, currentMajorationTable } = this.state

    return (
      <>
        <List className={classes.list}>
          {majorationTables.map(majorationTable => (
            <ListItem key={majorationTable.id} role={undefined} dense button>
              <ListItemText primary={majorationTable.name} />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="Edit"
                  onClick={this.handleEdit(majorationTable)}
                >
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton
                  aria-label="Duplicate"
                  onClick={this.handleDuplicate(majorationTable)}
                >
                  <DuplicateIcon color="primary" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <IconButton aria-label="Add" onClick={this.handleAdd}>
          <AddIcon color="primary" fontSize="small" />
        </IconButton>
        <TraderSettingsMajorationTablesDialog
          onClose={this.handleClose}
          majorationTable={currentMajorationTable}
          open={open}
          onSave={this.handleSave}
          submitLabel={this.getSubmitLabel()}
        />
      </>
    )
  }
}

export default withStyles(styles)(TraderSettingsMajorationTablesList)
