import React, { useState } from 'react'
import { Button, Dialog } from '@material-ui/core'
import capitalize from 'lodash/capitalize'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Link } from 'react-router-dom'

import { KeyboardArrowLeft } from '@material-ui/icons'
import Fetcher from '../../../../components/Fetcher'
import SalesPmgList from '../../../../components/SalesPmgList'
import { t } from '../../../../i18n'
import { format } from '../../../../i18n/format'
import contractPMGService, {
  ContractPmgListItemView,
} from '../../../../services/contractPMGService'
import SalesListDetails from '../../../../components/Sale/SalesListDetails'

export const TraderPmgClosedSalesList = () => {
  const [showDetails, setShowDetails] = useState<
    Optional<ContractPmgListItemView>
  >()

  const fetchContractPMG = () => {
    return contractPMGService.findAll(['FIXED', 'EXPIRED'])
  }
  const getTableColumns = (): Array<string | MUIDataTableColumnDef> => [
    t('Client'),
    t('Numéro contrat'),
    t('Date de la vente'),
    t('Produit'),
    t('Campagne'),
    { name: 'tons', label: t('Tonnage'), options: { filter: false } },
    t('Modalité'),
    { name: 'price', label: t('Prix PMG (€/t)'), options: { filter: false } },
    t('Statut'),
    t('Complément (€/t)'),
  ]

  const contractPMGToTableData = React.useCallback(
    (contractPMG: ContractPmgListItemView) => [
      capitalize(contractPMG.customerSocialReason),
      contractPMG.contractId,
      format(contractPMG.creationDate, 'dd/MM/yyyy'),
      capitalize(contractPMG.productName),
      contractPMG.campaignYear,
      contractPMG.tonnage,
      capitalize(contractPMG.executionMode),
      contractPMG.price,
      contractPMG.supplement ? 'Fixé' : 'Expiré',
      contractPMG.supplement ? contractPMG.supplement : '/',
    ],
    []
  )

  const handlePreview = (value: ContractPmgListItemView) => {
    setShowDetails(value)
  }

  const handleCloseDetails = () => {
    setShowDetails(undefined)
  }

  return (
    <>
      <Link to={'/sales/pmg-list'}>
        <Button color="primary">
          <KeyboardArrowLeft />
          {t('Retour à la liste des contrats')}
        </Button>
      </Link>
      <Fetcher fetch={fetchContractPMG}>
        {(contractsPmg, refresh) => (
          <SalesPmgList
            contractsPmg={contractsPmg}
            columns={getTableColumns()}
            tableDataMapper={contractPMGToTableData}
            refresh={refresh}
            handlePreview={handlePreview}
            status="CLOSED"
          />
        )}
      </Fetcher>
      {showDetails && (
        <Dialog
          onClose={handleCloseDetails}
          open={true}
          maxWidth="lg"
          fullWidth
        >
          <SalesListDetails transaction={showDetails} />
        </Dialog>
      )}
    </>
  )
}

export default TraderPmgClosedSalesList
