import * as Yup from 'yup'

export default () =>
  Yup.object().shape({
    rawPrice: Yup.number().required(),
    storageFee: Yup.number()
      .nullable(true)
      .notRequired(),
    netPrice: Yup.number().required(),
  })
