import {
  createStyles,
  Typography,
  WithStyles,
  withStyles,
  Theme,
} from '@material-ui/core'
import Tab from '@material-ui/core/Tab/Tab'
import Tabs from '@material-ui/core/Tabs/Tabs'
import * as React from 'react'

import Flex from '../../../components/Flex'
import Margin from '../../../components/Margin'
import { t } from '../../../i18n'
import Campaign from '../../../models/Campaign'
import OfferDisplayPreference from '../../../models/OfferDisplayPreference'
import OfferDisplaySetting from '../../../models/OfferDisplaySetting'
import Product from '../../../models/Product'
import getCurrentCampaign from '../../../utils/getCurrentCampaign'
import sortCampaigns from '../../../utils/sortCampaigns'

import CustomerSettingsProducts, {
  ExpandedSettings,
} from './CustomerSettingsProducts'

const styles = (theme: Theme) =>
  createStyles({
    title: {
      marginRight: 20,
    },
    rootLabel: {
      width: 80,
      minWidth: 80,
      maxWidth: 80,
    },
    flex: {
      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column',
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  campaigns: Campaign[]
  products: Product[]
  initialSettings: OfferDisplaySetting[]
  onSettingsChange?: (settings: OfferDisplaySetting[]) => void
}

interface State {
  currentCampaign: Campaign
  settings: OfferDisplaySetting[]
}

class CustomerSettingsCampaigns extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      currentCampaign: getCurrentCampaign(sortCampaigns(this.props.campaigns)),
      settings: props.initialSettings,
    }
  }

  onTabChange = (event: React.ChangeEvent<{}>, value: Campaign) => {
    this.setState({ currentCampaign: value })
  }

  onProductPreferenceChange = (
    product: Product,
    preference: OfferDisplayPreference
  ) => {
    const { settings, currentCampaign } = this.state
    const productSettings = settings.filter(
      (setting: OfferDisplaySetting) =>
        setting.campaign.id === currentCampaign.id &&
        setting.product.id === product.id
    )
    if (productSettings.length === 1) {
      productSettings[0].preference = preference
    } else {
      settings.push({ campaign: currentCampaign, product, preference })
    }
    this.setState({ settings })

    const { onSettingsChange } = this.props
    if (onSettingsChange) {
      onSettingsChange(settings)
    }
  }

  expandSettings = (
    settings: OfferDisplaySetting[],
    campaign: Campaign,
    products: Product[]
  ): ExpandedSettings => {
    return products.reduce(
      (expandedSettings: ExpandedSettings, product: Product) => {
        const productSettings = settings.filter(
          (setting: OfferDisplaySetting) =>
            setting.campaign.id === campaign.id &&
            setting.product.id === product.id
        )
        expandedSettings[product.name] =
          productSettings.length === 1
            ? productSettings[0].preference
            : OfferDisplayPreference.Sell
        return expandedSettings
      },
      {}
    )
  }

  render() {
    const { classes, campaigns, products } = this.props
    const { currentCampaign, settings } = this.state

    const expandedSettings = this.expandSettings(
      settings,
      currentCampaign,
      products
    )

    return (
      <>
        <Flex direction="row" alignItems="center" className={classes.flex}>
          <Typography className={classes.title}>{t('Campagne')}</Typography>
          <Tabs
            value={currentCampaign}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.onTabChange}
            data-cy="campaigns"
          >
            {campaigns.map((campaign: Campaign, index: number) => (
              <Tab
                key={index}
                label={campaign.name}
                value={campaign}
                classes={{ root: classes.rootLabel }}
              />
            ))}
          </Tabs>
        </Flex>
        <Margin top={2.5}>
          <CustomerSettingsProducts
            products={products}
            settings={expandedSettings}
            onProductPreferenceChange={this.onProductPreferenceChange}
          />
        </Margin>
      </>
    )
  }
}

export default withStyles(styles)(CustomerSettingsCampaigns)
