import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import * as React from 'react'

import Poster from '../../../components/Poster'
import DeliverySite from '../../../models/DeliverySite'
import deliverySiteService from '../../../services/deliverySiteService'

interface Props {
  open: boolean
  onClose: () => any
  onSave: (deliverySite: DeliverySite) => any
}

interface State {
  deliverySiteName: string
}

class CreateNewDeliverySiteDialog extends React.Component<Props, State> {
  state: State = {
    deliverySiteName: '',
  }

  onSubmit = async () => {
    const { onSave } = this.props
    const { deliverySiteName } = this.state

    const deliverySite = await deliverySiteService.create(deliverySiteName)

    onSave(deliverySite.data)
  }

  onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      deliverySiteName: event.target.value,
    })
  }

  render() {
    const { open, onClose } = this.props
    const { deliverySiteName } = this.state

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Quel est le nom du nouveau lieu ?</DialogTitle>
        <DialogContent>
          <TextField
            value={deliverySiteName}
            name="name"
            onChange={this.onNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={onClose}>
            Annuler
          </Button>
          <Poster
            buttonText={'Ajouter'}
            variant={'text'}
            errorInSnackBar={true}
            message={
              'Une erreur est survenue. Veuillez vérifier que le nom entré ne soit pas déjà existant puis réessayez.'
            }
            onSubmit={this.onSubmit}
          />
        </DialogActions>
      </Dialog>
    )
  }
}

export default CreateNewDeliverySiteDialog
