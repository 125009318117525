import { Button, CardContent } from '@material-ui/core'
import Card from '@material-ui/core/Card/Card'
import { WithStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import { AxiosError } from 'axios'
import Calendar from '../../../components/Calendar/Calendar'
import CalendarHeader from '../../../components/Calendar/CalendarHeader'
import TraderCalendarReadOnlyLines from '../../../components/Calendar/TraderCalendarReadOnlyLines'
import CalendarError from '../../../components/CalendarError'
import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Loading from '../../../components/Loading'
import Margin from '../../../components/Margin'
import OfferRecapData from '../../../components/OfferRecapData'
import Poster from '../../../components/Poster'
import { t } from '../../../i18n'
import Offer from '../../../models/Offer'
import executionModeService from '../../../services/executionModeService'
import offerService from '../../../services/offerService'
import sectorService from '../../../services/sectorService'

import { formToOffer } from './FormValues'
import StrikesTabs from '../../../components/Calendar/StrikesTabs'
import StrikeSetting, { StrikeValue } from '../../../models/StrikeSetting'
import OffersPricesView from '../../../models/OffersPricesView'
import strikeService from '../../../services/strikeService'

const styles = () =>
  createStyles({
    card: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 15,
      marginBottom: 15,
    },
    cardContentColored: {
      width: '33%',
    },
    cardContent: {
      flex: '1 1',
      display: 'flex',
      flexDirection: 'column',
    },
    fullHeight: {
      height: '100%',
    },
  })

interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

interface State {
  offer?: Offer
  calendar?: OffersPricesView
  strikes: StrikeSetting[]
  calendarError?: AxiosError
}

class NewOfferRecap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      offer: undefined,
      calendar: undefined,
      calendarError: undefined,
      strikes: [],
    }
  }

  componentDidMount() {
    if (!this.props.location.state || !this.props.location.state.formOffer) {
      this.props.history.replace({ pathname: '/home/new-offer/step1' })
      return
    }

    const offer = formToOffer(this.props.location.state.formOffer)

    this.setState({ offer }, () => this.fetchNewOfferCalendar())
    if (offer.modePMG) {
      this.getStrikes()
    }
  }

  getStrikes = async () => {
    const { data: strikes } = await strikeService.findAll()
    this.setState({ strikes })
  }

  onPublish = async () => {
    const { history } = this.props
    const { offer } = this.state
    await offerService.create(offer!)
    history.replace('/home')
  }

  onError = (err: string): string => {
    let messageError = 'Une erreur est survenue. Veuillez réessayer.'

    if (err.toString().includes('409')) {
      messageError =
        "Impossible d'enregistrer l'offre, une autre offre existe déjà pour les mêmes campagne + produit + modalité d'exécution + période d'exécution. Si le problème persiste, contactez un administrateur."
    }

    if (err.toString().includes('406')) {
      messageError =
        "Impossible d'enregistrer l'offre, la date d'expiration du marché option précède l'expiration de l'offre."
    }

    return messageError
  }

  onBack = () => {
    const { history } = this.props
    history.replace({
      pathname: '/home/new-offer/step2',
      state: { formOffer: this.props.location.state.formOffer },
    })
  }

  fetchNewOfferCalendar = async (
    strikeValue: StrikeValue | undefined = undefined
  ) => {
    const { offer } = this.state

    try {
      const { data: calendar } = await offerService.getNewOfferCalendarPrices(
        offer!,
        offer!.campaign.startDateCalendar,
        offer!.campaign.endDateCalendar,
        strikeValue
      )
      this.setState({ calendar })
    } catch (e) {
      this.setState({ calendarError: e })
    }
  }

  handleStrikeChange = (strikeValue?: StrikeValue) => {
    this.setState({ calendar: undefined })

    this.fetchNewOfferCalendar(strikeValue)
  }

  render() {
    const { classes } = this.props
    const { offer, calendar, calendarError, strikes } = this.state

    if (!offer) {
      return (
        <>
          <Typography variant="h5">{t('Nouvelle offre')}</Typography>
          <Loading />
        </>
      )
    }

    return (
      <>
        <Typography variant="h5">{t('Nouvelle offre')}</Typography>
        <Card className={classes.card}>
          <OfferRecapData
            offer={offer}
            cardContentColoredClasses={classes.cardContentColored}
          />
          <CardContent className={classes.cardContent}>
            <Flex direction="row" alignItems="center" wrap={false}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ marginRight: 24 }}
              >
                {t('Récapitulatif de l’offre')}
              </Typography>
              {strikes && strikes.length > 0 && (
                <StrikesTabs
                  strikes={strikes}
                  handleChange={this.handleStrikeChange}
                />
              )}
            </Flex>
            <Margin top={2} bottom={2}>
              <Fetcher
                fetch={[
                  executionModeService.findActives,
                  sectorService.findAllActives,
                ]}
                loadingRender={Loading}
                errorRender={CalendarError}
              >
                {([executionModes, sectors]) => (
                  <>
                    {calendarError ? (
                      <CalendarError
                        error={calendarError}
                        refresh={this.fetchNewOfferCalendar}
                      >
                        {t('Erreur : Veuillez réessayer.')}
                      </CalendarError>
                    ) : !calendar ? (
                      <Loading />
                    ) : (
                      <Calendar campaign={offer.campaign}>
                        <CalendarHeader />
                        <TraderCalendarReadOnlyLines
                          sectors={sectors}
                          executionModes={executionModes}
                          calendar={calendar}
                        />
                      </Calendar>
                    )}
                  </>
                )}
              </Fetcher>
            </Margin>
            <Flex direction="row" justify="center">
              <Margin right>
                <Button color="primary" onClick={this.onBack}>
                  {t('Précedent')}
                </Button>
              </Margin>
              <Poster
                onSubmit={this.onPublish}
                disabled={false}
                errorInSnackBar={true}
                buttonText="Publier l'offre"
                onError={this.onError}
              />
            </Flex>
          </CardContent>
        </Card>
      </>
    )
  }
}

export default withStyles(styles)(NewOfferRecap)
