import { createStyles, Theme } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import LockIcon from '@material-ui/icons/LockOutlined'
import qs from 'qs'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { t } from '../i18n'
import * as authService from '../services/authService'

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: 'auto',
      display: 'block', // Fix IE11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`,
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  })

interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

interface State {
  loading: boolean
  error?: string
  newPassword: string
  newPasswordRepeat: string
}

class ResetPassword extends React.Component<Props, State> {
  state = {
    error: undefined,
    loading: false,
    newPassword: '',
    newPasswordRepeat: '',
  }

  onSubmit = () => async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const { newPassword, newPasswordRepeat } = this.state
    const { history, location } = this.props

    this.setState({ loading: true })

    try {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true })

      if (newPassword !== newPasswordRepeat) {
        this.setState({
          error: t('Les mots de passe ne correspondent pas.'),
          loading: false,
        })
        return
      }

      await authService.resetPasswordFinish(params.key, newPassword)
      this.setState({ loading: false, newPassword: '', newPasswordRepeat: '' })
      history.push('/sign-in')
    } catch (e) {
      switch (e.response.data.status) {
        case 400:
          this.setState({
            error: t(
              "Le mot de passe doit être formé d'au moins 8 caractères."
            ),
            loading: false,
          })
          break
        case 404:
          this.setState({
            error: t('La clé de réinitialisation est invalide.'),
            loading: false,
          })
          break
      }
    }
  }

  onChange = (key: keyof State) => (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement
    this.setState((state: State) => ({
      ...state,
      [key]: target.value,
    }))
  }

  render() {
    const { classes } = this.props
    const { loading, newPassword, newPasswordRepeat, error } = this.state

    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography variant="h5">
            {t('Réinitialiser le mot de passe')}
          </Typography>
          <form className={classes.form} onSubmit={this.onSubmit()}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">
                {t('Nouveau mot de passe')}
              </InputLabel>
              <Input
                name="newPassword"
                type="password"
                id="newPassword"
                autoComplete="password"
                value={newPassword}
                onChange={this.onChange('newPassword')}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">
                {t('Répétez le nouveau mot de passe')}
              </InputLabel>
              <Input
                name="newPasswordRepeat"
                type="password"
                id="newPasswordRepeat"
                value={newPasswordRepeat}
                onChange={this.onChange('newPasswordRepeat')}
              />
            </FormControl>
            {error && <Typography color="error">{error}</Typography>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
              disabled={loading}
            >
              {t('Réinitialiser')}
            </Button>
          </form>
        </Paper>
      </main>
    )
  }
}

export default compose<Props, State>(
  withStyles(styles),
  withRouter
)(ResetPassword)
