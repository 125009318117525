import format from 'date-fns/format'
import { FieldProps } from 'formik'
import memoize from 'memoize-one'
import * as React from 'react'

import ExecutionMode from '../../models/ExecutionMode'
import OfferExecution from '../../models/OfferExecution'
import monthToDate from '../../utils/monthToDate'

import { CalendarContext } from './Calendar'
import CalendarFormLine, { CalendarLineValues } from './CalendarFormLine'

interface Props extends FieldProps {
  executionModes: ExecutionMode[]
  color: string
}

class CalendarFormLines extends React.Component<Props> {
  executionModeToCalendarLineValues = memoize(
    (executionMode: ExecutionMode): CalendarLineValues => {
      return this.props.field.value
        .filter(
          (executionDetail: OfferExecution) =>
            executionDetail.executionMode.id === executionMode.id
        )
        .reduce((acc: CalendarLineValues, executionDetail: OfferExecution) => {
          acc[format(executionDetail.executionDate, 'MM/yy')] = true
          return acc
        }, {})
    }
  )

  calendarLineValuesToExecutionDetails = (
    executionMode: ExecutionMode,
    calendarLineValues: CalendarLineValues
  ): OfferExecution[] => {
    const values: OfferExecution[] = this.props.field.value
    return [
      ...values.filter(({ executionMode: { id } }) => id !== executionMode.id),
      ...Object.entries(calendarLineValues)
        .filter(([_, isChecked]) => isChecked)
        .map(([month]) => ({
          executionDate: monthToDate(month),
          executionMode,
        })),
    ]
  }

  handleLineChange = (executionMode: ExecutionMode) => (
    calendarLineValues: CalendarLineValues
  ) => {
    const {
      form: { setFieldValue, setTouched },
      field: { name },
    } = this.props
    const values = this.calendarLineValuesToExecutionDetails(
      executionMode,
      calendarLineValues
    )
    setTouched({ [name]: true })
    setFieldValue(name, values)
  }

  render() {
    const { executionModes, color } = this.props

    return (
      <CalendarContext.Consumer>
        {() => (
          <tbody>
            {executionModes.map((executionMode, index) => (
              <CalendarFormLine
                key={executionMode.id}
                title={executionMode.name}
                onChange={this.handleLineChange(executionMode)}
                value={this.executionModeToCalendarLineValues(executionMode)}
                color={color}
                dark={index % 2 === 0}
              />
            ))}
          </tbody>
        )}
      </CalendarContext.Consumer>
    )
  }
}

export default CalendarFormLines
