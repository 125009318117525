import { Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton/IconButton'
import RefreshIcon from 'mdi-material-ui/Sync'
import React, { useState } from 'react'

import compareAsc from 'date-fns/compareAsc'
import Calendar from '../../../components/Calendar/Calendar'
import CalendarHeader from '../../../components/Calendar/CalendarHeader'
import TraderCalendarReadOnlyLines from '../../../components/Calendar/TraderCalendarReadOnlyLines'
import CalendarError from '../../../components/CalendarError'
import CalendarLoading from '../../../components/CalendarLoading'
import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Margin from '../../../components/Margin'
import { t } from '../../../i18n'
import { format } from '../../../i18n/format'
import Campaign from '../../../models/Campaign'
import ExecutionMode from '../../../models/ExecutionMode'
import Offer from '../../../models/Offer'
import OffersPricesView from '../../../models/OffersPricesView'
import Sector from '../../../models/Sector'
import offerService from '../../../services/offerService'
import StrikesTabs from '../../../components/Calendar/StrikesTabs'
import StrikeSetting, { StrikeValue } from '../../../models/StrikeSetting'

type Props = {
  offers: Offer[]
  selectedSectors: Sector[]
  executionModes: ExecutionMode[]
  strikes: StrikeSetting[]
  handleRefreshClicked: () => void
  lastUpdatedDate: Date
  selectedCampaign: Campaign
  onClick: (offer: Offer, calendar: OffersPricesView) => void
  backInTime: Date
}

const TraderOffersListCardCalendar = ({
  selectedSectors,
  executionModes,
  strikes,
  offers,
  lastUpdatedDate,
  handleRefreshClicked,
  selectedCampaign,
  backInTime,
  onClick,
}: Props) => {
  const [strikeValue, setStrikeValue] = useState<Optional<StrikeValue>>()
  const [hasPmg, setHasPmg] = React.useState<boolean>()

  React.useEffect(() => {
    setHasPmg(offers.some(o => o.modePMG))
  }, [offers])

  const findOneOfferPricesView = (
    calendar: OffersPricesView,
    offerId: number
  ) => {
    const filteredCalendar = { executionModePerSector: {} }

    Object.keys(calendar.executionModePerSector).forEach(executionModeKey => {
      Object.keys(calendar.executionModePerSector[executionModeKey]).forEach(
        sectorKey => {
          Object.keys(
            calendar.executionModePerSector[executionModeKey][sectorKey]
          )
            .filter(monthKey => {
              return (
                calendar.executionModePerSector[executionModeKey][sectorKey][
                  monthKey
                ].offerId === offerId
              )
            })
            .forEach(monthKey => {
              if (!filteredCalendar.executionModePerSector[executionModeKey]) {
                filteredCalendar.executionModePerSector[executionModeKey] = {}
              }
              if (
                !filteredCalendar.executionModePerSector[executionModeKey][
                  sectorKey
                ]
              ) {
                filteredCalendar.executionModePerSector[executionModeKey][
                  sectorKey
                ] = {}
              }

              filteredCalendar.executionModePerSector[executionModeKey][
                sectorKey
              ][monthKey] =
                calendar.executionModePerSector[executionModeKey][sectorKey][
                  monthKey
                ]
            })
        }
      )
    })

    return filteredCalendar
  }

  const handleClick = (calendar: OffersPricesView) => (offerId: number) => {
    const offer = offers.find(o => o.id === offerId)
    if (offer) {
      onClick(offer, findOneOfferPricesView(calendar, offerId))
    }
  }

  const offerIds = offers.map(o => o.id!)

  if (offers.length === 0) {
    return (
      <>
        <Margin bottom>
          <Flex direction="row" alignItems="center">
            <Flex grow />
            <Typography color="primary">
              {t('Dernière mise à jour : ')} {format(backInTime, 'dd/MM/yyyy')}
            </Typography>
            <Margin left>
              <IconButton onClick={handleRefreshClicked}>
                <RefreshIcon color="primary" />
              </IconButton>
            </Margin>
          </Flex>
        </Margin>
        <Flex grow alignItems="center" justify="flex-start">
          <Margin top={10}>
            <Typography variant="h6" color="primary" align="center">
              {t('Aucune offre active')}
            </Typography>
          </Margin>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Margin bottom>
        <Flex direction="row" alignItems="center">
          <Typography variant="h6" color="textSecondary">
            {t('Offres actives le')} {format(backInTime, 'dd/MM/yyyy à HH:mm')}{' '}
            ({offers.length})
          </Typography>

          <Margin right={3} left={1}>
            {hasPmg && (
              <StrikesTabs strikes={strikes} handleChange={setStrikeValue} />
            )}
          </Margin>
          <Flex grow />
          <Typography color="primary" variant="caption" display="block">
            {t('Dernière mise à jour : ')}{' '}
            {format(lastUpdatedDate, 'dd/MM/yy à HH:mm')}
          </Typography>
          <Margin left>
            <IconButton onClick={handleRefreshClicked}>
              <RefreshIcon color="primary" />
            </IconButton>
          </Margin>
        </Flex>
      </Margin>
      <Fetcher
        fetch={offerService.getCalendarWithoutPrices}
        fetchProps={[
          offerIds,
          selectedCampaign.startDateCalendar,
          selectedCampaign.endDateCalendar,
          strikeValue,
          compareAsc(backInTime, new Date().setHours(0, 0, 0, 0)) < 0, // "queryExpired" to true if backInTime date is before today
        ]}
        loadingRender={CalendarLoading}
        errorRender={CalendarError}
      >
        {calendar => (
          <Calendar campaign={selectedCampaign}>
            <CalendarHeader />
            <TraderCalendarReadOnlyLines
              onClick={handleClick(calendar)}
              sectors={selectedSectors}
              executionModes={executionModes}
              calendar={calendar}
            />
          </Calendar>
        )}
      </Fetcher>
    </>
  )
}

export default TraderOffersListCardCalendar
