import * as React from 'react'

import { WithStyles, createStyles, withStyles } from '@material-ui/core'

import Tab from '@material-ui/core/Tab/Tab'
import Tabs from '@material-ui/core/Tabs/Tabs'
import Typography from '@material-ui/core/Typography/Typography'
import { Link } from 'react-router-dom'
import Campaign from '../../../../models/Campaign'
import Error from '../../../../components/Error'
import Fetcher from '../../../../components/Fetcher'
import Flex from '../../../../components/Flex'
import Loading from '../../../../components/Loading'
import MajorationTable from '../../../../models/MajorationTable'
import Margin from '../../../../components/Margin'
import TraderSettingsConfigCard from '../TraderSettingsConfigCard'
import TraderSettingsMajorationTablesList from './TraderSettingsMajorationTablesList'
import adviceService from '../../../../services/adviceService'
import campaignService from '../../../../services/campaignService'
import majorationTableService from '../../../../services/majorationTableService'
import { t } from '../../../../i18n'

const styles = () =>
  createStyles({
    cardContent: {
      paddingRight: 15,
      width: '100%',
    },
  })

interface Props extends WithStyles<typeof styles> {}

interface State {
  campaignIndex: number
}

class TraderSettingsMajorationTables extends React.Component<Props, State> {
  state: State = {
    campaignIndex: 0,
  }

  handleCampaignIndexChange = (
    _: React.ChangeEvent<{}>,
    campaignIndex: number
  ) => this.setState({ campaignIndex })

  renderCampaignsTabs = (campaigns: Campaign[]) => {
    if (campaigns.length === 0) {
      return (
        <Typography>
          {t("Merci d'abord de renseigner vos campagnes:")} &nbsp;
          <Link to={'/settings/campaigns'}>
            <Typography color="primary" component="span">
              {t('liste des campagnes')}
            </Typography>
          </Link>
        </Typography>
      )
    }

    const { campaignIndex } = this.state
    const selectedCampaign = campaigns[campaignIndex]

    return (
      <>
        <Flex direction="row" alignItems="center">
          <Margin right={2}>
            <Typography variant="body2" color="textPrimary">
              {t('Campagne')}
            </Typography>
          </Margin>
          <Tabs
            value={campaignIndex}
            onChange={this.handleCampaignIndexChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {campaigns.map(campaign => (
              <Tab key={campaign.id} label={campaign.name} />
            ))}
          </Tabs>
        </Flex>
        <Fetcher
          fetch={majorationTableService.findAllByCampaign}
          fetchProps={[selectedCampaign.id]}
          loadingRender={Loading}
          errorRender={Error}
        >
          {this.renderContent(campaigns)}
        </Fetcher>
      </>
    )
  }

  renderContent = (campaigns: Campaign[]) => (
    majorationTables: MajorationTable[],
    refresh: () => void
  ) => {
    const { campaignIndex } = this.state
    const selectedCampaign = campaigns[campaignIndex]
    return (
      <TraderSettingsMajorationTablesList
        campaign={selectedCampaign}
        majorationTables={majorationTables}
        refresh={refresh}
      />
    )
  }

  render() {
    const { classes } = this.props

    return (
      <TraderSettingsConfigCard
        cardTitle={'Vos tables de majorations'}
        adviceMessage={
          <Fetcher fetch={adviceService.findOne} fetchProps={['MAJORATION']}>
            {advice => (
              <div dangerouslySetInnerHTML={{ __html: advice.template }} />
            )}
          </Fetcher>
        }
      >
        <div className={classes.cardContent}>
          <Fetcher
            fetch={campaignService.findAllActive}
            loadingRender={Loading}
            errorRender={Error}
          >
            {this.renderCampaignsTabs}
          </Fetcher>
        </div>
      </TraderSettingsConfigCard>
    )
  }
}

export default withStyles(styles)(TraderSettingsMajorationTables)
