import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'

import Campaign from '../../models/Campaign'
import getCampaignMonths from '../../utils/getCampaignMonths'
import { Omit } from '../../utils/Omit'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflowX: 'auto',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      borderWidth: 0,
      textAlign: 'left',
    },
    error: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.error.main,
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    },
  })

interface Props extends WithStyles<typeof styles> {
  error?: boolean
  campaign: Campaign
}

interface State {
  months: string[]
  set?: (state: Omit<State, 'set'>) => void
}
export type CalendarContextType = State

export const CalendarContext = React.createContext<State>({ months: [] })

class Calendar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      months: getCampaignMonths(props.campaign),
      set: this.set,
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.campaign !== prevProps.campaign) {
      this.setState({ months: getCampaignMonths(this.props.campaign) })
    }
  }

  set = (newState: State) => this.setState(newState)

  render() {
    const { classes, children, error } = this.props
    return (
      <CalendarContext.Provider value={this.state}>
        <div
          className={classNames(classes.root, error && classes.error)}
          data-cy="calendar"
        >
          <table className={classes.table}>{children}</table>
        </div>
      </CalendarContext.Provider>
    )
  }
}

export default withStyles(styles)(Calendar)
