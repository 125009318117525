import { Theme, Typography, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'

import { t } from '../../i18n'

import { CalendarContext } from './Calendar'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius / 2,
    '& > th': {
      paddingTop: theme.spacing(1.2),
      paddingBottom: theme.spacing(1.2),
      backgroundColor: theme.palette.primary.main,
      whiteSpace: 'nowrap',
    },
  },
  title: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(2),
  },
  date: {
    textAlign: 'center',
  },
  dateSpan: {
    borderRightColor: theme.palette.common.white,
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    paddingRight: theme.spacing(1.7),
    paddingLeft: theme.spacing(1.7),
  },
  lastDate: {
    borderRightWidth: 0,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    '& > *': {
      borderRightWidth: 0,
    },
  },
}))

const CalendarHeader = () => {
  const classes = useStyles()

  return (
    <CalendarContext.Consumer>
      {({ months }) => (
        <thead>
          <tr className={classes.root}>
            <th className={classes.title}>
              <Typography variant="body2" color="inherit" noWrap>
                {t("Périodes d'exécution")}
              </Typography>
            </th>
            {months &&
              months.map((month, index) => (
                <th
                  key={month}
                  className={classNames(
                    classes.date,
                    index === months.length - 1 && classes.lastDate
                  )}
                >
                  <Typography
                    variant="caption"
                    display="block"
                    color="inherit"
                    className={classes.dateSpan}
                  >
                    {month}
                  </Typography>
                </th>
              ))}
          </tr>
        </thead>
      )}
    </CalendarContext.Consumer>
  )
}

export default CalendarHeader
