import axios from '../config/axios'
import ExecutionMode from '../models/ExecutionMode'

export const findAll = () => axios.get<ExecutionMode[]>('api/execution-modes')
export const findActives = () =>
  axios.get<ExecutionMode[]>('api/execution-modes?active=true')
export const findActivesByUserId = (userId: number) =>
  axios.get<ExecutionMode[]>('api/execution-modes', {
    params: { active: true, userId },
  })
export const update = (executionModes: ExecutionMode[]) =>
  axios.put<ExecutionMode[]>('api/execution-modes', executionModes)
export const updateLabel = (executionMode: ExecutionMode) =>
  axios.put<ExecutionMode>(
    `api/execution-modes/${executionMode.id}`,
    executionMode
  )

export default {
  findAll,
  update,
  findActives,
  findActivesByUserId,
  updateLabel,
}
