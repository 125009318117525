import React from 'react'

import { t } from '../../../../i18n'

import TraderSettingsConfigCard from '../../../trader/Settings/TraderSettingsConfigCard'
import Fetcher from '../../../../components/Fetcher'
import adviceService from '../../../../services/adviceService'
import SuperAdminPMGSettingsForm from './SuperAdminPMGSettingsForm'
import pmgSettingsService from '../../../../services/pmgSettingsService'

export const SuperAdminPMGSettings = () => {
  return (
    <TraderSettingsConfigCard
      cardTitle={t('Paramétrage PMG')}
      adviceMessage={
        <Fetcher fetch={adviceService.findOne} fetchProps={['PMG']}>
          {advice => (
            <div dangerouslySetInnerHTML={{ __html: advice.template }} />
          )}
        </Fetcher>
      }
    >
      <Fetcher fetch={pmgSettingsService.findFirst}>
        {pmgData => <SuperAdminPMGSettingsForm settings={pmgData} />}
      </Fetcher>
    </TraderSettingsConfigCard>
  )
}

export default SuperAdminPMGSettings
