import queryString from 'query-string'
import axios from '../config/axios'
import TargetOrder from '../models/TargetOrder'
import { EExecutionMode } from '../models/ExecutionMode'
import Campaign from '../models/Campaign'
import Product from '../models/Product'

export type TargetOrderRequest = {
  executionMode: EExecutionMode
  executionMonth: Date
  campaign: Pick<Campaign, 'id'>
  product: Pick<Product, 'id'>
  tons: number
  paymentDate: Date
  targetPrice: number
}

export type TargetOrderView = {
  id: number
  offerId: number
  builtAt: Date
  executionDate: Date
  tons: number
  customerTargetPrice: number
  actualPrice: number
  totalPrice: number
  campaignName: string
  campaignId: number
  executionModeLabel: string
  productName: string
  productId: number
  paymentDate: Date
  executionMode: EExecutionMode
  comments: string
  status: 'CREATED' | 'EXECUTED' | 'CANCELED'
  customerSocialReason: string
  customerId: number
  tonnageExecuted: number
  updatedBy: string
  updatedAt: Date
}

const BASE_URL = 'api/target-orders'

export const getOne = (request: TargetOrderRequest) =>
  axios.post<TargetOrder>(BASE_URL + '/generate', request, {
    timeout: parseInt(process.env.REACT_APP_SLOW_API_TIMEOUT || '10000'),
  })

export const getOneForCustomer = (
  userId: number,
  request: TargetOrderRequest
) =>
  axios.post<TargetOrder>(BASE_URL + '/generate', request, {
    params: { userId },
    timeout: parseInt(process.env.REACT_APP_SLOW_API_TIMEOUT || '10000'),
  })

export const save = (order: TargetOrder) =>
  axios.post<TargetOrder>(BASE_URL, order, {
    timeout: parseInt(process.env.REACT_APP_SLOW_API_TIMEOUT || '10000'),
  })

export const saveForCustomer = (userId: number, order: TargetOrder) =>
  axios.post<TargetOrder>(BASE_URL, order, {
    params: { userId },
  })

export const findAll = (statuses: [string]) =>
  axios.get<TargetOrderView[]>(
    `${BASE_URL}?${queryString.stringify({ statuses })}`
  )

export const cancelById = (id: number) => axios.delete(`${BASE_URL}/${id}`)

export const findByCustomer = (id: number, statuses: [string]) =>
  axios.get(`${BASE_URL}?userId=${id}&${queryString.stringify({ statuses })}`)

export const findById = (id: number) =>
  axios.get<TargetOrder>(`${BASE_URL}/${id}`)

export const countByOffer = (offerId: number) =>
  axios.get<number>(`${BASE_URL}/count-pending`, {
    params: { offerId },
  })

export default {
  findAll,
  findByCustomer,
  findById,
  cancelById,
  getOne,
  getOneForCustomer,
  save,
  saveForCustomer,
  countByOffer,
}
