import {
  Button,
  createStyles,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { ErrorRenderProps } from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import Margin from '../../../components/Margin'
import { t } from '../../../i18n'

const styles = createStyles({
  root: {
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

type Props = ErrorRenderProps & WithStyles<typeof styles>

const TraderCalendarError: React.FunctionComponent<Props> = ({
  classes,
  children,
  refresh,
  error,
}) => (
  <Flex
    alignItems={'center'}
    justify={'flex-start'}
    fullWidth={true}
    className={classes.root}
  >
    <Margin bottom={2}>
      <div className={classes.content}>
        <Margin right>
          <ErrorIcon color="error" />
        </Margin>
        {error.response && error.response.status === 500 && (
          <>
            <Link to="/settings/parities">
              <Button color="primary" size="small">
                {t('Vérifiez vos parités de transport')}
              </Button>
            </Link>
            <Typography variant="subtitle1">{t('et réessayez.')}</Typography>
          </>
        )}
        {error.response && error.response.status !== 500 && (
          <Typography variant="subtitle1">{children}</Typography>
        )}
      </div>
    </Margin>
    <div className={classes.content}>
      <Button variant="contained" color="primary" onClick={refresh}>
        {t('Réessayer')}
      </Button>
    </div>
  </Flex>
)

export default withStyles(styles)(TraderCalendarError)
