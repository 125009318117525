import { createStyles, withStyles } from '@material-ui/core/styles'
import * as React from 'react'

const styles = createStyles({
  '@global': {
    body: {
      minHeight: '100vh',
      width: '100vw',
      overflowX: 'hidden',
    },
    html: {
      fontSize: '62.5%', // to have easier rem unit (1rem = 10px; 1.6rem = 16px)
    },
    '#root': {
      display: 'flex',
      minHeight: '100vh',
      width: '100vw',
      overflowX: 'hidden',
    },
    a: {
      color: 'inherit',
      textDecoration: 'none',
    },
    'a:hover': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '.align-right': {
      textAlign: 'right',
    },
    '.align-left': {
      textAlign: 'left',
    },
    '.align-center': {
      textAlign: 'center',
    },
    '.align-justify': {
      textAlign: 'justify',
      whiteSpace: 'pre-line',
    },
  },
})

const GlobalCss: React.FunctionComponent<{}> = () => null

export default withStyles(styles)(GlobalCss)
