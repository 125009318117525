import {
  CardContent,
  createStyles,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import memoize from 'memoize-one'
import * as React from 'react'

import CalendarError from '../../../components/CalendarError'
import CardContentColored from '../../../components/CardContentColored'
import Fetcher from '../../../components/Fetcher'
import Loading from '../../../components/Loading'
import Margin from '../../../components/Margin'
import VerticalSelect, { ALL } from '../../../components/VerticalSelect'
import { t } from '../../../i18n'
import Campaign from '../../../models/Campaign'
import ExecutionMode from '../../../models/ExecutionMode'
import Offer from '../../../models/Offer'
import OffersPricesView from '../../../models/OffersPricesView'
import Product from '../../../models/Product'
import Sector from '../../../models/Sector'
import offerService from '../../../services/offerService'
import getCurrentCampaign from '../../../utils/getCurrentCampaign'
import sortCampaigns from '../../../utils/sortCampaigns'

import TraderOffersListCardCalendar from './TraderOffersListCardCalendar'
import TraderOffersListOfferRecap from './TraderOffersListOfferRecap'
import StrikeSetting from '../../../models/StrikeSetting'

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 400,
  },
  offers: {
    display: 'flex',
    flexDirection: 'column',
    flex: 14,
    overflow: 'hidden',
  },
  cardContent: {
    padding: 0,
    flex: 2,
  },
})

type Props = WithStyles<typeof styles> & {
  campaigns: Campaign[]
  products: Product[]
  sectors: Sector[]
  executionModes: ExecutionMode[]
  strikes: StrikeSetting[]
  backInTime: Date
}

type State = {
  selectedCampaign: Campaign
  selectedProduct: Product
  selectedSector: Sector | typeof ALL
  selectedOffer?: { calendar: OffersPricesView; offer: Offer }
}

class TraderOffersListCard extends React.Component<Props, State> {
  filterOffers = memoize(
    (
      selectedCampaign: Campaign,
      selectedProduct: Product,
      selectedSector: Sector | typeof ALL,
      offers: Offer[]
    ) => {
      return offers.filter(offer => {
        const hasCampaign = offer.campaign.id === selectedCampaign.id
        const hasProduct = offer.product.id === selectedProduct.id
        let hasSector = true
        if (
          !(offer.sectorsRestriction.length === 0 || selectedSector === ALL)
        ) {
          hasSector = offer.sectorsRestriction.some(
            sector => sector.id === selectedSector.id
          )
        }
        return hasCampaign && hasProduct && hasSector
      })
    }
  )

  constructor(props: Props) {
    super(props)
    this.state = {
      selectedCampaign: getCurrentCampaign(sortCampaigns(props.campaigns)),
      selectedProduct: props.products[0],
      selectedSector: ALL,
      selectedOffer: undefined,
    }
  }

  onCampaignChange = (selectedCampaign: Campaign) =>
    this.setState({ selectedCampaign })
  campaignsKeyExtractor = (c: Campaign) => c.id
  campaignsRenderItem = (c: Campaign) => t(c.name)
  onProductChange = (selectedProduct: Product) =>
    this.setState({ selectedProduct })
  productsKeyExtractor = (p: Product) => p.id
  productsRenderItem = (campaignId: number) => (p: Product) =>
    t(p.name) +
    (p.nbOffersPerCampaign[campaignId]
      ? ` (${p.nbOffersPerCampaign[campaignId]})`
      : '')
  onSectorChange = (selectedSector: Sector) => this.setState({ selectedSector })
  sectorsKeyExtractor = (s: Sector) => s.id
  sectorsRenderItem = (s: Sector) => t(s.name)

  getSelectedSecors = (
    sectors: Sector[],
    selectedSector: Sector | typeof ALL
  ): Sector[] => {
    if (selectedSector === ALL) {
      return sectors
    }
    return [selectedSector]
  }

  handleOfferClick = (offer: Offer, calendar: OffersPricesView) => {
    this.setState({ selectedOffer: { offer, calendar } })
  }

  handleReturnClick = () => {
    this.setState({ selectedOffer: undefined })
  }

  render() {
    const {
      classes,
      campaigns,
      products,
      sectors,
      executionModes,
      strikes,
      backInTime,
    } = this.props
    const {
      selectedCampaign,
      selectedProduct,
      selectedSector,
      selectedOffer,
    } = this.state

    if (selectedOffer) {
      return (
        <TraderOffersListOfferRecap
          calendar={selectedOffer.calendar}
          offer={selectedOffer.offer}
          sectors={sectors}
          executionModes={executionModes}
          campaign={selectedCampaign}
          onBack={this.handleReturnClick}
        />
      )
    } else {
      const titleMarginProps = { left: 2, right: 2, top: 2, bottom: 2 }
      return (
        <Card className={classes.root} data-cy={'offers'}>
          <CardContentColored
            color="primary"
            className={classes.cardContent}
            data-cy={'campaigns'}
          >
            <Margin {...titleMarginProps}>
              <Typography
                variant="button"
                align="center"
                color="inherit"
                noWrap
              >
                {t('CAMPAGNE')}
              </Typography>
            </Margin>
            <VerticalSelect
              values={sortCampaigns(campaigns)}
              defaultValue={selectedCampaign}
              onChange={this.onCampaignChange}
              keyExtractor={this.campaignsKeyExtractor}
              renderItem={this.campaignsRenderItem}
            />
          </CardContentColored>
          <CardContentColored
            color="primary-400"
            className={classes.cardContent}
            data-cy={'products'}
          >
            <Margin {...titleMarginProps}>
              <Typography
                variant="button"
                align="center"
                color="inherit"
                noWrap
              >
                {t('PRODUIT')}
              </Typography>
            </Margin>
            <VerticalSelect
              values={products}
              defaultValue={selectedProduct}
              onChange={this.onProductChange}
              keyExtractor={this.productsKeyExtractor}
              renderItem={this.productsRenderItem(selectedCampaign.id)}
            />
          </CardContentColored>
          <CardContentColored
            color="primary-300"
            className={classes.cardContent}
            data-cy={'sectors'}
          >
            <Margin {...titleMarginProps}>
              <Typography
                variant="button"
                align="center"
                color="inherit"
                noWrap
              >
                {t('SECTEUR')}
              </Typography>
            </Margin>
            <VerticalSelect
              values={sectors}
              defaultValue={selectedSector}
              onChange={this.onSectorChange}
              keyExtractor={this.sectorsKeyExtractor}
              renderItem={this.sectorsRenderItem}
              allItem
            />
          </CardContentColored>
          <CardContent className={classes.offers}>
            <Fetcher
              fetch={offerService.findAllActiveAtTime}
              fetchProps={[backInTime]}
              loadingRender={Loading}
              errorRender={CalendarError}
            >
              {(offers, refresh, lastUpdated) => (
                <TraderOffersListCardCalendar
                  backInTime={backInTime}
                  offers={this.filterOffers(
                    selectedCampaign,
                    selectedProduct,
                    selectedSector,
                    offers
                  )}
                  selectedSectors={this.getSelectedSecors(
                    sectors,
                    selectedSector
                  )}
                  onClick={this.handleOfferClick}
                  executionModes={executionModes}
                  strikes={strikes}
                  handleRefreshClicked={refresh}
                  lastUpdatedDate={lastUpdated}
                  selectedCampaign={selectedCampaign}
                />
              )}
            </Fetcher>
          </CardContent>
        </Card>
      )
    }
  }
}

export default withStyles(styles)(TraderOffersListCard)
