import {
  createStyles,
  Grid,
  Theme,
  Typography,
  WithStyles,
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import memoizeOne from 'memoize-one'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import Fetcher from '../../../components/Fetcher'
import Margin from '../../../components/Margin'
import SaleConfirmationDialog from '../../../components/Sale/SaleConfirmationDialog'
import SaleErrorDialog from '../../../components/Sale/SaleErrorDialog'
import SaleRecapCard from '../../../components/Sale/SaleRecapCard'
import { User } from '../../../contexts/UserContext'
import { t } from '../../../i18n'
import { format } from '../../../i18n/format'
import Campaign from '../../../models/Campaign'
import CustomerStock from '../../../models/CustomerStock'
import ExecutionMode, { EExecutionMode } from '../../../models/ExecutionMode'
import Offer from '../../../models/Offer'
import OfferExecution from '../../../models/OfferExecution'
import Transaction from '../../../models/Transaction'
import campaignService from '../../../services/campaignService'
import customerStockService from '../../../services/customerStockService'
import offerService from '../../../services/offerService'
import transactionService from '../../../services/transactionService'
import CustomerStocksCard from '../../customer/Home/CustomerStocksCard'
import TimerContext from '../../../contexts/TimerContext'
import TargetOrder from '../../../models/TargetOrder'
import targetOrderService from '../../../services/targetOrderService'
import { StrikeMap } from '../../../components/Sale/SaleCard'

const styles = (theme: Theme) =>
  createStyles({
    leftPanel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    input: {
      width: 200,
    },
    card: {
      display: 'flex',
      flexDirection: 'row',
      color: theme.palette.common.white,
    },
    back: {
      marginLeft: -8,
      paddingTop: 0,
      paddingRight: 4,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    bold: {
      fontSize: '2.37rem',
      fontWeight: 700,
    },
    saleOption: {
      flex: 2,
    },
    help: {
      flex: 1,
      borderLeftStyle: 'solid',
      borderLeftWidth: 2,
      borderLeftColor: theme.palette.grey[300],
    },
    action: {
      width: 235,
    },
  })

interface RouteParams {
  token?: string
  order: Transaction | TargetOrder
  campaign: Campaign
  customer: User
  strikeSettings: StrikeMap
  status?: 'SALE_CONFIRMED'
}

interface State {
  status: 'WAITING_VALIDATION' | 'SALE_CONFIRMED' | 'DEFAULT'
  error?: string
}

type Props = RouteComponentProps<{}, {}, RouteParams> &
  WithStyles<typeof styles>

class SalesmanCustomerSaleRecap extends React.Component<Props, State> {
  static contextType = TimerContext

  state: State = {
    status: this.props.location.state.status || 'DEFAULT',
    error: undefined,
  }

  getExecutionMode: (
    offer: Offer,
    executionModeType: EExecutionMode
  ) => ExecutionMode = memoizeOne(
    (offer: Offer, executionModeType: EExecutionMode) => {
      return offer.executionDetails.reduce(
        (acc: ExecutionMode | null, executionDetail: OfferExecution) => {
          if (executionDetail.executionMode.type === executionModeType) {
            return executionDetail.executionMode
          }
          return acc
        },
        null
      )!
    }
  )

  componentDidMount(): void {
    const { location, history } = this.props

    if (!location.state || !location.state.order || !location.state.customer) {
      history.replace('/customers')
    }
  }

  handleConfirmSale = () => async () => {
    const { location, history } = this.props
    const { token, customer, order } = location.state
    this.setState({ status: 'WAITING_VALIDATION' })
    try {
      if (token !== undefined) {
        await transactionService.saveForCustomer(customer.id!, token)
        this.context.setEndDate(undefined)
      } else {
        await targetOrderService.saveForCustomer(
          customer.id!,
          order as TargetOrder
        )
      }
      this.setState({ status: 'SALE_CONFIRMED' })
      history.replace({
        ...location,
        state: { ...location.state, status: 'SALE_CONFIRMED' },
      })
    } catch (error) {
      this.context.setEndDate(undefined)
      if (error.response) {
        switch (error.response.status) {
          case 408:
            this.setState({ error: t('Temps expiré') })
            break
          case 409:
            this.setState({
              error: t(
                "Le tonnage entré dépasse le tonnage maximal encore disponible de l'offre"
              ),
            })
            break
          default:
            this.setState({ error: t('Erreur lors du traitement de la vente') })
            break
        }
      } else if (error.request) {
        this.setState({
          error: t(
            'Erreur lors de la confirmation de la vente, nous vous conseillons de vérifier la liste de vos ventes avant de réessayer'
          ),
        })
      } else {
        this.setState({ error: t('Erreur inconnue'), status: 'DEFAULT' })
      }
    }
  }

  closeConfirmationDialog = () => {
    const { history, location } = this.props
    const { customer } = location.state
    history.replace({ pathname: `/customers/${customer.id}/` })
  }

  handleBack = (offer: Offer) => () => {
    const { history, location } = this.props
    const { order, campaign, customer } = location.state
    this.context.setEndDate(undefined)
    const executionMode = this.getExecutionMode(offer, order.executionMode)
    history.replace({
      pathname: `/customers/${customer.id}/sale`,
      state: {
        executionMode,
        executionMonth: format(order.executionDate, 'MM/yy'),
        campaign,
        product: offer.product,
        payment: order.paymentDate,
        customer,
        strike: 'strike' in order ? order.strike : undefined,
      },
    })
  }

  renderRecap = (offer: Offer) => {
    const { location } = this.props
    const { status, error } = this.state
    const { order, strikeSettings } = location.state
    const executionMode = this.getExecutionMode(offer, order.executionMode)

    return (
      <>
        <SaleRecapCard
          offer={offer}
          order={order}
          executionMode={executionMode}
          isWaitingValidation={status === 'WAITING_VALIDATION'}
          onBack={this.handleBack(offer)}
          onConfirmSale={this.handleConfirmSale()}
          strikeSettings={strikeSettings}
        />
        <SaleConfirmationDialog
          open={status === 'SALE_CONFIRMED'}
          onClose={this.closeConfirmationDialog}
        />
        <SaleErrorDialog
          open={error !== undefined}
          message={error}
          onClose={this.handleBack(offer)}
        />
      </>
    )
  }

  renderCustomerStock = ([campaigns, customerStocks]: [
    Campaign[],
    CustomerStock[]
  ]) => {
    return (
      <CustomerStocksCard
        campaigns={campaigns}
        customerStocks={customerStocks}
      />
    )
  }

  fetchOffer = () => {
    const { offerId } = this.props.location.state.order
    return offerService.findById(offerId)
  }

  fetchCustomerStockService = () => {
    const { customer } = this.props.location.state
    return customerStockService.findAll(customer!.id)
  }

  render() {
    const { location } = this.props
    if (!location.state || !location.state.order || !location.state.customer) {
      return null
    }

    return (
      <>
        <Margin bottom={2} top={4}>
          <Typography variant="h6">{t('Récapitulatif de la vente')}</Typography>
        </Margin>
        <Fetcher fetch={this.fetchOffer}>{this.renderRecap}</Fetcher>
        <Margin top={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Fetcher
                fetch={[
                  campaignService.findAllActive,
                  this.fetchCustomerStockService,
                ]}
              >
                {this.renderCustomerStock}
              </Fetcher>
            </Grid>
          </Grid>
        </Margin>
      </>
    )
  }
}

const enhancer = compose<Props, {}>(
  withRouter,
  withStyles(styles)
)

export default enhancer(SalesmanCustomerSaleRecap)
