import React, { useContext, useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'
import Link from '@material-ui/core/Link'
import CardLoading from '../components/CardLoading'
import UserContext from '../contexts/UserContext'
import authService from '../services/authService'
import { t } from '../i18n'
import useRouter from '../hooks/useRouter'
import Flex from '../components/Flex'

const useStyle = makeStyles({
  loading: {
    width: '100%',
  },
  fullWidth: { width: '100%' },
})

const SecuredAuth = () => {
  const { set: setUser } = useContext(UserContext)
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const { history, location } = useRouter()
  const classes = useStyle()

  const init = async () => {
    try {
      const params = new URLSearchParams(location.search)
      const token = params.get('token')

      const {
        token_type: tokenType,
        access_token: accessToken,
        refresh_token: refreshToken,
      } = (await authService.getAccessTokenFromJWT(token as string)).data
      const user = await authService.getUserData(
        tokenType,
        accessToken,
        refreshToken
      )
      user.username = user.login
      setUser(user)
      if (
        !location.state ||
        !location.state.from ||
        location.state.from === 'sign-in'
      ) {
        history.push('/')
      } else {
        history.push(location.state.from)
      }
    } catch (e) {
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      {loading && (
        <div className={classes.loading}>
          <CardLoading />
        </div>
      )}
      {error && (
        <Flex
          className={classes.fullWidth}
          justify="center"
          alignItems="center"
        >
          <Typography color="error">
            {t("Erreur nous n'avons pas pu vous identifer, veuillez réessayer")}
          </Typography>
          <Link
            variant="body2"
            onClick={() => history.goBack()}
            style={{ cursor: 'pointer' }}
          >
            Retour page précédente
          </Link>
        </Flex>
      )}
    </>
  )
}

export default SecuredAuth
