import MuiCheckbox from '@material-ui/core/Checkbox'
import FormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@material-ui/core/FormControlLabel'
import * as React from 'react'

import { Omit } from '../../utils/Omit'

import { CheckboxProps, fieldToCheckbox } from './Checkbox'

export interface CheckboxWithLabelProps extends CheckboxProps {
  label: Omit<
    MuiFormControlLabelProps,
    'checked' | 'name' | 'onChange' | 'value' | 'inputRef'
  >
}

export const CheckboxWithLabel: React.ComponentType<CheckboxWithLabelProps> = ({
  label,
  ...props
}) => (
  <FormControlLabel
    control={<MuiCheckbox {...fieldToCheckbox(props)} />}
    {...label}
  />
)

CheckboxWithLabel.displayName = 'FormikMaterialUICheckboxWithLabel'
