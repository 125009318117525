import {
  CircularProgress,
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import * as React from 'react'

import Flex from './Flex'
import Margin from './Margin'

const styles = createStyles({
  root: {
    height: '100%',
  },
})

type Props = WithStyles<typeof styles>

const CalendarLoading: React.FunctionComponent<Props> = ({ classes }) => (
  <Margin top={10}>
    <Flex
      alignItems={'center'}
      justify={'flex-start'}
      fullWidth={true}
      className={classes.root}
    >
      <CircularProgress color="primary" size={64} />
    </Flex>
  </Margin>
)

export default withStyles(styles)(CalendarLoading)
