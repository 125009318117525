import { Grid, Typography } from '@material-ui/core'
import memoize from 'memoize-one'
import * as React from 'react'

import Fetcher from '../../../components/Fetcher'
import Margin from '../../../components/Margin'
import UserContext from '../../../contexts/UserContext'
import { t } from '../../../i18n'
import OfferDisplayPreference from '../../../models/OfferDisplayPreference'
import OfferDisplaySetting from '../../../models/OfferDisplaySetting'
import campaignService from '../../../services/campaignService'
import customerSettingsService from '../../../services/customerSettingsService'
import customerStockService from '../../../services/customerStockService'
import executionModeService from '../../../services/executionModeService'
import productService from '../../../services/productService'

import CustomerOffersCard from './CustomerOffersCard'
import CustomerStocksCard from './CustomerStocksCard'
import TargetOrdersInProgress from '../../../components/TargetOrdersInProgress'
import targetOrderService, {
  TargetOrderView,
} from '../../../services/targetOrderService'
import strikeService from '../../../services/strikeService'

class CustomerHome extends React.Component<{}> {
  hasProductToWatch = memoize(
    (settings: OfferDisplaySetting[]): boolean =>
      settings.filter(
        (s: OfferDisplaySetting) =>
          s.preference === OfferDisplayPreference.Watch
      ).length > 0
  )

  fetchTargetOrder = () => {
    return targetOrderService.findAll(['CREATED'])
  }

  renderTargetOrdersInProgress = (
    targetOrders: TargetOrderView[],
    refresh: () => void
  ) => {
    return (
      <TargetOrdersInProgress
        targetOrders={targetOrders}
        refresh={refresh}
        currentUserIsCustomer={true}
      />
    )
  }

  render() {
    return (
      <UserContext.Consumer>
        {userContext => (
          <Fetcher
            fetch={[
              productService.findAll,
              campaignService.findAllActive,
              executionModeService.findActives,
              strikeService.findAll,
              customerStockService.findAll,
              customerSettingsService.findAll,
            ]}
          >
            {([
              products,
              campaigns,
              executionModes,
              strikes,
              customerStocks,
              settings,
            ]) => {
              return (
                <div data-cy="home">
                  <Margin bottom={2}>
                    <Typography variant="h5">
                      {t('Vos offres personnalisées')}
                    </Typography>
                  </Margin>
                  <Margin bottom={2}>
                    <CustomerOffersCard
                      products={products}
                      customerSector={userContext.user!.sector!}
                      campaigns={campaigns}
                      executionModes={executionModes}
                      strikes={strikes}
                      settings={settings}
                      fromCustomer={true}
                      offerDisplayPreference={OfferDisplayPreference.Sell}
                    />
                  </Margin>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <CustomerStocksCard
                        campaigns={campaigns}
                        customerStocks={customerStocks}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Fetcher fetch={this.fetchTargetOrder}>
                        {this.renderTargetOrdersInProgress}
                      </Fetcher>
                    </Grid>
                  </Grid>
                  {this.hasProductToWatch(settings) && (
                    <>
                      <Margin top={3} bottom={2}>
                        <Typography variant="h5">
                          {t('Les produits à garder à l’oeil')}
                        </Typography>
                      </Margin>
                      <CustomerOffersCard
                        products={products}
                        customerSector={userContext.user!.sector!}
                        campaigns={campaigns}
                        executionModes={executionModes}
                        strikes={strikes}
                        settings={settings}
                        fromCustomer={true}
                        offerDisplayPreference={OfferDisplayPreference.Watch}
                      />
                    </>
                  )}
                </div>
              )
            }}
          </Fetcher>
        )}
      </UserContext.Consumer>
    )
  }
}

export default CustomerHome
