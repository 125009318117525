import { FormControlLabel } from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import Card from '@material-ui/core/Card/Card'
import CardContent from '@material-ui/core/CardContent/CardContent'
import MuiCheckbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider/Divider'
import Fade from '@material-ui/core/Fade'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import { Theme, WithStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import Typography from '@material-ui/core/Typography'
import { capitalize } from '@material-ui/core/utils/helpers'
import Grid from '@material-ui/core/Grid'
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik'
import memoizeOne from 'memoize-one'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import WarningIcon from '@material-ui/icons/Warning'
import Calendar from '../../../components/Calendar/Calendar'
import CalendarFormLines from '../../../components/Calendar/CalendarFormLines'
import CalendarHeader from '../../../components/Calendar/CalendarHeader'
import Error from '../../../components/Error'
import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import { CheckboxWithLabel } from '../../../components/Form/CheckboxWithLabel'
import { DownshiftMultiple } from '../../../components/Form/DownshiftMultiple'
import FieldError from '../../../components/Form/FieldError'
import { Select } from '../../../components/Form/Select'
import { Switch } from '../../../components/Form/Switch'
import TextField from '../../../components/Form/TextField'
import Loading from '../../../components/Loading'
import Margin from '../../../components/Margin'
import { t } from '../../../i18n'
import { format } from '../../../i18n/format'
import Campaign from '../../../models/Campaign'
import DeliverySite from '../../../models/DeliverySite'
import ExecutionMode from '../../../models/ExecutionMode'
import Instrument from '../../../models/Instrument'
import MajorationTable from '../../../models/MajorationTable'
import Product from '../../../models/Product'
import Sector from '../../../models/Sector'
import UserTag from '../../../models/UserTag'
import campaignService from '../../../services/campaignService'
import deliverySiteService from '../../../services/deliverySiteService'
import executionModeService from '../../../services/executionModeService'
import instrumentService from '../../../services/instrumentService'
import majorationTableService from '../../../services/majorationTableService'
import offerService from '../../../services/offerService'
import * as productService from '../../../services/productService'
import * as sectorService from '../../../services/sectorService'
import userTagsService from '../../../services/userTagsService'
import { Effect, EffectState } from '../../../utils/Effect'
import getCurrentCampaign from '../../../utils/getCurrentCampaign'

import FormValues, { getDefaultFormValues } from './FormValues'
import MajorationTableDialog from './MajorationTableDialog'
import NewOfferStep1ValidationSchema from './NewOfferStep1ValidationSchema'

const styles = (theme: Theme) =>
  createStyles({
    card: {
      marginTop: 15,
      marginBottom: 15,
    },
    formControl: {
      marginRight: theme.spacing(8),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 270,
    },
    tooltip: { margin: '0 0 -8px 0' },
    datePicker: {
      maxWidth: 270,
    },
    colorSwitchBase: {
      color: theme.palette.primary.main,
    },
    colorSwitchBar: {
      backgroundColor: theme.palette.primary.light,
    },
    input: {
      maxWidth: 270,
    },
    iconButton: {
      margin: theme.spacing(1),
      position: 'absolute',
      right: -64,
      top: 18,
    },
  })

interface Props extends WithStyles<typeof styles>, RouteComponentProps {}

interface State {
  instrumentsRef: Instrument[]
  sectorsRef: Sector[]
  majorationTables: MajorationTable[]
  majorationTableDialogVisibility: boolean
  isFetchingLastPublishedForCampaignAndProduct: boolean
}

export type FetchedValues = [
  Sector[],
  Instrument[],
  Campaign[],
  Product[],
  DeliverySite[],
  UserTag[],
  ExecutionMode[]
]

type SetFieldValue = FormikProps<FormValues>['setFieldValue']

class NewOfferStep1 extends React.Component<Props, State> {
  state: State = {
    instrumentsRef: [],
    sectorsRef: [],
    majorationTables: [],
    majorationTableDialogVisibility: false,
    isFetchingLastPublishedForCampaignAndProduct: false,
  }

  getInitialFormValues: (
    fetchedValues: FetchedValues
  ) => FormValues = memoizeOne((fetchedValues: FetchedValues) => {
    if (this.props.location.state && this.props.location.state.formOffer) {
      const formOffer = this.props.location.state.formOffer
      this.fetchMajorationTables(formOffer.campaign.id)
      return formOffer
    }
    const currentCampaign = getCurrentCampaign(fetchedValues[2])
    if (currentCampaign === undefined) {
      return {}
    }
    this.fetchMajorationTables(currentCampaign.id)
    return getDefaultFormValues(currentCampaign)
  })

  fillFromSimilarOffer = async (nextFormikState: EffectState<FormValues>) => {
    const { values, touched, setFieldValue } = nextFormikState
    const { campaign, product } = values

    if (!campaign || !product) {
      return
    }

    this.setState({ isFetchingLastPublishedForCampaignAndProduct: true })
    const {
      data: offer,
    } = await offerService.getLastPublishedForCampaignAndProduct(
      campaign,
      product
    )

    if (!touched.priceToDestination) {
      if (!offer || !offer.priceToDestination) {
        setFieldValue('priceToDestination', '0')
      } else {
        setFieldValue('priceToDestination', offer.priceToDestination)
      }
    }

    if (!touched.collectFee) {
      if (!offer || !offer.collectFee) {
        setFieldValue('collectFee', '0')
      } else {
        setFieldValue('collectFee', offer.collectFee)
      }
    }

    if (!touched.oilPercent) {
      if (!offer || !offer.oilPercent) {
        setFieldValue('oilPercent', '0')
      } else {
        setFieldValue('oilPercent', offer.oilPercent)
      }
    }
    this.setState({ isFetchingLastPublishedForCampaignAndProduct: false })
  }

  onFetcherChange = ([sectorsRef, instrumentsRef]: [
    Sector[],
    Instrument[]
  ]) => {
    this.setState({ sectorsRef, instrumentsRef })
  }

  fetchMajorationTables = async (campaignId: number) => {
    this.setState({ majorationTables: [] })
    const {
      data: majorationTables,
    } = await majorationTableService.findAllByCampaign(campaignId)
    this.setState({ majorationTables })
  }

  toggleMajorationTableDialog = () => {
    this.setState(({ majorationTableDialogVisibility }) => ({
      majorationTableDialogVisibility: !majorationTableDialogVisibility,
    }))
  }

  saveNewMajorationTable = (setFieldValue: SetFieldValue) => async (
    newMajorationTable: MajorationTable
  ) => {
    const res = await majorationTableService.create(newMajorationTable)

    setFieldValue('majorationTable', res.data as MajorationTable)
    this.setState({
      majorationTables: [...this.state.majorationTables, res.data],
    })
    this.toggleMajorationTableDialog()
  }

  getInstrumentFromMarketAndMaturity = (market?: string, maturity?: string) => {
    const { instrumentsRef } = this.state
    if (!instrumentsRef || !market || !maturity) {
      return undefined
    }

    return instrumentsRef[market].find(
      (instrument: Instrument) =>
        format(instrument.maturity, 'yyyy-MM-dd') === maturity
    )
  }

  getSectors = (sectorsRestriction: boolean[]) => {
    const { sectorsRef } = this.state
    return sectorsRestriction.reduce(
      (acc: Sector[], checked, sectorIndex) =>
        checked ? [...acc, sectorsRef[sectorIndex]] : acc,
      []
    )
  }

  removeSectorsRestriction = (setFieldValue: SetFieldValue) => () => {
    setFieldValue('sectorsRestriction', [])
  }

  onNext = (
    values: FormValues,
    { setSubmitting }: FormikActions<FormValues>
  ) => {
    const { history } = this.props
    setSubmitting(false)
    const instrument = this.getInstrumentFromMarketAndMaturity(
      values.market,
      values.maturity
    )
    const instrumentPMG = this.getInstrumentFromMarketAndMaturity(
      values.marketPMG,
      values.maturityPMG
    )
    const sectors = this.getSectors(values.sectorsRestriction)
    const formOffer = { ...values, instrument, instrumentPMG, sectors }
    history.push({
      pathname: '/home/new-offer/step2',
      state: { formOffer },
    })
  }

  // only side effect changes here
  onFormikStateChange = async (
    currentFormikState: EffectState<FormValues>,
    nextFormikState: EffectState<FormValues>
  ) => {
    if (!nextFormikState || !currentFormikState) {
      return
    }

    if (
      nextFormikState.values.campaign &&
      nextFormikState.values.product &&
      (currentFormikState.values.campaign !== nextFormikState.values.campaign ||
        currentFormikState.values.product !== nextFormikState.values.product)
    ) {
      this.fillFromSimilarOffer(nextFormikState)
    }

    if (
      nextFormikState.values.campaign &&
      currentFormikState.values.campaign !== nextFormikState.values.campaign
    ) {
      nextFormikState.setFieldValue('majorationTable', undefined)
      nextFormikState.setFieldValue('executionDetails', [])
      this.fetchMajorationTables(nextFormikState.values.campaign.id)
    }

    if (
      nextFormikState.values.market &&
      currentFormikState.values.market !== nextFormikState.values.market
    ) {
      nextFormikState.setFieldValue('maturity', undefined)
    }

    if (
      nextFormikState.values.type &&
      currentFormikState.values.type !== nextFormikState.values.type
    ) {
      nextFormikState.setFieldValue('market', undefined)
      nextFormikState.setFieldValue('maturity', undefined)
    }

    if (
      nextFormikState.values.type === 'FIXED' &&
      nextFormikState.values.type !== currentFormikState.values.type &&
      currentFormikState.values.modePMG
    ) {
      nextFormikState.setFieldValue('modePMG', false)
      nextFormikState.setFieldValue('marketPMG', undefined)
      nextFormikState.setFieldValue('maturityPMG', undefined)
      nextFormikState.setFieldValue('optionFee', undefined)
      nextFormikState.setFieldValue('contractTemplates', undefined)
    }
    if (currentFormikState.values.modePMG && !nextFormikState.values.modePMG) {
      nextFormikState.setFieldValue('marketPMG', undefined)
      nextFormikState.setFieldValue('maturityPMG', undefined)
      nextFormikState.setFieldValue('optionFee', undefined)
      nextFormikState.setFieldValue('contractTemplates', undefined)
    }
  }

  renderForm = ([
    sectors,
    instruments,
    campaigns,
    products,
    deliverySites,
    userTags,
    executionModes,
  ]: FetchedValues) => ({
    values,
    touched,
    errors,
    isSubmitting,
    setFieldValue,
  }: FormikProps<FormValues>) => {
    const { classes } = this.props
    const { majorationTables } = this.state
    return (
      <Form>
        {(!campaigns || campaigns.length === 0) && (
          <Grid container direction="row" alignItems="center" justify="center">
            <WarningIcon color="error" />
            <Typography variant="h5" color="error">
              {t("Vous devez d'abord créer une campagne")}
            </Typography>
          </Grid>
        )}
        {(!products || products.length === 0) && (
          <Grid container direction="row" alignItems="center" justify="center">
            <WarningIcon color="error" />
            <Typography variant="h5" color="error">
              {t("Vous devez d'abord créer un produit")}
            </Typography>
          </Grid>
        )}
        {(!deliverySites || deliverySites.length === 0) && (
          <Grid container direction="row" alignItems="center" justify="center">
            <WarningIcon color="error" />
            <Typography variant="h5" color="error">
              {t("Vous devez d'abord créer un lieu de destination")}
            </Typography>
          </Grid>
        )}
        <Effect onChange={this.onFormikStateChange} />
        <Margin bottom={1}>
          <Typography variant="h6">{t('Production concernée')}</Typography>
        </Margin>
        <FormControl className={classes.formControl}>
          <Field
            component={Select}
            label={t('Campagne')}
            name="campaign"
            type="object"
            options={campaigns}
          >
            {campaigns.map((campaign: Campaign) => (
              <MenuItem key={campaign.id} value={campaign.id}>
                {campaign.name}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Field
            component={Select}
            label={t('Produit')}
            name="product"
            type="object"
            options={products}
          >
            {products.map((product: Product) => (
              <MenuItem key={product.id} value={product.id}>
                {capitalize(product.name)}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
        <Fade in={values.product && values.product.oleaginous}>
          <FormControl className={classes.formControl}>
            <Field
              component={TextField}
              name="oilPercent"
              label={t('Teneur en huile')}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              disabled={this.state.isFetchingLastPublishedForCampaignAndProduct}
              progressBarEnabled={
                this.state.isFetchingLastPublishedForCampaignAndProduct
              }
            />
          </FormControl>
        </Fade>
        <Margin top={1}>
          <Typography variant="h6">{t('Lieu de destination')}</Typography>
          <Typography variant="caption" display="block">
            {t('Cette information ne sera pas visible par le client.')}
          </Typography>
        </Margin>
        <FormControl className={classes.formControl}>
          <Field
            component={Select}
            name="deliverySite"
            type="object"
            options={deliverySites}
          >
            {deliverySites.map((deliverySite: DeliverySite) => (
              <MenuItem key={deliverySite.id} value={deliverySite.id}>
                {capitalize(deliverySite.name)}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
        <Margin top={4} bottom={2}>
          <Divider />
        </Margin>
        <Margin bottom={1}>
          <Typography variant="h6">{t('Clients concernés')}</Typography>
        </Margin>
        <FormControl component={'fieldset' as 'div'}>
          <Margin bottom={2}>
            <FormLabel component={'legend' as 'label'}>
              {t('Secteurs concernés par l’offre')}
            </FormLabel>
          </Margin>

          <FormGroup row>
            {sectors.map((sector: Sector, idx: number) => (
              <Tooltip
                key={sector.id}
                title={sector.name}
                placement="bottom"
                classes={{ tooltipPlacementTop: classes.tooltip }}
              >
                <div>
                  <Field
                    name={`sectorsRestriction.${idx}`}
                    component={CheckboxWithLabel}
                    color="primary"
                    label={{
                      labelPlacement: 'top',
                      label: t(String(sector.externalId)),
                    }}
                    style={{ paddingTop: 0 }}
                  />
                </div>
              </Tooltip>
            ))}
            <Tooltip
              title={t('Tous les secteurs')}
              placement="bottom"
              classes={{ tooltipPlacementTop: classes.tooltip }}
            >
              <FormControlLabel
                control={
                  <MuiCheckbox
                    color="primary"
                    checked={
                      values.sectorsRestriction
                        ? values.sectorsRestriction.filter(active => active)
                            .length === 0
                        : false
                    }
                    onChange={this.removeSectorsRestriction(setFieldValue)}
                    style={{ paddingTop: 0 }}
                  />
                }
                labelPlacement={'top'}
                label={t('Tous')}
              />
            </Tooltip>
          </FormGroup>
          <FieldError name="sectorsRestriction" />
        </FormControl>
        <Margin top={1} bottom={2}>
          <Flex>
            <FormControl
              component={'fieldset' as 'div'}
              className={classes.formControl}
            >
              <Margin bottom={2}>
                <FormLabel component={'legend' as 'label'}>
                  {t('Catégorie de client (optionnel)')}
                </FormLabel>
              </Margin>
              <Field
                name="userTagsRestriction"
                suggestions={userTags}
                suggestionLabel="name"
                placeholder={t('Ajouter une catégorie')}
                component={DownshiftMultiple}
              />
            </FormControl>
          </Flex>
        </Margin>
        <Margin top={4} bottom={2}>
          <Divider />
        </Margin>
        <Margin bottom={2}>
          <Typography variant="h6">
            {t('Modalités et périodes d’exécution')}
          </Typography>
          <Typography variant="caption" display="block">
            {t(
              'Pour les modalités désirées, sélectionnez les périodes d’exécution possibles pour le client.'
            )}
          </Typography>
        </Margin>
        {values.campaign ? (
          <>
            <Calendar
              error={!!(touched.executionDetails && errors.executionDetails)}
              campaign={values.campaign}
            >
              <CalendarHeader />
              <Field
                component={CalendarFormLines}
                name="executionDetails"
                color={values.color}
                executionModes={executionModes}
              />
            </Calendar>
            <FieldError name="executionDetails" />
          </>
        ) : (
          <div />
        )}

        <Margin top={4} bottom={2}>
          <Divider />
        </Margin>
        <Margin bottom={1}>
          <Typography variant="h6">{t('Type d’offre')}</Typography>
        </Margin>
        <Margin bottom={2}>
          <Flex direction="row" alignItems="center">
            <Typography
              variant="subtitle2"
              color={values.type === 'INDEXED' ? 'textSecondary' : 'primary'}
            >
              {t('Fixe')}
            </Typography>
            <Field
              name="type"
              component={Switch}
              label={{ label: t('Fixe') }}
              color="primary"
              classes={{
                switchBase: classes.colorSwitchBase,
                track: classes.colorSwitchBar,
              }}
              true={'INDEXED'}
              false={'FIXED'}
            />
            <Typography
              variant="subtitle2"
              color={values.type === 'INDEXED' ? 'primary' : 'textSecondary'}
            >
              {t('Indexée')}
            </Typography>
          </Flex>
        </Margin>
        {values.type === 'INDEXED' && instruments && (
          <Margin bottom={2}>
            <Flex direction="row">
              <FormControl className={classes.formControl}>
                <Field
                  component={Select}
                  name="market"
                  label={t('Marché Euronext')}
                >
                  {Object.keys(instruments).map((market: string, idx) => (
                    <MenuItem key={idx} value={market}>
                      {capitalize(market)}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>

              <div>
                <FormControl className={classes.formControl}>
                  <Field
                    component={Select}
                    name="maturity"
                    label={t('Echéance du marché')}
                    disabled={!values.market}
                  >
                    {values.market &&
                      instruments[values.market].map(
                        (instrument: Instrument, idx: number) => (
                          <MenuItem
                            key={idx}
                            value={format(instrument.maturity, 'yyyy-MM-dd')}
                          >
                            {capitalize(
                              format(instrument.maturity, 'MMMM yyyy')
                            )}
                          </MenuItem>
                        )
                      )}
                  </Field>
                </FormControl>
                {!values.market && (
                  <FormHelperText>
                    {t(
                      `Veuillez sélectionner un marché Euronext pour afficher les échéance du marché.`
                    )}
                  </FormHelperText>
                )}
              </div>
            </Flex>
          </Margin>
        )}
        {values.type === 'INDEXED' && (
          <>
            <Margin bottom={2}>
              <Flex direction="row">
                <FormControl className={classes.formControl}>
                  <Field
                    component={TextField}
                    name="destinationFee"
                    label={t('Prime')}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Flex>
            </Margin>
          </>
        )}
        {values.type === 'FIXED' && (
          <Margin bottom={2}>
            <Flex direction="row">
              <FormControl className={classes.formControl}>
                <Field
                  component={TextField}
                  name="priceToDestination"
                  label={t('Prix')}
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  disabled={
                    this.state.isFetchingLastPublishedForCampaignAndProduct
                  }
                  progressBarEnabled={
                    this.state.isFetchingLastPublishedForCampaignAndProduct
                  }
                />
              </FormControl>
            </Flex>
          </Margin>
        )}
        <Margin bottom={2}>
          <Flex direction="row">
            <div>
              <FormControl className={classes.formControl}>
                <Field
                  component={Select}
                  name="majorationTable"
                  label={t('Table de majoration')}
                  type="object"
                  options={majorationTables}
                  disabled={majorationTables.length === 0}
                >
                  {majorationTables.map((majorationTable: MajorationTable) => (
                    <MenuItem
                      key={majorationTable.id}
                      value={majorationTable.id}
                    >
                      {capitalize(majorationTable.name)}
                    </MenuItem>
                  ))}
                </Field>

                {values.majorationTable && (
                  <Tooltip title={t('Editer')} placement="top">
                    <IconButton
                      color="primary"
                      className={classes.iconButton}
                      onClick={this.toggleMajorationTableDialog}
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  </Tooltip>
                )}
              </FormControl>
              {majorationTables.length === 0 && (
                <FormHelperText>
                  {t(
                    `Veuillez sélectionner une campagne pour afficher les tables de majorations.`
                  )}
                </FormHelperText>
              )}
            </div>

            {values.majorationTable && (
              <FormControl className={classes.formControl}>
                <Field
                  component={Select}
                  name="majorationMonth"
                  label={t('Mois de référence de majoration')}
                >
                  {values.majorationTable.majorationValues.map(
                    ({ startDate }, idx) => (
                      <MenuItem key={idx} value={startDate}>
                        {capitalize(format(startDate, 'MMMM yyyy'))}
                      </MenuItem>
                    )
                  )}
                </Field>
              </FormControl>
            )}
          </Flex>
        </Margin>
        <Margin bottom={2}>
          <Flex direction="row">
            <FormControl className={classes.formControl}>
              <Field
                component={TextField}
                name="collectFee"
                label={t('Marge de collecte')}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
                disabled={
                  this.state.isFetchingLastPublishedForCampaignAndProduct
                }
                progressBarEnabled={
                  this.state.isFetchingLastPublishedForCampaignAndProduct
                }
              />
            </FormControl>
          </Flex>
        </Margin>
        <Margin bottom={2}>
          <Flex direction="row">
            <FormControl className={classes.formControl}>
              <Field
                component={TextField}
                className={classes.input}
                name="maneuver"
                label={t('Manoeuvre commerciale (optionel)')}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
              <FormHelperText>
                {t(
                  'Définissez la marge de négocation donnée à vos commerciaux. Exemple : + ou - 2€ par rapport au prix affiché.'
                )}
              </FormHelperText>
              {values.maneuver && (
                <FormHelperText>
                  {t(
                    `Vos commerciaux auront une manoeuvre de négocation de ${values.maneuver}€ par rapport au prix affiché.`
                  )}
                </FormHelperText>
              )}
            </FormControl>
          </Flex>
        </Margin>
        {values.type === 'INDEXED' && (
          <Margin bottom={2}>
            <Typography>{t('Mode PMG')}</Typography>
            <Flex direction="row" alignItems="center">
              <Typography
                variant="subtitle2"
                color={values.type === 'INDEXED' ? 'textSecondary' : 'primary'}
              >
                {t('Non')}
              </Typography>
              <Field
                name="modePMG"
                component={Switch}
                color="primary"
                classes={{
                  switchBase: classes.colorSwitchBase,
                  track: classes.colorSwitchBar,
                }}
                true={true}
                false={false}
              />
              <Typography
                variant="subtitle2"
                color={values.type === 'INDEXED' ? 'primary' : 'textSecondary'}
              >
                {t('Oui')}
              </Typography>
            </Flex>
          </Margin>
        )}
        {values.modePMG && (
          <>
            <Margin bottom={2}>
              <Flex direction="row">
                <FormControl className={classes.formControl}>
                  <Field
                    component={Select}
                    name="marketPMG"
                    label={t("Marché de l'option")}
                  >
                    {Object.keys(instruments)
                      .filter(market =>
                        instruments[market].some(
                          (instrument: Instrument) => instrument.usableForPMG
                        )
                      )
                      .map((market, index) => (
                        <MenuItem value={market} key={index}>
                          {capitalize(market)}
                        </MenuItem>
                      ))}
                  </Field>
                </FormControl>

                <div>
                  <FormControl className={classes.formControl}>
                    <Field
                      component={Select}
                      name="maturityPMG"
                      label={t("Echéance de l'option")}
                      disabled={!values.marketPMG}
                    >
                      {values.marketPMG &&
                        instruments[values.marketPMG] &&
                        instruments[values.marketPMG].map(
                          (instrument: Instrument, idx: number) => (
                            <MenuItem
                              key={idx}
                              value={format(instrument.maturity, 'yyyy-MM-dd')}
                            >
                              {capitalize(
                                format(instrument.maturity, 'MMMM yyyy')
                              )}
                            </MenuItem>
                          )
                        )}
                    </Field>
                  </FormControl>
                  {!values.marketPMG && (
                    <FormHelperText>
                      {t(
                        `Veuillez sélectionner un marché de l'option pour afficher les échéances.`
                      )}
                    </FormHelperText>
                  )}
                </div>
              </Flex>
            </Margin>
            <Margin bottom={2}>
              <Flex direction="row">
                <FormControl className={classes.formControl}>
                  <Field
                    component={TextField}
                    name="optionFee"
                    label={t('Marge sur option')}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Flex>
            </Margin>
          </>
        )}

        <Margin top={4}>
          <Flex alignItems="center">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
            >
              {t('Suivant')}
            </Button>
          </Flex>
        </Margin>

        <MajorationTableDialog
          majorationTable={values.majorationTable!}
          open={this.state.majorationTableDialogVisibility}
          onClose={this.toggleMajorationTableDialog}
          onSave={this.saveNewMajorationTable(setFieldValue)}
        />
      </Form>
    )
  }

  renderFormik = (fetchedValues: FetchedValues) => (
    <Formik
      initialValues={this.getInitialFormValues(fetchedValues)}
      onSubmit={this.onNext}
      validationSchema={NewOfferStep1ValidationSchema}
      render={this.renderForm(fetchedValues)}
    />
  )

  render() {
    const { classes } = this.props
    return (
      <>
        <Typography variant="h5">{t('Nouvelle offre')}</Typography>
        <Card className={classes.card}>
          <CardContent>
            <Fetcher
              fetch={[
                sectorService.findAllActives,
                instrumentService.findAll,
                campaignService.findAllActive,
                productService.findAll,
                deliverySiteService.findAllActives,
                userTagsService.findAll,
                executionModeService.findActives,
              ]}
              onChange={this.onFetcherChange}
              loadingRender={Loading}
              errorRender={Error}
            >
              {this.renderFormik}
            </Fetcher>
          </CardContent>
        </Card>
      </>
    )
  }
}

export default withStyles(styles)(NewOfferStep1)
