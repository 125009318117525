import * as Yup from 'yup'

import { t } from '../../../i18n'

export default Yup.object().shape({
  storageSettings: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required(),
      product: Yup.object().required(),
      enabled: Yup.boolean().required(),
      dailyFees: Yup.number().when('enabled', {
        is: true,
        then: Yup.number()
          .min(0, t('Les frais doivent être un nombre positif'))
          .required(),
      }),
      inputFees: Yup.number().when('enabled', {
        is: true,
        then: Yup.number()
          .min(0, t('Les frais doivent être un nombre positif'))
          .required(),
      }),
      startDateDay: Yup.string().when('enabled', {
        is: true,
        then: Yup.string().required(),
      }),
      startDateMonth: Yup.string().when('enabled', {
        is: true,
        then: Yup.string().required(),
      }),
      endDateDay: Yup.string().when('enabled', {
        is: true,
        then: Yup.string().required(),
      }),
      endDateMonth: Yup.string().when('enabled', {
        is: true,
        then: Yup.string().required(),
      }),
      ceiling: Yup.number().when('enabled', {
        is: true,
        then: Yup.number()
          .min(0, t('Le plafond doit être un nombre positif'))
          .required(),
      }),
    })
  ),
})
