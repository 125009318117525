import * as React from 'react'

import {
  Button,
  Divider,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core'

import Fetcher from '../../../components/Fetcher'
import Flex from '../../../components/Flex'
import TraderSettingsConfigCard from './TraderSettingsConfigCard'
import UploadFileButton from '../../../components/UploadFileButton'
import adviceService from '../../../services/adviceService'
import stockContributionService from '../../../services/stockContributionService'
import { t } from '../../../i18n'
import theme from '../../../theme'

const styles = () =>
  createStyles({
    cardContent: {
      paddingRight: 15,
      width: '100%',
    },
    divider: {
      margin: theme.spacing.unit * 2,
    },
  })

interface Props extends WithStyles<typeof styles> {}

class ContributionsSettings extends React.Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <TraderSettingsConfigCard
        cardTitle={t('Vos apports')}
        adviceMessage={
          <>
            <Fetcher
              fetch={adviceService.findOne}
              fetchProps={['CONTRIBUTION']}
            >
              {advice => (
                <div dangerouslySetInnerHTML={{ __html: advice.template }} />
              )}
            </Fetcher>
            <Divider variant="middle" className={classes.divider} />
            {t("Format du template d'import à respecter : ")}
            <br />
            <br />
            <Button
              href={`${process.env.PUBLIC_URL}/examples/import_stock_contributions.csv`}
            >
              {t('Exemple de fichier')}
            </Button>
          </>
        }
      >
        <Flex
          justify="center"
          alignItems="center"
          className={classes.cardContent}
        >
          <UploadFileButton
            size="large"
            action={stockContributionService.importCsv}
          />
        </Flex>
      </TraderSettingsConfigCard>
    )
  }
}

export default withStyles(styles)(ContributionsSettings)
