import { AxiosError } from 'axios'
import * as React from 'react'

import { t } from '../i18n'

import CardError from './CardError'
import CardLoading from './CardLoading'

type FetchFunction = (...props: any[]) => Promise<any>
type FetchedValue = any | any[]

export interface ErrorRenderProps {
  refresh: () => void
  children: string
  error: AxiosError
}

type Props = {
  fetch: FetchFunction | FetchFunction[]
  children: (
    value: FetchedValue,
    refresh: () => void,
    lastUpdated: Date
  ) => React.ReactElement<any>
  fetchProps?: any[] | any[][]
  loadingRender?: React.ComponentType<any>
  errorRender?: React.ComponentType<ErrorRenderProps>
  onChange?: (value: FetchedValue) => void
}

type State = {
  loaded: boolean
  error?: AxiosError
  value?: FetchedValue
  lastUpdated?: Date
}

class Fetcher extends React.Component<Props, State> {
  readonly state = {
    loaded: false,
    error: undefined,
    value: undefined,
    lastUpdated: undefined,
  }

  _isMounted = false

  async componentDidMount() {
    this._isMounted = true
    await this.fetch()
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.fetchProps !== this.props.fetchProps) {
      this.setState({ loaded: false, error: undefined })
      await this.fetch()
    }
  }

  async componentWillUnmount() {
    this._isMounted = false
  }

  fetch = async () => {
    const { fetch, fetchProps, onChange } = this.props
    try {
      if (fetch instanceof Array) {
        const values = await Promise.all(
          fetch.map((f, index) => f(...(fetchProps ? fetchProps[index] : [])))
        )
        const value = values.map(v => v.data)
        this._isMounted &&
          this.setState({ value, loaded: true, lastUpdated: new Date() })
        if (onChange) {
          onChange(value)
        }
      } else {
        const { data: value } = await fetch(...(fetchProps || []))
        this._isMounted &&
          this.setState({ value, loaded: true, lastUpdated: new Date() })
        if (onChange) {
          onChange(value)
        }
      }
    } catch (e) {
      if (e.response) {
        this._isMounted && this.setState({ loaded: true, error: e })
      } else {
        this._isMounted && this.setState({ loaded: true, error: undefined })
      }
    }
  }

  refresh = async () => {
    this.setState({ loaded: false, error: undefined })
    await this.fetch()
  }

  render() {
    const {
      children,
      loadingRender: CustomLoading,
      errorRender: CustomError,
    } = this.props
    const { loaded, error, value, lastUpdated } = this.state

    if (!loaded) {
      return CustomLoading ? <CustomLoading /> : <CardLoading />
    } else if (error || !value) {
      return CustomError && error ? (
        <CustomError refresh={this.refresh} error={error!}>
          {t('Erreur : Veuillez réessayer.')}
        </CustomError>
      ) : (
        <CardError onRefreshClicked={this.refresh}>
          {t('Erreur : Veuillez réessayer.')}
        </CardError>
      )
    }
    return children(value, this.refresh, lastUpdated!)
  }
}

export default Fetcher
