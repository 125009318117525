import * as React from 'react'

import { EExecutionMode } from '../models/ExecutionMode'
import Role from '../models/Role'
import Sector from '../models/Sector'
import UserTag from '../models/UserTag'

export interface User {
  id?: number
  username?: string
  firstname?: string
  lastname?: string
  socialReason?: string
  contact?: string
  email?: string
  delegatedSalesman?: User
  traderMailContact?: string
  phone?: string
  mobile?: string
  address?: string
  address2?: string
  postalCode?: string
  city?: string
  login?: string
  externalId?: string
  roles: Role[]
  sector?: Sector
  enabled: boolean
  tags: UserTag[]
  executionModes: EExecutionMode[]
  bushelDepartureBonus?: number
}

export interface UserContextType {
  user?: User
  set: (newUser: User) => void
  hasRole: (role: Role) => boolean
  hasAnyRole: (...roles: Role[]) => boolean
  logoLastModifiedAt?: number
  refreshLogo: () => void
}

const UserContext = React.createContext<UserContextType>({
  set: () => {},
  hasRole: () => false,
  hasAnyRole: () => false,
  refreshLogo: () => {},
})

type Props = {
  children: React.ReactNode
}

export function UserContextProvider({ children }: Props) {
  const [user, setUser] = React.useState<Optional<User>>(() => {
    const user = localStorage.getItem('user') || undefined
    return user ? JSON.parse(user) : undefined
  })

  const [logoLastModifiedAt, setLogoLastModifiedAt] = React.useState<number>()

  const set = React.useCallback(
    (newUser: User) => {
      setUser(newUser)
      setLogoLastModifiedAt(Date.now())
      if (newUser) {
        localStorage.setItem('user', JSON.stringify(newUser))
      } else {
        localStorage.removeItem('user')
      }
    },
    [setUser, setLogoLastModifiedAt]
  )

  const hasRole = React.useCallback(
    (role: Role) => {
      return !!user && user.roles.includes(role)
    },
    [user]
  )

  const hasAnyRole = React.useCallback(
    (...roles: Role[]) => {
      return !!user && user.roles.some(value => roles.includes(value))
    },
    [user]
  )

  const refreshLogo = React.useCallback(() => {
    setLogoLastModifiedAt(Date.now())
  }, [setLogoLastModifiedAt])

  const context = React.useMemo(
    () => ({
      user,
      set,
      hasRole,
      hasAnyRole,
      logoLastModifiedAt,
      refreshLogo,
    }),
    [user, set, hasRole, hasAnyRole, logoLastModifiedAt, refreshLogo]
  )

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

export default UserContext
